export default {
  install(Vue) {
    Vue.prototype.$constants = () => {
      return {
        TOTAL_VISIBLE_PAGES: 7,
        PAGE_SIZE: 40,
        PAGE_SIZE_20: 20,
        COLUMN_LIMIT_10: 10,
        COLUMN_LIMIT15: 15,
        COLUMN_LIMIT20: 20,
        COLUMN_LIMIT_30: 30,
        COLUMN_LIMIT35: 35,
        COLUMN_LIMIT_50: 50,
        COLUMN_LIMIT100: 100,
        CLEAR_BIT_LOGO: "https://logo.clearbit.com/",
        URL_DOUBLE_SLASH: "//",
        RESEARCH_STATUS_LIST: [
          {
            id: "01",
            type: "QA",
          },
          {
            id: "02",
            type: "Q",
          },
          {
            id: "03",
            type: "QF",
          },
          {
            id: "04",
            type: "NQ",
          },
          {
            id: "05",
            type: "NF",
          },
          {
            id: "06",
            type: "D",
          },
          {
            id: "07",
            type: "Dup",
          },
        ],
        RESEARCH_STATUS_REUSE_LIST: [
          {
            id: "01",
            type: "QR",
          },
          {
            id: "02",
            type: "NQ",
          },
          {
            id: "03",
            type: "NF",
          },
          {
            id: "04",
            type: "D",
          },
          {
            id: "05",
            type: "Dup",
          },
        ],
        USER_ROLES: {
          MANAGER: "manager",
          AGENT: "agent",
          COMPLIANCE: "compliance",
          PROJECT_OWNER: "projectOwner",
        },
        ACCOUNT_TYPE: {
          PARENT: "Parent",
          SUBSIDIARY: "Subsidiary",
          INDEPENDENT: "Independent",
        },
        ACCOUNT_DISPOSITIONS: {
          ACQUIRED_MERGED_SUBSIDIARY: "Acquired/Merged/Subsidiary",
          BANKRUPT_SHUT_DOWN: "Bankrupt/Shut Down",
          WEBSITE_NOT_FOUND: "Website Not Found",
        },
        ACCOUNT_MASTER_REVENUE: [
          "0 - $1M",
          "$1M - $10M",
          "$10M - $50M",
          "$50M - $100M",
          "$100M - $250M",
          "$250M - $500M",
          "$500M - $1B",
          "$1B - $10B",
          "$10B+",
        ],
        ACCOUNT_MASTER_EMPLOYEE_SIZE: [
          "0 - 10",
          "11 - 50",
          "51 - 200",
          "201 - 500",
          "501 - 1,000",
          "1,001 - 5,000",
          "5,001 - 10,000",
          "10,000+",
        ],
        US_STATES: [
          "Alabama",
          "Alaska",
          "Arizona",
          "Arkansas",
          "California",
          "Colorado",
          "Connecticut",
          "Delaware",
          "District of Columbia",
          "Florida",
          "Georgia",
          "Hawaii",
          "Idaho",
          "Illinois",
          "Indiana",
          "Iowa",
          "Kansas",
          "Kentucky",
          "Louisiana",
          "Maine",
          "Maryland",
          "Massachusetts",
          "Michigan",
          "Minnesota",
          "Mississippi",
          "Missouri",
          "Montana",
          "Nebraska",
          "Nevada",
          "New Hampshire",
          "New Jersey",
          "New Mexico",
          "New York",
          "North Carolina",
          "North Dakota",
          "Ohio",
          "Oklahoma",
          "Oregon",
          "Pennsylvania",
          "Rhode Island",
          "South Carolina",
          "South Dakota",
          "Tennessee",
          "Texas",
          "Utah",
          "Vermont",
          "Virginia",
          "Washington",
          "West Virginia",
          "Wisconsin",
          "Wyoming",
          "American Samoa",
          "Canal Zone",
          "Guam",
          "Puerto Rico",
          "Virgin Islands",
        ],
      };
    };
  },
};
