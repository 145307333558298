var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticStyle:{"z-index":"1050"},attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isLink)?_c('a',_vm._g(_vm._b({directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['ctrl', 'alt', 'm']),expression:"['ctrl', 'alt', 'm']",modifiers:{"once":true}}],attrs:{"text":"","color":"primary","dark":""},on:{"shortkey":function($event){return _vm.debounceOnShortKey('spec')}}},'a',attrs,false),on),[_vm._v(" Specification ")]):_c('v-btn',_vm._g(_vm._b({directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['ctrl', 'alt', 'm']),expression:"['ctrl', 'alt', 'm']",modifiers:{"once":true}}],staticClass:"float-right mr-4",attrs:{"color":"primary","depressed":""},on:{"shortkey":function($event){return _vm.debounceOnShortKey('spec')}}},'v-btn',attrs,false),on),[_vm._v(" Specification "),_c('v-icon',{attrs:{"right":""}},[_vm._v("list_alt")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['ctrl', 'alt', 'x']),expression:"['ctrl', 'alt', 'x']",modifiers:{"once":true}}],staticClass:"float-right",attrs:{"icon":"","dark":"","x-large":""},on:{"click":function($event){_vm.dialog = false},"shortkey":function($event){return _vm.debounceOnShortKey('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.projectName)+" - Specifications")])],1),_c('div',{staticClass:"scroller"},_vm._l((_vm.projectSpecs),function(spec,index){return _c('div',{key:spec.id,staticClass:"specs_item",staticStyle:{"background":"rgb(184 220 255 / 10%)"}},[_c('section',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-subheader',[_c('b',[_vm._v("Spec name")]),_c('span',{staticStyle:{"color":"red","font-size":"23px"}},[_vm._v("*")]),_vm._v(": ")])],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"10"}},[_c('p',[_vm._v(" "+_vm._s(spec.name)+" ")])])],1)],1),_c('h4',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.projectSpecs[index] &&
            _vm.projectSpecs[index].accountValueKeys &&
            _vm.projectSpecs[index].accountValueKeys.length
          ),expression:"\n            projectSpecs[index] &&\n            projectSpecs[index].accountValueKeys &&\n            projectSpecs[index].accountValueKeys.length\n          "}],staticClass:"spec_heading read_view_spec"},[_vm._v(" Account Fields ")]),_vm._l((_vm.projectSpecs[index].accountValueKeys),function(valuesKey){return _c('div',{key:valuesKey},[(_vm.projectSpecs[index].newValues[valuesKey])?_c('div',[(_vm.accountFields.includes(valuesKey))?_c('div',[_c('v-row',{staticClass:"read_view_spec"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2"}},[_c('v-subheader',{attrs:{"for":"key"}},[_vm._v(_vm._s(valuesKey)+" :")])],1),_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('p',{attrs:{"for":"value"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.projectSpecs[index].newValues[valuesKey])}},[_vm._v(_vm._s(_vm.projectSpecs[index].newValues[valuesKey]))])])])],1)],1):_vm._e()]):_vm._e()])}),_c('h4',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.projectSpecs[index] &&
            _vm.projectSpecs[index].contactValueKeys &&
            _vm.projectSpecs[index].contactValueKeys.length
          ),expression:"\n            projectSpecs[index] &&\n            projectSpecs[index].contactValueKeys &&\n            projectSpecs[index].contactValueKeys.length\n          "}],staticClass:"spec_heading read_view_spec"},[_vm._v(" Contact Fields ")]),_vm._l((_vm.projectSpecs[index].contactValueKeys),function(valuesKey){return _c('div',{key:valuesKey},[(_vm.projectSpecs[index].newValues[valuesKey])?_c('div',[(_vm.contactFields.includes(valuesKey))?_c('div',[_c('v-row',{staticClass:"read_view_spec"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2"}},[_c('v-subheader',{attrs:{"for":"key"}},[_vm._v(_vm._s(valuesKey)+" :")])],1),_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('p',{attrs:{"for":"value"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.projectSpecs[index].newValues[valuesKey])}},[_vm._v(_vm._s(_vm.projectSpecs[index].newValues[valuesKey]))])])])],1)],1):_vm._e()]):_vm._e()])}),_c('h4',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.projectSpecs[index] &&
            _vm.projectSpecs[index].customValueKeys &&
            _vm.projectSpecs[index].customValueKeys.length
          ),expression:"\n            projectSpecs[index] &&\n            projectSpecs[index].customValueKeys &&\n            projectSpecs[index].customValueKeys.length\n          "}],staticClass:"spec_heading read_view_spec"},[_vm._v(" Custom Fields ")]),_vm._l((_vm.projectSpecs[index].customValueKeys),function(valuesKey){return _c('div',{key:valuesKey},[(_vm.projectSpecs[index].newValues[valuesKey])?_c('div',[(
                !_vm.contactFields.includes(valuesKey) &&
                !_vm.accountFields.includes(valuesKey)
              )?_c('div',[_c('v-row',{staticClass:"read_view_spec"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2"}},[_c('v-subheader',{attrs:{"for":"key"}},[_vm._v(_vm._s(valuesKey)+" :")])],1),_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('p',{attrs:{"for":"value"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.projectSpecs[index].newValues[valuesKey])}},[_vm._v(_vm._s(_vm.projectSpecs[index].newValues[valuesKey]))])])])],1)],1):_vm._e()]):_vm._e()])})],2)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }