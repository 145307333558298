<template>
  <div class="datePicker">
    <div v-if="selectRange">
      <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="convertDateRangeToString"
            label="Choose Date Range"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            @click="newDate = []"
            outlined
            dense
          ></v-text-field>
        </template>

        <v-date-picker v-model="newDate" @change="selectDate" range>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dateMenu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="dateMenu = false"> OK </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <div v-else>
      <v-menu
        ref="menu"
        v-model="dateMenu"
        :close-on-content-click="true"
        :return-value.sync="newDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="selectedDate"
            :label="datePlaceHolder"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            @click:clear="clearDate"
            @click="setDate"
            clearable
            v-on="on"
            outlined
            dense
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="newDate"
          no-title
          scrollable
          :min="todayDate"
          @change="selectDate"
        >
        </v-date-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedDate: String,
    selectedDateRange: Array,
    disablePastDate: Boolean,
    selectRange: Boolean,
    datePlaceHolder: String,
  },
  computed: {
    convertDateRangeToString() {
      return this.selectedDateRange.join(" ~ ");
    },
  },
  data() {
    return {
      newDate: this.selectRange ? this.selectedDateRange : this.selectedDate,
      date: null,
      dateMenu: false,
      todayDate: null,
    };
  },
  methods: {
    selectDate: function () {
      this.$emit("selectDate", this.newDate);
    },
    clearDate: function () {
      this.$emit("selectDate", null);
    },
    setDate: function () {
      if (this.disablePastDate) {
        this.todayDate = new Date().toISOString().slice(0, 10);
      }
    },
  },
};
</script>

<style scoped>
.datePicker {
  width: inherit;
}
</style>
