<template>
  <div>
    <vue-google-autocomplete
      :id="addressId"
      :ref="addressRef"
      classname="address-container"
      placeholder=""
      v-on:placechanged="getAddressData"
      :fields="[
        'address_components',
        'formatted_address',
        'icon',
        'id',
        'name',
        'geometry',
        'place_id',
        'scope',
        'type',
        'url',
      ]"
      types="address"
      :tabindex="tabIndex"
    >
    </vue-google-autocomplete>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { find, includes } from "lodash";

const ADDRESS_COMPONENTS = {
  // locality: {
  //   label: "city",
  //   useProp: "long_name",
  // },
  administrative_area_level_1: {
    label: "state",
    useProp: "short_name",
  },
  country: {
    label: "country",
    useProp: "long_name",
  },
  postal_code: {
    label: "zipCode",
    useProp: "long_name",
  },
};
export default {
  name: "AddressSearch",
  components: { VueGoogleAutocomplete },
  props: {
    addressId: String,
    addressRef: String,
    tabIndex: Number,
  },
  computed: {
    resetGoogleAddress: function () {
      return this.$store.state.resetGoogleAddressSearch;
    },
  },
  watch: {
    resetGoogleAddress: function (value) {
      this.resetAddressSearch(value);
    },
  },
  methods: {
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function (addressData, placeResultData, id) {
      console.log(addressData);
      console.log(id);
      this.$store.dispatch("setResetGoogleAddressSearchValue", false);

      this.$emit(
        "placechanged",
        this.formatAddress(placeResultData.address_components),
        placeResultData,
        this.id
      );
    },
    formatAddress: function (addressComponents) {
      let formatedAddress = {};
      let streetAndCity = {};

      for (let index = 0; index < addressComponents.length; index++) {
        let component = addressComponents[index];
        let addressType = component.types[0];

        if (ADDRESS_COMPONENTS[addressType]) {
          formatedAddress[ADDRESS_COMPONENTS[component.types[0]].label] =
            component[ADDRESS_COMPONENTS[addressType].useProp];
        }
      }
      streetAndCity = this.getStreetAndCity(addressComponents);
      formatedAddress.streetAddress = streetAndCity.streetAddress;
      formatedAddress.city = streetAndCity.city;

      return formatedAddress;
    },
    /**
     * Find Street address and city from address components
     * @param {Array} addressComponents
     *        address components array returned by google maps API
     * @returns {{streetAddress: String, city: String}}
     */
    getStreetAndCity: function getStreetAndCity(addressComponents) {
      let streetAddress = [];
      let city = [];

      for (let index = 0; index < addressComponents.length; index++) {
        // cache array lookup. helps with not looking up the index
        // everytime you need to access it.
        let addressComponent = addressComponents[index];

        // find locality and sublocality component types
        // these are the types we want to use for city
        // we can get various levels of this and we want the largest level
        // hence we want to be able to run the loop to find all of the levels
        // this should work for
        //   "neighborhood",
        //   "locality",
        //   "sublocality",
        //   "sublocality_level_1",
        //   "sublocality_level_2",
        //   "sublocality_level_3",
        //   "sublocality_level_4"
        let hasCityComponents = find(
          addressComponent.types,
          (t) =>
            includes(t, "neighborhood") ||
            includes(t, "locality") ||
            includes(t, "sublocality")
        );

        // find administrative levels
        // should work for
        //   "administrative_area_level_1",
        //   "administrative_area_level_2",
        //   "administrative_area_level_3",
        //   "administrative_area_level_4",
        //   "administrative_area_level_5"
        let hasAdministrativeComponents = find(addressComponent.types, (t) =>
          includes(t, "administrative")
        );

        // quit the loop once an administrative level is found
        // this helps in the case where no locality or sublocality is found
        // as well as quit after city has been found
        // we would want to stop processing street address in such a case
        if (hasAdministrativeComponents) {
          break;
        }

        // when a city component is found
        if (hasCityComponents) {
          city.push(addressComponent.long_name);
          continue;
        }

        // continue adding street components if city or administrative
        // components have not been found
        streetAddress.push(addressComponent.long_name);
      }

      return {
        streetAddress: streetAddress.join(" "),
        city: city.pop() || "", // get the last element of the array or empty string
      };
    },
    resetAddressSearch: function (value) {
      if (value) {
        this.$refs[this.addressRef].clear();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.address-container {
  background: white;
  width: 100%;
  height: 40px;
  padding: 8px 0;
  padding-left: 8px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 3px;
}
</style>
