<template>
  <div class="app">
    <div class="content">
      <div class="header">
        <h1>
          <router-link to="/agent-project-list">Data Project </router-link> >
          <span style="white-space: pre-wrap"> {{ projectName }} </span>
        </h1>

        <v-container fluid class="text-right md-4 py-0">
          <TaskFilter />

          <v-btn
            class="ml-2 white--text"
            color="#8B83BA"
            :loading="isPageLoading"
            :disabled="isPageLoading"
            @click="pageRefresh()"
            >refresh <v-icon>mdi-cached</v-icon></v-btn
          >
        </v-container>
      </div>

      <v-container
        fluid
        class="float-left pl-0 pt-0"
        v-if="Object.keys(filter).length"
      >
        <v-col cols="12" sm="12" class="pb-0">
          <v-card elevation="1" class="px-3">
            <h5
              class="text-overline pl-2"
              style="border-bottom: 1px solid #ddd"
            >
              Applied Filter:
              <span
                data-v-41106904=""
                style="
                  margin-left: 10px;
                  font-family: 'Lato';
                  text-transform: none;
                  color: rgb(32, 95, 172);
                  cursor: pointer;
                  letter-spacing: 0px;
                  font-size: 13px;
                "
                @click="clearAllFilters()"
                >( Clear all )</span
              >
            </h5>
            <v-chip-group>
              <v-chip
                close
                label
                @click:close="removeFilter(key)"
                v-for="(value, key) in filter"
                :key="key"
              >
                {{ filterMapping[key] }}:
                {{ value }}
              </v-chip>
            </v-chip-group>
          </v-card>
        </v-col>
      </v-container>

      <div class="tabs">
        <div class="tab-details">
          <div>
            <AgentTasks ref="AgentTasks" :projectId="$route.params.projectId" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AgentTasks from "./AgentTasks.vue";
import TaskFilter from "./taskListFilters.vue";
export default {
  name: "AgentTaskList",
  components: {
    AgentTasks,
    TaskFilter,
  },
  data() {
    return {
      filterMapping: {
        "account.name": "AccountName",
        "account.website": "Website",
        "account.employeeSize": "EmployeeSize",
        "account.revenue": "Revenue",
        "account.disposition": "Disposition",
        "task.status": "Status",
        "task.priority": "Priority",
        "taskType.type": "Activity",
        "account.qualifiedContacts": "QualifiedContacts",
        "account.potential": "Potential",
      },
      filter: {},
      isPageLoading: false,
    };
  },
  mounted: function () {
    let filter = Object.assign({}, this.taskFilter);
    delete filter.status;
    this.filter = filter;
  },
  computed: {
    taskFilter() {
      return this.$store.state.taskFilter;
    },
    projectName() {
      return this.$store.state.agentsSelectedProject.name;
    },
  },
  watch: {
    taskFilter() {
      let filter = Object.assign({}, this.taskFilter);
      delete filter.status;
      this.filter = filter;
    },
  },
  methods: {
    async pageRefresh() {
      try {
        this.isPageLoading = true;
        await this.$refs.AgentTasks.pageRefresh();
        this.isPageLoading = false;
      } catch (error) {
        this.isPageLoading = false;
      }
    },
    clearAllFilters() {
      this.$store.dispatch("selectTaskListFilter", { filter: {} });
    },
    removeFilter(filterKey) {
      let filter = Object.assign({}, this.taskFilter);
      delete filter[filterKey];
      this.$store.dispatch("selectTaskListFilter", { filter: filter });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  padding-left: 70px;
  background: repeating-linear-gradient(350deg, #f7f9fb, transparent 5px);
}

.header {
  float: left;
  display: block;
  width: 100%;
  margin: 15px 0 10px;
}
a {
  text-decoration: none;
}
button.btn {
  float: right;
  background: #ffffff;
  border: 1px solid #c6c2de;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  padding: 5px 15px;
  cursor: pointer;
}
.header h1 {
  float: left;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}
.tabs li {
  list-style: none;
}
.tabs li a {
  align-items: center;
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  margin-bottom: -1px;
  padding: 0.5em 1em;
  vertical-align: top;
  cursor: pointer;
  font-size: 16px;
}
.tabs ul {
  align-items: center;
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
}
.tabs li.is-active a {
  border-bottom-color: #3273dc;
  color: #3273dc;
}
.tab-details {
  padding-top: 20px;
}

.header,
.toolbar,
.project_list,
.project_list .list_item {
  float: left;
  display: block; /*width: 100%;*/
}
</style>
