<template>
  <v-app id="app">
    <Navbar />
    <Notification />
    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-app>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.templates
  .v-treeview
  .v-treeview-node__toggle
  + button.v-treeview-node__checkbox {
  display: none !important;
}

a {
  text-decoration: none;
}
.pac-container {
  z-index: 1200;
}

.gradient_bg {
  background: repeating-linear-gradient(
    350deg,
    #f7f9fb,
    transparent 5px
  ) !important;
}
</style>

<script>
import Navbar from "./components/Navbar";
import Notification from "./components/notification.vue";

export default {
  components: {
    Navbar,
    Notification,
  },
  name: "Layout",
  mounted: async function () {
    if (!localStorage.getItem("name")) {
      window.location.href = window.location.origin + "/auth/login.html";
    }
  },
};
</script>
