<template>
  <div class="content">
    <div class="header">
      <h1 class="mt-4">Client Management</h1>
    </div>

    <div class="toolbar">
      <ClientForm
        :openCreateClientForm="true"
        v-on:fetchClients="getClientList($event)"
      />
    </div>

    <div class="client">
      <div class="client_list">
        <v-expansion-panels class="mt-4" multiple>
          <v-expansion-panel class="client_list_headers" :readonly="true">
            <v-expansion-panel-header hide-actions>
              <div class="d-flex justify-space-right">
                <div class="field client_name_flex">
                  <h6>Client Name</h6>
                </div>

                <div class="field">
                  <h6>Pseudonym</h6>
                </div>

                <!-- <div class="field">
                <h6>Created By</h6>
              </div>

              <div class="field">
                <h6>Updated By</h6>
              </div> -->

                <div class="field">
                  <h6>Created At</h6>
                </div>

                <div class="field">
                  <h6>updated at</h6>
                </div>

                <div class="field text-center">
                  <h6>edit</h6>
                </div>
              </div>
            </v-expansion-panel-header>
          </v-expansion-panel>

          <v-expansion-panel
            class="list_item"
            v-for="client in clientList"
            :key="client.id"
            :readonly="true"
          >
            <v-expansion-panel-header hide-actions>
              <div class="d-flex justify-space-right">
                <div class="field client_name_flex">
                  <h4>{{ client.name | checkEmpty | markEmpty }}</h4>
                </div>

                <div class="field">
                  <h4>
                    {{ client.pseudonym | checkEmpty | markEmpty }}
                  </h4>
                </div>

                <!-- <div class="field" v-if="client.createdBy">
                <h4>{{ client.createdBy }}</h4>
              </div>
              <div class="field" v-else>
                <h4>- - -</h4>
              </div>

              <div class="field" v-if="client.updatedBy">
                <h4>
                  {{ client.updatedBy }}
                </h4>
              </div>
              <div class="field" v-else>
                <h4>- - -</h4>
              </div> -->

                <div class="field">
                  <h4>
                    {{ client.createdAt | checkEmpty | markEmpty | formatDate }}
                  </h4>
                </div>

                <div class="field">
                  <h4>
                    {{ client.updatedAt | checkEmpty | markEmpty | formatDate }}
                  </h4>
                </div>

                <div class="field" style="width: 100px">
                  <ClientForm
                    :openEditClientForm="true"
                    :clientId="client.id"
                    :clientName="client.name"
                    :clientPseudonym="client.pseudonym"
                    v-on:fetchClients="getClientList($event)"
                  />
                </div>
              </div>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div class="text-center">
        <v-pagination
          v-model="pageNumber"
          :length="Math.ceil(totalCount / pageSize)"
          :total-visible="$constants().TOTAL_VISIBLE_PAGES"
          circle
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="fetchClients()"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../services/Api";
import ClientForm from "./clientForm.vue";

export default {
  components: {
    ClientForm,
  },
  mounted: async function () {
    this.fetchClients();
  },

  data() {
    return {
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE,
      totalCount: 0,
      clientList: [],
      emailPatterns: {
        totalContact: 24,
        patterns: [
          {
            contacts: 9,
            occurance: 37.5,
            pattern: "DDhameliya@uab.edu",
          },
          {
            contacts: 2,
            occurance: 8.33,
            pattern: "DarshanD@uab.edu",
          },
          {
            contacts: 2,
            occurance: 8.33,
            pattern: "Dhameliya@uab.edu",
          },
        ],
      },
    };
  },
  methods: {
    fetchClients: function () {
      var self = this;
      Api.getClients({
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
        details: true,
      })
        .then((response) => {
          self.clientList = response.data.docs;
          self.totalCount = response.data.totalCount;
          console.dir(response.data);
        })
        .catch((error) => {
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().FETCH_CLIENT_ERROR,
          };
          self.setNotification(notification);
        });
    },
    getClientList() {
      this.fetchClients();
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
  },
};
</script>

<style scoped>
.client_list button.v-expansion-panel-header {
  cursor: auto !important;
}

.content {
  margin-left: 70px;
}

.header {
  margin: 15px 0 10px;
}

.header button {
  margin-right: 15px;
}

.header h1 {
  float: left;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}

.clients {
  width: 100%;
}
.counter_stats {
  float: left;
  width: 1300px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.counter_stats::-webkit-scrollbar {
  -webkit-appearance: none;
}

.counter_stats::-webkit-scrollbar:horizontal {
  height: 11px;
}

.counter_stats::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: #8b83ba;
}
.counter_stats .counter {
  margin: 10px 20px;
  flex: 0 0 auto;
}
.counter_stats .counter .counter_title {
  font-weight: 400;
  margin: 10px 0 15px;
  font-family: "Lato";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
  text-transform: uppercase !important;
}
.counter_stats .counter .counter_value {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #1c76d2;
  margin: 0;
  text-align: center;
}
.client_list {
  width: 99%;
  float: left;
  margin: 5px 0 20px;
}

.stat_item {
  display: inline-block;
  margin: 0 15px;
  width: 160px;
}
.stat_item h6 {
  font-weight: 500;
  font-size: 14px;
}

.client_list_headers {
  background: #ffffff;
  padding: 5px 10px;
  padding-left: 0;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
}

.client_list_headers h6 {
  font-family: "Lato";
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
  text-transform: uppercase !important;
}
.client_list_headers .field.client_name_flex,
.list_item .field.client_name_flex {
  width: 300px;
}

.client_list_headers .field,
.list_item .field {
  width: 175px;
  padding: 5px;
}

.list_item .field h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #25213b;
}
.client_import_download_buttons {
  position: absolute;
  top: 45px;
  right: 25px;
}
.notification {
  position: absolute;
}
@media (min-width: 1250px) and (max-width: 1400px) {
  .list_item .field h4 {
    font-size: 16px;
  }
  .client_list_headers .field.client_name_flex,
  .list_item .field.client_name_flex {
    width: 300px;
  }
  .client_list_headers .field,
  .list_item .field {
    width: 185px;
  }
}
</style>
