<template>
  <div class="d-inline-block text-center">
    <v-btn color="#8B83BA" rounded depressed dark @click="openDialog()">
      Link File <v-icon right>post_add</v-icon>
    </v-btn>
    <v-dialog
      v-model="fileLinkDialog"
      transition="dialog-top-transition"
      max-width="1000"
      persistent
    >
      <v-card>
        <v-toolbar dark color="#7c7c7c">
          <v-toolbar-title>Link Suppression File</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="text-h2 py-8 px-6">
            <v-autocomplete
              v-model="selectedFiles"
              :items="FileList"
              item-text="name"
              item-value="id"
              label="Select Suppression Files"
              :search-input.sync="search"
              chips
              deletable-chips
              multiple
              clearable
            ></v-autocomplete>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end mx-5">
          <v-btn
            color="primary"
            @click="linkFiles()"
            :disabled="isLinkFileBtn"
            :loading="isFileLinking"
          >
            Link Files to Project <v-icon right>link</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import Api from "../services/Api";

export default {
  name: "LinkFile",
  props: {
    projectId: String,
  },

  data() {
    return {
      selectedFiles: [],
      FileList: [],
      fileLinkDialog: false,
      search: null,
      select: null,
      isLinkFileBtn: true,
      isFileLinking: false,
    };
  },
  mounted: function () {},
  computed: {
    clientId() {
      return this.$store.state.projectClientId;
    },
  },
  watch: {
    search(val) {
      val !== this.select && this.querySelections(val);
    },
    selectedFiles() {
      this.selectedFiles.length
        ? (this.isLinkFileBtn = false)
        : (this.isLinkFileBtn = true);
    },
  },
  methods: {
    querySelections: _.debounce(async function (v) {
      var queryParams = {};
      queryParams.param = v;

      await this.fetchSharedFiles(queryParams);
    }, 500),
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    linkFiles: async function () {
      this.isFileLinking = true;
      Api.linkSharedFiles(this.clientId, this.projectId, this.selectedFiles)
        .then(() => {
          this.closeDialog();
          this.$emit("getFileList");
          let notification = {
            type: "success",
            showMessage: true,
            message: this.$notifications().LINK_FILE,
          };
          this.setNotification(notification);
        })
        .catch((error) => {
          this.isFileLinking = false;
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: this.$notifications().LINK_FILE_ERROR,
          };
          this.setNotification(notification);
        });
    },
    openDialog: async function () {
      try {
        await this.fetchSharedFiles();
      } catch (error) {
        console.log(`Could Not Get Shared Files ${error.message}`);
      }
      this.fileLinkDialog = true;
    },
    fetchSharedFiles: function (queryParams) {
      Api.getSharedFiles(this.clientId, this.projectId, queryParams)
        .then((response) => {
          this.FileList = response.data;
        })
        .catch((error) => console.log(error));
    },
    closeDialog: function () {
      this.isFileLinking = false;
      this.fileLinkDialog = false;
      this.selectedFiles = [];
    },
  },
};
</script>

<style scoped>
.content {
  margin-left: 70px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.header h1 {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}
.label {
  align-self: center;
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  text-transform: uppercase !important;
}
.fileDetailValue,
.mappingDetail {
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #25213b;
  text-overflow: unset;
}
label.fileDetailValue {
  align-self: center;
}
.fileImportForm {
  width: 60%;
  margin: 0 auto;
}
.mappingField .v-select__selection--comma {
  overflow: visible;
}
.mappingField,
.fileDetailField {
  width: 95%;
  height: 75px;
}
.mappingField .mappingFieldLabel,
.fileDetailField .fileDetailLabel {
  margin-top: 10px;
  align-self: baseline;
}
.supportFileUpload {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  padding: 24px 24px 16px;
  width: 100%;
}
.start_import_wrapper h6 {
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  text-transform: uppercase !important;
}
.notification {
  position: absolute;
}
</style>
