var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"contact_form"},[(_vm.contactReadOnlyFields.length)?_c('div',{staticClass:"form pt-2"},[_c('v-card',{staticClass:"mx-auto",attrs:{"tile":""}},[_c('v-list',{attrs:{"dense":"","flat":""}},[_c('v-list-item-group',{staticClass:"orderSection",attrs:{"color":"primary"}},_vm._l((_vm.sectionKeyBy),function(section){return _c('div',{key:section.section,style:({
                order: section.order,
              })},[(section.display)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#6e6893"}},'v-icon',attrs,false),on),[_vm._v(_vm._s(section.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(section.iconLabel))])])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"14px","font-family":"'Lato'"}},_vm._l((section.fields),function(field){return _c('div',{key:field.id,style:(section.direction)},[(_vm.formContact[field.id])?_c('span',{staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("checkEmpty")(_vm.formContact[field.id]))+" ")]),_vm._v(" "+_vm._s(section.separator)+" ")]}}],null,true)},[_c('span',[_vm._v(_vm._s(field.label))])])],1):_c('i',{staticClass:"mx-1",staticStyle:{"color":"red","font-size":"15px"}},[_vm._v(" "+_vm._s(field.label))])])}),0)],1)],1):_vm._e()],1)}),0)],1)],1)],1):_c('v-alert',{staticClass:"mt-3 col-md-12 float-left",attrs:{"text":"","type":"info"}},[_c('v-row',{staticClass:"text-center",attrs:{"align":"center"}},[_vm._v(" No Contact Selected ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }