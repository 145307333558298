<template>
  <div>
    <div class="account_import_download_buttons">
      <!-- fileUpload -->
      <FileUpload
        fileType="Import"
        :projectId="projectId"
        :projectName="projectName"
        importType="Account"
      />
      <!-- Download Button -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2 white--text"
            color="#8B83BA"
            fab
            small
            :loading="isDownloading"
            :disabled="isDownloading"
            v-bind="attrs"
            v-on="on"
            @click="accountDownload(selectedStage)"
            ><v-icon>{{ downloadDeliverableIcon }}</v-icon></v-btn
          >
        </template>
        <span>Download</span>
      </v-tooltip>

      <!-- Refresh Button -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2 white--text"
            :loading="loading"
            :disabled="loading"
            color="#8B83BA"
            fab
            small
            @click="pageRefresh()"
            ><v-icon v-bind="attrs" v-on="on">mdi-cached</v-icon></v-btn
          >
        </template>
        <span>Refresh</span>
      </v-tooltip>

      <GeneralizedFilter
        :inputFilterObject="inputFilterObject"
        v-on:filterObject="applyFilter($event)"
      />

      <AssignTasks
        pageName="account"
        :projectId="projectId"
        :filter="filter"
        ref="AssignTasks"
        :maxBlockSize="accountListTotalCounts"
      />
    </div>

    <v-container fluid class="accounts">
      <!-- disposition -->
      <v-row>
        <v-col cols="12" sm="11" class="py-0">
          <v-slide-group
            v-model="mainDisposition"
            class="counter_stats"
            active-class="activeDisposition"
            show-arrows
          >
            <v-slide-item
              v-for="disposition in dispositionsFinal"
              :key="disposition.id"
              v-slot="{ active, toggle }"
            >
              <v-card
                class="ml-2 counter"
                height="80"
                width="150"
                :color="active ? '#205fac' : 'FFFFFF'"
                @click="styleSplitDispositions(disposition.disposition)"
                v-on:click="toggle"
              >
                <h4 class="counter_value pt-2">
                  {{ disposition.count | humanizeNumber }}
                </h4>
                <h4 class="counter_title">{{ disposition.disposition }}</h4>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-col>
        <v-col cols="12" sm="1"> </v-col>
      </v-row>

      <!-- splittedDispositions -->
      <v-row>
        <v-col cols="12" sm="11" class="py-0">
          <v-slide-group
            v-model="subDisposition"
            class="counter_stats pr-6"
            v-bind:style="styleSplitDisposition"
            show-arrows
            active-class="activeDisposition"
          >
            <v-slide-item
              v-for="(value, name) in splittedDispositions"
              :key="value.id"
              v-slot="{ active, toggle }"
            >
              <v-card
                class="ml-2 mb-2 counter"
                height="80"
                width="150"
                :color="active ? '#205fac' : 'FFFFFF'"
                @click="filterListByDisposition(name)"
                v-on:click="toggle"
              >
                <h4 class="counter_value pt-2">
                  {{ value | humanizeNumber }}
                </h4>
                <h4 class="counter_title">{{ name }}</h4>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>

      <v-row v-if="Object.keys(filterForChips).length">
        <v-col cols="12" sm="11" class="pb-0">
          <!-- filter chips -->
          <v-card elevation="1" class="px-3">
            <h5
              class="text-overline pl-2"
              style="border-bottom: 1px solid #ddd"
            >
              Applied Filter:
              <span
                data-v-41106904=""
                style="
                  margin-left: 10px;
                  font-family: 'Lato';
                  text-transform: none;
                  color: rgb(32, 95, 172);
                  cursor: pointer;
                  letter-spacing: 0px;
                  font-size: 13px;
                "
                @click="clearAllFilters()"
                >( Clear all )</span
              >
            </h5>
            <v-chip-group column>
              <span
                v-for="(subProps, key) in filterForChips"
                :key="key"
                style="display: inline"
              >
                <span
                  v-if="Array.isArray(subProps.value) && subProps.value.length"
                >
                  <v-chip
                    close
                    label
                    @click:close="removeFilter(key, subValue)"
                    v-for="(subValue, index) in subProps.value"
                    :key="index"
                  >
                    <span style="padding-right: 5px">
                      {{ subProps.text }}:
                    </span>
                    <span
                      v-if="
                        subProps.operator !== operatorEqualTo &&
                        subProps.operator !== operatorBetween
                      "
                      style="padding-right: 2px"
                    >
                      {{ subProps.operator }}
                    </span>
                    <span v-if="subValue.length"> {{ subValue }}</span>
                  </v-chip>
                </span>
                <span v-else>
                  <v-chip close label @click:close="removeFilter(key)">
                    <span style="padding-right: 5px">
                      {{ subProps.text }}:
                    </span>
                    <span
                      v-if="
                        subProps.operator !== operatorEqualTo &&
                        subProps.operator !== operatorBetween
                      "
                      style="padding-right: 2px"
                    >
                      {{ subProps.operator }}
                    </span>
                    <span v-if="subProps.value.length">
                      {{ subProps.value }}</span
                    >
                  </v-chip>
                </span>
              </span>
            </v-chip-group>
          </v-card>
        </v-col>
      </v-row>

      <!-- dataGrid -->
      <v-row>
        <v-col cols="12" sm="11">
          <DataGrid
            gridId="pmAccountPageId"
            gridRef="pmAccountPageRef"
            gridHeight="53vh"
            :headers="headers"
            :itemList="accountList"
            :loading="loading"
            :totalCount="accountListTotalCounts"
            :pageNo="pageNumber"
            @fetchGrideData="fetchAccountData($event)"
            :allowMultiSort="true"
          />
        </v-col>
      </v-row>

      <!-- stages -->
      <v-row class="container-fluid">
        <v-col cols="12" sm="1">
          <div class="counter_stage">
            <v-icon class="arrow_forward_ios">arrow_forward_ios</v-icon>
            <v-icon class="arrow_back_ios">arrow_back_ios</v-icon>
            <div
              class="counter"
              v-bind:class="{ active: 'Total' === selectedStage }"
              @click="applyStageAsFilter('Total')"
            >
              <h4 class="counter_value">
                {{ stagesTotalCount | humanizeNumber }}
              </h4>
              <h4 class="counter_title">Total</h4>
            </div>
            <div
              class="counter"
              v-for="stage in stages"
              :key="stage.id"
              @click="applyStageAsFilter(stage.stage)"
              v-bind:class="{ active: stage.stage === selectedStage }"
            >
              <h4 class="counter_value">{{ stage.count | humanizeNumber }}</h4>

              <h4 class="counter_title" v-if="stage.stage">
                {{ stage.stage }}
              </h4>
              <h4 class="counter_title" v-else>Pending</h4>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import _ from "lodash";
import Api from "../services/Api";
import FileUpload from "./fileUpload";
import DataGrid from "./dataGrid.vue";
import GeneralizedFilter from "./GeneralizedFilters.vue";
var fileDownload = require("js-file-download");
import AssignTasks from "./AssignTasks.vue";

export default {
  name: "PROJECTAccount",
  components: {
    FileUpload,
    DataGrid,
    GeneralizedFilter,
    AssignTasks,
  },
  props: {
    projectId: String,
    projectName: String,
  },
  data() {
    return {
      mainDisposition: 0,
      subDisposition: null,
      loading: false,
      isDownloading: false,
      accountList: [],
      totalCount: "",
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE,
      dispositions: [],
      stages: [],
      dispositionsFinal: [],
      splittedDispositions: {},
      downloadInProgressIcon: "file_download",
      downloadDeliverableIcon: "file_download",
      accountDownloadErrorTimeOut: 5000,
      badDispositionSplit: {},
      dispositionsMapping: {
        Pending: "Pending",
        "Contact Built": "Processed",
        "Already in CRM - Suppression": "Suppressed",
        "Contact Found: Email Bad": "Processed",
        "Acquired/Merged/Subsidiary/Bankrupt/Shut Down": "Doesn't Exist",
        "Required Title Not Found": "Processed",
        "Outside Geography (Country)": "Processed",
        "Staff Level Title Available": "Processed",
        "Manager Level Title Available": "Processed",
        "Generic Title Available": "Processed",
        "Website Not Found": "Doesn't Exist",
      },
      operatorEqualTo: "=",
      operatorBetween: "between",
      styleSplitDisposition: {
        margin: "0 0 0 150px",
      },
      filter: {},
      accountSearchUrl: "",
      selectedStage: "Total",
      accountListTotalCounts: 0,
      stageOrdered: [
        "Pending",
        "Rework",
        "Re-work",
        "Review",
        "Compliance",
        "Ready",
        "Delivered",
        "Bad",
        "Discarded",
        "Working",
      ],
      stagesTotalCount: "",
      //To set column size set header width large : 200px,common : 150px, small : 125px
      headers: [
        {
          value: "name",
          text: "Name",
          sortable: true,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "domain",
          text: "Domain",
          sortable: true,
          divider: true,
          width: "150px",
          type: "link",
          googleSearch: false,
        },
        {
          value: "industry",
          text: "Industry",
          sortable: false,
          divider: true,
          width: "150px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "disposition",
          text: "Disposition",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "masterDisposition",
          text: "Master Disposition",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT35,
          googleSearch: false,
        },
        {
          value: "masterComments",
          text: "Master Comments",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT35,
          googleSearch: false,
        },
        {
          value: "stage",
          text: "Stage",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "complianceStatus",
          text: "Compliance Status",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "isAssigned",
          text: "Is Assigned",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "label",
          text: "Label",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "duplicateOf",
          text: "Label Description",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "createdAt",
          text: "Created Date",
          sortable: false,
          divider: true,
          width: "150px",
          type: "date",
          googleSearch: false,
        },
        {
          value: "updatedAt",
          text: "Updated Date",
          sortable: false,
          divider: true,
          width: "150px",
          type: "date",
          googleSearch: false,
        },
        {
          value: "potential",
          text: "Potential",
          sortable: false,
          divider: true,
          width: "150px",
          type: "number",
          googleSearch: false,
        },
      ],
      // List of columns, its types and its values for filters
      inputFilterObject: {
        potential: {
          text: "Potential",
          type: "number",
          operators: ["IS", "LESS THAN", "GREATER THAN"],
          values: [],
        },
        createdAt: {
          text: "Created Date",
          type: "date",
          operators: ["BETWEEN"],
          values: [],
        },
        updatedAt: {
          text: "Updated Date",
          type: "date",
          operators: ["BETWEEN"],
          values: [],
        },
        label: {
          text: "Label",
          type: "string",
          operators: ["IS"],
          values: ["suppressed", "inclusion", "duplicate"],
        },
        isAssigned: {
          text: "Is Assigned",
          type: "string",
          operators: ["IS"],
          values: ["true", "false"],
        },
        masterDisposition: {
          text: "Master Disposition",
          type: "string",
          operators: ["IS"],
          values: [
            "Active Account",
            "Acquired/Merged/Subsidiary",
            "Bankrupt/Shut Down",
          ],
        },
      },
      filterForChips: {},
      totalDispositions: 0,
      sort: {},
    };
  },
  mounted: function () {
    this.fetchAccountList();
    this.fetchAccountStats();
    this.$refs.AssignTasks.getButtonText({
      callApi: true,
      fromPage: "account",
    });
  },
  methods: {
    clearAllFilters: async function () {
      this.loading = true;
      this.filterForChips = {};
      this.filter = {};
      await this.resetDispositionAndStageFilter();
      await this.fetchAccountList();
      await this.fetchAccountStats();
      this.loading = false;
    },
    removeFilter: async function (filterKey, subValue) {
      this.pageNumber = 1;
      this.loading = true;
      if (!subValue) {
        delete this.filterForChips[filterKey];
        delete this.filter[filterKey];
      } else {
        const filterForChipsValues = _.get(
          this.filterForChips,
          [filterKey, "value"],
          []
        );
        const filterValues = _.get(this.filter, [filterKey, "value"], []);
        if (filterForChipsValues.length <= 1 && filterValues.length <= 1) {
          delete this.filterForChips[filterKey];
          delete this.filter[filterKey];
        } else {
          const filterForChipsValueIndex = _.indexOf(
            filterForChipsValues,
            subValue
          );
          if (filterForChipsValueIndex > -1) {
            this.filterForChips[filterKey]["value"].splice(
              filterForChipsValueIndex,
              1
            );
          }
          const filterValueIndex = _.indexOf(filterValues, subValue);
          if (filterValueIndex > -1) {
            this.filter[filterKey]["value"].splice(filterValueIndex, 1);
          }
        }
      }
      await this.resetDispositionAndStageFilter();
      await this.fetchAccountList();
      await this.fetchAccountStats();
      this.loading = false;
    },
    applyFilter: async function (filterApplied) {
      this.loading = true;
      this.pageNumber = 1;
      const keyOfFilterApplied = Object.keys(filterApplied)[0];
      this.filterForChips[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      this.modifyFilterForChips(keyOfFilterApplied);
      filterApplied[keyOfFilterApplied] = _.omit(
        filterApplied[keyOfFilterApplied],
        ["text", "itemText", "itemValue", "search", "type"]
      );
      this.filter[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      await this.resetDispositionAndStageFilter();
      await this.fetchAccountList();
      await this.fetchAccountStats();
      this.loading = false;
    },
    modifyFilterForChips: function (keyOfFilterApplied) {
      const valueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["value"];
      const operatorOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["operator"];
      const itemTextOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemText"];
      const itemValueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemValue"];
      if (
        operatorOfFilteredForChips === "between" &&
        Array.isArray(valueOfFilteredForChips)
      ) {
        this.filterForChips[keyOfFilterApplied]["value"] =
          this.filterForChips[keyOfFilterApplied]["value"].join(" ~ ");
      }
      if (itemTextOfFilteredForChips && itemValueOfFilteredForChips) {
        let valuesOfFilteredId = _.map(
          this.inputFilterObject[keyOfFilterApplied].values,
          (item) => {
            if (item[itemValueOfFilteredForChips] === valueOfFilteredForChips) {
              return item[itemTextOfFilteredForChips];
            }
          }
        );
        valuesOfFilteredId = _.compact(valuesOfFilteredId);
        this.filterForChips[keyOfFilterApplied]["value"] =
          valuesOfFilteredId[0];
      }
    },
    resetDispositionAndStageFilter: async function () {
      this.mainDisposition = 0;
      this.subDisposition = null;
      delete this.filter["stage"];
      delete this.filter["disposition"];
      this.selectedStage = "Total";
      this.splittedDispositions = {};
    },
    applyStageAsFilter(value) {
      this.loading = true;
      this.selectedStage = value;
      delete this.filter["stage"];
      if (value && value !== "Total") {
        this.filter["stage"] = {
          operator: "=",
          value: value,
        };
      }
      this.pageNumber = 1;
      this.fetchAccountList();
      this.loading = false;
    },
    createAccountSearchLink(accountDomain) {
      this.accountSearchUrl =
        `https://www.google.com/search?q=` + encodeURIComponent(accountDomain);
    },
    pageRefresh: async function () {
      this.loading = true;
      await this.fetchAccountList();
      await this.fetchAccountStats();
      this.$refs.AssignTasks.getButtonText({
        callApi: true,
        fromPage: "account",
      });
      this.loading = false;
    },
    fetchAccountList: function () {
      Api.getAccounts(this.projectId, {
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
        filter: JSON.stringify(this.filter),
        sort: JSON.stringify(this.sort),
      })
        .then((response) => {
          this.accountListTotalCounts = response.data.totalCount;
          this.accountList = response.data.docs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    orderStages() {
      let self = this;

      let c = self.stageOrdered
        .map((i) => self.stages.find((j) => j.stage === i))
        .filter((x) => x !== null && typeof x !== "undefined");

      return c;
    },
    changeIconOfDownloadBtn: function (stage, disable) {
      if (stage === "Ready") {
        if (disable) {
          this.downloadDeliverableIcon = "downloading";
        } else {
          this.downloadDeliverableIcon = "file_download";
        }
      } else if (stage === "In Progress") {
        if (disable) {
          this.downloadInProgressIcon = "downloading";
        } else {
          this.downloadInProgressIcon = "file_download";
        }
      }
    },
    accountDownload: function (stage) {
      const self = this;
      const downloadFilter = Object.assign({}, this.filter);
      this.isDownloading = true;
      this.changeIconOfDownloadBtn(stage, true);
      Api.downloadAccount(this.projectId, {
        download: true,
        filter: downloadFilter,
      })
        .then((response) => {
          const processedProjectName = this.projectName
            .trim()
            .split(" ")
            .join("_");
          var downloadFileName = `${processedProjectName}_account_${Date.now()}.csv`;
          if (stage === "Ready") {
            downloadFileName = `${processedProjectName}_account_deliverable_${Date.now()}.csv`;
          } else if (stage === "In Progress") {
            downloadFileName = `${processedProjectName}_account_in_progress_${Date.now()}.csv`;
          } else {
            downloadFileName = `${processedProjectName}_account_compliance_${Date.now()}.csv`;
          }

          const responseContentType = response.headers["content-type"];

          let notification = {
            type: "success",
            showMessage: true,
          };
          this.isDownloading = false;
          this.changeIconOfDownloadBtn(stage, false);
          if (responseContentType === "application/csv") {
            notification.message = self.$notifications().FILE_SUCCESS;
            self.setNotification(notification);
            return fileDownload(response.data, downloadFileName);
          }

          notification.message =
            self.$notifications().DOWNLOAD_JOB_SUBMISSION_SUCCESS;
          self.setNotification(notification);
          return;
        })
        .catch((error) => {
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().FILE_ERROR,
          };
          self.setNotification(notification);
          this.isDownloading = false;
          this.changeIconOfDownloadBtn(stage, false);
        });
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    generateAccountDispositions(dispositions) {
      var self = this;
      var finalDispositions = {
        Total: 0,
        Processed: 0,
        Suppressed: 0,
        "Doesn't Exist": 0,
        "Bad Disposition": 0,
        Pending: 0,
      };

      dispositions.forEach(function (item) {
        finalDispositions["Total"] = self.dispositionTotalCount;
        if (self.dispositionsMapping[item.disposition]) {
          finalDispositions[self.dispositionsMapping[item.disposition]] =
            finalDispositions[self.dispositionsMapping[item.disposition]] +
            parseInt(item.count);
        } else {
          if (item.disposition === null) {
            item.disposition = "Pending";
          } else if (self.dispositionsMapping[item.disposition] === undefined) {
            finalDispositions["Bad Disposition"] =
              Math.floor(finalDispositions["Bad Disposition"]) +
              Math.floor(item.count);
            self.badDispositionSplit[item.disposition] = item.count;
          } else {
            finalDispositions[item.disposition] = item.count;
          }
        }
      });
      this.totalCount = finalDispositions["Total"];
      var arrayDispositions = Object.keys(finalDispositions).map((key) => {
        return {
          disposition: key,
          count: finalDispositions[key],
        };
      });
      self.dispositionsFinal = arrayDispositions;
    },
    styleSplitDispositions(disposition) {
      this.filterListByDisposition(disposition);
      switch (disposition) {
        case "Suppressed":
          this.styleSplitDisposition.margin = "0 0 0 310px";
          break;
        case "Doesn't Exist":
          this.styleSplitDisposition.margin = "0 0 0 460px";
          break;
        case "Bad Disposition":
          this.styleSplitDisposition.margin = "0 0 0 590px";
          break;
        case "Pending":
          this.styleSplitDisposition.margin = "0 0 0 760px";
          break;
        default:
          this.styleSplitDisposition.margin = "0 0 0 150px";
      }
      this.splitDispositions(disposition);
    },
    splitDispositions(disposition) {
      this.splittedDispositions = {};
      var self = this;

      for (const key in this.dispositionsMapping) {
        if (this.dispositionsMapping[key] === disposition) {
          this.splittedDispositions[key] = 0;
        }
      }
      this.dispositions.forEach(function (item) {
        if (self.dispositionsMapping[item.disposition] === disposition) {
          self.splittedDispositions[item.disposition] = item.count;
        }
      });

      if (disposition == "Bad Disposition") {
        self.splittedDispositions = Object.assign({}, this.badDispositionSplit);
      }
    },
    filterListByDisposition: async function (filterDisposition) {
      // Reset the stage Filter
      this.selectedStage = "Total";
      delete this.filter["stage"];
      delete this.filter["disposition"];

      let dispositions = Object.keys(this.dispositionsMapping).filter(
        (k) => this.dispositionsMapping[k] === filterDisposition
      );

      if (
        filterDisposition === "Pending" ||
        filterDisposition === "Processed" ||
        filterDisposition === "Suppressed" ||
        filterDisposition === "Doesn't Exist"
      ) {
        filterDisposition = dispositions;
      } else if (filterDisposition === "Bad Disposition") {
        filterDisposition = Object.keys(this.badDispositionSplit);
        if (filterDisposition.length === 0) {
          filterDisposition = ["Bad Disposition"];
        }
      } else if (filterDisposition === "Total") {
        filterDisposition = [];
      }

      if (typeof filterDisposition === "string") {
        filterDisposition = [filterDisposition];
      }

      if (filterDisposition.length) {
        this.filter["disposition"] = {
          operator: "=",
          value: filterDisposition,
        };
      }
      this.pageNumber = 1;

      this.loading = true;
      await this.fetchAccountList();
      await this.fetchAccountStats();
      this.loading = false;
    },
    fetchAccountStats: function () {
      return Api.getAccountStats(this.projectId, {
        filter: JSON.stringify(this.filter),
      })
        .then((response) => {
          this.dispositions = response.data.dispositions.data;
          this.dispositionTotalCount = response.data.dispositions.totalCount;

          this.stages = response.data.stages.data;
          this.stagesTotalCount = response.data.stages.totalCount;

          this.stages = this.orderStages();
          this.generateAccountDispositions(this.dispositions);
        })
        .catch((error) => console.log(error));
    },
    generateSortPayload(sortBy, sortDesc) {
      var sortPayload = {};
      sortBy.forEach(function (item, index) {
        sortPayload[item] = sortDesc[index] ? "asc" : "desc";
      });
      return sortPayload;
    },
    fetchAccountData(options) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = options;

      this.pageNumber = page;
      this.pageSize = itemsPerPage;
      this.sort = this.generateSortPayload(sortBy, sortDesc);

      //api call
      this.fetchAccountList();
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.accounts {
  width: 100%;
  position: relative;
}
.counter_stats {
  float: left;
  width: 1300px;
  margin-left: -10px;
  margin-bottom: 10px;
}
.counter_stage .counter .counter_title {
  font-weight: 400;
  margin: 10px 0 15px;
  font-family: "Lato";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
}
.counter_stage .counter .counter_value {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #1c76d2;
  margin: 0;
  text-align: center;
}
.counter_stage .counter {
  padding: 5px 0 10px;
  padding-left: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.counter_stage .counter.active {
  background-color: #205fac;
}

.counter_stage .counter:last-of-type {
  border-bottom: 0;
}

.counter_stage {
  float: left;
  width: 90px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-left: 13px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  background: white;
}

.counter_stage:hover {
  z-index: 100000;
  width: 150px;
  background: white;
  transition: width 0.1s;
}
.counter_stage:hover i {
  padding: 7px 13px 0 8px;
}
.counter_stage i {
  position: absolute;
  left: -20px;
  top: 65px;
  padding: 7px 0 0 10px;
  border: 2px solid white;
  width: 42px;
  height: 42px;
  background: #205fac;
  border-radius: 100%;
  color: white;
}
.counter_stage i.arrow_forward_ios {
  display: none;
}
.counter_stage:hover i.arrow_forward_ios {
  display: block;
}
.counter_stage i.arrow_back_ios {
  display: block;
}
.counter_stage:hover i.arrow_back_ios {
  display: none;
}

.counter_stats::-webkit-scrollbar {
  -webkit-appearance: none;
}

.counter_stats::-webkit-scrollbar:horizontal {
  height: 11px;
}

.counter_stats::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: #8b83ba;
}
.counter_stats .counter {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 5px;
  height: 100px !important;
}
.counter_stats .counter .counter_title {
  font-weight: 400;
  margin: 10px 0 15px;
  font-family: "Lato";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
  max-height: 40px;
  overflow: hidden;
}
.counter_stats .counter .counter_value {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #1c76d2;
  margin: 0;
  text-align: center;
}

.account_import_download_buttons {
  position: absolute;
  top: 45px;
  right: 25px;
}
.notification {
  position: absolute;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

.counter_stats .counter.activeDisposition {
  border-radius: 4px;
}

.counter_stats .counter.activeDisposition .counter_value,
.counter_stats .counter.activeDisposition .counter_title,
.counter_stage .counter.active .counter_title,
.counter_stage .counter.active .counter_value {
  color: white;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .list_item .field h4 a {
  text-overflow: ellipsis;
  width: 95%;
  display: inline-block;
  overflow: hidden;
} */
</style>
