<template>
  <div class="dispose_actions">
    <v-menu
      v-model="isDisposeDropdown"
      :close-on-content-click="false"
      :nudge-width="175"
      right
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          class="ml-4 float-right"
          color="primary"
          ref="disposeAccountBtn"
          @click="resetDispose()"
          v-bind="attrs"
          v-on="on"
          v-shortkey.once="['ctrl', 'alt', 'l']"
          @shortkey="debounceOnShortKey()"
          >Dispose - Account
          <span class="material-icons"> send </span>
        </v-btn>
      </template>
      <v-card>
        <v-form
          v-if="!showMasterUpdateConfirmation"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-list>
            <!-- disposition -->
            <v-list-item class="pt-4">
              <v-select
                v-model="accountDisposition"
                :items="accountDispositionList"
                :rules="[
                  (v) => !!v || 'Account Disposition is required',
                  (v) => (v && !!v.trim()) || 'Account Disposition is required',
                ]"
                @blur="
                  formatFormFields($event.target.value, 'accountDisposition')
                "
                @change="resetFormValidation(accountDisposition)"
                item-text="dispositionType"
                label="Select Account Disposition"
                outlined
                hide-details
                dense
                clearable
                autofocus
              ></v-select>
            </v-list-item>

            <!-- website -->
            <v-list-item class="pt-4" v-if="showParentWebsite">
              <v-text-field
                label="Parent Website"
                id="parentWebsite"
                v-model="parentWebsite"
                :rules="[rules.url]"
                @blur="formatFormFields($event.target.value, 'parentWebsite')"
                outlined
                dense
              ></v-text-field>
            </v-list-item>

            <!-- Comments -->
            <v-list-item class="pt-4">
              <v-textarea
                label="Comments"
                id="comment"
                v-model="comments"
                @blur="formatFormFields($event.target.value, 'comments')"
                outlined
                dense
              >
              </v-textarea>
            </v-list-item>

            <!-- source -->
            <v-list-item>
              <v-text-field
                label="Source"
                id="source"
                v-model="source"
                @blur="formatFormFields($event.target.value, 'source')"
                outlined
                dense
              ></v-text-field>
            </v-list-item>
          </v-list>
          <v-list-item class="d-flex flex-row-reverse">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed @click="submitDisposition()">
                Submit Disposition
              </v-btn>
            </v-card-actions>
          </v-list-item>
        </v-form>
        <div style="max-width: 400px" v-if="showMasterUpdateConfirmation">
          <v-card-title class="text-h5">
            Account Dispose Confirmation
          </v-card-title>

          <v-card-text
            ><b>{{ account.name }}</b> information is older than
            <b>{{ updateMasterDate }} yrs</b>, Please confirm if there's no
            changes to account information and dispose it again.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="confirmAccountConfirmation()"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import Api from "../services/Api";
import _ from "lodash";
import validator from "validator";
import moment from "moment";

export default {
  name: "AccountDispose",
  props: {
    changedAccountData: Object,
  },
  data() {
    return {
      showMasterUpdateConfirmation: false,
      isDisposeDropdown: false,
      accountDisposition: "",
      comments: "",
      source: "",
      parentWebsite: "",
      accountDispositionList: [],
      valid: false,
      showParentWebsite: false,
      rules: {
        url: (value) => {
          if (!value) {
            return "ParentWebsite is required";
          }
          if (value && !validator.isURL(value.trim(), [".+"])) {
            return "URL is not valid";
          }
          return true;
        },
      },
    };
  },
  mounted: function () {
    this.fetchAllDispostions();
  },
  computed: {
    agentContactState() {
      return _.get(this.$store.state, "agentContactState");
    },
    projectId() {
      return _.get(this.$store.state, "workspace.task.Project.id", null);
    },
    account() {
      return _.get(this.$store.state.workspace, "account", null);
    },
    inActionDisposeMenu() {
      return this.$store.state.workspace.inActionDisposeMenu;
    },
  },
  watch: {
    inActionDisposeMenu: function (value) {
      if (value === "contact" || value === "") {
        this.isDisposeDropdown = false;
      } else {
        this.isDisposeDropdown = true;
      }
    },
    account: function () {
      this.accountDisposition = "";
      this.parentWebsite = "";
      this.comments = "";
      this.source = "";
    },
    accountDisposition: function (newDisposition) {
      if (newDisposition === "Acquired/Merged/Subsidiary") {
        this.showParentWebsite = true;
      } else {
        this.parentWebsite = "";
        this.showParentWebsite = false;
      }
    },
  },
  methods: {
    confirmAccountConfirmation() {
      this.showMasterUpdateConfirmation = false;
    },
    debounceOnShortKey: _.debounce(async function () {
      this.isDisposeDropdown = true;
      await this.$store.dispatch("setInActionDisposeMenu", "account");
      await this.$refs.disposeAccountBtn.$el.focus();
      await this.resetDispose();
    }, 1000),
    formatFormFields: async function (fieldValue, fieldModel) {
      if (!fieldValue || !fieldModel) {
        return;
      }
      this[fieldModel] = fieldValue.trim();
      return;
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    submitDisposition() {
      var formValid = this.$refs.form.validate();
      if (!formValid) {
        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().FORM_VALIDATION_ERROR,
        };
        this.setNotification(notification);
        return;
      }
      let dispositionPayload = {
        disposition: this.accountDisposition,
        parentWebsite: this.parentWebsite,
        comments: this.comments,
        source: this.source,
      };
      this.accountDisposition = "";
      this.parentWebsite = "";
      this.comments = "";
      this.source = "";
      this.$emit("disposition", dispositionPayload);

      let agentContactState = this.agentContactState;
      _.unset(agentContactState, `${this.projectId}.${this.account.id}`);
      this.$store.dispatch("setAgentContactStateAction", agentContactState);

      this.isDisposeDropdown = false;
    },
    fetchAllDispostions: function () {
      Api.getAllDispostions()
        .then((response) => {
          this.accountDispositionList = _.map(
            response.data,
            function (disposition) {
              if (
                disposition.dispositionLevel === "Contact/Account" ||
                disposition.dispositionLevel === "Account"
              ) {
                return disposition;
              }
            }
          );
          this.accountDispositionList = _.compact(this.accountDispositionList);
        })
        .catch((error) => console.log(error));
    },
    resetDispose: function () {
      if (this.account.masterUpdatedAt) {
        var accountUpdatedDt = moment(this.account.masterUpdatedAt);
        var now = moment();
        this.showMasterUpdateConfirmation = false;
        const updateMasterDate = now.diff(accountUpdatedDt, "years");
        if (updateMasterDate > 0) {
          this.showMasterUpdateConfirmation = true;
        }
      }
      if (this.accountDisposition || this.comments || this.source) {
        return;
      }
      if (this.account.TaskLink) {
        this.accountDisposition = this.account.TaskLink.disposition || "";
        this.comments = this.account.TaskLink.comments || "";
        this.source = this.account.source || "";
        this.parentWebsite = this.account.parentWebsite || "";
        return;
      }
    },
    resetFormValidation: function (selectedDisposition) {
      if (selectedDisposition !== "Acquired/Merged/Subsidiary") {
        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>

<style scoped>
.dispose_actions {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
