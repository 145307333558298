<template>
  <div class="content">
    <div class="job_master_import_download_buttons">
      <!-- Refresh Button -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2 white--text"
            :loading="loading"
            :disabled="loading"
            color="#8B83BA"
            fab
            small
            @click="pageRefresh()"
            ><v-icon v-bind="attrs" v-on="on">mdi-cached</v-icon></v-btn
          >
        </template>
        <span>Refresh</span>
      </v-tooltip>
    </div>

    <v-container fluid class="jobMaster">
      <!-- Error Analysis Button -->
      <v-row>
        <v-col>
          <v-btn class="button" elevated @click="redirectToMasterUploadStats">
            Error Analysis
          </v-btn>
        </v-col>
      </v-row>

      <!-- dataGrid -->
      <v-row>
        <v-col cols="12" sm="12">
          <DataGrid
            gridId="jobMasterPageId"
            gridRef="jobMasterPageRef"
            gridHeight="75vh"
            :headers="headers"
            :itemList="jobMasterList"
            :loading="loading"
            :totalCount="jobMasterListTotalCounts"
            :pageNo="pageNumber"
            @fetchGrideData="fetchJobMasterData($event)"
            @fetchButtonClickAction="fileDownloadByHeader($event)"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import Api from "../services/MasterApi";
import DataGrid from "./dataGrid.vue";

export default {
  name: "JobMaster",
  components: {
    DataGrid,
  },
  mounted: async function () {
    await this.pageRefresh();
  },
  data() {
    return {
      loading: false,
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE,
      filter: {},
      sort: {
        createdAt: "desc",
      },
      jobMasterListTotalCounts: 0,
      jobMasterList: [],
      //To set column size set header width large : 200px,common : 150px, small : 125px
      headers: [
        {
          value: "fileName",
          text: "File Name",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "operationName",
          text: "Job Name",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "processed",
          text: "Processed",
          sortable: false,
          divider: true,
          width: "150px",
          type: "number",
          googleSearch: false,
        },
        {
          value: "imported",
          text: "Imported",
          sortable: false,
          divider: true,
          width: "150px",
          type: "number",
          googleSearch: false,
        },
        {
          value: "errored",
          text: "Errored",
          sortable: false,
          divider: true,
          width: "115px",
          type: "number",
          googleSearch: false,
        },
        {
          value: "createdAt",
          text: "Started",
          sortable: false,
          divider: true,
          width: "150px",
          type: "momentFormatDate",
          googleSearch: false,
        },
        {
          value: "updatedAt",
          text: "Ended",
          sortable: false,
          divider: true,
          width: "150px",
          type: "momentFormatDate",
          googleSearch: false,
        },
        {
          value: "status",
          text: "Status",
          divider: true,
          sortable: false,
          width: "125px",
          type: "string",
        },
        {
          value: "jobId",
          text: "Error Report",
          divider: true,
          sortable: false,
          width: "125px",
          type: "button",
          icon: "summarize",
        },
        {
          value: "jobId",
          text: "Uploaded File",
          divider: true,
          sortable: false,
          width: "125px",
          type: "button",
          icon: "file_download",
        },
      ],
    };
  },
  methods: {
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    pageRefresh: async function () {
      this.loading = true;
      await this.fetchMasterJobList();
      this.loading = false;
    },
    fetchMasterJobList: async function () {
      Api.getMasterJobs({
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
        sort: JSON.stringify(this.sort),
        jobType: ["upload"],
      })
        .then(async (response) => {
          this.jobMasterListTotalCounts = response.data.totalCount;
          this.jobMasterList = response.data.docs;
          this.jobMasterList = await this.refreshJobStatus(this.jobMasterList);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refreshJobStatus: async function (jobs) {
      let modifiedJobs = [];
      const emptyUpdatedAtJobStatus = [
        "Queued",
        "Processing",
        "Preparing",
        "Verifying",
        "Verified",
      ];
      modifiedJobs = _.map(jobs, function (job) {
        job.itemInfo = {};
        job.itemInfo.header = "Uploaded File";
        if (
          _.get(job, "status", "").toLowerCase() === "completed" ||
          _.get(job, "status", "").toLowerCase() === "failed" ||
          _.get(job, "status", "").toLowerCase() === "cancelled" ||
          _.get(job, "status", "").toLowerCase() === "processing"
        ) {
          job.itemInfo.isDisableButton = false;
        } else {
          job.itemInfo.isDisableButton = true;
        }

        if (emptyUpdatedAtJobStatus.indexOf(job.status) > -1) {
          job.updatedAt = "";
        }
        return job;
      });
      return modifiedJobs;
    },
    fetchJobMasterData: async function (options) {
      this.loading = true;
      const { page, itemsPerPage } = options;

      this.pageNumber = page;
      this.pageSize = itemsPerPage;

      //api call
      await this.fetchMasterJobList();
      this.loading = false;
    },
    downloadMasterErrorFile: function (jobId) {
      return Api.downloadJobErrorCsvMaster(jobId, {
        download: true,
      })
        .then((response) => {
          let fileDownloadLink = response.data.signedUrl;
          window.open(fileDownloadLink, "_blank");
          let notification = {
            type: "success",
            showMessage: true,
            message: this.$notifications().ERROR_REPORT_DOWNLOAD_SUCCESS,
          };
          this.setNotification(notification);
        })
        .catch(() => {
          let notification = {
            type: "error",
            showMessage: true,
            message: this.$notifications().ERROR_REPORT_DOWNLOAD_ERROR,
          };
          this.setNotification(notification);
        });
    },
    fileDownloadByHeader: async function (data) {
      let jobId = data.value,
        headerName = data.headerName;
      if (headerName === "Error Report") {
        await this.downloadMasterErrorFile(jobId);
      }

      if (headerName === "Uploaded File") {
        await this.downloadMasterFile(jobId);
      }
    },
    downloadMasterFile: async function (jobId) {
      try {
        const response = await this.downloadMasterJobFile(jobId);
        this.downloadLink = response.data.url;
        window.open(this.downloadLink, "_blank");
        let notification = {
          type: "success",
          showMessage: true,
          message: this.$notifications().FILE_DOWNLOAD_SUCCESS,
        };
        this.setNotification(notification);
      } catch (error) {
        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().FILE_DOWNLOAD_ERROR,
        };
        this.setNotification(notification);
      }
    },
    downloadMasterJobFile: async function (jobId) {
      return Api.downloadMasterJobFile(jobId);
    },
    redirectToMasterUploadStats: async function () {
      const currentOrigin = window.location.origin;
      const originToRedirect = currentOrigin.replace(".da.", ".masterdb.");
      window.open(originToRedirect, "_blank");
    },
  },
};
</script>

<style scoped>
.job_master_import_download_buttons {
  position: absolute;
  top: 60px;
  right: 15px;
}

.jobMaster {
  width: 100%;
  position: relative;
}

.content {
  margin-left: 0 !important;
}

.job_master_import_download_buttons h1 {
  float: left;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}

.button {
  animation: pulse 1s infinite;
  border: 1px solid rgb(139, 131, 186);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(139, 131, 186, 0);
  }
  50% {
    box-shadow: 0 0 0 1px rgba(139, 131, 186, 0.9);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(139, 131, 186, 0);
  }
}
</style>
