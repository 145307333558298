<template>
  <v-dialog
    style="z-index: 1050"
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <a
        v-if="isLink"
        text
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        v-shortkey.once="['ctrl', 'alt', 'm']"
        @shortkey="debounceOnShortKey('spec')"
      >
        Specification
      </a>

      <v-btn
        v-else
        v-bind="attrs"
        v-on="on"
        class="float-right mr-4"
        color="primary"
        depressed
        v-shortkey.once="['ctrl', 'alt', 'm']"
        @shortkey="debounceOnShortKey('spec')"
      >
        Specification
        <v-icon right>list_alt</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn
          class="float-right"
          icon
          dark
          x-large
          @click="dialog = false"
          v-shortkey.once="['ctrl', 'alt', 'x']"
          @shortkey="debounceOnShortKey('close')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ projectName }} - Specifications</v-toolbar-title>
      </v-toolbar>
      <div class="scroller">
        <div
          class="specs_item"
          v-for="(spec, index) in projectSpecs"
          :key="spec.id"
          style="background: rgb(184 220 255 / 10%)"
        >
          <section>
            <v-row>
              <v-col cols="12" md="2">
                <v-subheader
                  ><b>Spec name</b
                  ><span style="color: red; font-size: 23px">*</span>:
                </v-subheader>
              </v-col>
              <v-col cols="12" md="10" class="mt-2">
                <p>
                  {{ spec.name }}
                </p>
              </v-col>
            </v-row>
          </section>

          <h4
            class="spec_heading read_view_spec"
            v-show="
              projectSpecs[index] &&
              projectSpecs[index].accountValueKeys &&
              projectSpecs[index].accountValueKeys.length
            "
          >
            Account Fields
          </h4>
          <div
            v-for="valuesKey in projectSpecs[index].accountValueKeys"
            :key="valuesKey"
          >
            <div v-if="projectSpecs[index].newValues[valuesKey]">
              <div v-if="accountFields.includes(valuesKey)">
                <v-row class="read_view_spec">
                  <v-col cols="12" md="2" class="py-0">
                    <v-subheader for="key">{{ valuesKey }} :</v-subheader>
                  </v-col>
                  <v-col cols="12" md="10">
                    <p for="value">
                      <span v-html="projectSpecs[index].newValues[valuesKey]">{{
                        projectSpecs[index].newValues[valuesKey]
                      }}</span>
                    </p>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>

          <h4
            class="spec_heading read_view_spec"
            v-show="
              projectSpecs[index] &&
              projectSpecs[index].contactValueKeys &&
              projectSpecs[index].contactValueKeys.length
            "
          >
            Contact Fields
          </h4>
          <div
            v-for="valuesKey in projectSpecs[index].contactValueKeys"
            :key="valuesKey"
          >
            <div v-if="projectSpecs[index].newValues[valuesKey]">
              <div v-if="contactFields.includes(valuesKey)">
                <v-row class="read_view_spec">
                  <v-col cols="12" md="2" class="py-0">
                    <v-subheader for="key">{{ valuesKey }} :</v-subheader>
                  </v-col>
                  <v-col cols="12" md="10">
                    <p for="value">
                      <span v-html="projectSpecs[index].newValues[valuesKey]">{{
                        projectSpecs[index].newValues[valuesKey]
                      }}</span>
                    </p>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>

          <h4
            class="spec_heading read_view_spec"
            v-show="
              projectSpecs[index] &&
              projectSpecs[index].customValueKeys &&
              projectSpecs[index].customValueKeys.length
            "
          >
            Custom Fields
          </h4>
          <div
            v-for="valuesKey in projectSpecs[index].customValueKeys"
            :key="valuesKey"
          >
            <div v-if="projectSpecs[index].newValues[valuesKey]">
              <div
                v-if="
                  !contactFields.includes(valuesKey) &&
                  !accountFields.includes(valuesKey)
                "
              >
                <v-row class="read_view_spec">
                  <v-col cols="12" md="2" class="py-0">
                    <v-subheader for="key">{{ valuesKey }} :</v-subheader>
                  </v-col>
                  <v-col cols="12" md="10">
                    <p for="value">
                      <span v-html="projectSpecs[index].newValues[valuesKey]">{{
                        projectSpecs[index].newValues[valuesKey]
                      }}</span>
                    </p>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      dialog: false,
      projectSpecsPrev: [],
      fieldList: [
        "job_level",
        "job_department",
        "job_function",
        "job_title",
        "excluded_job_title",
        "employee_count",
        "company_revenue",
        "industry",
        "sub_industry",
        "geography",
        "contacts_per_account",
        "note",
      ],
      contactFields: [
        "Job Level",
        "Job Department",
        "Job Function",
        "Job Title",
        "Excluded Job Title",
      ],
      customFields: {},
      accountFields: [
        "Employee Count",
        "Company Revenue",
        "Industry",
        "Sub Industry",
        "Geography",
        "Contacts Per Account",
        "Note",
      ],
    };
  },
  props: {
    isLink: Boolean,
  },
  computed: {
    projectSpecs() {
      return this.fetchProjectSpec(
        [].concat(this.$store.state.workspace.specifications)
      );
    },
    projectName() {
      return this.$store.state.agentsSelectedProject.name;
    },
    clientId() {
      return this.$store.state.projectClientId;
    },
  },
  methods: {
    debounceOnShortKey: _.debounce(async function (value) {
      switch (value) {
        case "spec":
          this.dialog = true;
          break;
        case "close":
          this.dialog = false;
          break;
      }
    }, 1000),
    renameKeys: function (projectSpecs) {
      let jsonMap = {
        job_level: "Job Level",
        job_department: "Job Department",
        job_function: "Job Function",
        job_title: "Job Title",
        excluded_job_title: "Excluded Job Title",
        employee_count: "Employee Count",
        company_revenue: "Company Revenue",
        industry: "Industry",
        sub_industry: "Sub Industry",
        geography: "Geography",
        contacts_per_account: "Contacts Per Account",
        note: "Note",
      };
      for (var i = 0; i < projectSpecs.length; i++) {
        projectSpecs[i].newValues = Object.assign({}, projectSpecs[i].values);
        var specValuesKeys = Object.keys(projectSpecs[i].newValues);
        for (var j = 0; j < specValuesKeys.length; j++) {
          var keyToCheck = specValuesKeys[j];
          if (
            projectSpecs[i].newValues[keyToCheck] &&
            typeof projectSpecs[i].newValues[keyToCheck] === "object"
          ) {
            projectSpecs[i].newValues[keyToCheck] = _.compact(
              projectSpecs[i].newValues[keyToCheck]
            );
            projectSpecs[i].newValues[keyToCheck] =
              projectSpecs[i].newValues[keyToCheck].join(", ");
          }
          var index = _.indexOf(this.fieldList, keyToCheck);
          if (index > -1) {
            var newKey = jsonMap[keyToCheck];
            projectSpecs[i].newValues[newKey] =
              projectSpecs[i].newValues[keyToCheck];
            delete projectSpecs[i].newValues[keyToCheck];
          }
        }
        projectSpecs[i].valuesKeys = Object.keys(projectSpecs[i].newValues);
        var validKeys = Object.keys(
          _.pickBy(projectSpecs[i].newValues, _.identity)
        );
        projectSpecs[i].contactValueKeys = _.intersection(
          validKeys,
          this.contactFields
        );
        projectSpecs[i].accountValueKeys = _.intersection(
          validKeys,
          this.accountFields
        );
        var contactAccountValueKeys = _.concat(
          projectSpecs[i].contactValueKeys,
          projectSpecs[i].accountValueKeys
        );
        projectSpecs[i].customValueKeys = _.difference(
          validKeys,
          contactAccountValueKeys
        );
      }
      return projectSpecs;
    },
    loadFields: function (specs) {
      specs.forEach((spec) => {
        var keys = Object.keys(spec.values);
        var unique = _.difference(keys, this.fieldList);
        this.customFields[spec.id] = unique.map((item) => {
          return { key: item, value: spec.values[item] };
        });
      });
      this.projectSpecsPrev = Object.assign({}, specs);
    },
    fetchProjectSpec: function (projectSpecs) {
      if (projectSpecs == "N/A") {
        return;
      }
      projectSpecs = this.renameKeys(projectSpecs);
      this.loadFields(projectSpecs);
      return projectSpecs;
    },
  },
};
</script>

<style scoped>
.tabs li {
  list-style: none;
}

.tabs li a {
  align-items: center;
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  margin-bottom: -1px;
  padding: 0.5em 1em;
  vertical-align: top;
  cursor: pointer;
  font-size: 16px;
}

.read_view_spec {
  align-items: center !important;
}

.specs_item {
  border: 1px solid #6e6893;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 20px 10px 10px;
  margin-bottom: 20px;
  position: relative;
  margin: 20px auto;
  width: 97%;
}

.scroller {
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 10px;
  height: calc(100vh - 94px);
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}

.showMore div:nth-child(n + 4) {
  display: none;
}

.showMore div div:nth-child(n + 4) {
  display: block;
}

.specs_item input[readonly] {
  background: #fafafa;
  border: none;
}

.specs_item section label,
.specs_item div.v-subheader {
  display: inline-block;
  min-width: 35%;
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  height: auto;
  text-transform: uppercase !important;
}

.counterNumber p,
.specs_item p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #25213b;
  margin-bottom: 5px;
}

.specs_item section input {
  height: 25px;
  margin-left: 15px;
  min-width: 60%;
  padding-left: 10px;
}

.spec_heading {
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
  border-top: 1px solid #ccc;
  margin: 15px 15px 25px;
  text-transform: uppercase !important;
  letter-spacing: 0.5px;
}

h5.spec_heading {
  border: 0;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
}

.theme--light.v-subheader {
  color: rgba(0, 0, 0, 1);
}
</style>
