var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-google-autocomplete',{ref:_vm.addressRef,attrs:{"id":_vm.addressId,"classname":"address-container","placeholder":"","fields":[
      'address_components',
      'formatted_address',
      'icon',
      'id',
      'name',
      'geometry',
      'place_id',
      'scope',
      'type',
      'url' ],"types":"address","tabindex":_vm.tabIndex},on:{"placechanged":_vm.getAddressData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }