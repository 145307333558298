<template>
  <v-container fluid class="content">
    <div>
      <v-row class="header mb-3">
        <v-col col="12" sm="5" style="display: flex"
          ><h1 class="ml-4">Agent Work Preview</h1>
          <v-btn
            class="ml-2 mt-2 white--text refreshButton"
            :loading="loading"
            :disabled="loading"
            color="#8B83BA"
            @click="pageRefresh()"
            >refresh <v-icon>mdi-cached</v-icon></v-btn
          >
        </v-col>
      </v-row>

      <v-data-table
        height="75vh"
        style="padding-right: 100px; white-space: nowrap"
        fixed-header
        :headers="headers"
        :items="contacts"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalCount"
        loading-text="Loading... Please wait"
        class="elevation-1"
        dense
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'navigate_before',
          nextIcon: 'navigate_next',
          'items-per-page-options': [5, 10, 20, 40],
        }"
      >
        <!-- Filter -->
        <template v-for="header in headers" v-slot:[`header.${header.value}`]>
          {{ header.text }}
          <v-menu
            v-if="header.filterable"
            offset-y
            :close-on-content-click="showFilterBox"
            v-bind:key="header.text"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon
                  small
                  :color="getFilterIconColor(header.value)"
                  @click="showFilterBox = false"
                >
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-text-field
                v-model="filter[header.value]"
                class="pa-4"
                type="text"
                label="Enter the search term"
              ></v-text-field>
              <v-btn
                @click="clearFilter(header.value)"
                small
                text
                color="primary"
                class="ml-2 mb-2"
                >Clear</v-btn
              >
              <v-btn
                @click="filterBy(header.value)"
                small
                text
                color="primary"
                class="ml-2 mb-2"
                >Apply</v-btn
              >
            </div>
          </v-menu>
        </template>

        <!-- Date -->
        <template v-slot:[`header.date`]="{ header }">
          {{ header.text }}
          <v-menu offset-y :close-on-content-click="showFilterBox">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon
                  small
                  :color="getFilterIconColor(header.value)"
                  @click="showFilterBox = false"
                >
                  mdi-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <DatePickerMenu
                style="padding: 10px"
                :datePlaceHolder="datePlaceHolder"
                v-model="filter[header.value]"
                :selectedDate="filter[header.value]"
                v-on:selectDate="setSelectedDateInFilter(header.value, $event)"
                :disablePastDate="false"
              />

              <v-btn
                @click="clearFilter(header.value)"
                small
                text
                color="primary"
                class="ml-2 mb-2"
                >Clear</v-btn
              >
              <v-btn
                @click="filterBy(header.value)"
                small
                text
                color="primary"
                class="ml-2 mb-2"
                >Apply</v-btn
              >
            </div>
          </v-menu>
        </template>

        <!-- Workspace -->
        <template v-slot:[`item.taskId`]="{ item }">
          <v-icon
            right
            @click="redirectToWorkSpace(item)"
            :disabled="item.disableWorkspaceRedirect"
          >
            open_in_new
          </v-icon>
        </template>

        <!-- companyWebsite ToolTip -->
        <template v-slot:[`item.companyWebsite`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{
                item.companyWebsite | truncate($constants().COLUMN_LIMIT20)
              }}</span>
            </template>
            <span>{{ item.companyWebsite }}</span>
          </v-tooltip>
        </template>

        <!-- contactSource(linkedin) ToolTip-->
        <template v-slot:[`item.contactSource(linkedin)`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <span dark v-on="on">
                {{
                  item["contactSource(linkedin)"]
                    | truncate($constants().COLUMN_LIMIT20)
                }}</span
              >
            </template>
            <span>{{ item["contactSource(linkedin)"] }}</span>
          </v-tooltip>
        </template>

        <!-- contactSource(others) ToolTip -->
        <template v-slot:[`item.contactSource(others)`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <span dark v-on="on">{{
                item["contactSource(others)"]
                  | truncate($constants().COLUMN_LIMIT20)
              }}</span>
            </template>
            <span>{{ item["contactSource(others)"] }}</span>
          </v-tooltip>
        </template>

        <!-- companyLinkedinUrl ToolTip-->
        <template v-slot:[`item.companyLinkedinUrl`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <span dark v-on="on">{{
                item.companyLinkedinUrl | truncate($constants().COLUMN_LIMIT20)
              }}</span>
            </template>
            <span>{{ item.companyLinkedinUrl }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import Api from "../services/Api";
import sanitize from "./sanitizer";
import DatePickerMenu from "./DatePickerMenu.vue";
import parsePhoneNumber from "libphonenumber-js";

export default {
  name: "AgentPreview",
  components: {
    DatePickerMenu,
  },
  data() {
    return {
      headers: [
        {
          value: "taskId",
          text: "WorkSpace",
          sortable: false,
          filterable: false,
          divider: true,
          link: true,
          align: "left",
        },
        {
          value: "date",
          text: "Date",
          sortable: true,
          filterable: true,
          divider: true,
          width: "120px",
          align: "left",
        },
        {
          value: "contactDisposeDate",
          text: "Contact Dispose Date",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "accountDisposeDate",
          text: "Account Dispose Date",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "projectName",
          text: "Project Name",
          sortable: true,
          filterable: true,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "researchStatus",
          text: "Research Status",
          sortable: true,
          filterable: true,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "email",
          text: "Email",
          sortable: true,
          filterable: true,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "companyName",
          text: "Company Name",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "accountDisposition",
          text: "Account Disposition",
          sortable: true,
          filterable: true,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "accountComments",
          text: "Account Comments",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "ccPersona(contactCustom1)",
          text: "CC Persona (contact.custom1)",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "prefix",
          text: "Prefix",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "firstName",
          text: "First Name",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "middleName",
          text: "Middle Name",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "lastName",
          text: "Last Name",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "jobTitle",
          text: "Job Title",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "department",
          text: "Department",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "function",
          text: "Function",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "level",
          text: "Level",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "address1",
          text: "Address1",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "address2",
          text: "Address2",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "city",
          text: "City",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "state",
          text: "State",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "zip",
          text: "Zip",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "country",
          text: "Country",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "phone",
          text: "Phone",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "directPhone",
          text: "Direct Phone",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "genericEmail",
          text: "Generic Email",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "zb",
          text: "ZB",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "zbTime",
          text: "ZB Time",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "gmailStatus(domainStatus)",
          text: "Gmail Status (Domain Status)",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "companyWebsite",
          text: "Company Website",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "contactDisposition",
          text: "Contact Disposition",
          sortable: true,
          filterable: true,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "contactComments",
          text: "Contact Comments",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "contactSource(others)",
          text: "Contact Source (Others)",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "contactSource(linkedin)",
          text: "Contact Source (Linkedin)",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "companyLinkedinUrl",
          text: "Company Linkedin URL",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "address1HQ",
          text: "Address1 HQ",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "address2HQ",
          text: "Address2 HQ",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "cityHQ",
          text: "City HQ",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "stateHQ",
          text: "State HQ",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "zipHQ",
          text: "Zip HQ",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "countryHQ",
          text: "Country HQ",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "phoneHQ",
          text: "Phone HQ",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "industry",
          text: "Industry",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "annualRevenue",
          text: "Annual Revenue",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "employeeSize",
          text: "Employee Size",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "employeeRange(LI)",
          text: "Employee Range (LI)",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "employeeSize(LI)",
          text: "Employee Size (LI)",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "employeeSource(LI)",
          text: "Employee Source (LI)",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "employeeSize(Z+)",
          text: "Employee Size (Z+)",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "employeeSource(Z+)",
          text: "Employee Source (Z+)",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "finalBucket",
          text: "Final Bucket",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "employeeSize(others)",
          text: "Employee Size (Others)",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
        {
          value: "source(employeeSizeOther)",
          text: "Source (Employee Size Others)",
          sortable: true,
          filterable: false,
          divider: true,
          width: "200px",
          align: "left",
        },
      ],
      dateColumns: [
        "zbTime",
        "contactDisposeDate",
        "accountDisposeDate",
        "date",
      ],
      numberColumns: ["phone", "directPhone", "phoneHQ"],
      contacts: [],
      totalCount: 0,
      loading: false,
      options: {},
      filter: {},
      defaultFilter: {
        date: new Date().toISOString().slice(0, 10), // Today Date - yyyy-mm-dd
      },
      showFilterBox: false,
      datePlaceHolder: "Select Date",
    };
  },
  mounted: function () {
    if (!this.appliedFilter)
      this.$store.commit("setAgentPreviewFilter", this.defaultFilter);
    this.filter = _.cloneDeep(this.appliedFilter);
    this.options = _.cloneDeep(this.appliedOptions);
  },
  computed: {
    appliedFilter: function () {
      return this.$store.state.agentPreviewFilter;
    },
    appliedOptions: function () {
      return this.$store.state.agentPreviewOptions;
    },
  },
  watch: {
    options: function () {
      let options = _.cloneDeep(this.options);
      this.$store.commit("setAgentPreviewOptions", options);
      this.getAgentPreviewList();
    },
    appliedFilter: function () {
      this.getAgentPreviewList();
    },
  },
  methods: {
    getFilterIconColor: function (columnName) {
      if (columnName in this.appliedFilter) {
        return "primary";
      }
      return "";
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    pageRefresh: async function () {
      this.getAgentPreviewList();
    },
    setSelectedDateInFilter(columnName, val) {
      let filter = {};
      filter[columnName] = val;
      this.filter = filter;
    },
    filterBy(columnName) {
      this.showFilterBox = true;
      var filterValue = this.filter[columnName];
      filterValue = sanitize(filterValue);

      let filter = {};
      if (columnName in this.filter && !filterValue) {
        this.filter = _.cloneDeep(filter);
        this.$store.commit("setAgentPreviewFilter", filter);
      }
      if (!filterValue) return;

      filter[columnName] = filterValue;
      this.filter = _.cloneDeep(filter);
      this.options.page = 1;
      this.$store.commit("setAgentPreviewFilter", filter);
    },
    clearFilter(columnName) {
      this.showFilterBox = true;
      if (!(columnName in this.filter)) return;

      let filter = _.cloneDeep(this.filter);
      delete filter[columnName];
      this.filter = _.cloneDeep(filter);
      this.options.page = 1;
      this.$store.commit("setAgentPreviewFilter", filter);
    },
    async getAgentPreviewList() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      this.sort = {};
      if (sortBy[0]) {
        var key = sortBy[0];
        this.sort = {
          [key]: sortDesc[0] ? "asc" : "desc",
        };
      }

      // sort and filter are not allowed together
      // if filter is applied then remove sort
      if (!_.isEmpty(this.filter)) {
        let filterColumnName = Object.keys(this.filter)[0];
        delete this.sort[filterColumnName];
      }

      var param = {
        pageNo: page - 1,
        pageSize: itemsPerPage,
        sort: this.sort,
        filter: this.appliedFilter,
      };

      Api.getAgentPreview(param)
        .then((response) => {
          this.contacts = this.formatContactData(response.data.rows);
          this.totalCount = parseInt(response.data.count);
          this.loading = false;
        })
        .catch(() => {
          //If API fails give a notification to user & stop loader
          let notification = {
            type: "error",
            showMessage: true,
            message: this.$notifications().CONTACT_DATA_TABLE_GET_ERROR,
          };
          this.setNotification(notification);
          this.loading = false;
        });
    },
    formatContactData(_contactList) {
      let contactList = _.cloneDeep(_contactList);

      for (let index = 0; index < contactList.length; index++) {
        let contact = contactList[index];

        // Formate Date Value
        for (let index = 0; index < this.dateColumns.length; index++) {
          const dateColumn = this.dateColumns[index];
          contact[dateColumn] = this.$options.filters.formatDate(
            contact[dateColumn]
          );
        }

        // Formate Number Value
        for (let index = 0; index < this.numberColumns.length; index++) {
          const numberColumn = this.numberColumns[index];
          if (!contact[numberColumn]) {
            continue;
          }
          let parsedNumber = parsePhoneNumber(contact[numberColumn]);
          if (parsedNumber) {
            contact[numberColumn] = parsedNumber.formatInternational();
          }
        }

        // Disable workspace button
        if (contact["taskStatus"] && contact["taskStatus"] === "In-Active") {
          contact.disableWorkspaceRedirect = true;
        } else {
          contact.disableWorkspaceRedirect = false;
        }
      }

      return contactList;
    },
    redirectToWorkSpace(item) {
      this.$router
        .push({
          name: "Workspace",
          params: {
            taskId: item["taskId"],
            projectId: item["projectId"],
          },
          query: {
            contactId: item["contactId"],
          },
        })
        .catch((e) => e);
    },
  },
};
</script>

<style scoped>
.content {
  margin-left: 70px;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  vertical-align: top;
}
.refreshButton {
  position: absolute;
  right: 25px;
}
.v-data-table >>> th:nth-child(1),
.v-data-table >>> td:nth-child(1) {
  position: sticky !important;
  left: 0;
  background: white;
}
.v-data-table >>> th:first-child {
  z-index: 999;
}
</style>
