module.exports = {
  getRoles: async function () {
    let roles = await localStorage.getItem("roles");
    return roles;
  },
  getRolesSync: function () {
    let roles = localStorage.getItem("roles");
    return roles;
  },
};
