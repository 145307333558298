<template>
  <div class="settings">
    <v-btn
      class="ml-2 setup_save_btn white--text"
      color="#8B83BA"
      :loading="isSaving"
      rounded
      :disabled="isSaving"
      @click="saveForm(projectId)"
    >
      Save Setup
      <v-icon right>save</v-icon>
    </v-btn>

    <div class="form">
      <div class="column first">
        <v-form ref="form" v-model="valid" lazy-validation>
          <!-- project name -->
          <v-row v-if="isOwner">
            <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
              <label class="label">Project Name</label>
            </v-col>
            <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
              <v-text-field
                v-model="project.name"
                required
                :rules="[(v) => !!v || 'Project Name is required']"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- alias project name -->
          <v-row>
            <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
              <label class="label"
                >Alias Project Name<span style="color: red">*</span></label
              >
            </v-col>
            <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
              <v-text-field
                v-model="project.aliasName"
                required
                :disabled="!isOwner"
                :rules="aliasProjectNameRules"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- project type -->
          <v-row>
            <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
              <label class="label">Project Type</label>
            </v-col>
            <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
              <v-select
                v-model="project.ProjectTypeId"
                :items="projectType"
                item-value="id"
                item-text="type"
                :rules="[(v) => !!v || 'Project Type is required']"
                label="Select Project Type"
                required
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <!-- project description -->
          <v-row>
            <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
              <label class="label">Project Description</label>
            </v-col>
            <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
              <v-textarea
                v-model="project.description"
                outlined
                dense
                rows="2"
              ></v-textarea>
            </v-col>
          </v-row>

          <!-- client Name  -->
          <v-row>
            <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
              <label class="label">Client Name</label>
            </v-col>
            <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
              <v-text-field
                v-model="project.Client.name"
                disabled
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <hr style="margin: 20px 0" />
          <v-row>
            <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
              <label class="label">Field Customization</label>
            </v-col>
            <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
              <v-select
                v-model="project.TemplateId"
                :items="fieldList"
                item-value="id"
                item-text="name"
                label="Select Template"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
              <label class="label">Contact Expiry</label>
            </v-col>
            <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
              <v-select
                v-model="project.contactExpiry"
                :items="contactExpiryList"
                item-value="value"
                item-text="title"
                label="Select Duration"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>

          <hr />

          <v-row>
            <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
              <label class="label">Received Date</label>
            </v-col>
            <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
              <DatePickerMenu
                :datePlaceHolder="receivedDateLabel"
                v-model="project.receivedDate"
                :selectedDate="project.receivedDate"
                :disablePastDate="false"
                v-on:selectDate="project.receivedDate = $event"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
              <label class="label">Due Date</label>
            </v-col>
            <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
              <DatePickerMenu
                :datePlaceHolder="dueDateLabel"
                v-model="project.dueDate"
                :selectedDate="project.dueDate"
                :disablePastDate="true"
                v-on:selectDate="project.dueDate = $event"
              />
            </v-col>
          </v-row>
        </v-form>
      </div>

      <div class="column">
        <v-row>
          <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
            <label class="label">Account Manager</label>
          </v-col>
          <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
            <v-combobox
              @keydown="changeAutoComplete($event, 'userSearch', 'owner_main')"
              @focus="changeAutoComplete($event, 'userSearch', 'owner_main')"
              v-model="projectUser.owner_main"
              :search-input.sync="search"
              :loading="loading"
              :items="items"
              item-value="id"
              item-text="fullName"
              chips
              :disabled="!isOwner"
              clearable
              deletable-chips
              multiple
              outlined
              dense
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
            <label class="label">Operations Manager</label>
          </v-col>
          <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
            <v-combobox
              @keydown="
                changeAutoComplete($event, 'userSearch', 'owner_assigned')
              "
              @focus="
                changeAutoComplete($event, 'userSearch', 'owner_assigned')
              "
              v-model="projectUser.owner_assigned"
              :search-input.sync="search"
              :loading="loading"
              :items="items"
              chips
              item-value="id"
              item-text="fullName"
              clearable
              deletable-chips
              multiple
              outlined
              dense
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
            <label class="label">Compliance</label>
          </v-col>
          <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
            <v-combobox
              @keydown="changeAutoComplete($event, 'userSearch', 'compliance')"
              @focus="changeAutoComplete($event, 'userSearch', 'compliance')"
              v-model="projectUser.compliance"
              :search-input.sync="search"
              :loading="loading"
              :items="items"
              chips
              item-value="id"
              item-text="fullName"
              clearable
              deletable-chips
              multiple
              outlined
              dense
            ></v-combobox>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
            <label class="label">Client POC</label>
          </v-col>
          <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
            <v-text-field
              v-model="project.ProjectSetting.clientPoc"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
            <label class="label">Priority</label>
          </v-col>
          <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
            <v-select
              v-model="project.ProjectSetting.priority"
              :items="['High', 'Medium', 'Low']"
              item-value="id"
              item-text="name"
              label="Choose Priority"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex pt-0 mt-2 pb-1" cols="12" sm="12">
            <label class="label">Project Status</label>
          </v-col>
          <v-col class="d-flex pt-0 mt-1 pb-1" cols="12" sm="12">
            <v-select
              v-model="project.ProjectSetting.status"
              :items="['Yet to Start', 'Active', 'On Hold', 'Completed']"
              item-value="id"
              item-text="name"
              label="Set Status"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
        <hr style="margin: 20px 0" />
        <v-row v-show="showDeleteProjectBtn">
          <v-form
            ref="deleteProjectForm"
            class="delete_project_form"
            v-model="deleteProjectValid"
            lazy-validation
          >
            <div class="text-center delete_project_btn">
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-col class="pt-0 mt-2 pb-1" cols="12" sm="12">
                    <label class="delete_project_text"
                      >Delete this Project</label
                    >
                  </v-col>

                  <v-col style="display: inline-block">
                    <div cols="2" class="d-flex">
                      <label class="delete_project_text_capitalize flex"
                        >Once you delete a <b>Project</b>, there is no going
                        back. Please be certain.</label
                      >
                      <div>
                        <v-progress-circular
                          v-show="projectDeleteProgress"
                          class="float-right"
                          :size="30"
                          :width="3"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                        <v-btn
                          class="delete_project_popup_btn flex"
                          v-bind="attrs"
                          :disabled="projectDeleteProgress"
                          v-on="on"
                        >
                          Delete this project
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    <div style="display: block; width: 100%">
                      <label> Are you <b>ABSOLUTELY</b> sure? </label>
                      <v-icon
                        @click="cancelDeleteProject()"
                        style="float: right"
                        >mdi-close</v-icon
                      >
                    </div>
                  </v-card-title>

                  <v-card-text style="padding-top: 5px" class="pt-4">
                    This action cannot be undone. This will permanently delete
                    the project
                    <span
                      style="
                        color: black;
                        font-weight: bold;
                        font-size: 17px;
                        white-space: pre-wrap;
                      "
                      >{{ projectName }}</span
                    >
                    and related associations JobError, Job, Contact, Account,
                    Contact Suppression, Account Suppression, ProjectSetting,
                    ProjectSpec, File Chunk, File
                  </v-card-text>

                  <v-card-text>
                    Please type
                    <span
                      style="
                        color: black;
                        font-weight: bold;
                        font-size: 17px;
                        white-space: pre-wrap;
                      "
                      >{{ projectName }}</span
                    >
                    to confirm.

                    <v-text-field
                      label="Enter Project Name"
                      v-model="confirmProjectName"
                      style="padding-top: 10px; padding-bottom: 0px"
                      v-on:input="checkProjectNameValid()"
                      outlined
                      dense
                      required
                      :rules="[(v) => !!v || 'Enter Project Name']"
                    ></v-text-field>

                    <v-btn
                      @click="deleteProject()"
                      class="delete_project_confirmation_btn"
                      :disabled="disableProjectDeleteConfirmBtn"
                    >
                      I Understand the consequences, delete this project
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </div>
          </v-form>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "../services/Api";
import moment from "moment";
import _ from "lodash";
import DatePickerMenu from "./DatePickerMenu.vue";
import { getRoles } from "../roles.js";

export default {
  name: "PROJECTSetup",
  props: {
    projectId: String,
  },
  components: {
    DatePickerMenu,
  },
  data() {
    return {
      isOwner: false,
      isSaving: false,
      projectDeleteProgress: false,
      loggedInUser: null,
      showDeleteProjectBtn: false,
      valid: false,
      projectType: [],
      receivedDateMenu: null,
      dueDateMenu: null,
      userLevel: null,
      autoCompleteSelected: null,
      loading: false,
      items: [],
      search: null,
      project: {
        Client: {},
        ProjectSetting: {
          target: {},
        },
        Dispositions: {},
        ProjectTypeId: null,
        Users: [],
      },
      projectUser: {
        owner_main: [],
        owner_assigned: [],
        compliance: [],
      },
      todayDate: new Date().toISOString().slice(0, 10),
      projectSetting: {},
      fieldList: [],
      projectList: [],
      projectDateMenu: null,
      receivedDateLabel: "Select Received Date",
      dueDateLabel: "Select Due Date",
      dialog: false,
      confirmProjectName: "",
      projectName: "",
      userRole: "manager",
      deleteProjectValid: false,
      disableProjectDeleteConfirmBtn: true,
      contactExpiryList: [
        { value: "0", title: "0 Days" },
        { value: "30", title: "30 Days" },
        { value: "60", title: "60 Days" },
        { value: "90", title: "90 Days" },
        { value: "120", title: "120 Days" },
        { value: "150", title: "150 Days" },
        { value: "180", title: "180 Days" },
        { value: "210", title: "210 Days" },
        { value: "240", title: "240 Days" },
        { value: "270", title: "270 Days" },
        { value: "300", title: "300 Days" },
        { value: "330", title: "330 Days" },
        { value: "360", title: "360 Days" },
      ],
      aliasProjectNameRules: [
        (v) => !!v || "Alias Project Name is required",
        (v) => (v && !!v.trim()) || "Alias Project Name is required",
      ],
    };
  },
  created: async function () {
    let role = await getRoles();
    this.setupForProjectOwner(
      role.indexOf(this.$constants().USER_ROLES.PROJECT_OWNER) > -1
    );
  },
  mounted: function () {
    this.fetchProject(this.projectId);
    this.fetchTemplates();
    this.fetchProjectType();
    this.fetchLoggedInUser();
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
  },
  methods: {
    setupForProjectOwner: function (isOwner) {
      this.isOwner = isOwner;
    },
    fetchLoggedInUser() {
      this.loggedInUser = window.localStorage.getItem("user_id");
    },
    checkProjectNameValid() {
      let self = this;
      var formValid = this.$refs.deleteProjectForm.validate();
      self.disableProjectDeleteConfirmBtn = true;
      if (
        formValid &&
        self.projectName.trim() === self.confirmProjectName.trim()
      ) {
        self.disableProjectDeleteConfirmBtn = false;
      }
    },
    deleteProject() {
      let self = this;
      self.projectDeleteProgress = true;
      var formValid = this.$refs.deleteProjectForm.validate();

      if (formValid) {
        if (self.projectName.trim() === self.confirmProjectName.trim()) {
          self.dialog = false;

          Api.deleteProject(self.projectId, {
            projectName: self.projectName,
          })
            .then((result) => {
              self.projectDeleteProgress = false;
              console.log(result);

              let notification = {
                type: "success",
                showMessage: true,
                message: self.$notifications().PROJECT_DELETE_SUCCESS,
              };
              self.setNotification(notification);

              self.$router
                .push({
                  name: "PROJECTList",
                })
                .catch((e) => e);
            })
            .catch((error) => {
              self.projectDeleteProgress = false;
              console.log(error);
              let notification = {
                type: "error",
                showMessage: true,
                message: self.$notifications().PROJECT_DELETE_ERROR,
              };
              self.setNotification(notification);
            });
        } else {
          self.projectDeleteProgress = false;
        }
      }
    },
    cancelDeleteProject() {
      this.dialog = false;
      this.confirmProjectName = "";
      this.projectDeleteProgress = false;
      this.disableProjectDeleteConfirmBtn = true;
      this.$refs.deleteProjectForm.resetValidation();
    },
    changeAutoComplete: function (event, label, userLevel) {
      this.items = [];
      this.userLevel = userLevel;
      this.autoCompleteSelected = label;
    },
    showUniqueUsers(userList) {
      var currentUsers = [].concat(
        this.projectUser.compliance,
        this.projectUser.owner_assigned,
        this.projectUser.owner_main
      );
      return _.differenceBy(userList, currentUsers, "id");
    },
    querySelections(v) {
      const self = this;
      self.loading = true;
      // Simulated ajax query
      self.items = [];
      switch (this.userLevel) {
        case "owner_main":
          this.userRole = this.$constants().USER_ROLES.PROJECT_OWNER;
          break;
        case "owner_assigned":
          this.userRole = this.$constants().USER_ROLES.MANAGER;
          break;
        case "compliance":
          this.userRole = this.$constants().USER_ROLES.COMPLIANCE;
          break;
      }
      Api.autoCompleteInterFace(this.autoCompleteSelected, {
        param: v,
        userRole: this.userRole,
      }).then(function (response) {
        let userList = _.map(response.data, function (user) {
          user.fullName = user.firstName + " " + user.lastName;
          return user;
        });
        self.items = self.showUniqueUsers(userList);
      });
      this.loading = false;
    },
    saveForm: function (projectId) {
      var formValid = this.$refs.form.validate();
      if (!formValid) {
        return;
      }

      this.isSaving = true;
      let self = this;

      if (_.get(this.project, "Client.id", null)) {
        this.$store.dispatch("setProjectClientId", this.project.Client.id);
      }

      this.projectSetting = {
        name: this.project.name,
        aliasName: this.project.aliasName,
        receivedDate: this.project.receivedDate,
        dueDate: this.project.dueDate,
        target: this.project.ProjectSetting.target,
        contactsPerAccount: this.project.ProjectSetting.contactsPerAccount,
        clientPoc: this.project.ProjectSetting.clientPoc,
        priority: this.project.ProjectSetting.priority,
        status: this.project.ProjectSetting.status,
        projectTypeId: this.project.ProjectTypeId,
        users: this.projectUser,
        clientId: this.project.Client.id,
        description: this.project.description,
        templateId: this.project.TemplateId,
        contactExpiry: this.project.contactExpiry,
      };
      return Api.editProjectSetting(projectId, this.projectSetting)
        .then(function () {
          let notification = {
            type: "success",
            showMessage: true,
            message: self.$notifications().PROJECT_SETUP_SUCCESS,
          };
          self.setNotification(notification);

          self.isSaving = false;
          self.fetchProject(projectId);
        })
        .catch((error) => {
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().PROJECT_SETUP_ERROR,
          };
          self.setNotification(notification);
          self.isSaving = false;
        });
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    fetchProject: function (projectId) {
      var self = this;
      return Api.getProject(projectId)
        .then((response) => {
          var emptyCheck = _.isEmpty(response.data);
          if (!emptyCheck) {
            self.project = response.data;
            self.projectName = self.project.name;

            if (self.project.dueDate) {
              self.project.dueDate = moment(self.project.dueDate).format(
                "yyyy-MM-DD"
              );
            } else {
              self.project.dueDate = "";
            }
            if (self.project.receivedDate) {
              self.project.receivedDate = moment(
                self.project.receivedDate
              ).format("yyyy-MM-DD");
            } else {
              self.project.receivedDate = "";
            }
            self.projectUser.owner_main = _.map(
              self.project.Users,
              function (user) {
                if (user.ProjectUser.userLevel === "owner_main") {
                  var fullName = user.firstName + " " + user.lastName;
                  return {
                    id: user.id,
                    fullName: fullName,
                  };
                }
              }
            );
            self.projectUser.owner_assigned = _.map(
              self.project.Users,
              function (user) {
                if (user.ProjectUser.userLevel === "owner_assigned") {
                  var fullName = user.firstName + " " + user.lastName;
                  return {
                    id: user.id,
                    fullName: fullName,
                  };
                }
              }
            );
            self.projectUser.compliance = _.map(
              self.project.Users,
              function (user) {
                if (user.ProjectUser.userLevel === "compliance") {
                  var fullName = user.firstName + " " + user.lastName;
                  return {
                    id: user.id,
                    fullName: fullName,
                  };
                }
              }
            );
            self.projectUser.owner_main = _.compact(
              self.projectUser.owner_main
            );
            self.projectUser.owner_assigned = _.compact(
              self.projectUser.owner_assigned
            );
            self.projectUser.compliance = _.compact(
              self.projectUser.compliance
            );
            var userIds = _.map(self.projectUser.owner_main, "id");
            var userIndex = _.indexOf(userIds, this.loggedInUser);
            if (userIndex > -1) {
              this.showDeleteProjectBtn = true;
            } else {
              this.showDeleteProjectBtn = false;
            }
            return self.project;
          }
          this.$router
            .push({
              name: "PROJECTList",
            })
            .catch((e) => e);
        })
        .catch((error) => console.log(error));
    },
    fetchUsers: function () {
      return Api.getUser({
        userRole: this.userRole,
      })
        .then((response) => {
          console.log(response.data);
          return response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchTemplates: function () {
      Api.getTemplateNames()
        .then((response) => {
          this.fieldList = response.data;
          console.dir(response.data);
        })
        .catch((error) => console.log(error));
    },
    fetchProjectType: function () {
      Api.getProjectTypes()
        .then((response) => {
          this.projectType = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
<style scoped>
.settings {
  margin-left: 50px;
}
.settings .row {
  margin: -20px 0;
}
.form {
  padding-top: 20px;
}
.column {
  width: 46%;
  float: left;
  display: block;
  margin-left: 2.5%;
  padding-right: 30px;
}
.column.first {
  border-right: 1px solid #aaa;
  margin-left: 0;
}

.form .label {
  font-family: "Lato";
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  padding-left: 5px;
  text-transform: uppercase !important;
}
.form section {
  padding: 9px 5px;
}
.form section input {
  height: 25px;
  margin-top: 5px;
  width: 95%;
  padding-left: 10px;
}
.form section select {
  height: 25px;
  margin-top: 5px;
  width: 95%;
  padding-left: 10px;
}

.form #target {
  display: flex;
  width: 100%;
  padding: 5px;
}
.form section #account,
.form section #contact {
  width: 50%;
  padding-left: 10px;
  padding-right: 40px;
}
.form section label {
  display: inline-block;
  min-width: 35%;
  font-weight: 400;
}
.form_actions button {
  padding: 8px 25px;
  border-radius: 3px;
  outline: 0;
  margin-right: 15px;
  background: white;
  border: 1px solid black;
  cursor: pointer;
}
.form hr {
  margin: 25px 0;
}
.form_actions button.save {
  background: #205fac;
  color: white;
}

.setup_save_btn {
  position: absolute;
  top: 45px;
  right: 25px;
}
.notification {
  position: absolute;
}

.right {
  float: right;
}

.delete_project_btn {
  background: lightgoldenrodyellow;
  border-color: brown;
  color: #24292e;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.delete_project_text {
  font-weight: 600;
  padding-bottom: 5px;
  text-align: left;
  font-family: Lato;
  font-size: 15px;
  text-transform: uppercase;
  color: brown;
  display: block;
  border-bottom: 1px solid;
}

.delete_project_text_capitalize {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  color: maroon;
  padding-bottom: 5px;
  text-align: left;
}

.delete_project_popup_btn {
  background-color: #f3f4f6 !important;
  border-color: rgba(27, 31, 35, 0.15);
  box-shadow: 0 1px 0 rgba(27, 31, 35, 0.1),
    inset 0 1px 0 rgba(255, 255, 255, 0.03);
  color: red !important;
  border-width: 1px;
  border-style: solid;
  border-color: brown;
  float: right;
  margin-right: 10px;
}

.delete_project_popup_btn:hover {
  background-color: #cb2431 !important;
  border-color: rgba(27, 31, 35, 0.15);
  transition-duration: 0.1s;
  border-style: solid;
  text-decoration: none;
  color: white !important;
  border-width: 1px;
  float: right;
  margin-right: 10px;
}

.delete_project_form {
  /*position: absolute;
  bottom: 10px;
  width: 45%;*/
  margin-top: 20px;
}

.delete_project_confirmation_btn {
  width: 450px;
  font-size: 12px !important;
  border-width: 1px;
  border-style: solid;
  border-color: red;
  color: rgb(228, 31, 31) !important;
  font-weight: bold;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
