import axios from "axios";
const api = axios.create({
  baseURL:
    window.location.protocol + "//" + window.location.host + "/api/v1/master/",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      // alert("Your LogIn Token Is Expired Please LogIn Again");
      let logoutDomain =
        location.protocol + "//" + location.host + "/auth/login.html";
      window.auth0Client.logout({
        returnTo: logoutDomain,
        clientId: process.env.VUE_APP_OAUTHCLIENTID,
      });
      localStorage.clear();
    } else {
      return Promise.reject(error);
    }
  }
);

const Api = {
  getMasterAccounts: async function (params) {
    return api.get("accounts", {
      params: params,
    });
  },
  getMasterContacts: async function (params) {
    return api.get("contacts", {
      params: params,
    });
  },
  getMasterJobs: async function (params) {
    return api.get("jobs", {
      params: params,
    });
  },
  downloadJobErrorCsvMaster: async function (jobId, params) {
    return api.get("jobs/" + jobId + "/jobErrors", {
      params: params,
    });
  },
  downloadMasterJobFile: async function (jobId) {
    return api.get("jobs/" + jobId + "/getSignedURL");
  },
  uploadMasterFile: async function (formData, config) {
    return api.post("/files", formData, config);
  },
  deleteMasterFile: async function (fileId) {
    return api.delete("files/" + fileId);
  },
  getSIC: async function (params) {
    return api.get("/sicCode", {
      params: params,
    });
  },
  getNAICS: async function (params) {
    return api.get("/naicsCode", {
      params: params,
    });
  },
  getAllIndustry: async function (isIntegrationValue) {
    let isIntegration = isIntegrationValue ? true : false;
    return api.get("/industry" + "?isSubIndustry=" + isIntegration);
  },
  getAllSubIndustry: async function (industrys) {
    return api.get("/subIndustry", {
      params: {
        industrys: industrys,
      },
    });
  },
  getLocations: async function () {
    return api.get("/locations");
  },
  getTechnology: async function (params) {
    return api.get("/technology", {
      params: params,
    });
  },
  getVerifyAccounts: async function (params) {
    return api.get("/verifyAccounts", {
      params,
    });
  },
  saveVerifyAccount: async function (account, changedAccountData) {
    return api.post("/verifyAccounts", {
      account,
      changedAccountData,
    });
  },
  getVerifyContacts: async function (params) {
    return api.get("/verifyContacts", {
      params,
    });
  },
  saveVerifyContact: async function (
    contact,
    changedContactData,
    accountDomain
  ) {
    return api.post("/verifyContacts", {
      contact,
      changedContactData,
      accountDomain,
    });
  },
  fetchAccount: async function (domain) {
    return api.get("/account/" + domain);
  },
  updateMasterAccount: async function (domain, payload) {
    return api.put("/accounts/" + domain, payload);
  },
  getJobLevels: async function () {
    return api.get("/jobLevels");
  },
  getJobDepartments: async function (jobLevel) {
    return api.get("/jobDepartments", {
      params: {
        jobLevel: jobLevel,
      },
    });
  },
};

export default Api;
