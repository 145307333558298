module.exports = [
  {
    label: "Error Id",
    id: "errorId",
    mapping: ["Error Id", "errorId", "rowErrorId", "Row Error Id"],
    type: ["account", "contact"],
  },
  {
    label: "Company Domain",
    id: "domain",
    mapping: ["domain", "url", "url/domain", "company domain"],
    type: ["account", "list", "accountInclusion"],
  },
  {
    label: "Name",
    id: "name",
    mapping: [
      "companyName",
      "company_companyname",
      "company name",
      "account name",
      "name",
      "account.name",
    ],
    type: ["account"],
  },
  {
    label: "parentAccountDomain",
    id: "ParentAccountDomain",
    mapping: [
      "parentAccountDomain",
      "account.parentAccountDomain",
      "parent account domain",
    ],
    type: ["account"],
  },
  {
    label: "Company Website",
    id: "website",
    mapping: [
      "companyWebsite",
      "company_website",
      "website",
      "url",
      "account.website",
    ],
    type: ["account", "list", "accountInclusion", "contactInclusion"],
  },
  {
    label: "Type",
    id: "type",
    mapping: [
      "type",
      "account.type",
      "company type",
      "entitytype",
      "entity type",
    ],
    type: ["account"],
  },
  {
    label: "Type Descriptive",
    id: "typeDescriptive",
    mapping: [
      "typeDescriptive",
      "type descriptive",
      "account.typeDescriptive",
      "company type descriptive",
    ],
    type: ["account"],
  },
  {
    label: "Alias Name",
    id: "aliasName",
    mapping: ["aliasName", "account.aliasName", "alias name"],
    type: ["account", "list"],
  },
  {
    label: "Email",
    id: "email",
    mapping: ["email", "company email", "account.email"],
    type: ["account"],
  },
  {
    label: "Industry",
    id: "industry",
    mapping: [
      "industry",
      "primary industry",
      "main industry",
      "account.industry",
    ],
    type: ["account"],
  },
  {
    label: "Sub-Industry",
    id: "subIndustry",
    mapping: [
      "sub-industry",
      "primary sub-industry",
      "subIndustry",
      "account.subIndustry",
      "sub industry",
    ],
    type: ["account"],
  },
  {
    label: "SIC Code",
    id: "sicCode",
    mapping: ["sicCode", "sic code", "sic", "account.sicCode"],
    type: ["account"],
  },
  {
    label: "SIC Description",
    id: "sicDescription",
    mapping: ["sicDescription", "sic description", "account.sicDescription"],
    type: ["account"],
  },
  {
    label: "NAICS Code",
    id: "naicsCode",
    mapping: ["naicsCode", "naics code", "naics", "account.naicsCode"],
    type: ["account"],
  },
  {
    label: "NAICS Description",
    id: "naicsDescription",
    mapping: [
      "naicsDescription",
      "naics description",
      "account.naicsDescription",
    ],
    type: ["account"],
  },
  {
    label: "Employee Size (LI)",
    id: "employeeSizeLI",
    mapping: [
      "employeeSize(li)",
      "employee size (li)",
      "employeeSizeLI",
      "account.employeeSizeLI",
      "account.employeeSizeLI",
      "employee size li",
    ],
    type: ["account"],
  },
  {
    label: "Employee Size (Z+)",
    id: "employeeSizeZPlus",
    mapping: [
      "employeeSizeZPlus",
      "employeeSize(z+)",
      "employee size (z+)",
      "employeeSizeZ_plus",
      "employeeSizeZPlus",
      "account.employeeSizeZ_plus",
      "account.employeeSizeZPlus",
      "employee size zplus",
    ],
    type: ["account"],
  },
  {
    label: "Employee Size (Others)",
    id: "employeeSizeOthers",
    mapping: [
      "employeeSize(others)",
      "employee size (others)",
      "employeeSizeOthers",
      "account.employeeSizeOthers",
      "employee size others",
    ],
    type: ["account"],
  },
  {
    label: "Revenue",
    id: "revenue",
    mapping: [
      "revenue",
      "account.revenue",
      "companyrevenue",
      "company revenue",
    ],
    type: ["account"],
  },
  {
    label: "Total Funding",
    id: "totalFunding",
    mapping: ["totalFunding", "account.totalFunding", "total funding"],
    type: ["account"],
  },
  {
    label: "IT Spend",
    id: "itSpend",
    mapping: ["itSpend", "account.itSpend", "it spend", "itspent", "it spent"],
    type: ["account"],
  },
  {
    label: "Linkedin URL",
    id: "liUrl",
    mapping: [
      "CompanyLinkedinURL",
      "Company Linkedin URL",
      "Company LI URL",
      "CompanyLIURL",
      "linkedInUrl",
      "liUrl",
      "account.linkedInUrl",
      "account.liUrl",
    ],
    type: ["account"],
  },
  {
    label: "Company Description",
    id: "description",
    mapping: [
      "CompanyDescription",
      "Company_Description",
      "Company Description",
      "description",
      "account.description",
    ],
    type: ["account"],
  },
  {
    label: "DUNS Number",
    id: "duns",
    mapping: ["duns", "account.duns", "duns number"],
    type: ["account"],
  },
  {
    label: "Disposition",
    id: "disposition",
    mapping: ["Disposition", "account.Disposition", "account disposition"],
    type: ["account"],
  },
  {
    label: "Comments",
    id: "comments",
    mapping: ["comments", "account.comments", "disposition comments"],
    type: ["account"],
  },
  {
    label: "Technology",
    id: "technology",
    mapping: [
      "segment/technology",
      "tech",
      "technology",
      "segment",
      "technology",
      "account.technology",
    ],
    type: ["account", "list"],
  },
  {
    label: "Product and Services",
    id: "productAndService",
    mapping: [
      "productAndService",
      "product and service",
      "account.productAndService",
    ],
    type: ["account"],
  },
  {
    label: "Tags",
    id: "tags",
    mapping: ["tags", "account.tags"],
    type: ["account", "list"],
  },
  {
    label: "Requested By",
    id: "requestedBy",
    mapping: [
      "agent",
      "agentname",
      "agent_name",
      "updatedBy",
      "requestedBy",
      "Requested By",
      "account.requestedBy",
    ],
    type: ["account"],
  },
  {
    label: "Founded Year",
    id: "foundedYear",
    mapping: ["foundedYear", "founded year"],
    type: ["account"],
  },
  {
    label: "Latest Funding Amount ($)",
    id: "latestFundingAmount",
    mapping: [
      "latestFundingAmount",
      "fundingAmount",
      "latestFunding",
      "latest funding amount",
    ],
    type: ["account"],
  },
  {
    label: "Last Raised At",
    id: "lastRaisedAt",
    mapping: ["lastRaisedAt", "raisedAt", "last raised at"],
    type: ["account"],
  },
  {
    label: "Last Funding Type",
    id: "lastFundingType",
    mapping: ["lastFundingType", "lastfundingtype", "last funding type"],
    type: ["account"],
  },
  {
    label: "No Of Retail Locations",
    id: "noOfRetailLocations",
    mapping: [
      "noOfRetailLocations",
      "noofretaillocations",
      "no of retail locations",
    ],
    type: ["account"],
  },
  {
    label: "SEO Description",
    id: "seoDescription",
    mapping: [
      "seoDescription",
      "SEO_Description",
      "SEO_Desc",
      "SEO_Detail",
      "seo description",
    ],
    type: ["account"],
  },
  {
    label: "Keywords",
    id: "keywords",
    mapping: ["keywords"],
    type: ["account"],
  },
  {
    label: "IsVerified",
    id: "isVerified",
    mapping: ["accountIsVerified"],
    type: ["account"],
  },
  {
    label: "Contact ID",
    id: "id",
    mapping: ["id", "contact id"],
    type: ["contact"],
  },
  {
    label: "Location ID",
    id: "LocationId",
    mapping: ["LocationId", "location id"],
    type: ["contact"],
  },
  {
    label: "First Name",
    id: "firstName",
    mapping: ["firstName", "first name"],
    type: ["contact"],
  },
  {
    label: "Middle Name",
    id: "middleName",
    mapping: ["middleName", "middle name"],
    type: ["contact"],
  },
  {
    label: "Last Name",
    id: "lastName",
    mapping: ["lastName", "last name"],
    type: ["contact"],
  },
  {
    label: "Job Title",
    id: "jobTitle",
    mapping: ["jobTitle", "job title"],
    type: ["contact"],
  },
  {
    label: "Job Level",
    id: "jobLevel",
    mapping: ["jobLevel", "job level"],
    type: ["contact"],
  },
  {
    label: "Job Department",
    id: "jobDepartment",
    mapping: ["jobDepartment", "job department"],
    type: ["contact"],
  },
  {
    label: "Job Function",
    id: "jobFunction",
    mapping: ["jobFunction", "job function", "job Function"],
    type: ["contact"],
  },
  {
    label: "Work Email",
    id: "workEmail",
    mapping: ["workEmail", "work email", "email"],
    type: ["contact", "contactInclusion"],
  },
  {
    label: "ZB Status",
    id: "zbStatus",
    mapping: ["zbStatus", "zb status", "zb"],
    type: ["contact"],
  },
  {
    label: "ZB Date",
    id: "zbDate",
    mapping: ["zbDate", "zb date", "hrzbdateandtime", "hr zb date and time"],
    type: ["contact"],
  },
  {
    label: "Domain Status",
    id: "domainStatus",
    mapping: [
      "domainStatus",
      "domain status",
      "mailtesterstatus",
      "mailtester status",
    ],
    type: ["contact"],
  },
  {
    label: "Domain Date",
    id: "domainDate",
    mapping: ["domainDate", "domain date"],
    type: ["contact"],
  },
  {
    label: "Personal Email",
    id: "personalEmail",
    mapping: [
      "personalEmail",
      "personal email",
      "genericemail",
      "generic email",
    ],
    type: ["contact"],
  },
  {
    label: "Personal Email Validation Status",
    id: "personalEmailStatus",
    mapping: ["personalEmailStatus", "personal email validation status"],
    type: ["contact"],
  },
  {
    label: "Email Tags",
    id: "emailTags",
    mapping: ["emailTags", "email tags"],
    type: ["contact"],
  },
  {
    label: "Email Open",
    id: "emailOpen",
    mapping: ["emailOpen", "email open"],
    type: ["contact"],
  },
  {
    label: "Email Click",
    id: "emailClick",
    mapping: ["emailClick", "email click"],
    type: ["contact"],
  },
  {
    label: "Direct Dial",
    id: "directDial",
    mapping: ["directDial", "direct dial", "directphone", "direct phone"],
    type: ["contact"],
  },
  {
    label: "Mobile Number 1",
    id: "mobileNumber1",
    mapping: ["mobileNumber1", "mobile number 1"],
    type: ["contact"],
  },
  {
    label: "Mobile Number 2",
    id: "mobileNumber2",
    mapping: ["mobileNumber2", "mobile number 2"],
    type: ["contact"],
  },
  {
    label: "Home Number",
    id: "homeNumber",
    mapping: ["homeNumber", "home number"],
    type: ["contact"],
  },
  {
    label: "Linkedin Handle",
    id: "linkedinHandle",
    mapping: ["linkedinHandle", "linkedin handle", "linkedinurl", "liurl"],
    type: ["contact"],
  },
  {
    label: "Facebook Handle",
    id: "facebookHandle",
    mapping: ["facebookHandle", "facebook handle"],
    type: ["contact"],
  },
  {
    label: "Twitter Handle",
    id: "twitterHandle",
    mapping: ["twitterHandle", "twitter handle"],
    type: ["contact"],
  },
  {
    label: "Contact Disposition",
    id: "disposition",
    mapping: ["disposition", "contact disposition"],
    type: ["contact"],
  },
  {
    label: "Home Address Street 1",
    id: "homeAddressStreet1",
    mapping: ["homeAddressStreet1", "home address street 1", "address.street1"],
    type: ["contact"],
  },
  {
    label: "Home Address Street 2",
    id: "homeAddressStreet2",
    mapping: ["homeAddressStreet2", "home address street 2", "address.street2"],
    type: ["contact"],
  },
  {
    label: "Home Address City",
    id: "homeAddressCity",
    mapping: ["homeAddressCity", "home address city", "address.city"],
    type: ["contact"],
  },
  {
    label: "Home Address State",
    id: "homeAddressState",
    mapping: ["homeAddressState", "home address state", "address.state"],
    type: ["contact"],
  },
  {
    label: "Home Address ZipCode",
    id: "homeAddressZipCode",
    mapping: ["homeAddressZipCode", "home address zipcode", "address.zipcode"],
    type: ["contact"],
  },
  {
    label: "Home Address Country",
    id: "homeAddressCountry",
    mapping: ["homeAddressCountry", "home address country", "address.country"],
    type: ["contact"],
  },
  {
    label: "Other Source Link  ",
    id: "otherSourceLink",
    mapping: ["otherSourceLink", "other source link"],
    type: ["contact"],
  },
  {
    label: "IsVerified",
    id: "isVerified",
    mapping: ["contactIsVerified"],
    type: ["contact"],
  },
  {
    label: "LocationType",
    id: "locationType",
    mapping: ["location.locationType", "locationType", "location type"],
    type: ["location"],
  },
  {
    label: "Address 1",
    id: "address1",
    mapping: [
      "address1",
      "address1HQ",
      "address_1",
      "address_1HQ",
      "address 1",
      "address 1HQ",
      "company street address",
      "address",
      "addressHQ",
      "location.address",
      "account.addressHQ",
      "addressHQ.address1HQ",
      "account.addressHQ.address1HQ",
      "companyaddress1",
      "company address 1",
    ],
    type: ["location"],
  },
  {
    label: "Address 2",
    id: "address2",
    mapping: [
      "address2",
      "address2HQ",
      "address_2",
      "address_2HQ",
      "address 2",
      "address 2HQ",
      "company street address",
      "address",
      "addressHQ",
      "location.address",
      "account.addressHQ",
      "addressHQ.address2HQ",
      "account.addressHQ.address2HQ",
      "companyaddress2",
      "company address 2",
    ],
    type: ["location"],
  },
  {
    label: "City",
    id: "city",
    mapping: [
      "city",
      "company city",
      "address.city",
      "location.city",
      "companycity",
      "company city",
    ],
    type: ["location"],
  },
  {
    label: "State",
    id: "state",
    mapping: [
      "state",
      "company state",
      "location.state",
      "account.state",
      "companystate",
      "company state",
    ],
    type: ["location"],
  },
  {
    label: "Zip",
    id: "zipCode",
    mapping: [
      "zip",
      "zipcode",
      "zip_",
      "zip",
      "company zip code",
      "zip",
      "account.zipCode",
      "zip code",
      "companyzipcode",
      "company zipcode",
    ],
    type: ["location"],
  },
  {
    label: "Country",
    id: "country",
    mapping: [
      "country",
      "company country",
      "account.country",
      "companycountry",
      "company country",
    ],
    type: ["location"],
  },
  {
    label: "Phone 1",
    id: "phone1",
    mapping: [
      "phone",
      "phone1",
      "company phone1",
      "account.phone1",
      "location.phone1",
      "phone 1",
    ],
    type: ["location"],
  },
  {
    label: "Phone 2",
    id: "phone2",
    mapping: [
      "phone2",
      "phone 2",
      "company phone2",
      "account.phone2",
      "location.phone2",
    ],
    type: ["location"],
  },
];
