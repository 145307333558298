<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          <div style="display: inline-block">
            <label> Account Dispose Error </label>
            <v-icon @click="cancel()" style="float: right">mdi-close</v-icon>
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="mb-1">
                    <p style="font-size: 18px; font-family: 'Lato'">
                      Error:
                      <br />
                      <b style="color: red"> {{ disposeErr }} </b>
                    </p>
                  </div>
                  <div class="mb-1">
                    <p style="font-size: 18px; font-family: 'Lato'">
                      Reason:
                      <br />
                      <b style="color: red"> {{ disposeErrReason }} </b>
                    </p>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
        </v-card-title>

        <v-card-text style="padding-top: 5px" class="pt-4"> </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AccountDisposeError",
  data() {
    return {
      dialog: false,
      disposeErr: "",
      disposeErrReason: "",
    };
  },
  computed: {
    accountDispositionError: function () {
      return this.$store.state.accountDisposeError;
    },
  },
  watch: {
    accountDispositionError: function (value) {
      if (value && value.desc && value.err) {
        this.dialog = true;
        this.disposeErrReason = value.err;
        this.disposeErr = value.desc;
      }
    },
  },
  methods: {
    cancel: function () {
      this.dialog = false;
      this.$store.dispatch("populateAccountDisposeError", {});
    },
  },
};
</script>

<style scoped></style>
