<template>
  <v-menu
    class="mt-1"
    :close-on-content-click="false"
    v-model="filtersMenu"
    :nudge-width="800"
    :max-width="800"
    offset-y
    back
    transition="slide-y-transition"
  >
    <!-- Filter tooltip -->
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            style="margin-left: 10px !important"
            color="#8B83BA"
            depressed
            fab
            small
            dark
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
            @click="loadFilterForm()"
            ><span class="material-icons"> filter_alt </span>
          </v-btn>
        </template>
        <span>Filters</span>
      </v-tooltip>
    </template>

    <!-- Filter & Search -->
    <v-form
      ref="GeneralizedFilterForm"
      @submit.prevent="applyFilter()"
      style="background: white"
      lazy-validation
    >
      <h3 class="filterHeadersTitle pt-3">Filter & Search</h3>
      <v-list class="pa-4">
        <v-row class="filterHeaders mt-0" style="border-bottom: 1px solid #eee">
          <v-col class="py-1"><span>field to search</span></v-col>
          <v-col class="py-1"><span>type of search</span></v-col>
          <v-col class="py-1"><span>search for...</span></v-col>
        </v-row>
        <v-row>
          <!-- Filter By -->
          <v-col>
            <v-select
              v-model="filterBy"
              item-text="text"
              item-value="value"
              :items="filterableFields"
              v-on:change="processOperatorsAndItsValues()"
              label="Filter By"
              :rules="[rules.notEmptyRule]"
              required
            ></v-select>
          </v-col>

          <!-- Operator -->
          <v-col>
            <v-select
              v-model="operator"
              item-text="text"
              item-value="value"
              :disabled="!isFilterBySelected"
              :items="filterableFieldsAllowedOperators"
              v-on:change="processValuesSelection()"
              label="Operator"
              :rules="[rules.notEmptyRule]"
              required
            ></v-select>
          </v-col>

          <!-- searchFor -->
          <v-col>
            <!-- For Custom Array Of Objects Input -->
            <!-- string -->
            <v-autocomplete
              v-if="
                valueSelectionType == 'string' &&
                selectedFilterItemText &&
                selectedFilterItemValue
              "
              v-model="filterValueStr"
              :search-input.sync="inputSearch"
              :item-text="selectedFilterItemText"
              :item-value="selectedFilterItemValue"
              :disabled="!isFilterBySelected || !isFilterValuesRequired"
              :items="filterableFieldsAllowedValues"
              label="Value"
              no-filter
              :rules="isFilterValuesRequired ? [rules.notEmptyRule] : []"
              required
            ></v-autocomplete>

            <!-- For String/Number Input -->
            <v-text-field
              v-else-if="
                (valueSelectionType == 'string' ||
                  valueSelectionType == 'number') &&
                !filterableFieldsAllowedValues.length
              "
              v-model="filterValueStr"
              :disabled="!isFilterBySelected || !isFilterValuesRequired"
              :type="valueSelectionType == 'number' ? 'number' : 'text'"
              label="Value"
              :rules="isFilterValuesRequired ? [rules.notEmptyRule] : []"
              required
            ></v-text-field>

            <!-- For Array Of Strings Input -->
            <v-autocomplete
              v-if="
                valueSelectionType == 'string' &&
                filterableFieldsAllowedValues.length &&
                !selectedFilterItemText &&
                !selectedFilterItemValue
              "
              v-model="filterValueStr"
              :disabled="!isFilterBySelected || !isFilterValuesRequired"
              :items="filterableFieldsAllowedValues"
              label="Value"
              :rules="isFilterValuesRequired ? [rules.notEmptyRule] : []"
              required
            ></v-autocomplete>

            <!-- For String Input - Multipe Values -->
            <v-combobox
              v-if="
                valueSelectionType == 'array' &&
                !filterableFieldsAllowedValues.length
              "
              v-model="filterValueArr"
              :disabled="!isFilterBySelected || !isFilterValuesRequired"
              :rules="isFilterValuesRequired ? [rules.notEmptyRule] : []"
              label="Value"
              multiple
              dense
              @change="enableApply()"
              chips
              deletable-chips
              required
            ></v-combobox>

            <!-- For Array Of Strings Input (For Multi Selection) -->
            <v-autocomplete
              v-else-if="
                valueSelectionType == 'array' &&
                selectedFilterItemText &&
                selectedFilterItemValue
              "
              v-model="filterValueArr"
              :search-input.sync="inputSearch"
              :item-text="selectedFilterItemText"
              :item-value="selectedFilterItemValue"
              :disabled="!isFilterBySelected || !isFilterValuesRequired"
              :items="filterableFieldsAllowedValues"
              label="Value"
              no-filter
              :rules="isFilterValuesRequired ? [rules.notEmptyRule] : []"
              required
              multiple
              dense
              chips
              deletable-chips
            ></v-autocomplete>

            <!-- array -->
            <v-autocomplete
              v-else-if="valueSelectionType == 'array'"
              v-model="filterValueArr"
              :disabled="
                !isFilterBySelected ||
                !isFilterValuesRequired ||
                selectedOperatorValue === 'isNull'
              "
              :items="filterableFieldsAllowedValues"
              clearable
              dense
              label="Value"
              :rules="
                filterableFieldsAllowedValues.length &&
                selectedOperatorValue !== 'isNull'
                  ? [rules.listNotEmptyRule]
                  : []
              "
              @change="enableApply()"
              required
              chips
              deletable-chips
              multiple
            ></v-autocomplete>

            <!-- For Custom Date Input -->
            <v-menu
              v-if="
                valueSelectionType == 'date' &&
                selectedOperatorValue != 'between'
              "
              :disabled="!isFilterBySelected || !isFilterValuesRequired"
              v-model="dateMenu"
              :close-on-content-click="false"
              min-width="290px"
              nudge-right="30px"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :rules="isFilterValuesRequired ? [rules.notEmptyRule] : []"
                  v-model="filterValueStr"
                  @click="emptyFilterValue()"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterValueStr"
                @input="dateMenu = false"
              ></v-date-picker>
            </v-menu>

            <!-- For Custom Date Input (In Range) -->
            <v-menu
              v-if="
                valueSelectionType == 'date' &&
                selectedOperatorValue == 'between'
              "
              :disabled="!isFilterBySelected || !isFilterValuesRequired"
              v-model="dateMenu"
              :close-on-content-click="false"
              min-width="290px"
              nudge-right="30px"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :rules="
                    isFilterValuesRequired
                      ? [rules.notEmptyRule, rules.validDateRangeRule]
                      : []
                  "
                  v-model="filterValueStr"
                  label="Date"
                  prepend-icon="mdi-calendar"
                  @click="emptyFilterValue()"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker range v-model="filterValueArr">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateMenu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="formatDateRangeView()">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <!-- apply -->
        <v-list-item class="d-flex flex-row-reverse">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="ml-2 white--text"
              color="#8B83BA"
              depressed
              :disabled="applyDisabled"
              type="submit"
            >
              Apply
            </v-btn>
          </v-card-actions>
        </v-list-item>
      </v-list>
    </v-form>
  </v-menu>
</template>

<script>
import _ from "lodash";

export default {
  name: "GeneralizedFilter",
  components: {},
  props: {
    // {
    //   text: "Name of the filter",
    //   type: "string"/"number"/"array"/"date",
    //   operators: ["IS", "IS NULL","BETWEEN","LESS THAN", "GREATER THAN", "BEGINS WITH"],
    //   values: [], // listing data
    //   itemText: // display which property of object for listing
    //   itemValue: // bind which property of object of selected
    //   search: boolean
    // }
    inputFilterObject: Object,
  },
  data() {
    return {
      applyDisabled: false,
      inputSearch: "",
      dateMenu: false,
      filtersMenu: false,
      outputFilterObject: {},
      isFilterBySelected: false,
      isFilterValuesRequired: false,
      operators: [
        { key: "IS", value: "=" },
        { key: "LESS THAN", value: "<" },
        { key: "GREATER THAN", value: ">" },
        { key: "IS NULL", value: "isNull" },
        { key: "BETWEEN", value: "between" },
        { key: "BEGINS WITH", value: "beginsWith" },
      ],
      filterableFields: [],
      filterableFieldsAllowedOperators: [],
      filterableFieldsAllowedValues: [],
      filterableFieldText: "",
      filterBy: "",
      filterValueStr: "",
      filterValueArr: [],
      operator: "",
      selectedOperatorValue: "",
      selectedFilterItemText: "",
      selectedFilterItemValue: "",
      selectedFilterSearch: false,
      valueSelectionType: "string",
      rules: {
        notEmptyRule: (value) => {
          if (!value) {
            return "This is required";
          }
          return true;
        },
        listNotEmptyRule: (value) => {
          if (!value.length) {
            return "This is required";
          }
          return true;
        },
        validDateRangeRule: (_value) => {
          const value = _value.split(" ~ ");
          if (value.length < 2) {
            return "Select date in range";
          }
          if (value[0] > value[1]) {
            return "This is invalid start and end date";
          }
          return true;
        },
      },
    };
  },
  mounted: function () {
    this.setFilterableFields();
  },
  computed: {},
  watch: {
    filterBy: function (value) {
      this.emitFilterBy(value);
    },
    inputSearch: _.debounce(function (value) {
      if (this.selectedFilterSearch) {
        this.emitSearchParam(value);
      }
    }, 500),
    inputFilterObject: {
      handler(value) {
        if (this.selectedFilterSearch) {
          this.filterableFieldsAllowedValues = _.get(
            value,
            [this.filterBy, "values"],
            []
          );
        }
        this.setFilterableFields();
      },
      deep: true,
    },
  },
  methods: {
    enableApply: function () {
      this.applyDisabled = false;
      if (!this.filterValueArr.length) {
        this.applyDisabled = true;
      }
    },
    emitFilterBy: function (value) {
      this.$emit("fetchFilters", value);
    },
    emitSearchParam: function (value) {
      const searchInput = {
        param: value,
        column: this.filterBy,
      };
      this.$emit("searchValue", searchInput);
    },
    formatDateRangeView: function () {
      this.filterValueStr = this.filterValueArr.join(" ~ ");
      this.dateMenu = false;
    },
    loadFilterForm: function () {
      this.filtersMenu = true;
      this.isFilterBySelected = false;
      this.isFilterValuesRequired = false;
      this.valueSelectionType = "string";
      if (!this.$refs.GeneralizedFilterForm) return;
      this.$refs.GeneralizedFilterForm.reset();
      this.resetFilter();
    },
    resetFilter: function () {
      this.filterBy = "";
      this.operator = "";
      this.selectedOperatorValue = "";
      this.selectedFilterItemText = "";
      this.selectedFilterItemValue = "";
      this.selectedFilterSearch = false;
      this.filterValueStr = "";
      this.filterValueArr = [];
    },
    setFilterableFields: function () {
      if (!this.inputFilterObject) {
        return;
      }
      for (const [key, subProps] of Object.entries(this.inputFilterObject)) {
        this.filterableFields.push({
          text: _.get(subProps, "text", key),
          value: key,
        });
      }
    },
    processValuesSelection: function () {
      this.filterValueStr = "";
      this.filterValueArr = [];
      const selectedOperator = _.find(this.operators, { key: this.operator });
      this.selectedOperatorValue = _.get(selectedOperator, "value", "");
      if (this.selectedOperatorValue === "isNull") {
        this.isFilterValuesRequired = false;
        return;
      }
      this.isFilterValuesRequired = true;
      return;
    },
    processOperatorsAndItsValues: function () {
      this.operator = "";
      this.selectedOperatorValue = "";
      this.selectedFilterItemText = "";
      this.selectedFilterItemValue = "";
      this.selectedFilterSearch = false;
      this.filterValueStr = "";
      this.filterValueArr = [];
      this.isFilterBySelected = true;
      this.isFilterValuesRequired = true;
      this.$refs.GeneralizedFilterForm.resetValidation();
      this.filterableFieldsAllowedOperators = _.get(
        this.inputFilterObject,
        [this.filterBy, "operators"],
        []
      );
      this.valueSelectionType = _.get(
        this.inputFilterObject,
        [this.filterBy, "type"],
        ""
      );
      this.filterableFieldsAllowedValues = _.get(
        this.inputFilterObject,
        [this.filterBy, "values"],
        []
      );

      this.filterableFieldText = _.get(
        this.inputFilterObject,
        [this.filterBy, "text"],
        this.filterBy
      );

      this.selectedFilterItemText = _.get(
        this.inputFilterObject,
        [this.filterBy, "itemText"],
        ""
      );

      this.selectedFilterItemValue = _.get(
        this.inputFilterObject,
        [this.filterBy, "itemValue"],
        ""
      );

      this.selectedFilterSearch = _.get(
        this.inputFilterObject,
        [this.filterBy, "search"],
        false
      );
      if (
        this.valueSelectionType == "array" &&
        !this.filterableFieldsAllowedValues.length
      ) {
        this.applyDisabled = true;
      }
    },
    applyFilter: function () {
      let formValid = this.$refs.GeneralizedFilterForm.validate();
      if (!formValid) {
        return;
      }
      let selectedOperator = _.find(this.operators, { key: this.operator });
      this.selectedOperatorValue = _.get(selectedOperator, "value", "");
      this.outputFilterObject = {};
      this.outputFilterObject[this.filterBy] = {};
      this.outputFilterObject[this.filterBy]["operator"] =
        this.selectedOperatorValue;
      this.outputFilterObject[this.filterBy]["text"] = this.filterableFieldText;
      this.outputFilterObject[this.filterBy]["itemText"] =
        this.selectedFilterItemText;
      this.outputFilterObject[this.filterBy]["itemValue"] =
        this.selectedFilterItemValue;
      this.outputFilterObject[this.filterBy]["search"] =
        this.selectedFilterSearch;
      this.outputFilterObject[this.filterBy]["type"] = this.valueSelectionType;
      if (
        this.valueSelectionType === "number" ||
        this.valueSelectionType === "string" ||
        (this.valueSelectionType === "date" &&
          this.selectedOperatorValue !== "between")
      ) {
        this.outputFilterObject[this.filterBy]["value"] =
          this.filterValueStr.trim();
      } else if (
        this.valueSelectionType === "array" ||
        (this.valueSelectionType === "date" &&
          this.selectedOperatorValue === "between")
      ) {
        this.filterValueArr = this.filterValueArr.map((value) => {
          return value.trim();
        });
        this.outputFilterObject[this.filterBy]["value"] = this.filterValueArr;
      }
      this.filtersMenu = false;
      this.$emit("filterObject", this.outputFilterObject);
    },
    emptyFilterValue: function () {
      this.filterValueStr = "";
      this.filterValueArr = [];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.accordion {
  width: 550px;
}

.dateError {
  width: 358px;
  color: red;
  margin-right: 400px;
}

.lbl {
  width: 125px;
}

.filterHeaders span {
  color: #857db1;
  font-weight: 800;
  text-transform: uppercase;
}
.filterHeadersTitle {
  padding-left: 15px;
  font-size: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  color: #00458d;
  background: repeating-linear-gradient(350deg, #f7f9fb, transparent 5px);
}
.date {
  width: 200px;
}
</style>
