<template>
  <div class="app">
    <div class="content">
      <div class="header">
        <h1>Master Database</h1>
      </div>

      <!-- tabs -->
      <div class="tabs">
        <ul>
          <!-- account -->
          <li :class="{ 'is-active': 'account' == showTab }">
            <a @click="switchTab('account')">
              <span>Accounts</span>
            </a>
          </li>

          <!-- contact -->
          <li :class="{ 'is-active': 'contact' == showTab }">
            <a @click="switchTab('contact')">
              <span>Contacts</span>
            </a>
          </li>

          <!-- Jobs -->
          <li v-if="allowAccess" :class="{ 'is-active': 'job' == showTab }">
            <a @click="switchTab('job')">
              <span>Jobs</span>
            </a>
          </li>

          <!-- download -->
          <li :class="{ 'is-active': 'download' == showTab }">
            <a @click="switchTab('download')">
              <span>Downloads</span>
            </a>
          </li>

          <!-- approval -->
          <li
            v-if="allowAccess"
            :class="{ 'is-active': 'approval' == showTab }"
          >
            <a @click="switchTab('approval')">
              <span
                >Account
                <sup
                  style="
                    font-size: 10px;
                    background: #205fac;
                    margin-left: 3px;
                    border: 1px solid #205fac;
                    padding: 1px 3px;
                    color: white;
                    border-radius: 4px;
                  "
                  >Approvals</sup
                >
              </span>
            </a>
          </li>
          <!-- Contacts Approval -->
          <li
            v-if="allowAccess"
            :class="{ 'is-active': 'contactApproval' == showTab }"
          >
            <a @click="switchTab('contactApproval')">
              <span
                >Contact
                <sup
                  style="
                    font-size: 10px;
                    background: #205fac;
                    margin-left: 3px;
                    border: 1px solid #205fac;
                    padding: 1px 3px;
                    color: white;
                    border-radius: 4px;
                  "
                  >Approvals</sup
                >
              </span>
            </a>
          </li>
        </ul>

        <!-- showTabs -->
        <div class="tab-details">
          <div v-if="'account' == showTab">
            <AccountMaster ref="accountMaster" />
          </div>

          <div v-if="'contact' == showTab">
            <ContactMaster ref="ContactMaster" />
          </div>

          <div v-if="allowAccess && 'job' == showTab">
            <JobMaster ref="jobMaster" />
          </div>

          <div v-if="'download' == showTab">
            <DownloadMaster ref="downloadMaster" />
          </div>

          <div v-if="allowAccess && 'approval' == showTab">
            <VerifyAccountMaster ref="verifyAccountMaster" />
          </div>

          <div v-if="allowAccess && 'contactApproval' == showTab">
            <VerifyContactMaster ref="verifyContactMaster" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountMaster from "./AccountMaster.vue";
import ContactMaster from "./ContactMaster.vue";
import JobMaster from "./JobMaster.vue";
import DownloadMaster from "./DownloadMaster.vue";
import VerifyAccountMaster from "./VerifyAccountMaster.vue";
import VerifyContactMaster from "./VerifyContactMaster.vue";
import { getRoles } from "../roles.js";

export default {
  name: "DetailMaster",
  components: {
    AccountMaster,
    ContactMaster,
    JobMaster,
    DownloadMaster,
    VerifyAccountMaster,
    VerifyContactMaster,
  },
  props: {},
  data() {
    return {
      allowAccess: false,
      showTab: "",
    };
  },
  async created() {
    this.showTab = this.$route.params.tab;
    let role = await getRoles();
    if (role.indexOf("compliance") > -1) {
      this.allowAccess = true;
    }
  },
  methods: {
    switchTab(payload) {
      this.showTab = payload;
      switch (this.showTab) {
        case "account":
        case "download":
        case "job":
        case "contact":
        case "approval":
        case "contactApproval":
          this.$router
            .push({
              name: "Master Database",
              params: {
                tab: this.showTab,
              },
            })
            .catch((e) => e);
          break;
        default:
          break;
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  margin-left: 60px;
}
.header {
  float: left;
  display: block;
  width: 100%;
  margin: 15px 0 10px;
}
a {
  text-decoration: none;
}
button.btn {
  float: right;
  background: #ffffff;
  border: 1px solid #c6c2de;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  padding: 5px 15px;
  cursor: pointer;
}
.header h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
  padding-bottom: 8px;
  padding-left: 15px;
  border-bottom: 1px solid #ccc;
}
.tabs li {
  list-style: none;
}
.tabs li a {
  align-items: center;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  padding: 0.5em 1em;
  vertical-align: top;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.tabs li:hover a {
  color: #205fac;
}
.tabs ul {
  align-items: center;
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
}
.tabs li.is-active a {
  border: 1px solid #dbdbdb;
  border-top: 3px solid;
  border-bottom: #f7f9fb;
  color: #205fac;
  background: repeating-linear-gradient(350deg, #f7f9fb, transparent 5px);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-weight: 800;
  text-decoration: none;
}
.tab-details {
  padding-top: 20px;
  background: repeating-linear-gradient(350deg, #f7f9fb, transparent 5px);
}
</style>
