var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"content",attrs:{"fluid":""}},[_c('div',[_c('v-row',{staticClass:"header mb-3"},[_c('v-col',{staticStyle:{"display":"flex"},attrs:{"col":"12","sm":"5"}},[_c('h1',{staticClass:"ml-4"},[_vm._v("Agent Work Preview")]),_c('v-btn',{staticClass:"ml-2 mt-2 white--text refreshButton",attrs:{"loading":_vm.loading,"disabled":_vm.loading,"color":"#8B83BA"},on:{"click":function($event){return _vm.pageRefresh()}}},[_vm._v("refresh "),_c('v-icon',[_vm._v("mdi-cached")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"padding-right":"100px","white-space":"nowrap"},attrs:{"height":"75vh","fixed-header":"","headers":_vm.headers,"items":_vm.contacts,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalCount,"loading-text":"Loading... Please wait","dense":"","footer-props":{
        showFirstLastPage: true,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'navigate_before',
        nextIcon: 'navigate_next',
        'items-per-page-options': [5, 10, 20, 40],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(){return [_vm._v(" "+_vm._s(header.text)+" "),(header.filterable)?_c('v-menu',{key:header.text,attrs:{"offset-y":"","close-on-content-click":_vm.showFilterBox},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.getFilterIconColor(header.value)},on:{"click":function($event){_vm.showFilterBox = false}}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Enter the search term"},model:{value:(_vm.filter[header.value]),callback:function ($$v) {_vm.$set(_vm.filter, header.value, $$v)},expression:"filter[header.value]"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.clearFilter(header.value)}}},[_vm._v("Clear")]),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.filterBy(header.value)}}},[_vm._v("Apply")])],1)]):_vm._e()]},proxy:true}}),{key:"header.date",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":_vm.showFilterBox},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.getFilterIconColor(header.value)},on:{"click":function($event){_vm.showFilterBox = false}}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('DatePickerMenu',{staticStyle:{"padding":"10px"},attrs:{"datePlaceHolder":_vm.datePlaceHolder,"selectedDate":_vm.filter[header.value],"disablePastDate":false},on:{"selectDate":function($event){return _vm.setSelectedDateInFilter(header.value, $event)}},model:{value:(_vm.filter[header.value]),callback:function ($$v) {_vm.$set(_vm.filter, header.value, $$v)},expression:"filter[header.value]"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.clearFilter(header.value)}}},[_vm._v("Clear")]),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.filterBy(header.value)}}},[_vm._v("Apply")])],1)])]}},{key:"item.taskId",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{attrs:{"right":"","disabled":item.disableWorkspaceRedirect},on:{"click":function($event){return _vm.redirectToWorkSpace(item)}}},[_vm._v(" open_in_new ")])]}},{key:"item.companyWebsite",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("truncate")(item.companyWebsite,_vm.$constants().COLUMN_LIMIT20)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.companyWebsite))])])]}},{key:"item.contactSource(linkedin)",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({attrs:{"dark":""}},on),[_vm._v(" "+_vm._s(_vm._f("truncate")(item["contactSource(linkedin)"],_vm.$constants().COLUMN_LIMIT20)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item["contactSource(linkedin)"]))])])]}},{key:"item.contactSource(others)",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({attrs:{"dark":""}},on),[_vm._v(_vm._s(_vm._f("truncate")(item["contactSource(others)"],_vm.$constants().COLUMN_LIMIT20)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item["contactSource(others)"]))])])]}},{key:"item.companyLinkedinUrl",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({attrs:{"dark":""}},on),[_vm._v(_vm._s(_vm._f("truncate")(item.companyLinkedinUrl,_vm.$constants().COLUMN_LIMIT20)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.companyLinkedinUrl))])])]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }