<template>
  <div class="content templates">
    <div class="header">
      <h1 class="col-md-11 py-1">
        Templates
        <span v-if="currentTemplate.name"> > {{ currentTemplate.name }}</span>
        <span v-if="typeListMapping[type]"> > {{ typeListMapping[type] }}</span>
      </h1>
      <v-divider></v-divider>
    </div>

    <template>
      <v-stepper class="container-fluid" v-model="elementIndex">
        <v-stepper-header>
          <v-stepper-step :complete="elementIndex > 1" step="1">
            Choose Template
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="elementIndex > 2" step="2">
            Select Type
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="elementIndex > 3" step="3">
            Edit Mapping
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step step="4"> Preview </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <div class="header mb-4">
                    <h1 style="font-size: 20px; font-weight: 400; float: left">
                      List of Templates
                    </h1>
                  </div>
                  <v-dialog
                    v-model="createTemplateDialog"
                    persistent
                    color="primary"
                    max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        depressed
                        class="mr-2 float-right"
                        dark
                        style="display: none"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Create New
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title color="primary" dark>
                        <span class="text-h5">Save as New Template</span>
                      </v-card-title>
                      <v-card-text>
                        <v-text-field
                          label="Template Name"
                          v-model="newTemplateName"
                          @input="templateError = ''"
                          required
                          outlined
                        ></v-text-field>

                        <v-alert outlined type="error" v-if="templateError">
                          {{ templateError }}
                        </v-alert>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="
                            () => {
                              createTemplateDialog = false;
                              templateError = '';
                            }
                          "
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          :disabled="!newTemplateName"
                          @click="saveTemplateAs({ name: newTemplateName })"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-card
                    elevation="1"
                    outlined
                    style="border-radius: 4px"
                    class="mb-2 d-flex"
                    v-bind:class="{ highlight: templateItem.id === template }"
                    v-for="templateItem in templateList"
                    :key="templateItem.id"
                  >
                    <v-card-title class="col-md-8">
                      <!-- <v-icon
                        left
                        color="green"
                        v-if="templateItem.id === template"
                      >
                        check_circle
                      </v-icon>
                      <v-icon left color="gray" v-else>
                        radio_button_unchecked
                      </v-icon> -->
                      {{ templateItem.name }}
                    </v-card-title>
                    <v-card-actions class="d-block col-md-4">
                      <v-btn
                        color="primary"
                        depressed
                        size="sm"
                        class="float-right ml-2"
                        @click="setTemplateName(templateItem.id)"
                      >
                        View
                        <v-icon right dark> navigate_next </v-icon>
                      </v-btn>
                      <v-btn
                        color="primary"
                        depressed
                        class="ml-2 float-right"
                        dark
                        size="sm"
                        @click="openCloneDialog(templateItem.id)"
                      >
                        Clone
                        <v-icon right dark> content_copy </v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <v-dialog
                    v-model="saveTemplateDialog"
                    persistent
                    color="primary"
                    max-width="600px"
                  >
                    <v-card>
                      <v-card-title color="primary" dark>
                        <span class="text-h5">Clone Template</span>
                      </v-card-title>
                      <v-card-text>
                        <v-text-field
                          label="Template Name"
                          @input="templateError = ''"
                          v-model="cloneCurrentTemplate.name"
                          required
                          outlined
                        ></v-text-field>

                        <v-alert outlined type="error" v-if="templateError">
                          {{ templateError }}
                        </v-alert>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="
                            () => {
                              saveTemplateDialog = false;
                              templateError = '';
                            }
                          "
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          :disabled="!cloneCurrentTemplate.name"
                          @click="saveTemplateAs(cloneCurrentTemplate)"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-container>
              <v-btn depressed class="mr-2" @click="navigateSteps(1, 'back')"
                ><v-icon left>navigate_before</v-icon> Back
              </v-btn>
              <v-row>
                <v-col class="mt-6" cols="12" sm="8">
                  <v-card
                    elevation="1"
                    outlined
                    style="border-radius: 4px"
                    class="mb-2 d-flex col-md-12"
                    v-for="typeItem in typeList"
                    v-bind:class="{ highlight: typeItem.id === type }"
                    :key="typeItem.id"
                  >
                    <v-card-title class="col-md-8">
                      <!-- <v-icon left color="green" v-if="typeItem.id === type">
                        check_circle
                      </v-icon>
                      <v-icon left color="gray" v-else>
                        radio_button_unchecked
                      </v-icon> -->
                      {{ typeItem.name }}
                    </v-card-title>
                    <v-card-actions class="d-block col-md-4">
                      <v-btn
                        color="primary"
                        depressed
                        size="sm"
                        class="float-right ml-2"
                        @click="setType(typeItem.id)"
                      >
                        Customize
                        <v-icon right dark> dashboard_customize </v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-content>

          <v-stepper-content step="3" class="pt-0">
            <div class="container">
              <div class="col-md-12 text-right mb-4">
                <v-btn
                  depressed
                  class="mr-2 float-left"
                  @click="navigateSteps(2, 'back')"
                  ><v-icon left>navigate_before</v-icon> Back
                </v-btn>
                <v-btn color="primary" @click="assignPreview()">
                  Preview <v-icon right>preview</v-icon></v-btn
                >
              </div>
              <templateMapping
                ref="templateMapping"
                @selectTemplate="setTemplate($event)"
                @setCsvTemplate="setCsvTemplate($event)"
                :templateId="template"
                :type="type"
              />
            </div>
          </v-stepper-content>

          <v-stepper-content step="4">
            <div class="col-md-12 text-right mb-4">
              <v-btn depressed class="mr-2" @click="navigateSteps(3, 'back')"
                ><v-icon left>navigate_before</v-icon> Back
              </v-btn>
              <v-btn depressed class="mr-2" @click="saveTemplate(true)">
                Save & Continue Editing
              </v-btn>
              <v-btn class="mr-2" color="primary" @click="saveTemplate(false)">
                Save Template
              </v-btn>
            </div>
            <v-card outlined class="mb-12">
              <div v-if="type === '01'">
                <contactFormPreview />
              </div>
              <div v-if="type === '02'">
                <contactFormReadOnlyPreview />
              </div>
              <div v-if="type === '03'">
                <accountFormPreview />
              </div>
              <div v-if="type === '04' || type === '05' || type === '06'">
                <v-data-table
                  :headers="csvHeaders"
                  hide-default-footer
                ></v-data-table>
              </div>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </div>
</template>

<script>
import Api from "../services/Api";
import _ from "lodash";
import templateMapping from "./templateMapping.vue";
import contactFormPreview from "./contactFormPreview.vue";
import accountFormPreview from "./accountFormPreview.vue";
import contactFormReadOnlyPreview from "./contactFormReadOnlyPreview.vue";

export default {
  components: {
    templateMapping,
    contactFormPreview,
    accountFormPreview,
    contactFormReadOnlyPreview,
  },
  mounted: async function () {
    this.fetchTemplates();
  },
  data() {
    return {
      newTemplateName: "",
      createTemplateDialog: false,
      csvHeaders: [],
      saveTemplateDialog: false,
      type: "",
      template: "",
      selectedTemplate: {},
      templateList: [],
      typeList: [
        {
          id: "01",
          name: "Contact Form",
        },
        {
          id: "02",
          name: "Contact Readonly",
        },
        {
          id: "03",
          name: "Account Form",
        },
        {
          id: "04",
          name: "CSV Task Export",
        },
        {
          id: "05",
          name: "CSV Account Export",
        },
        {
          id: "06",
          name: "CSV Contact Export",
        },
      ],
      typeListMapping: {
        "01": "Contact Form",
        "02": "Contact Readonly",
        "03": "Account Form",
        "04": "CSV Task Export",
        "05": "CSV Account Export",
        "06": "CSV Contact Export",
      },
      elementIndex: 1,
      isNextBtnDisable: true,
      currentTemplate: "",
      cloneCurrentTemplate: {},
      templateError: "",
    };
  },
  methods: {
    openCloneDialog: function (value) {
      this.setTemplateName(value, true);
      this.cloneCurrentTemplate = _.cloneDeep(this.currentTemplate);
      this.saveTemplateDialog = true;
    },
    assignPreview: function () {
      this.elementIndex = 4;
      this.$refs.templateMapping.setPreview();
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    saveTemplateAs: async function (templatePayload) {
      let notification;
      if (!templatePayload.config) {
        let XINXINId = "01";
        let XINXINTemplate = this.templateList.find((x) => x.id === XINXINId);
        templatePayload = {
          name: templatePayload.name,
          config: XINXINTemplate.config,
        };
      }
      try {
        let template = await Api.createTemplate(
          templatePayload.name,
          templatePayload.config
        );
        this.elementIndex = 1;
        this.type = "";
        this.template = template.data.id;
        let self = this;
        this.fetchTemplates()
          .then(() => {
            self.setTemplateName(self.template);
            self.setTemplate(self.currentTemplate, true);
            self.saveTemplateDialog = false;
            self.createTemplateDialog = false;
            self.templateError = "";
            notification = {
              type: "success",
              showMessage: true,
              message: self.$notifications().TEMPLATE_UPDATE_SUCCESS,
            };
            self.setNotification(notification);
          })
          .catch((error) => {
            throw error;
          });
      } catch (error) {
        if (
          error.response.data &&
          error.response.data.err === "TEMPLATE_NAME_ALREADY_EXISTS"
        ) {
          this.templateError = "Template Name Already Exists";
          return;
        }
        notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().TEMPLATE_UPDATE_FAILED,
        };
        this.setNotification(notification);
      }
    },
    saveTemplate: async function (value) {
      let notification;
      try {
        await Api.saveTemplate(
          this.selectedTemplate.id,
          this.selectedTemplate.name,
          this.selectedTemplate.config
        );
        if (value) {
          this.elementIndex = 2;
        } else {
          this.elementIndex = 1;
        }
        this.navigateSteps(this.elementIndex, "back");
        const result = await Api.getTemplateById(this.selectedTemplate.id);
        this.setTemplate(result.data, true);
        notification = {
          type: "success",
          showMessage: true,
          message: this.$notifications().TEMPLATE_UPDATE_SUCCESS,
        };
        this.setNotification(notification);
      } catch (error) {
        notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().TEMPLATE_UPDATE_FAILED,
        };
        this.setNotification(notification);
      }
    },
    setTemplateName: function (id, dontNavigate) {
      this.template = id;
      this.currentTemplate = this.templateList.find((x) => x.id === id);
      if (!dontNavigate) {
        this.navigateSteps(2, "next");
      }
    },
    setType: function (id) {
      this.type = id;
      this.navigateSteps(3, "next");
    },
    navigateSteps(elementIndex, movement) {
      this.elementIndex = elementIndex;
      if (movement === "back" && (this.template || this.type)) {
        this.isNextBtnDisable = false;
      } else {
        this.isNextBtnDisable = true;
      }
      if (this.elementIndex === 1) {
        this.type = "";
      }
    },
    disableNextButton(value) {
      if (value) {
        this.isNextBtnDisable = false;
      } else {
        this.isNextBtnDisable = true;
      }
    },
    setTemplate: function (value, dontNavigate) {
      this.selectedTemplate = value;
      if (!dontNavigate) {
        this.elementIndex = 4;
      }
      return this.$store.dispatch("setTemplateData", value);
    },
    setCsvTemplate: function (value) {
      const csvHeadersArr = [];
      value.forEach((field) => {
        csvHeadersArr.push({ text: field.label, sortable: false });
      });
      this.csvHeaders = _.compact(csvHeadersArr);
    },
    fetchTemplates: function () {
      let self = this;
      return Api.getTemplateNames({})
        .then((response) => {
          self.templateList = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped>
.client_list button.v-expansion-panel-header {
  cursor: auto !important;
}

.content {
  margin-left: 70px;
}

.highlight {
  border: 2px solid #aaa !important;
  background: #f9fcff !important;
}

.header {
  margin: 10px 0 5px;
}

.header button {
  margin-right: 15px;
}

.header h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}
.label {
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  text-transform: uppercase !important;
}
</style>
