module.exports = [
  {
    id: "account.domain",
    label: "Company Domain",
  },
  {
    id: "account.name",
    label: "Company name",
  },
  {
    id: "account.parentAccountName",
    label: "Parent Account Name",
  },
  {
    id: "account.website",
    label: "Website",
  },
  {
    id: "account.type",
    label: "CompanyType",
  },
  {
    id: "account.aliasName",
    label: "Alias Name",
  },
  {
    id: "account.email",
    label: "Company Email",
  },
  {
    id: "account.industry",
    label: "Industry",
  },
  {
    id: "account.subIndustry",
    label: "Sub Industry",
  },
  {
    id: "account.zoomIndustry",
    label: "Zoom Industry",
  },
  {
    id: "account.zoomSubIndustry",
    label: "Zoom Sub Industry",
  },
  {
    id: "account.sicCode",
    label: "SIC Code",
  },
  {
    id: "account.sicDescription",
    label: "SIC Description",
  },
  {
    id: "account.naicsCode",
    label: "NAICS Code",
  },
  {
    id: "account.naicsDescription",
    label: "NAICS Description",
  },
  {
    id: "account.employeeSizeLI",
    label: "Employee Size LI",
  },
  {
    id: "account.employeeSizeZPlus",
    label: "Employee Size ZPlus",
  },
  {
    id: "account.employeeSizeOthers",
    label: "Employee Size Others",
  },
  {
    id: "account.revenue",
    label: "Revenue",
  },
  {
    id: "account.totalFunding",
    label: "Total Funding",
  },
  {
    id: "account.itSpend",
    label: "IT Spend",
  },
  {
    id: "account.liUrl",
    label: "Company Linkedin URL",
  },
  {
    id: "account.description",
    label: "Description",
  },
  {
    id: "account.duns",
    label: "DUNS Number",
  },
  {
    id: "account.technology",
    label: "Technology",
  },
  {
    id: "account.tags",
    label: "Tags",
  },
  {
    id: "account.state",
    label: "State",
  },
  {
    id: "account.zipCode",
    label: "ZipCode",
  },
  {
    id: "account.country",
    label: "Country",
  },
  {
    id: "account.createdAt",
    label: "Created At",
  },
  {
    id: "account.createdBy",
    label: "Created By",
  },
  {
    id: "account.updatedAt",
    label: "Updated At",
  },
  {
    id: "account.updatedBy",
    label: "Updated By",
  },
  {
    id: "account.updatedBy",
    label: "Updated By",
  },
  {
    id: "location.locationType",
    label: "LocationType",
  },
  {
    id: "location.address1",
    label: "Address 1",
  },
  {
    id: "location.address2",
    label: "Address 2",
  },
  {
    id: "location.city",
    label: "City",
  },
  {
    id: "location.state",
    label: "State",
  },
  {
    id: "location.stateAbbreviation",
    label: "State Abbreviation",
  },
  {
    id: "location.zipCode",
    label: "Zip Code",
  },
  {
    id: "location.country",
    label: "Country",
  },
  {
    id: "location.phone1",
    label: "Phone 1",
  },
  {
    id: "location.phone2",
    label: "Phone 2",
  },
];
