<template>
  <div class="content">
    <div>
      <!-- Refresh Button -->
      <v-btn
        class="ml-2 refreshButton white--text"
        color="#8B83BA"
        :loading="loading"
        :disabled="loading"
        @click="pageRefresh()"
        rounded
        >refresh <v-icon>mdi-cached</v-icon></v-btn
      >
    </div>

    <v-container fluid class="downloadMaster">
      <!-- dataGrid -->
      <v-row>
        <v-col cols="12" sm="12">
          <DataGrid
            gridId="downloadPageId"
            gridRef="downloadPageRef"
            gridHeight="75vh"
            :headers="headers"
            :itemList="downloadList"
            :loading="loading"
            :totalCount="totalCount"
            :pageNo="pageNumber"
            @fetchGrideData="fetchDownloadData($event)"
            @fetchButtonClickAction="downloadFile($event)"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Api from "../services/Api";
import _ from "lodash";
import DataGrid from "./dataGrid.vue";

export default {
  name: "PROJECTDownload",
  components: {
    DataGrid,
  },
  props: {
    projectId: String,
  },
  data() {
    return {
      loading: false,
      isDownloading: false,
      jobClicked: undefined,
      isJobComplete: false,
      downloadList: [],
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE,
      totalCount: 0,
      downloadLink: "",
      //To set column size set header width large : 200px,common : 150px, small : 125px
      headers: [
        {
          value: "fileName",
          text: "File Name",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "operation_name",
          text: "File Type",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "createdAt",
          text: "Requested Time",
          sortable: false,
          divider: true,
          width: "150px",
          type: "date",
          googleSearch: false,
        },
        {
          value: "status",
          text: "Status",
          divider: true,
          sortable: false,
          width: "125px",
          type: "string",
        },
        {
          value: "jobId",
          text: "File Link",
          divider: true,
          sortable: false,
          width: "115px",
          type: "button",
          icon: "file_download",
        },
      ],
      filter: {},
      sort: {
        createdAt: "desc",
      },
    };
  },
  mounted: function () {
    this.fetchJobs();
  },

  methods: {
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    pageRefresh: async function () {
      this.loading = true;
      await this.fetchJobs();
      this.loading = false;
    },
    fetchDownloadData: async function (options) {
      this.loading = true;
      const { page, itemsPerPage } = options;

      this.pageNumber = page;
      this.pageSize = itemsPerPage;

      //api call
      await this.fetchJobs();
      this.loading = false;
    },
    downloadFile: async function (data) {
      try {
        const jobId = data.value;
        this.jobClicked = jobId;
        this.isDownloading = true;
        const response = await this.downloadJobFile(jobId, this.projectId);
        this.isDownloading = false;
        this.jobClicked = "";
        console.log(response);
        this.downloadLink = response.data.url;
        window.open(this.downloadLink, "_blank");
        let notification = {
          type: "success",
          showMessage: true,
          message: this.$notifications().FILE_DOWNLOAD_SUCCESS,
        };
        console.log(notification);
        this.setNotification(notification);
      } catch (error) {
        this.isDownloading = false;
        this.jobClicked = "";
        console.log(">>>>> Error while Requesting file download", error);
        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().FILE_DOWNLOAD_ERROR,
        };
        this.setNotification(notification);
      }
    },
    fetchJobs: function () {
      let self = this;
      return Api.getJobList(this.projectId, {
        sort: { createdAt: "desc" },
        fileStatus: ["download"],
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
      })
        .then((response) => {
          self.totalCount = response.data.totalCount;
          self.downloadList = response.data.docs;
          self.refreshJobStatus(self.downloadList);
          let notification = {
            type: "success",
            showMessage: true,
            message: self.$notifications().STATUS_SUCCESS,
          };
          self.setNotification(notification);
        })
        .catch((error) => {
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().STATUS_ERROR,
          };
          self.setNotification(notification);
        });
    },
    downloadJobFile: function (jobId, projectId) {
      return Api.downloadJobFile(projectId, jobId);
    },
    refreshJobStatus: async function (jobs) {
      let modifiedJobs = [];
      modifiedJobs = _.map(jobs, function (job) {
        job.itemInfo = {};
        job.itemInfo.header = "File Link";
        if (_.get(job, "status", "").toLowerCase() === "completed") {
          job.itemInfo.isDisableButton = false;
        } else {
          job.itemInfo.isDisableButton = true;
          job.createdAt = "";
        }
        return job;
      });
      return modifiedJobs;
    },
  },
};
</script>
<style scoped>
.downloadMaster {
  width: 100%;
  position: relative;
}

.content {
  margin-left: 0 !important;
}

.project_list {
  /*width: 1320px;*/
}

.project_list_headers {
  background: #ffffff;
  padding: 5px 10px;
  padding-left: 0;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
}

.project_list_headers h6 {
  font-family: "Lato";
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
  text-transform: uppercase;
}
.project_list_headers .field.project_name_flex,
.list_item .field.project_name_flex {
  width: 300px;
}

.project_list_headers .field,
.list_item .field {
  width: 175px;
}
.project_list_headers .field.sm,
.list_item .field.sm {
  width: 150px;
}
.refreshButton {
  position: absolute;
  top: 45px;
  right: 25px;
}

/* .list_item .contentField {
  width: 150px;
} */
.list_item .field h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  color: #25213b;
}

.list_item h6 {
  font-family: "Lato";
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
  text-transform: uppercase;
}
.list_item h6 {
  display: none;
}
.list_item.v-item--active h6 {
  display: block;
}
.list_item h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  color: #25213b;
}

.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  display: none;
}

@media (min-width: 1200px) and (max-width: 1450px) {
  .project_list_headers .field.project_name_flex,
  .list_item .field.project_name_flex {
    width: 270px;
  }

  .list_item .field h4 {
    font-size: 15px;
  }

  .project_list_headers .field,
  .list_item .field {
    width: 160px;
  }

  .project_list_headers .field.sm,
  .list_item .field.sm {
    width: 140px;
  }

  .project_list_headers h6 {
    font-size: 14px;
  }
}
.notification {
  position: absolute;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
