<template>
  <div id="TaskMiniView">
    <div>
      <div id="TaskMiniView">
        <div id="accountDetails" class="font-style">
          <div class="main-header">
            <!-- The escape sequence \\ inserts a backslash in a string -->
            <div
              class="close-btn"
              @click="goToTasks()"
              v-shortkey.once="['ctrl', 'alt', '\\']"
              @shortkey="debounceOnShortKey()"
            >
              <v-icon color="white">arrow_back</v-icon>
              <!-- <label style="font-size: 14px; cursor: pointer">
                <b> Task List </b>
              </label> -->
            </div>
          </div>

          <div class="main-body">
            <v-row class="mb-1 pl-3 pt-3">
              <v-col cols="12" md="12">
                <v-row>
                  <div>
                    <label class="label" id="workTypeLabelHolder">
                      <b>Work Type:</b>
                    </label>
                  </div>
                </v-row>
                <v-row>
                  <div>
                    <p
                      class="label mb-0 text-left"
                      style="text-align: left"
                      id="workTypeValueHolder"
                    >
                      {{ taskType | checkEmpty | markEmpty }}
                    </p>
                  </div>
                </v-row>
              </v-col>
              <v-col class="mt-2" cols="12" md="12">
                <v-row>
                  <div>
                    <label class="label" id="priorityLabelHolder"
                      ><b>Priority:</b></label
                    >
                  </div>
                </v-row>
                <v-row>
                  <div>
                    <label class="label" id="priorityValueHolder">{{
                      task.priority
                    }}</label>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>

      <div class="worspace_tabs">
        <div class="tabs">
          <ul>
            <li :class="{ 'is-active': 'projectYield' == showTab }">
              <a @click="switchTab('projectYield')">
                <span>Yield</span>
              </a>
            </li>

            <li>
              <specification :isLink="true" />
            </li>

            <li></li>
          </ul>

          <hr />

          <div class="tab-details pa-3">
            <div v-show="'projectYield' == showTab">
              <h4 class="spec_heading mt-0">Contacts</h4>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12" md="6" class="py-0 pt-2">
                  <div class="spec_section px-1">
                    <p class="value">
                      {{
                        projectYield.positiveDisposedContactsCounts
                          | humanizeNumber
                      }}
                    </p>
                    <h4 class="spec_keys">Today</h4>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="py-0 pt-2">
                  <div class="spec_section px-1">
                    <p class="value">
                      {{
                        projectYield.positiveDisposedContactsOverallCounts
                          | humanizeNumber
                      }}
                    </p>
                    <h4 class="spec_keys">Overall</h4>
                  </div>
                </v-col>
              </v-row>

              <h4 class="spec_heading">Accounts</h4>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12" md="6" class="py-0 pt-2">
                  <div class="spec_section px-1">
                    <p class="value">
                      <span>
                        {{
                          projectYield.disposedAccountCounts | humanizeNumber
                        }}
                      </span>
                      <span v-if="projectYield.negativeDisposedAccountCounts">
                        /
                      </span>
                      <span v-if="projectYield.negativeDisposedAccountCounts">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              style="cursor: default"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{
                                projectYield.negativeDisposedAccountCounts
                                  | humanizeNumber
                              }}
                            </span>
                          </template>
                          <span> <h4>Negative Disposed</h4></span>
                        </v-tooltip>
                      </span>
                    </p>
                    <h4 class="spec_keys">Today</h4>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="py-0 pt-2">
                  <div class="spec_section px-1">
                    <p class="value">
                      <span>
                        {{
                          projectYield.disposedAccountOverallCounts
                            | humanizeNumber
                        }}
                      </span>
                      <span
                        v-if="projectYield.negativeDisposedAccountOverallCounts"
                      >
                        /
                      </span>
                      <span
                        v-if="projectYield.negativeDisposedAccountOverallCounts"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              style="cursor: default"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{
                                projectYield.negativeDisposedAccountOverallCounts
                                  | humanizeNumber
                              }}
                            </span>
                          </template>
                          <span> <h4>Negative Disposed</h4></span>
                        </v-tooltip>
                      </span>
                    </p>
                    <h4 class="spec_keys">Overall</h4>
                  </div>
                </v-col>
              </v-row>

              <h4 class="spec_heading">Targets</h4>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12" md="6" class="py-0 pt-2">
                  <div class="spec_section px-1">
                    <p class="value">
                      {{ projectYield.accountTarget | humanizeNumber }}
                    </p>
                    <h4 class="spec_keys">Accounts</h4>
                  </div>
                </v-col>
                <v-col cols="12" md="6" class="py-0 pt-2">
                  <div class="spec_section px-1">
                    <p class="value">
                      {{ projectYield.contactTarget | humanizeNumber }}
                    </p>
                    <h4 class="spec_keys">Contacts</h4>
                  </div>
                </v-col>
                <v-col cols="12" md="12" class="py-0">
                  <div class="spec_section px-1">
                    <p class="value">
                      {{ projectYield.contactsPerAccount | humanizeNumber }}
                    </p>
                    <h4 class="spec_keys">Contacts Per Account</h4>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import specification from "./specification.vue";

export default {
  components: { specification },
  name: "TaskMiniView",
  props: {},
  data() {
    return {
      showTab: "projectYield",

      projectSpecsPrev: [],
      fieldList: [
        "job_level",
        "job_department",
        "job_function",
        "job_title",
        "employee_count",
        "company_revenue",
        "industry",
        "sub_industry",
        "geography",
        "contacts_per_account",
        "note",
      ],
      contactFields: [
        "Job Level",
        "Job Department",
        "Job Function",
        "Job Title",
      ],
      customFields: {},
      accountFields: [
        "Employee Count",
        "Company Revenue",
        "Industry",
        "Sub Industry",
        "Geography",
        "Contacts Per Account",
        "Note",
      ],
    };
  },
  computed: {
    projectYield() {
      return this.$store.state.workspace.yield;
    },
    task() {
      return this.$store.state.workspace.task;
    },
    taskType() {
      if (this.task && this.task.TaskType) {
        return this.task.TaskType.type;
      }
      return null;
    },
    projectName() {
      if (this.task && this.task.Project) {
        return this.task.Project.name;
      }
      return null;
    },
    projectSpecs() {
      return this.fetchProjectSpec(
        [].concat(this.$store.state.workspace.specifications)
      );
    },
    account() {
      return _.get(this.$store.state.workspace, "account", null);
    },
  },
  methods: {
    debounceOnShortKey: _.debounce(async function () {
      await this.goToTasks();
    }, 1000),
    switchTab: function (payload) {
      this.showTab = payload;
    },
    renameKeys: function (projectSpecs) {
      let jsonMap = {
        job_level: "Job Level",
        job_department: "Job Department",
        job_function: "Job Function",
        job_title: "Job Title",
        employee_count: "Employee Count",
        company_revenue: "Company Revenue",
        industry: "Industry",
        sub_industry: "Sub Industry",
        geography: "Geography",
        contacts_per_account: "Contacts Per Account",
        note: "Note",
      };
      for (var i = 0; i < projectSpecs.length; i++) {
        projectSpecs[i].newValues = Object.assign({}, projectSpecs[i].values);
        var specValuesKeys = Object.keys(projectSpecs[i].newValues);
        for (var j = 0; j < specValuesKeys.length; j++) {
          var keyToCheck = specValuesKeys[j];
          if (
            projectSpecs[i].newValues[keyToCheck] &&
            typeof projectSpecs[i].newValues[keyToCheck] === "object"
          ) {
            projectSpecs[i].newValues[keyToCheck] = _.compact(
              projectSpecs[i].newValues[keyToCheck]
            );
            projectSpecs[i].newValues[keyToCheck] =
              projectSpecs[i].newValues[keyToCheck].join(", ");
          }
          var index = _.indexOf(this.fieldList, keyToCheck);
          if (index > -1) {
            var newKey = jsonMap[keyToCheck];
            projectSpecs[i].newValues[newKey] =
              projectSpecs[i].newValues[keyToCheck];
            delete projectSpecs[i].newValues[keyToCheck];
          }
        }
        projectSpecs[i].valuesKeys = Object.keys(projectSpecs[i].newValues);
        var validKeys = Object.keys(
          _.pickBy(projectSpecs[i].newValues, _.identity)
        );
        projectSpecs[i].contactValueKeys = _.intersection(
          validKeys,
          this.contactFields
        );
        projectSpecs[i].accountValueKeys = _.intersection(
          validKeys,
          this.accountFields
        );
        var contactAccountValueKeys = _.concat(
          projectSpecs[i].contactValueKeys,
          projectSpecs[i].accountValueKeys
        );
        projectSpecs[i].customValueKeys = _.difference(
          validKeys,
          contactAccountValueKeys
        );
      }
      return projectSpecs;
    },
    loadFields: function (specs) {
      specs.forEach((spec) => {
        var keys = Object.keys(spec.values);
        var unique = _.difference(keys, this.fieldList);
        this.customFields[spec.id] = unique.map((item) => {
          return { key: item, value: spec.values[item] };
        });
      });
      this.projectSpecsPrev = Object.assign({}, specs);
    },
    fetchProjectSpec: function (projectSpecs) {
      if (projectSpecs == "N/A") {
        return;
      }
      projectSpecs = this.renameKeys(projectSpecs);
      this.loadFields(projectSpecs);
      return projectSpecs;
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    goToTasks() {
      if (this.checkAccountDispose()) {
        this.$router
          .push({
            name: "AgentTaskList",
          })
          .catch((e) => e);
      } else {
        this.$emit("checkAccountDisposition", true);
      }
    },
    checkAccountDispose() {
      if (this.account.disposition && this.account.disposition !== "Pending") {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
#TaskMiniView {
  text-align: center;
  color: #353232;
  margin-top: 49px;
}
#accountDetails {
  background: repeating-linear-gradient(130deg, #353232, #444141 5px);
}

#companyNameValueHolder {
  float: left;
}

.row {
  padding-bottom: 0.2%;
}

#accountDetails .main-header p,
.worspace_tabs p {
  margin-bottom: 0;
}
.main-header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f0f1f4;
}

.main-header .close-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 30px;
  flex: 1;
  align-self: center;
  padding: 10px;
}

.main-header .totalTask {
  font-size: 30px;
  flex: 1;
  align-self: center;
  font-weight: 500;
  padding: 10px;
}

.font-style {
  color: white;
}

#mainbody.label {
  padding-bottom: 10%;
}

.main-body {
  padding: 10px 20px;
}

.worspace_wrapper {
  margin-left: 70px;
}
.topbar {
  width: 100%;
  height: 65px;
  position: absolute;
  top: 0;
  left: 70px;
}
.task_list {
  width: 30%;
}
.task_detail {
  width: 400px;
}
.workspace {
  width: calc(100% - 470px);
  margin-left: 400px;
}
.header_nav {
}
.account_banner {
}
.form_actions {
}
.agent_form {
}
.header h1 {
  float: left;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}
.tabs li {
  list-style: none;
}
.tabs li a {
  align-items: center;
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  margin-bottom: -1px;
  padding: 0.5em 1em;
  vertical-align: top;
  cursor: pointer;
  font-size: 16px;
}
.tabs ul {
  align-items: center;
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  padding-left: 0;
  border-right: 1px solid #aaa;
}
.tabs li.is-active a {
  border-bottom-color: #3273dc;
  color: #3273dc;
}
.tab-details {
  padding-top: 20px;
  overflow-y: scroll;
  height: calc(100vh - 205px);
}
.breadcrumb,
.worspace_tabs {
  display: block;
}

.spec_section p.value {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #8b83ba;
  font-family: "Lato";
}
.spec_section {
  margin-top: 10px;
  font-size: 14px;
}
.spec_keys {
  font-size: 14px;
  font-weight: 600;
  color: #8b83ba;
  padding-bottom: 10px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
} /* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} /* Handle */
::-webkit-scrollbar-thumb {
  background: #8b83ba;
  border-radius: 10px;
} /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #686094;
}

/* Buttons */

::-webkit-scrollbar-button:single-button {
  background-color: #686094;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
} /* Up
*/
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
}
::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
} /* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
}
::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}
.v-divider {
  border-color: #aaa !important;
}

.spec_heading {
  text-align: center;
  font-size: 16px;
  margin-top: 16px;
  border-top: 1px solid #aaa;
}
</style>
