<template>
  <div class="content">
    <!-- buttons -->
    <div class="contact_master_buttons">
      <!-- file upload -->
      <FileUpload
        ref="masterFileUpload"
        :fileType="fileType"
        :importType="importType"
        v-if="allowImport"
        @openInclusion="setImportType($event)"
      />

      <!-- file download -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2 white--text"
            color="#8B83BA"
            dark
            fab
            small
            :loading="isDownloading"
            :disabled="isDownloading"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{ downloadDeliverableIcon }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title
              style="cursor: pointer"
              @click="contactDownload()"
              >Filtered Records
              <v-icon right>filter_alt</v-icon></v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              style="cursor: pointer"
              @click="openInclusionUpload()"
              >Inclusion Export
              <v-icon right>cloud_download</v-icon></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- Refresh Button -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2 white--text"
            :loading="loading"
            :disabled="loading"
            fab
            small
            color="#8B83BA"
            @click="pageRefresh()"
            ><v-icon v-bind="attrs" v-on="on">mdi-cached</v-icon></v-btn
          >
        </template>
        <span>Refresh</span>
      </v-tooltip>

      <!-- filters -->
      <GeneralizedFilter
        :inputFilterObject="inputFilterObject"
        v-on:filterObject="applyFilter($event)"
        v-on:fetchFilters="fetchFacets($event)"
      />
    </div>

    <v-container class="contactsMaster" fluid>
      <!-- filter chips -->
      <v-row v-if="Object.keys(filterForChips).length">
        <v-col cols="12" sm="12" class="py-0 pb-4">
          <v-card elevation="1" class="px-3">
            <h5
              class="text-overline pl-2"
              style="border-bottom: 1px solid #ddd"
            >
              Applied Filter:
              <span
                data-v-41106904=""
                style="
                  margin-left: 10px;
                  font-family: 'Lato';
                  text-transform: none;
                  color: rgb(32, 95, 172);
                  cursor: pointer;
                  letter-spacing: 0px;
                  font-size: 13px;
                "
                @click="clearAllFilters()"
                >( Clear all )</span
              >
            </h5>
            <v-chip-group column>
              <span
                v-for="(subProps, key) in filterForChips"
                :key="key"
                style="display: inline"
              >
                <span
                  v-if="Array.isArray(subProps.value) && subProps.value.length"
                >
                  <v-chip
                    close
                    label
                    @click:close="removeFilter(key, subValue)"
                    v-for="(subValue, index) in subProps.value"
                    :key="index"
                  >
                    <span style="padding-right: 5px">
                      {{ subProps.text }}:
                    </span>
                    <span
                      v-if="
                        subProps.operator !== operatorEqualTo &&
                        subProps.operator !== operatorBetween
                      "
                      style="padding-right: 2px"
                    >
                      {{ subProps.operator }}
                    </span>
                    <span v-if="subValue.length"> {{ subValue }}</span>
                  </v-chip>
                </span>
                <span v-else>
                  <v-chip close label @click:close="removeFilter(key)">
                    <span style="padding-right: 5px">
                      {{ subProps.text }} :
                    </span>
                    <span
                      v-if="
                        subProps.operator !== operatorEqualTo &&
                        subProps.operator !== operatorBetween
                      "
                      style="padding-right: 2px"
                    >
                      {{ subProps.operator }}
                    </span>
                    <span v-if="subProps.value.length">
                      {{ subProps.value }}</span
                    >
                  </v-chip>
                </span>
              </span>
            </v-chip-group>
          </v-card>
        </v-col>
      </v-row>

      <!-- dataGrid -->
      <v-row>
        <v-col cols="12" sm="12" class="pt-0">
          <DataGrid
            gridId="contactMasterPageId"
            gridRef="contactMasterPageRef"
            gridHeight="75vh"
            :headers="headers"
            :itemList="contactMasterList"
            :loading="loading"
            :totalCount="contactMasterListTotalCounts"
            :pageNo="pageNumber"
            @fetchGrideData="fetchContactMasterData($event)"
            :allowMultiSort="false"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import { getRoles } from "../roles.js";
import Api from "../services/MasterApi";
import DataGrid from "./dataGrid.vue";
import GeneralizedFilter from "./GeneralizedFilters.vue";
import FileUpload from "./fileUploadMaster.vue";
const fileDownload = require("js-file-download");

export default {
  name: "ContactMaster",
  components: {
    DataGrid,
    GeneralizedFilter,
    FileUpload,
  },
  mounted: async function () {
    await this.pageRefresh();
    this.fetchMasterContactList();
    await this.fetchFacets("jobLevel");
    await this.fetchFacets("jobDepartment");
    this.getRole();
  },
  data() {
    return {
      loading: false,
      isDownloading: false,
      allowImport: false,
      pageNumber: 1,
      contactMasterListTotalCounts: 0,
      pageSize: this.$constants().PAGE_SIZE,
      downloadDeliverableIcon: "file_download",
      operatorEqualTo: "=",
      operatorBetween: "between",
      contactMasterList: [],
      filter: {},
      outputFilterObject: {},
      filterForChips: {},
      //To set column size set header width large : 200px,common : 150px, small : 125px
      headers: [
        {
          value: "accountName",
          text: "Account Name",
          sortable: false,
          divider: true,
          width: "250px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT_30,
          googleSearch: false,
        },
        //Contact FN + LN
        {
          value: "name",
          text: "Name",
          sortable: false,
          divider: true,
          width: "250px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT_30,
          googleSearch: false,
        },
        {
          value: "jobTitle",
          text: "Job Title",
          sortable: false,
          divider: true,
          width: "150px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "jobLevel",
          text: "Job Level",
          sortable: false,
          divider: true,
          width: "150px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "jobDepartment",
          text: "Job Department",
          sortable: false,
          divider: true,
          width: "150px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "workEmail",
          text: "Work Email",
          sortable: false,
          divider: true,
          width: "250px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT_30,
          googleSearch: false,
        },
        {
          value: "locationCountry",
          text: "Country",
          sortable: false,
          divider: true,
          width: "150px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT_30,
          googleSearch: false,
        },
        {
          value: "disposition",
          text: "Disposition",
          sortable: false,
          divider: true,
          width: "125px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "updatedAt",
          text: "Last Modified Date",
          sortable: false,
          divider: true,
          width: "125px",
          type: "date",
          googleSearch: false,
        },
      ],
      inputFilterObject: {
        locationCountry: {
          text: "Location Country",
          type: "string",
          operators: ["IS", "IS NULL"],
          values: [],
        },
        disposition: {
          text: "Disposition",
          type: "string",
          operators: ["IS"],
          values: ["Active Contact", "No Longer"],
        },
        AccountDomain: {
          text: "Company Domain",
          type: "string",
          operators: ["IS"],
          values: [],
        },
        domainStatus: {
          text: "Domain Status",
          type: "array",
          operators: ["IS", "IS NULL"],
          values: ["Passed", "Blocked", "Failed"],
        },
        emailTags: {
          text: "Email Tags",
          type: "string",
          operators: ["IS", "IS NULL"],
          values: ["Opt-Out", "Opt-In"],
        },
        emailOpen: {
          text: "Email Open",
          type: "string",
          operators: ["IS"],
          values: ["Yes", "No"],
        },
        emailClick: {
          text: "Email Click",
          type: "string",
          operators: ["IS"],
          values: ["Yes", "No"],
        },
        name: {
          text: "Name",
          type: "array",
          operators: ["IS"],
          values: [],
        },
        workEmail: {
          text: "WorkEmail",
          type: "string",
          operators: ["IS"],
          values: [],
        },
        jobTitle: {
          text: "Job Title",
          type: "array",
          operators: ["IS"],
          values: [],
        },
        jobLevel: {
          text: "Job Level",
          type: "array",
          operators: ["IS", "IS NULL"],
          values: [],
        },
        jobDepartment: {
          text: "Job Department",
          type: "array",
          operators: ["IS", "IS NULL"],
          values: [],
        },
        zbStatus: {
          text: "ZB Status",
          type: "array",
          operators: ["IS", "IS NULL"],
          values: [
            "valid",
            "invalid",
            "catch-All",
            "spamtrap",
            "abuse",
            "do not mail",
            "unknown",
          ],
        },
        updatedAt: {
          text: "Last Modified Date",
          type: "date",
          operators: ["BETWEEN"],
          values: [],
        },
      },
      fileType: "",
      importType: "",
    };
  },
  computed: {
    jobLevel() {
      return this.$store.state.jobLevel;
    },
    jobDepartment() {
      return this.$store.state.jobDepartment;
    },
  },
  methods: {
    setImportType: function (isInclusion) {
      this.fileType = "Import";
      this.importType = "Contact";
      if (isInclusion) {
        this.fileType = "InclusionExport";
        this.importType = "Contact Inclusion Export";
      }
    },
    getRole: async function () {
      let role = await getRoles();
      if (role.indexOf("compliance") > -1) {
        this.allowImport = true;
      }
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    fetchMasterContactList: async function () {
      this.loading = true;

      Api.getMasterContacts({
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
        filter: JSON.stringify(this.filter),
      })
        .then((response) => {
          this.contactMasterListTotalCounts = response.data.totalCount;
          this.contactMasterList = response.data.docs;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchFacets: async function (field) {
      let self = this;
      switch (field) {
        case "jobLevel":
          if (!this.jobLevel.length) {
            await this.$store.dispatch("fetchMasterJobLevels");
          }
          self.inputFilterObject.jobLevel.values = this.jobLevel;
          break;

        case "jobDepartment":
          if (!this.jobDepartment.length) {
            await this.$store.dispatch("fetchMasterJobDepartments");
          }
          self.inputFilterObject.jobDepartment.values = this.jobDepartment;
          break;

        default:
          break;
      }
    },
    pageRefresh: async function () {
      await this.fetchMasterContactList();
    },
    modifyFilterForChips: function (keyOfFilterApplied) {
      const valueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["value"];
      const operatorOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["operator"];
      const itemTextOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemText"];
      const itemValueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemValue"];
      const searchOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["search"];

      if (
        operatorOfFilteredForChips === "between" &&
        Array.isArray(valueOfFilteredForChips)
      ) {
        this.filterForChips[keyOfFilterApplied]["value"] =
          this.filterForChips[keyOfFilterApplied]["value"].join(" ~ ");
      }

      if (
        itemTextOfFilteredForChips &&
        itemValueOfFilteredForChips &&
        searchOfFilteredForChips
      ) {
        let valuesOfFilteredId = [];
        _.forEach(this.inputFilterObject[keyOfFilterApplied].values, (item) => {
          if (
            valueOfFilteredForChips.indexOf(item[itemValueOfFilteredForChips]) >
            -1
          ) {
            valuesOfFilteredId.push(item[itemTextOfFilteredForChips]);
          }
        });
        valuesOfFilteredId = _.compact(valuesOfFilteredId);
        this.filterForChips[keyOfFilterApplied]["value"] = valuesOfFilteredId;
      } else if (itemTextOfFilteredForChips && itemValueOfFilteredForChips) {
        let valuesOfFilteredId = _.map(
          this.inputFilterObject[keyOfFilterApplied].values,
          (item) => {
            if (item[itemValueOfFilteredForChips] === valueOfFilteredForChips) {
              return item[itemTextOfFilteredForChips];
            }
          }
        );
        valuesOfFilteredId = _.compact(valuesOfFilteredId);
        this.filterForChips[keyOfFilterApplied]["value"] =
          valuesOfFilteredId[0];
      }
    },
    applyFilter: async function (filterApplied) {
      this.pageNumber = 1;

      const keyOfFilterApplied = Object.keys(filterApplied)[0];

      this.filterForChips[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      this.modifyFilterForChips(keyOfFilterApplied);

      filterApplied[keyOfFilterApplied] = _.omit(
        filterApplied[keyOfFilterApplied],
        ["text", "itemText", "itemValue", "search", "type"]
      );
      this.filter[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      await this.fetchMasterContactList();
    },
    clearAllFilters: async function () {
      this.filterForChips = {};
      this.filter = {};
      await this.fetchMasterContactList();
    },
    removeFilter: async function (filterKey, subValue) {
      this.pageNumber = 1;
      if (!subValue) {
        delete this.filterForChips[filterKey];
        delete this.filter[filterKey];
      } else {
        const filterForChipsValues = _.get(
          this.filterForChips,
          [filterKey, "value"],
          []
        );
        const filterValues = _.get(this.filter, [filterKey, "value"], []);
        if (filterForChipsValues.length <= 1 && filterValues.length <= 1) {
          delete this.filterForChips[filterKey];
          delete this.filter[filterKey];
        } else {
          const filterForChipsValueIndex = _.indexOf(
            filterForChipsValues,
            subValue
          );
          if (filterForChipsValueIndex > -1) {
            this.filterForChips[filterKey]["value"].splice(
              filterForChipsValueIndex,
              1
            );
          }
          // TODO :: Required Some Better Alternative
          for (var i = 0; i < filterValues.length; i++) {
            const filterValueIndex =
              filterValues[i].length > subValue.length
                ? filterValues[i].indexOf(subValue)
                : subValue.indexOf(filterValues[i]);
            if (filterValueIndex > -1) {
              this.filter[filterKey]["value"].splice(i, 1);
              break;
            }
          }
        }
      }
      await this.fetchMasterContactList();
    },
    changeIconOfDownloadBtn: function (disable) {
      if (disable) {
        this.downloadDeliverableIcon = "downloading";
      } else {
        this.downloadDeliverableIcon = "file_download";
      }
    },
    fetchContactMasterData: async function (options) {
      const { page, itemsPerPage } = options;

      this.pageNumber = page;
      this.pageSize = itemsPerPage;

      //api call
      await this.fetchMasterContactList();
    },
    openInclusionUpload: function () {
      this.$refs.masterFileUpload.openDialog("Contact Inclusion Export");
    },
    contactDownload: function () {
      const self = this;
      this.isDownloading = true;
      this.changeIconOfDownloadBtn(true);

      Api.getMasterContacts({
        filter: JSON.stringify(this.filter),
        download: true,
      })
        .then((response) => {
          const downloadFileName = `contact_master_${Date.now()}.csv`;
          const responseContentType = response.headers["content-type"];

          let notification = {
            type: "success",
            showMessage: true,
          };

          this.isDownloading = false;

          this.changeIconOfDownloadBtn(false);

          if (responseContentType === "application/csv") {
            notification.message = self.$notifications().FILE_SUCCESS;
            self.setNotification(notification);

            return fileDownload(response.data, downloadFileName);
          }

          notification.message =
            self.$notifications().DOWNLOAD_JOB_SUBMISSION_SUCCESS;
          self.setNotification(notification);
          return;
        })
        .catch(() => {
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().FILE_ERROR,
          };
          self.setNotification(notification);

          this.isDownloading = false;
          this.changeIconOfDownloadBtn(false);
        });
    },
  },
};
</script>

<style scoped>
.content {
  margin-left: 0 !important;
}
.contactsMaster {
  width: 100%;
  position: relative;
}
.contact_master_buttons {
  position: absolute;
  top: 60px;
  right: 15px;
}
</style>
