<template>
  <div class="contact_form">
    <div
      class="text-center float-left col-md-12 pa-0 mt-3"
      v-if="disableContactFormFields"
    >
      <v-alert
        class="py-3 mb-2"
        :color="contactStatusRibbon"
        border="bottom"
        elevation="2"
        colored-border
      >
        <v-list-item-icon
          style="float: left; margin-top: 0px; margin-right: 10px"
        >
          <v-icon
            color="red"
            v-if="
              formContact.complianceStatus &&
              formContact.complianceStatus !== 'Compliant'
            "
            left
            >highlight_off</v-icon
          >
          <v-icon color="green" v-else left>check_circle_outline</v-icon>
        </v-list-item-icon>

        <v-list-item-title class="text-left">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-if="formContact.TaskLink" v-bind="attrs" v-on="on"
                >{{ formContact.TaskLink.disposition }},
              </span>
              <span v-bind="attrs" v-on="on" v-else
                >{{ formContact.disposition }},
              </span>
            </template>
            <span><h4>Disposition</h4></span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-if="formContact.TaskLink" v-bind="attrs" v-on="on"
                >{{ formContact.TaskLink.researchStatus }}
              </span>
              <span v-bind="attrs" v-on="on" v-else
                >{{ formContact.researchStatus }}
              </span>
            </template>
            <span><h4>Research Status</h4></span>
          </v-tooltip>

          <v-tooltip bottom v-if="formContact.TaskLink">
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                style="font-size: 14px"
                v-if="formContact.TaskLink"
              >
                ,
                {{
                  formContact.TaskLink.updatedAt
                    | checkEmpty
                    | markEmpty
                    | momentFormatDate
                }}
              </span>
            </template>
            <span>Disposed At </span>
          </v-tooltip>

          <v-tooltip bottom v-if="checkUserName">
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                style="font-size: 14px"
                v-if="formContact.contactCreator.userName"
              >
                ,
                {{
                  formContact.contactCreator.userName | checkEmpty | markEmpty
                }}
              </span>
            </template>
            <span>Created By </span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-badge
                  color="red"
                  inline
                  class="float-right"
                  content="Duplicate"
                  v-if="contactDuplicateFlag"
                ></v-badge>
                <v-badge
                  color="orange"
                  inline
                  class="float-right"
                  content="Suppressed"
                  v-else-if="contactSuppressionFlag"
                ></v-badge>
                <v-badge
                  color="#936827"
                  inline
                  content="Fuzzzy Suppressed"
                  v-else-if="contactFuzzySuppressedFlag"
                ></v-badge>
                <v-badge
                  color="green"
                  inline
                  :content="formContact.label"
                  v-else-if="contactReusedFlag"
                ></v-badge>
              </span>
            </template>
            <span>
              <h4 v-if="contactDuplicateFlag">Contact Duplicate</h4>
              <h4 v-else-if="contactSuppressionFlag">
                Contact Exact Suppressed
              </h4>
              <h4 v-else-if="contactFuzzySuppressedFlag">
                Contact Fuzzy Suppressed
              </h4>
              <h4 v-else-if="contactReusedFlag">Contact Reused</h4>
            </span>
          </v-tooltip>
        </v-list-item-title>
        <v-list-item-title
          v-if="formContact.complianceStatus"
          class="text-left"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-if="formContact.complianceStatus" v-bind="attrs" v-on="on"
                >{{ formContact.complianceStatus }}
              </span>
            </template>
            <span><h4>Compliance Status</h4></span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                style="font-size: 14px"
                v-if="formContact.complianceComments"
                v-bind="attrs"
                v-on="on"
                >, {{ formContact.complianceComments }}
              </span>
            </template>
            <span><h4>Compliance Comments</h4></span>
          </v-tooltip>
        </v-list-item-title>
      </v-alert>
    </div>

    <div
      class="form pt-3 mt-4 scroller"
      v-bind:class="{ minimized: !formMinimized }"
      v-if="formContact.id"
    >
      <v-card
        class="mx-auto"
        style="border: 1px solid #ddd; border-radius: 4px"
        tile
      >
        <v-list dense flat>
          <v-list-item-group color="primary">
            <div v-for="section in sectionKeyBy" :key="section.section">
              <v-list-item v-if="section.display">
                <v-list-item-icon>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#6e6893" v-bind="attrs" v-on="on">{{
                        section.icon
                      }}</v-icon>
                    </template>
                    <span>{{ section.iconLabel }}</span>
                  </v-tooltip>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    style="font-size: 14px; font-family: 'Lato'"
                  >
                    <div
                      v-for="field in section.fields"
                      :key="field.id"
                      v-bind:style="section.direction"
                    >
                      <span v-if="contactDetail[field.id]" class="mx-1">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                              {{ contactDetail[field.id] | checkEmpty }}
                            </span>
                            {{ section.separator }}
                          </template>
                          <span>{{ field.label }}</span>
                        </v-tooltip>
                      </span>
                      <i
                        class="mx-1"
                        style="color: red; font-size: 15px"
                        v-else
                      >
                        {{ field.label }}</i
                      >
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
      </v-card>
    </div>
    <v-alert v-else text class="mt-3 col-md-12 float-left" type="info">
      <v-row align="center" class="text-center"> No Contact Selected </v-row>
    </v-alert>
  </div>
</template>

<script>
import _ from "lodash";
import sanitize from "./sanitizer";
import parsePhoneNumber from "libphonenumber-js";
import dot from "dot-object";

export default {
  props: {
    isChecking: Boolean,
    formMinimized: Boolean,
  },
  components: {},
  data() {
    return {
      contactStatusRibbon: "cyan",
      formContact: {
        address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
        previous_address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
      },
      contactDetail: {},
      sectionKeyBy: {},
    };
  },
  mounted: function () {
    this.checkRibbon();
    this.contactFormFieldCustomization(this.contactReadOnlyFields);
    this.contactDetail = dot.dot(this.formContact);
  },
  computed: {
    checkUserName() {
      return _.get(this.formContact, "contactCreator.userName", null);
    },
    _() {
      return _;
    },
    selectedContact() {
      return this.$store.state.workspace.selectedContact;
    },
    projectId() {
      if (this.$store.state.workspace && this.$store.state.workspace.task) {
        return _.get(this.$store.state.workspace.task, "Project.id", null);
      }
      return null;
    },
    contactSuppressionFlag() {
      return this.$store.state.contactSuppressionFlag;
    },
    contactFuzzySuppressedFlag() {
      return this.$store.state.contactFuzzySuppressedFlag;
    },
    contactReusedFlag() {
      return this.$store.state.contactReusedFlag;
    },
    contactDuplicateFlag() {
      return this.$store.state.contactDuplicateFlag;
    },
    disableContactFormFields() {
      if (this.formContact.disposition) {
        return true;
      } else {
        return false;
      }
    },
    contactReadOnlyFields() {
      return this.$store.state.workspace.fieldCustomization
        .contactReadOnlyFields;
    },
    account() {
      return _.get(this.$store.state.workspace, "account", null);
    },
  },
  watch: {
    selectedContact: function (value) {
      this.formContact = sanitize(value);

      this.contactDetail = dot.dot(this.formContact);

      // this.disableContactFormFields = false;
      // Add a mutation for disableContactFormFields
      this.$store.state.disableContactFormFields = false;
      if (value.phone) {
        let parsedNumber = parsePhoneNumber(value.phone);
        if (parsedNumber) {
          this.contactDetail.phone = parsedNumber.formatNational();
        }
      }
      if (value.directPhone) {
        let parsedNumber = parsePhoneNumber(value.directPhone);
        if (parsedNumber) {
          this.contactDetail.directPhone = parsedNumber.formatNational();
        }
      }
      if (value.mobile) {
        let parsedNumber = parsePhoneNumber(value.mobile);
        if (parsedNumber) {
          this.contactDetail.mobile = parsedNumber.formatNational();
        }
      }

      this.checkRibbon();
    },
    contactReadOnlyFields: function (value) {
      this.contactFormFieldCustomization(value);
    },
  },
  methods: {
    checkRibbon: function () {
      if (
        !this.formContact.disposition &&
        this.formContact.label !== "suppressed" &&
        this.formContact.label !== "Exact Suppressed" &&
        this.formContact.label !== "duplicate" &&
        this.formContact.label !== "Fuzzy Suppressed"
      ) {
        this.contactStatusRibbon = "blue";
      } else if (this.formContact.label === "duplicate") {
        this.contactStatusRibbon = "red";
      } else if (this.formContact.label === "suppressed") {
        this.contactStatusRibbon = "orange";
      } else if (this.formContact.label === "Exact Suppressed") {
        this.contactStatusRibbon = "orange";
      } else if (this.formContact.label === "Fuzzy Suppressed") {
        this.contactStatusRibbon = "#936827";
      } else if (
        this.formContact.complianceStatus &&
        this.formContact.complianceStatus !== "Compliant"
      ) {
        this.contactStatusRibbon = "black";
      } else {
        this.contactStatusRibbon = "cyan";
      }
    },
    debounceOnShortKey: _.debounce(async function (value) {
      switch (value) {
        case "check":
          await this.$refs.checkBtn.$el.focus();
          await this.checkDupSup();
          break;
      }
    }, 1000),
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    checkDupSup: async function () {
      this.$emit("updateIsChecking", true);
      return this.$store
        .dispatch("contactCheck", {
          formContact: this.formContact,
          projectId: this.projectId,
          accountName: this.account.name,
        })
        .then(() => {
          this.$emit("updateIsChecking", false);
        })
        .catch(() => {
          this.$emit("updateIsChecking", false);
        });
    },
    contactFormFieldCustomization: function (contactReadOnly) {
      this.sectionKeyBy = _.keyBy(contactReadOnly, "section");

      for (const key in this.sectionKeyBy) {
        let sectionFields = [];
        let section = this.sectionKeyBy[key];
        sectionFields = section.fields;

        if (sectionFields.length) {
          sectionFields.forEach((element) => {
            element.id = element.id.replace("contact.", "");
          });
          let fieldKeyBy = _.keyBy(sectionFields, "id");
          this.sectionKeyBy[key].fields = fieldKeyBy;
        }
      }
    },
  },
};
</script>

<style scoped>
.highlight {
  background: lightyellow;
}

.previousTitles {
  font-size: 12px;
  margin-top: 5px;
  text-transform: none;
  position: absolute;
  right: 0;
  bottom: 2px;
}
.addressWrapper .address-container {
  width: 100%;
  height: 45px;
  padding: 8px 0;
  padding-left: 8px;
  border: 1px solid #ccc;
}

.addressWrapper + i {
  right: 20px;
  font-size: 25px;
  position: absolute;
  top: 8px;
}

.addressWrapper {
  width: 100%;
}

.contact_form hr {
  margin: 15px 10px 2px 10px;
  border-color: white;
}
.contact_form {
  padding: 10px;
}
.form h4 {
  font-family: Lato;
  font-size: 12px;
  color: #6e6893;
  font-weight: 400;
  position: relative;
}
.zerobounce-wrapper {
  width: 90%;
  float: right;
  margin-right: 0 !important;
}

.zerobounce-wrapper p {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #00458d;
}
.zerobounce-wrapper p b {
  float: right;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.right {
  float: right;
}
.scroller.minimized {
  height: calc(100vh - 210px);
}
.scroller {
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 10px;
  height: calc(100vh - 330px);
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}
.v-list-item__title {
  height: 30px !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
} /* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} /* Handle */
::-webkit-scrollbar-thumb {
  background: #8b83ba;
  border-radius: 10px;
} /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #686094;
}

/* Buttons */

::-webkit-scrollbar-button:single-button {
  background-color: #686094;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
} /* Up
  */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
}
::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
} /* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
}
::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
