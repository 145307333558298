<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <template v-slot:activator="{ on: dialog, dialogAttrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip, tooltipAttrs }">
          <v-btn
            v-bind:class="btnClass"
            color="error"
            dark
            icon
            v-bind="{ ...tooltipAttrs, ...dialogAttrs }"
            v-on="{ ...tooltip, ...dialog }"
            v-show="display"
          >
            <md-icon> {{ icon }} </md-icon>
          </v-btn>
        </template>
        <span>{{ tooltip }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        <p v-if="isDelete">Are you sure you want to delete ?</p>
        <p v-else>Are you sure you want to unlink File?</p>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="disagree()"> Cancel </v-btn>
        <v-btn color="green darken-1" text @click="agree()"> Confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    display: Boolean,
    icon: String,
    btnClass: String,
    tooltip: String,
    isDelete: Boolean,
  },
  components: {},
  data() {
    return {
      dialog: false,
      agreementValue: false,
    };
  },
  methods: {
    agree: function () {
      this.agreementValue = true;
      this.dialog = false;
      this.$emit("agreement", this.agreementValue);
    },
    disagree: function () {
      this.agreementValue = false;
      this.dialog = false;
      this.$emit("agreement", this.agreementValue);
    },
  },
};
</script>

<style scoped></style>
