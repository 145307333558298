const _ = require("lodash");

function sanitize(objectProps) {
  let object = objectProps;

  if (object && object.firstName) {
    object.firstName = object.firstName
      .toLowerCase()
      .replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  if (object && object.middleName) {
    object.middleName = object.middleName
      .toLowerCase()
      .replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  if (object && object.lastName) {
    object.lastName = object.lastName
      .toLowerCase()
      .replace(/\b(\w)/g, (s) => s.toUpperCase());
  }

  if (object && object.email) {
    object.email = object.email.toLowerCase();
  }

  if (object && object.genericEmail) {
    object.genericEmail = object.genericEmail.toLowerCase();
  }

  if (_.isString(object)) return sanitizeString(object);
  if (_.isArray(object)) return sanitizeArray(object);
  if (_.isPlainObject(object)) return sanitizeObject(object);

  return object;
}

function isProvided() {
  // ************ For Removing Keys Having Null and Empty Values ************
  // const typeIsNotSupported =
  //   !_.isNull(value) &&
  //   !_.isString(value) &&
  //   !_.isArray(value) &&
  //   !_.isPlainObject(value);
  // return typeIsNotSupported || !_.isEmpty(value);
  // ************************************************************************
  return true;
}

function sanitizeString(string) {
  return _.isEmpty(string) ? null : string.trim();
}

function sanitizeArray(array) {
  return _.filter(_.map(array, sanitize), isProvided);
}

function sanitizeObject(object) {
  return _.pickBy(_.mapValues(object, sanitize), isProvided);
}

module.exports = sanitize;
