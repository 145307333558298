<template>
  <div class="content">
    <v-container fluid class="accountsMaster">
      <!-- filter chips -->
      <v-row v-if="Object.keys(filterForChips).length">
        <v-col cols="12" sm="12" class="py-0 pb-4">
          <v-card elevation="1" class="px-3">
            <h5
              class="text-overline pl-2"
              style="border-bottom: 1px solid #ddd"
            >
              Applied Filter:
              <span
                data-v-41106904=""
                style="
                  margin-left: 10px;
                  font-family: 'Lato';
                  text-transform: none;
                  color: rgb(32, 95, 172);
                  cursor: pointer;
                  letter-spacing: 0px;
                  font-size: 13px;
                "
                @click="clearAllFilters()"
                >( Clear all )</span
              >
            </h5>
            <v-chip-group column>
              <span
                v-for="(subProps, key) in filterForChips"
                :key="key"
                style="display: inline"
              >
                <span
                  v-if="Array.isArray(subProps.value) && subProps.value.length"
                >
                  <v-chip
                    close
                    label
                    @click:close="removeFilter(key, subValue)"
                    v-for="(subValue, index) in subProps.value"
                    :key="index"
                  >
                    <span style="padding-right: 5px">
                      {{ subProps.text }}:
                    </span>
                    <span
                      v-if="
                        subProps.operator !== operatorEqualTo &&
                        subProps.operator !== operatorBetween
                      "
                      style="padding-right: 2px"
                    >
                      {{ subProps.operator }}
                    </span>
                    <span v-if="subValue.length"> {{ subValue }}</span>
                  </v-chip>
                </span>
                <span v-else>
                  <v-chip close label @click:close="removeFilter(key)">
                    <span style="padding-right: 5px">
                      {{ subProps.text }}:
                    </span>
                    <span
                      v-if="
                        subProps.operator !== operatorEqualTo &&
                        subProps.operator !== operatorBetween
                      "
                      style="padding-right: 2px"
                    >
                      {{ subProps.operator }}
                    </span>
                    <span v-if="subProps.value.length">
                      {{ subProps.value }}</span
                    >
                  </v-chip>
                </span>
              </span>
            </v-chip-group>
          </v-card>
        </v-col>
      </v-row>

      <!-- dataGrid -->
      <v-row>
        <v-col cols="12" sm="12" class="pt-0">
          <DataGrid
            gridId="accountMasterPageId"
            gridRef="accountMasterPageRef"
            gridHeight="75vh"
            :headers="headers"
            :itemList="accountMasterList"
            :loading="loading"
            :totalCount="accountMasterListTotalCounts"
            :pageNo="pageNumber"
            @fetchGrideData="fetchAccountMasterData($event)"
            :allowMultiSort="true"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- buttons -->
    <div class="account_master_import_download_buttons">
      <!-- file upload -->
      <FileUpload
        ref="masterFileUpload"
        @openInclusion="setImportType($event)"
        :fileType="fileType"
        :importType="importType"
        v-if="allowImport"
      />

      <!-- file download -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2 white--text"
            color="#8B83BA"
            dark
            fab
            small
            :loading="isDownloading"
            :disabled="isDownloading"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{ downloadDeliverableIcon }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title
              style="cursor: pointer"
              @click="accountDownload()"
              >Filtered Records
              <v-icon right>filter_alt</v-icon></v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title
              style="cursor: pointer"
              @click="openInclusionUpload()"
              >Inclusion Export
              <v-icon right>cloud_download</v-icon></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Refresh Button -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2 white--text"
            :loading="loading"
            :disabled="loading"
            fab
            small
            color="#8B83BA"
            @click="pageRefresh()"
            ><v-icon v-bind="attrs" v-on="on">mdi-cached</v-icon></v-btn
          >
        </template>
        <span>Refresh</span>
      </v-tooltip>

      <!-- filters -->
      <GeneralizedFilter
        :inputFilterObject="inputFilterObject"
        v-on:filterObject="applyFilter($event)"
        v-on:fetchFilters="fetchFacets($event)"
        v-on:searchValue="fetchListWithSearchParam($event)"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
var fileDownload = require("js-file-download");
import Api from "../services/MasterApi";
import DataGrid from "./dataGrid.vue";
import GeneralizedFilter from "./GeneralizedFilters.vue";
import FileUpload from "./fileUploadMaster.vue";
import { getRoles } from "../roles.js";

const ACCOUNT_DISPOSITION = {
  ACTIVE_ACCOUNT: "Active Account",
  ACQUIRED_MERGED_SUBSIDIARY: "Acquired/Merged/Subsidiary",
  BANKRUPT_SHUT_DOWN: "Bankrupt/Shut Down",
};

export default {
  name: "AccountMaster",
  components: {
    DataGrid,
    GeneralizedFilter,
    FileUpload,
  },
  mounted: async function () {
    const defaultFilter = {
      disposition: {
        text: "Disposition",
        operator: "=",
        value: ACCOUNT_DISPOSITION.ACTIVE_ACCOUNT,
      },
    };
    await this.applyFilter(defaultFilter);
    this.fetchFacets("industry");
    this.fetchSicForFilter();
    this.fetchNaicsForFilter();
    let role = await getRoles();
    if (role.indexOf("compliance") > -1) {
      this.allowImport = true;
    }
  },
  data() {
    return {
      allowImport: false,
      operatorEqualTo: "=",
      operatorBetween: "between",
      downloadDeliverableIcon: "file_download",
      isDownloading: false,
      loading: false,
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE,
      filter: {},
      sort: {},
      accountMasterListTotalCounts: 0,
      accountMasterList: [],
      //To set column size set header width large : 200px,common : 150px, small : 125px
      headers: [
        {
          value: "domain",
          text: "Company Domain",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "name",
          text: "Company Name",
          sortable: true,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT_50,
          googleSearch: false,
        },
        {
          value: "employeeSize",
          text: "Employee Size",
          sortable: true,
          divider: true,
          width: "100px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "type",
          text: "Company Type",
          sortable: false,
          divider: true,
          width: "150px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "industry",
          text: "Primary Industry",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "updatedAt",
          text: "Last Modified Date",
          sortable: true,
          divider: true,
          width: "150px",
          type: "date",
          googleSearch: false,
        },
      ],
      filterForChips: {},
      inputFilterObject: {
        name: {
          text: "Company Name",
          type: "array",
          operators: ["IS"],
          values: [],
        },
        website: {
          text: "Website",
          type: "array",
          operators: ["IS"],
          values: [],
        },
        technology: {
          text: "Technology",
          type: "array",
          operators: ["IS"],
          values: [],
        },
        type: {
          text: "Company Type",
          type: "array",
          operators: ["IS"],
          values: ["Parent", "Subsidiary", "Independent"],
        },
        updatedAt: {
          text: "Last Modified Date",
          type: "date",
          operators: ["BETWEEN"],
          values: [],
        },
        industry: {
          text: "Primary Industry",
          type: "array",
          operators: ["IS", "IS NULL"],
          values: [],
          search: true,
        },
        subIndustry: {
          text: "Sub-Industry",
          type: "array",
          operators: ["IS", "IS NULL"],
          values: [],
          search: true,
        },
        sicCode: {
          text: "SIC Code",
          type: "array",
          operators: ["IS", "IS NULL"],
          values: [],
          itemText: "fullTitle",
          itemValue: "id",
          search: true,
        },
        sicDescription: {
          text: "SIC Description",
          type: "array",
          operators: ["IS", "IS NULL"],
          values: [],
          itemText: "fullTitle",
          itemValue: "title",
          search: true,
        },
        naicsCode: {
          text: "NAICS Code",
          type: "array",
          operators: ["IS", "IS NULL"],
          values: [],
          itemText: "fullTitle",
          itemValue: "id",
          search: true,
        },
        naicsDescription: {
          text: "NAICS Description",
          type: "array",
          operators: ["IS", "IS NULL"],
          values: [],
          itemText: "fullTitle",
          itemValue: "title",
          search: true,
        },
        employeeSize: {
          text: "Employee Size",
          type: "number",
          operators: ["LESS THAN", "GREATER THAN"],
          values: [],
        },
        employeeRange: {
          text: "Employee Range",
          type: "array",
          operators: ["IS"],
          values: [
            "0 - 10",
            "11 - 50",
            "51 - 200",
            "201 - 500",
            "501 - 1,000",
            "1,001 - 5,000",
            "5,001 - 10,000",
            "10,000+",
          ],
        },
        revenue: {
          text: "Annual Revenue",
          type: "number",
          operators: ["LESS THAN", "GREATER THAN"],
          values: [],
        },
        revenueRange: {
          text: "Revenue Range",
          type: "array",
          operators: ["IS"],
          values: [
            "0 - $1M",
            "$1M - $10M",
            "$10M - $50M",
            "$50M - $100M",
            "$100M - $250M",
            "$250M - $500M",
            "$500M - $1B",
            "$1B - $10B",
            "$10B+",
          ],
        },
        tags: {
          text: "Tags",
          type: "string",
          operators: ["IS"],
          values: [],
        },
        country: {
          text: "Location Country",
          type: "string",
          operators: ["IS", "IS NULL"],
          values: [],
        },
        disposition: {
          text: "Disposition",
          type: "string",
          operators: ["IS"],
          values: Object.values(ACCOUNT_DISPOSITION),
        },
        // TODO: this code is working but currently this filters not implement in API so it`s not work
        // productAndServices: {
        //   text: "Product And Services",
        //   type: "array",
        //   operators: ["BEGINS WITH"],
        //   values: [],
        // },
        // typeDescriptive: {
        //   text: "Type Descriptive",
        //   type: "string",
        //   operators: ["BEGINS WITH"],
        //   values: [],
        // },
      },
      fileType: "",
      importType: "",
    };
  },
  methods: {
    setImportType: function (isInclusion) {
      this.fileType = "Import";
      this.importType = "Account";
      if (isInclusion) {
        this.fileType = "InclusionExport";
        this.importType = "Account Inclusion Export";
      }
    },
    fetchListWithSearchParam: function (data) {
      if (data.column === "sicCode" || data.column === "sicDescription") {
        this.fetchSicForFilter(data.param);
      }
      if (data.column === "naicsCode" || data.column === "naicsDescription") {
        this.fetchNaicsForFilter(data.param);
      }
    },
    fetchSicForFilter: function (searchQuery) {
      Api.getSIC({
        values: searchQuery,
      })
        .then((response) => {
          this.inputFilterObject.sicCode.values = response.data;
          this.inputFilterObject.sicDescription.values = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchNaicsForFilter: async function (searchQuery) {
      await Api.getNAICS({
        values: searchQuery,
      })
        .then((response) => {
          this.inputFilterObject.naicsCode.values = response.data;
          this.inputFilterObject.naicsDescription.values = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchFacets: function (field) {
      let self = this;
      switch (field) {
        case "industry":
          Api.getAllIndustry()
            .then((response) => {
              self.inputFilterObject.industry.values = _.compact(response.data);
            })
            .catch((error) => console.log(error));
          break;
        case "subIndustry":
          if (this.filter.industry) {
            Api.getAllSubIndustry(this.filter.industry.value)
              .then((response) => {
                self.inputFilterObject.subIndustry.values = _.compact(
                  response.data
                );
              })
              .catch((error) => console.log(error));
          } else {
            let notification = {
              type: "error",
              showMessage: true,
              message: "Please choose Industries to fetch Sub-Industry",
            };
            self.setNotification(notification);
          }
          break;
        default:
          break;
      }
    },
    clearAllFilters: async function () {
      this.filterForChips = {};
      this.filter = {};
      await this.fetchMasterAccountList();
    },
    removeFilter: async function (filterKey, subValue) {
      this.pageNumber = 1;
      if (!subValue) {
        delete this.filterForChips[filterKey];
        delete this.filter[filterKey];
      } else {
        const filterForChipsValues = _.get(
          this.filterForChips,
          [filterKey, "value"],
          []
        );
        const filterValues = _.get(this.filter, [filterKey, "value"], []);
        if (filterForChipsValues.length <= 1 && filterValues.length <= 1) {
          delete this.filterForChips[filterKey];
          delete this.filter[filterKey];
        } else {
          const filterForChipsValueIndex = _.indexOf(
            filterForChipsValues,
            subValue
          );
          if (filterForChipsValueIndex > -1) {
            this.filterForChips[filterKey]["value"].splice(
              filterForChipsValueIndex,
              1
            );
          }
          // TODO :: Required Some Better Alternative
          for (var i = 0; i < filterValues.length; i++) {
            const filterValueIndex =
              filterValues[i].length > subValue.length
                ? filterValues[i].indexOf(subValue)
                : subValue.indexOf(filterValues[i]);
            if (filterValueIndex > -1) {
              this.filter[filterKey]["value"].splice(i, 1);
              break;
            }
          }
        }
      }
      await this.fetchMasterAccountList();
    },
    modifyFilterForChips: function (keyOfFilterApplied) {
      const valueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["value"];
      const operatorOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["operator"];
      const itemTextOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemText"];
      const itemValueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemValue"];
      const searchOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["search"];
      if (
        operatorOfFilteredForChips === "between" &&
        Array.isArray(valueOfFilteredForChips)
      ) {
        this.filterForChips[keyOfFilterApplied]["value"] =
          this.filterForChips[keyOfFilterApplied]["value"].join(" ~ ");
      }

      if (
        itemTextOfFilteredForChips &&
        itemValueOfFilteredForChips &&
        searchOfFilteredForChips
      ) {
        let valuesOfFilteredId = [];
        _.forEach(this.inputFilterObject[keyOfFilterApplied].values, (item) => {
          if (
            valueOfFilteredForChips.indexOf(item[itemValueOfFilteredForChips]) >
            -1
          ) {
            valuesOfFilteredId.push(item[itemTextOfFilteredForChips]);
          }
        });
        valuesOfFilteredId = _.compact(valuesOfFilteredId);
        this.filterForChips[keyOfFilterApplied]["value"] = valuesOfFilteredId;
      } else if (itemTextOfFilteredForChips && itemValueOfFilteredForChips) {
        let valuesOfFilteredId = _.map(
          this.inputFilterObject[keyOfFilterApplied].values,
          (item) => {
            if (item[itemValueOfFilteredForChips] === valueOfFilteredForChips) {
              return item[itemTextOfFilteredForChips];
            }
          }
        );
        valuesOfFilteredId = _.compact(valuesOfFilteredId);
        this.filterForChips[keyOfFilterApplied]["value"] =
          valuesOfFilteredId[0];
      }
    },
    applyFilter: async function (filterApplied) {
      this.pageNumber = 1;
      const keyOfFilterApplied = Object.keys(filterApplied)[0];
      this.filterForChips[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      this.modifyFilterForChips(keyOfFilterApplied);
      filterApplied[keyOfFilterApplied] = _.omit(
        filterApplied[keyOfFilterApplied],
        ["text", "itemText", "itemValue", "search", "type"]
      );
      this.filter[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      await this.fetchMasterAccountList();
    },
    pageRefresh: async function () {
      await this.fetchMasterAccountList();
    },
    fetchMasterAccountList: async function () {
      this.loading = true;
      Api.getMasterAccounts({
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
        filter: JSON.stringify(this.filter),
        sort: JSON.stringify(this.sort),
      })
        .then((response) => {
          this.accountMasterListTotalCounts = response.data.totalCount;
          this.accountMasterList = response.data.docs;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    generateSortPayload: async function (sortBy, sortDesc) {
      var sortPayload = {};
      sortBy.forEach(function (item, index) {
        sortPayload[item] = sortDesc[index] ? "asc" : "desc";
      });
      return sortPayload;
    },
    fetchAccountMasterData: async function (options) {
      const { sortBy, sortDesc, page, itemsPerPage } = options;

      this.pageNumber = page;
      this.pageSize = itemsPerPage;
      this.sort = await this.generateSortPayload(sortBy, sortDesc);

      //api call
      await this.fetchMasterAccountList();
    },
    changeIconOfDownloadBtn: function (disable) {
      if (disable) {
        this.downloadDeliverableIcon = "downloading";
      } else {
        this.downloadDeliverableIcon = "file_download";
      }
    },
    openInclusionUpload: function () {
      this.$refs.masterFileUpload.openDialog("Account Inclusion Export");
    },
    accountDownload: function () {
      const self = this;
      this.isDownloading = true;
      this.changeIconOfDownloadBtn(true);

      Api.getMasterAccounts({
        filter: JSON.stringify(this.filter),
        sort: JSON.stringify(this.sort),
        download: true,
      })
        .then((response) => {
          var downloadFileName = `account_master_${Date.now()}.csv`;
          const responseContentType = response.headers["content-type"];

          let notification = {
            type: "success",
            showMessage: true,
          };
          this.isDownloading = false;
          this.changeIconOfDownloadBtn(false);
          if (responseContentType === "application/csv") {
            notification.message = self.$notifications().FILE_SUCCESS;
            self.setNotification(notification);
            return fileDownload(response.data, downloadFileName);
          }

          notification.message =
            self.$notifications().DOWNLOAD_JOB_SUBMISSION_SUCCESS;
          self.setNotification(notification);
          return;
        })
        .catch((error) => {
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().FILE_ERROR,
          };
          console.error(error);
          self.setNotification(notification);
          this.isDownloading = false;
          this.changeIconOfDownloadBtn(false);
        });
    },
  },
};
</script>

<style scoped>
.account_master_import_download_buttons {
  position: absolute;
  top: 60px;
  right: 15px;
}

.accountsMaster {
  width: 100%;
  position: relative;
}

.content {
  margin-left: 0 !important;
}

.account_master_import_download_buttons h1 {
  float: left;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}
</style>
