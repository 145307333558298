<template>
  <div class="d-inline-block">
    <v-tooltip bottom>
      <!-- Upload File tooltip-->
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="#8B83BA"
          depressed
          fab
          small
          dark
          @click="openDialog()"
          ><v-icon>upload</v-icon></v-btn
        >
      </template>
      <span>Upload File</span>
    </v-tooltip>

    <v-dialog v-model="fileUploadDialog" fullscreen hide-overlay>
      <v-card>
        <div class="content">
          <!-- header label & closeIcon -->
          <v-toolbar class="toolbar_master" dark color="#7c7c7c">
            <v-toolbar-title>Master File Upload</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-toolbar-items>
              <v-btn icon dark @click="fileUploadDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <!-- stepper -->
          <v-stepper v-model="stepperStepNo">
            <!-- stepper guider -->
            <v-stepper-header>
              <v-stepper-step :complete="stepperStepNo > 1" step="1">
                Choose Data
                <small>Select file to upload</small>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="stepperStepNo > 2" step="2">
                Edit Mapping
                <small>Tell us what your CSV header means</small>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="stepperStepNo > 3" step="3">
                Upload Options
                <small>What to do with Data</small>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="4">
                Summary
                <small>Review & Start Import </small>
              </v-stepper-step>
            </v-stepper-header>

            <!-- step-1 -->
            <v-stepper-content step="1">
              <div class="fileImportForm">
                <!-- alertBox -->
                <v-row class="mt-1 mb-5 ml-1" v-if="selectedImportType">
                  <v-alert
                    dense
                    class="col-md-12"
                    icon="cloud_upload"
                    border="bottom"
                    colored-border
                    color="primary"
                    outlined
                    elevation="2"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        Import your data to Goldmine Master
                      </v-col>
                      <v-col class="shrink">
                        <standard-headers
                          :fileType="fileType"
                          :importType="selectedImportType"
                          :filteredMappingFields="filteredMappingFields"
                          type="Master"
                        />
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-row>

                <!-- Import Type -->
                <v-row class="fileDetailField">
                  <v-col class="d-flex fileDetailLabel" cols="12" sm="3">
                    <label class="label">
                      Import Type: <span style="color: red">*</span></label
                    >
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="7">
                    <v-select
                      class="fileDetailValue"
                      v-on:change="
                        resetOperationValues();
                        filterMappingFields();
                      "
                      v-model="selectedImportType"
                      :items="importTypes"
                      item-text="title"
                      item-value="id"
                      label="Select Import Type"
                      single-line
                      solo
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>

                <!-- Source -->
                <v-row class="fileDetailField">
                  <v-col class="d-flex fileDetailLabel" cols="12" sm="3">
                    <label class="label">
                      Enter Source:
                      <span style="color: red">*</span></label
                    >
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="7">
                    <!-- hide-details="auto" -->
                    <v-text-field
                      class="fileDetailValue"
                      label="Source"
                      id="source"
                      v-model="source"
                      v-on:change="filterMappingFields()"
                      :rules="[
                        (v) => !!v || 'Source is required',
                        (v) => (v && !!v.trim()) || 'Source is required',
                      ]"
                      outlined
                      dense
                      clearable
                      single-line
                      solo
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- Select File -->
                <v-row class="fileDetailField">
                  <v-col class="d-flex fileDetailLabel" cols="12" sm="3">
                    <label class="label">
                      Select File: <span style="color: red">*</span></label
                    >
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="7">
                    <v-spacer>
                      <v-file-input
                        class="fileDetailValue"
                        :prepend-icon="importFileIcon"
                        :color="importFileIconColor"
                        v-model="file"
                        label="Select File"
                        counter
                        accept=".csv"
                        show-size
                        v-on:change="selectFile"
                        truncate-length="27"
                        solo
                      ></v-file-input>
                    </v-spacer>
                  </v-col>
                </v-row>

                <!-- Error in Parsing File -->
                <v-row class="mt-6" v-if="parsingError.code">
                  <div class="parsingError">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-uppercase"
                          >Error in Parsing File</v-list-item-title
                        >
                        <v-list-item-title class="mt-2"
                          ><b>Reason</b>:
                          {{ parsingError.message }}</v-list-item-title
                        >
                        <v-list-item-title class="mt-2"
                          ><b>Line Number</b>:
                          {{ parsingError.row + 2 }}</v-list-item-title
                        >
                        <v-list-item-title class="mt-2"
                          ><b>Type of Error</b>: {{ parsingError.type }} -
                          {{ parsingError.code }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-row>
              </div>

              <!-- Next -->
              <v-btn
                class="mt-10"
                color="#8B83BA"
                dark
                depressed
                @click="initMapping()"
                :disabled="disabledJumpMappingBtn"
              >
                Next
              </v-btn>
            </v-stepper-content>

            <!-- step-2 -->
            <v-stepper-content step="2">
              <div class="mappingForm">
                <v-row no-gutters>
                  <v-row class="mt-1 ml-1">
                    <v-alert
                      dense
                      outlined
                      class="col-md-12"
                      icon="format_list_numbered"
                      border="bottom"
                      colored-border
                      color="primary"
                      elevation="2"
                    >
                      <v-row align="center">
                        <v-col class="grow">
                          Your file has been auto-mapped to existing Database
                          Fields, but you can edit the mappings if you wish.
                          Unmapped fields will not be imported.
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-row>
                  <v-row
                    class="mappingField mb-4 ml-1"
                    style="border-bottom: 1px solid #ddd"
                  >
                    <v-col
                      class="d-flex mappingFieldLabel"
                      style="margin-right: 40px"
                      cols="12"
                      sm="4"
                    >
                      <label
                        class="label"
                        style="font-weight: 800; color: #00458d"
                      >
                        File Headers (CSV)</label
                      >
                    </v-col>
                    <v-col class="d-flex mappingFieldLabel" cols="12" sm="5">
                      <label
                        class="label"
                        style="font-weight: 800; color: #00458d"
                      >
                        Database Fields</label
                      >
                    </v-col>
                  </v-row>
                  <template v-for="(fileHeader, index) in fileHeaders">
                    <v-row class="mappingField ml-1 mt-0" :key="index">
                      <v-col
                        class="d-flex mappingFieldLabel fieldList"
                        cols="12"
                        sm="4"
                      >
                        <label class="label">
                          {{ fileHeader }}
                          <v-icon v-if="mapping[fileHeader]" color="#006000"
                            >task_alt</v-icon
                          >
                        </label>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="5">
                        <v-autocomplete
                          clearable
                          solo
                          class="mappingDetail"
                          v-on:change="
                            mappingColumn(fileHeader, mapping[fileHeader])
                          "
                          v-model="mapping[fileHeader]"
                          :items="filteredMappingFields"
                          item-text="label"
                          item-value="id"
                          persistent-hint
                          return-object
                          single-line
                          hide-details
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </template>
                </v-row>
              </div>

              <v-btn class="mr-2 mt-10" @click="stepperStepNo = 1">
                Back
              </v-btn>

              <v-btn
                color="#8B83BA"
                dark
                depressed
                class="mt-10"
                @click="stepperStepNo = reviewPageNumber"
              >
                Next
              </v-btn>
            </v-stepper-content>

            <!-- step-3 -->
            <v-stepper-content step="3">
              <div class="fileImportForm">
                <v-row class="mt-1 ml-1">
                  <v-alert
                    dense
                    class="col-md-11"
                    icon="settings_applications"
                    border="bottom"
                    colored-border
                    color="primary"
                    elevation="2"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        Your file is mapped, decide what needs to be done with
                        the Data.
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-row>
                <v-row
                  class="fileDetailField"
                  v-if="selectedImportType === 'Account'"
                >
                  <v-col class="d-flex" cols="12" sm="3">
                    <label class="label"> Operation Type: </label>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="9">
                    <v-radio-group v-model="operationOn.account" row>
                      <v-radio
                        v-for="item in operationTypes"
                        :key="item.value"
                        v-on:change="enableJumpReviewBtn()"
                        :label="item.label"
                        :value="item.value"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row
                  class="fileDetailField"
                  v-if="selectedImportType === 'Contact'"
                >
                  <v-col class="d-flex" cols="12" sm="3">
                    <label class="label"> Operation Type: </label>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="9">
                    <v-radio-group v-model="operationOn.contact" row>
                      <v-radio
                        v-for="item in operationTypes"
                        :key="item.value"
                        v-on:change="enableJumpReviewBtn()"
                        :label="item.label"
                        :value="item.value"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row
                  class="fileDetailField"
                  v-if="
                    selectedImportType === 'Account Meta' &&
                    getKeyByValue(mapping, 'account.aliasName')
                  "
                >
                  <v-col class="d-flex" cols="12" sm="3">
                    <label class="label"> Alias Name: </label>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="9">
                    <v-radio-group v-model="operationOn.aliasName" row>
                      <v-radio
                        v-on:change="enableJumpReviewBtn()"
                        label="Append"
                        value="Add"
                      ></v-radio>
                      <v-radio
                        v-on:change="enableJumpReviewBtn()"
                        label="Remove"
                        value="Remove"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row
                  class="fileDetailField"
                  v-if="
                    selectedImportType === 'Account Meta' &&
                    getKeyByValue(mapping, 'account.tags')
                  "
                >
                  <v-col class="d-flex" cols="12" sm="3">
                    <label class="label"> Tags: </label>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="9">
                    <v-radio-group v-model="operationOn.tags" row>
                      <v-radio
                        v-on:change="enableJumpReviewBtn()"
                        label="Append"
                        value="Add"
                      ></v-radio>
                      <v-radio
                        v-on:change="enableJumpReviewBtn()"
                        label="Remove"
                        value="Remove"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row
                  class="fileDetailField"
                  v-if="
                    selectedImportType === 'Account Meta' &&
                    getKeyByValue(mapping, 'account.technology')
                  "
                >
                  <v-col class="d-flex" cols="12" sm="3">
                    <label class="label"> Technology: </label>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="9">
                    <v-radio-group v-model="operationOn.technology" row>
                      <v-radio
                        v-on:change="enableJumpReviewBtn()"
                        label="Append"
                        value="Add"
                      ></v-radio>
                      <v-radio
                        v-on:change="enableJumpReviewBtn()"
                        label="Remove"
                        value="Remove"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </div>
              <v-btn class="mr-2 mt-10" @click="stepperStepNo = 2">
                Back
              </v-btn>
              <v-btn
                color="#8B83BA"
                dark
                depressed
                :disabled="disabledJumpReviewBtn"
                class="mt-10"
                @click="stepperStepNo = 4"
              >
                Next
              </v-btn>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-row class="start_import_wrapper">
                <v-col cols="12" md="3">
                  <v-card outlined>
                    <v-card-title>Type of Import</v-card-title>
                    <v-card-text
                      v-if="
                        selectedImportType === 'Contact Inclusion Export' ||
                        selectedImportType === 'Account Inclusion Export'
                      "
                      >{{ selectedImportType }}</v-card-text
                    >
                    <v-card-text v-else
                      >{{ selectedImportType }} {{ fileType }}</v-card-text
                    >
                  </v-card>
                </v-col>
                <v-col cols="12" md="2">
                  <v-card outlined>
                    <v-card-title>Type of Operation</v-card-title>
                    <v-card-text v-if="operationOn.account">
                      {{ operationOn.account }}
                    </v-card-text>
                    <v-card-text v-if="operationOn.aliasName">
                      Alias Name - {{ operationOn.aliasName }}
                    </v-card-text>
                    <v-card-text v-if="operationOn.tags">
                      Tags - {{ operationOn.tags }}</v-card-text
                    >
                    <v-card-text v-if="operationOn.technology">
                      Technology - {{ operationOn.technology }}
                    </v-card-text>
                    <v-card-text v-if="operationOn.contact">
                      Contact - {{ operationOn.contact }}
                    </v-card-text>
                    <v-card-text
                      v-if="
                        selectedImportType === 'Account Inclusion Export' ||
                        selectedImportType === 'Contact Inclusion Export'
                      "
                      >Inclusion Export</v-card-text
                    >
                  </v-card>
                </v-col>
                <v-col cols="12" md="5">
                  <v-card outlined>
                    <v-card-title>File Name</v-card-title>
                    <v-card-text>{{ selectedFileName }}</v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-btn
                class="mr-2 mt-10"
                @click="stepperStepNo = reviewBackPageNumber"
              >
                Back
              </v-btn>
              <v-btn
                class="mt-10"
                color="#8B83BA"
                dark
                depressed
                :loading="showFileUploadProgress"
                @click="uploadFile()"
                :disabled="disabledFileUploadBtn"
              >
                File Upload
                <template v-slot:loader>
                  <span>Uploading...</span>
                </template>
              </v-btn>
            </v-stepper-content>
          </v-stepper>
          <v-stepper v-model="stepperStepNo"> </v-stepper>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
var AccountMasterTemplate = require("./AccountMasterTemplate");
var ContactMasterTemplate = require("./ContactMasterTemplate");
import _ from "lodash";
import Api from "../services/MasterApi";
import standardHeaders from "./standardHeaders.vue";
var mappingFields = require("./masterMappingFields");

export default {
  name: "FileUpload",
  props: {
    fileType: String,
    importType: String,
  },
  components: {
    standardHeaders,
  },
  data() {
    return this.initialState();
  },
  watch: {
    importType(val) {
      this.selectedImportType = val;
      this.filterMappingFields();
    },
  },
  computed: {},
  mounted: function () {
    this.pageLoad();
  },
  methods: {
    getKeyByValue: function (object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },
    getTemplate: async function () {
      let template = AccountMasterTemplate;
      let contactTemplate = ContactMasterTemplate;
      this.extractKeysAndLabelsFromTemplate(template);
      this.extractKeysAndLabelsFromTemplate(contactTemplate, true);
    },
    extractKeysAndLabelsFromTemplate: function (template, isContact) {
      let account = Object.fromEntries(
        template.map((accountField) => [accountField.id, accountField.label])
      );

      _.set(
        this.templateMapping,
        "account",
        JSON.parse(JSON.stringify(account).replaceAll("account.", ""))
      );

      if (isContact) {
        let contact = Object.fromEntries(
          template.map((contactField) => [contactField.id, contactField.label])
        );

        _.set(
          this.templateMapping,
          "contact",
          JSON.parse(JSON.stringify(contact).replaceAll("contact.", ""))
        );
      }
    },
    pageLoad: function () {
      Object.assign(this.$data, this.initialState());
      this.$forceUpdate();
    },
    openDialog: async function (fileTypePayload) {
      if (fileTypePayload) {
        this.selectedImportType = fileTypePayload;
        this.$emit("openInclusion", true);
      } else {
        this.$emit("openInclusion", false);
      }

      this.pageLoad();
      try {
        await this.getTemplate();
        this.fileUploadDialog = true;
      } catch (error) {
        console.log(`Could Not Get Template Mapping ${error.message}`);
      }
      if (this.importType) {
        this.filterMappingFields();
      }

      if (
        this.importType === "Contact Inclusion Export" ||
        this.importType === "Account Inclusion Export"
      ) {
        this.reviewPageNumber = 4;
        this.reviewBackPageNumber = 2;
      }
    },
    initialState: function () {
      return {
        reviewPageNumber: 3,
        reviewBackPageNumber: 3,
        disabledJumpReviewBtn: true,
        fileUploadDialog: false,
        supportFileUploadDialog: false,
        countFileRow: 0,
        totalErrorFileParse: 0,
        progress: 0,
        file: null,
        fileParseComplete: false,
        disabledJumpMappingBtn: true,
        disabledFileUploadBtn: false,
        showFileUploadProgress: false,
        columnIsDuplicate: false,
        errorTimeOut: 5000,
        stepperStepNo: 1,
        selectedImportType: this.importType || "",
        selectedFileName: "",
        importTypes: [
          {
            id: "Account",
            title: "Account",
          },
          {
            id: "Account Inclusion Export",
            title: "Account Inclusion Export",
          },
          {
            id: "Account Meta",
            title: "List (Alias, Tags, Technology)",
          },
          {
            id: "Contact",
            title: "Contact",
          },
          {
            id: "Contact Inclusion Export",
            title: "Contact Inclusion Export",
          },
        ],
        importFileIcon: "attach_file",
        importFileIconColor: "primary",
        filteredMappingFields: [],
        mapping: {},
        operationName: "",
        parsingError: {},
        templateMapping: {},
        fileHeaders: [],
        operationTypes: [
          {
            label: "Skip (Add Net New)",
            value: "Skip",
          },
          {
            label: "Overwrite",
            value: "Overwrite",
          },
          {
            label: "Overwrite (If Not Verified)",
            value: "Overwrite If Not Verified",
          },
          {
            label: "Append (Insert Missing fields)",
            value: "Append",
          },
        ],
        operationOn: {
          account: "",
          contact: "",
          alias: "",
          tags: "",
          technology: "",
        },
        source: "",
      };
    },
    resetOperationValues: function () {
      this.operationOn = {
        account: "",
        contact: "",
        alias: "",
        tags: "",
        technology: "",
      };
      this.operationName = "";
    },
    filterMappingFields: function () {
      this.disabledJumpReviewBtn = true;
      this.enableJumpMappingBtn();
      var processMappingFields = JSON.parse(JSON.stringify(mappingFields));
      const accountTemplateMapping = _.get(this.templateMapping, "account", {});
      const contactTemplateMapping = _.get(this.templateMapping, "contact", {});
      this.filteredMappingFields = processMappingFields.filter(
        (mappingField) => {
          if (
            this.selectedImportType === "Account" &&
            mappingField["type"].includes("account")
          ) {
            mappingField.label = `Account.${_.get(
              accountTemplateMapping,
              `${mappingField.id}`,
              mappingField.label
            )}`;
            mappingField.id = `account.${mappingField.id}`;
            if (!mappingField.mapping.includes(mappingField.label)) {
              mappingField.mapping.push(mappingField.label);
            }
            return true;
          }
          if (
            this.selectedImportType === "Account" &&
            mappingField["type"].includes("location")
          ) {
            mappingField.label = `Location.${_.get(
              accountTemplateMapping,
              `${mappingField.id}`,
              mappingField.label
            )}`;
            mappingField.id = `location.${mappingField.id}`;

            /* NAS:Hack - bcoz location and account have the same key 'type' */
            if (mappingField.id === "location.locationType") {
              mappingField.id = "location.type";
            }
            if (!mappingField.mapping.includes(mappingField.label)) {
              mappingField.mapping.push(mappingField.label);
            }
            return true;
          }
          if (
            this.selectedImportType === "Account Meta" &&
            mappingField["type"].includes("list")
          ) {
            mappingField.label = `Account.${_.get(
              accountTemplateMapping,
              `${mappingField.id}`,
              mappingField.label
            )}`;
            mappingField.id = `account.${mappingField.id}`;
            if (!mappingField.mapping.includes(mappingField.label)) {
              mappingField.mapping.push(mappingField.label);
            }
            return true;
          }
          if (
            this.selectedImportType === "Account Inclusion Export" &&
            mappingField["type"].includes("accountInclusion")
          ) {
            mappingField.label = `Account.${_.get(
              accountTemplateMapping,
              `${mappingField.id}`,
              mappingField.label
            )}`;
            if (!mappingField.mapping.includes(mappingField.label)) {
              mappingField.mapping.push(mappingField.label);
            }
            return true;
          }
          if (
            this.selectedImportType === "Contact" &&
            mappingField["type"].includes("contact")
          ) {
            mappingField.label = `Contact.${_.get(
              contactTemplateMapping,
              `${mappingField.id}`,
              mappingField.label
            )}`;
            mappingField.id = `contact.${mappingField.id}`;
            if (!mappingField.mapping.includes(mappingField.label)) {
              mappingField.mapping.push(mappingField.label);
            }
            return true;
          }
          if (
            this.selectedImportType === "Contact" &&
            mappingField["type"].includes("account")
          ) {
            mappingField.label = `Account.${_.get(
              contactTemplateMapping,
              `${mappingField.id}`,
              mappingField.label
            )}`;
            mappingField.id = `account.${mappingField.id}`;

            if (!mappingField.mapping.includes(mappingField.label)) {
              mappingField.mapping.push(mappingField.label);
            }
            return true;
          }
          if (
            this.selectedImportType === "Contact" &&
            mappingField["type"].includes("location")
          ) {
            mappingField.label = `Location.${_.get(
              contactTemplateMapping,
              `${mappingField.id}`,
              mappingField.label
            )}`;
            mappingField.id = `location.${mappingField.id}`;

            if (!mappingField.mapping.includes(mappingField.label)) {
              mappingField.mapping.push(mappingField.label);
            }
            return true;
          }
          if (
            this.selectedImportType === "Contact Inclusion Export" &&
            mappingField["type"].includes("contactInclusion")
          ) {
            mappingField.label = `Contact.${_.get(
              contactTemplateMapping,
              `${mappingField.id}`,
              mappingField.label
            )}`;

            if (!mappingField.mapping.includes(mappingField.label)) {
              mappingField.mapping.push(mappingField.label);
            }
            return true;
          }
        }
      );
    },
    mappingColumn: function (fileHeader, value) {
      if (value) {
        this.mapping[fileHeader] = value["id"];
      } else {
        this.mapping[fileHeader] = "";
      }
    },
    enableJumpMappingBtn: function () {
      this.disabledJumpMappingBtn = !(
        this.fileType &&
        this.selectedImportType &&
        this.file &&
        this.fileParseComplete &&
        !this.totalErrorFileParse &&
        this.source
      );
    },
    enableJumpReviewBtn: function (value) {
      this.disabledJumpReviewBtn = value;
    },
    changeImportFileIcon: function () {
      if (this.columnIsDuplicate) {
        this.importFileIcon = "close";
        this.importFileIconColor = "warning";
        let notification = {
          type: "warning",
          showMessage: true,
          message: this.$notifications().FILE_UPLOAD_HEADER_ERROR,
        };
        this.setNotification(notification);
      } else if (
        this.file &&
        this.fileParseComplete &&
        this.totalErrorFileParse
      ) {
        this.importFileIcon = "close";
        this.importFileIconColor = "warning";
      } else if (
        this.file &&
        this.fileParseComplete &&
        !this.totalErrorFileParse
      ) {
        this.importFileIcon = "check";
        this.importFileIconColor = "success";
        this.enableJumpMappingBtn();
      } else {
        this.importFileIcon = "attach_file";
        this.importFileIconColor = "primary";
      }
    },
    initMapping: function () {
      this.stepperStepNo = 2;
      this.autoMappingFields();
    },
    autoMappingFields: function () {
      this.mapping = {};
      let fileHeadersInLowerCase = {};

      this.fileHeaders.forEach((header) => {
        fileHeadersInLowerCase[header.toLocaleLowerCase().trim()] = header;
        this.mapping[header] = "";
      });

      for (let index = 0; index < this.filteredMappingFields.length; index++) {
        const mappingField = this.filteredMappingFields[index];
        if (!mappingField["mapping"].length) {
          continue;
        }
        mappingField["mapping"].forEach((fieldName) => {
          if (fileHeadersInLowerCase[fieldName.toLowerCase()]) {
            let matchedField = fileHeadersInLowerCase[fieldName.toLowerCase()];
            this.mapping[matchedField] = mappingField["id"];
          }
        });
      }
      this.$forceUpdate();
    },
    onFileParsingComplete: function (results) {
      this.fileParseComplete = true;
      this.fileHeaders = results.meta.fields;
      this.countFileRow = results.data.length;
      this.totalErrorFileParse = results.errors.length;

      var tempArray = this.fileHeaders.map((x) =>
        typeof x === "string" ? x.toLowerCase() : x
      );
      tempArray = Array.from(new Set(tempArray));
      this.columnIsDuplicate =
        tempArray.length === this.fileHeaders.length ? false : true;
      this.changeImportFileIcon();
      this.showParsingError(results);
    },
    onFileParsingError: function (results) {
      this.fileParseComplete = true;
      this.totalErrorFileParse = results.errors.length;
      this.changeImportFileIcon();
      this.showParsingError(results);
    },
    selectFile: async function () {
      let self = this;
      this.columnIsDuplicate = false;
      this.fileParseComplete = false;
      this.countFileRow = 0;
      this.totalErrorFileParse = 0;
      this.parsingError = {};
      this.changeImportFileIcon();
      this.enableJumpMappingBtn();
      if (this.file) {
        this.$papa.parse(this.file, {
          header: true,
          delimiter: ",",
          skipEmptyLines: "greedy",
          complete: this.onFileParsingComplete,
          error: this.onFileParsingError,
          step: function (results, parser) {
            self.onFileParsingComplete(results);
            parser.abort();
            results = null;
            return false;
          },
        });
        this.selectedFileName = this.file.name;
      }
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    makeRequest: function (method, url, contentType, body, isAsync = true) {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open(method, url, isAsync);
        xhr.setRequestHeader("Content-Type", contentType);
        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            resolve({
              status: this.status,
              statusText: this.statusText,
            });
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          }
        };
        xhr.onerror = function () {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        };
        xhr.send(body);
      });
    },
    uploadFile: async function () {
      let self = this;
      self.disabledFileUploadBtn = true;
      self.showFileUploadProgress = true;

      let config = {
        onUploadProgress: function (progressEvent) {
          self.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      };

      let operationParamPayload = {};
      self.operationName = _.camelCase(
        `${self.selectedImportType}${self.fileType}`
      );

      if (self.operationName === "accountImport") {
        operationParamPayload["account"] = this.operationOn.account;
      }
      if (self.operationName === "contactImport") {
        operationParamPayload["contact"] = this.operationOn.contact;
      }
      if (
        self.operationName === "accountMetaImport" &&
        self.getKeyByValue(self.mapping, "account.aliasName")
      ) {
        operationParamPayload["aliasName"] = this.operationOn.aliasName;
      }
      if (
        self.operationName === "accountMetaImport" &&
        self.getKeyByValue(self.mapping, "account.tags")
      ) {
        operationParamPayload["tags"] = this.operationOn.tags;
      }
      if (
        self.operationName === "accountMetaImport" &&
        self.getKeyByValue(self.mapping, "account.technology")
      ) {
        operationParamPayload["technology"] = this.operationOn.technology;
      }

      /* For Inclusion Exports */
      const fileData = {
        operationParam: JSON.stringify(operationParamPayload),
        operationName: self.operationName,
        mapping: JSON.stringify(self.mapping),
        rowCount: self.countFileRow,
        fileType: self.fileType,
        fileName: self.file.name,
        fileContentType: self.file.type,
        source: self.source,
      };

      if (self.selectedImportType === "Account Inclusion Export") {
        fileData.operationName = "accountInclusionExport";
        fileData.fileType = "InclusionExport";
      }

      if (self.selectedImportType === "Contact Inclusion Export") {
        fileData.operationName = "contactInclusionExport";
        fileData.fileType = "InclusionExport";
      }

      let fileId;
      let uploadURL;

      // get the signedURL to upload a File
      try {
        const result = await Api.uploadMasterFile(fileData, config);

        uploadURL = result.data.uploadUrl;
        fileId = result.data.fileId;
      } catch (error) {
        // Get File Upload URL Failed
        // Show Error Notification
        self.disabledFileUploadBtn = false;
        self.showFileUploadProgress = false;
        let notification = {
          type: "error",
          showMessage: true,
          message: self.$notifications().FILE_UPLOAD_ERROR,
        };
        self.setNotification(notification);
        self.$emit("uploaded", false);
        return;
      }

      // upload File using the uploadURL
      try {
        if (!uploadURL) {
          throw new Error("Could Not Found UploadURl to Upload A file");
        }

        await self.makeRequest(
          "PUT",
          uploadURL,
          self.file.type,
          self.file,
          true
        );

        // File Upload Success
        // Show Success Notification
        self.showFileUploadProgress = false;
        self.fileUploadDialog = false;
        self.supportFileUploadDialog = false;
        let notification = {
          type: "success",
          showMessage: true,
          message: self.$notifications().FILE_UPLOADED,
        };
        self.setNotification(notification);

        self.$emit("uploaded", true);
      } catch (error) {
        // File Upload Failed
        // Delete File Entry
        try {
          await Api.deleteMasterFile(fileId);
        } catch (e) {
          console.log(e);
        }

        // File Upload Failed
        // Show Error Notification

        self.disabledFileUploadBtn = false;
        self.showFileUploadProgress = false;
        let notification = {
          type: "error",
          showMessage: true,
          message: self.$notifications().FILE_UPLOAD_ERROR,
        };
        self.setNotification(notification);
        self.$emit("uploaded", false);
        return;
      }
    },
    showParsingError: function (results) {
      this.parsingError = {};
      if (results.errors.length) {
        this.parsingError = results.errors[0];
      }
      if (this.columnIsDuplicate) {
        this.parsingError = {
          code: "Headers",
          message: "Duplicate Headers Found",
          row: -1,
          type: "Duplicate",
        };
      }
    },
  },
};
</script>

<style scoped>
.content {
  margin-left: 70px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.header h1 {
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}
.label {
  align-self: center;
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 800;
  text-transform: uppercase !important;
}
.fileDetailValue,
.mappingDetail {
  font-family: "Inter";
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #25213b;
  text-overflow: unset;
}
label.fileDetailValue {
  align-self: center;
}
.fileImportForm .mappingField {
  margin-left: 10px;
}
.fileImportForm .v-alert {
  margin-left: -15px;
}
.v-alert .grow {
  text-transform: uppercase;
  font-size: 13px;
}
.v-alert {
  background: linear-gradient(
    to bottom,
    rgb(238 238 238 / 50%) 0%,
    #bfdbf1 100%
  ) !important;
  color: #205fac;
}
.fileImportForm {
  width: 90%;
  margin-left: 15px;
}
.mappingField .v-select__selection--comma {
  overflow: visible;
}
.mappingField,
.fileDetailField {
  width: 100%;
  height: 75px;
}

.mappingField .mappingFieldLabel.fieldList .v-icon {
  position: absolute;
  right: 0px;
  margin: 0;
  top: 0px;
  font-size: 18px;
}
.mappingField .mappingFieldLabel.fieldList {
  position: relative;
  padding: 10px;
  margin-top: 15px;
  border: 1px solid #ddd;
  border-radius: 2px;
  border-left: 3px inset rgb(30 144 255 / 50%);
  margin-right: 40px;
  background: #f5f5f5;
}
.mappingField .mappingFieldLabel,
.fileDetailField .fileDetailLabel {
  margin-top: 10px;
  align-self: baseline;
}
.supportFileUpload {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  padding: 24px 24px 16px;
  width: 100%;
}
.start_import_wrapper .v-card__text {
  font-size: 18px;
  color: #747474;
  font-weight: 600;
  padding-top: 5px;
}
.start_import_wrapper .v-card__title {
  font-family: "Lato";
  font-size: 12px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  text-transform: uppercase !important;
  padding: 2px 15px 0px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd;
  background: linear-gradient(to right, #f5f5f5 0%, #ddd 100%);
}
.notification {
  position: absolute;
}
.mappingForm {
  width: 95%;
}
.parsingError {
  background: #fafad2;
  border: 1px solid brown;
  color: #24292e;
  border-radius: 4px;
  margin-top: 20px;
  width: 95%;
}

.toolbar_master .v-toolbar__title {
  font-weight: 500;
}
.toolbar_master {
  background: linear-gradient(to bottom, #9e9e9e 0%, #747474 100%);
  margin-bottom: 10px;
  border: 2px solid rgb(109 109 109 / 87%);
  border-radius: 4px !important;
}

.v-stepper__header {
  margin-bottom: 3px;
  border: 1px solid #eee;
}

.v-stepper__content {
  background: repeating-linear-gradient(350deg, #f7f9fb, transparent 5px);
}
</style>
