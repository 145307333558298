import PROJECTList from "./components/PROJECTList.vue";
import PROJECTDetail from "./components/PROJECTDetail.vue";
import clientList from "./components/clientList.vue";
import agentList from "./components/Agent.vue";
import AgentTaskList from "./components/AgentTaskList.vue";
import AgentProjectList from "./components/AgentProjectList.vue";
import AgentPreview from "./components/AgentPreview.vue";
import Workspace from "./components/Workspace.vue";
import Root from "./components/Root.vue";
import Templates from "./components/Templates.vue";
import SuppressionFilesList from "./components/SuppressionFilesList.vue";
import DetailMaster from "./components/DetailMaster.vue";
export default [
  {
    name: "home",
    path: "/",
    component: Root,
  },
  {
    name: "clientList",
    path: "/client-list",
    component: clientList,
    meta: {
      requiresAuth: true,
      roles: ["manager", "compliance", "projectOwner"],
    },
  },
  {
    name: "PROJECTList",
    path: "/project-list",
    component: PROJECTList,
  },
  {
    name: "PROJECTDetail",
    path: "/project-detail/:id/:tab",
    component: PROJECTDetail,
  },
  {
    name: "agentList",
    path: "/agents",
    component: agentList,
    meta: {
      requiresAuth: true,
      roles: ["manager", "compliance", "projectOwner"],
    },
  },
  {
    name: "AgentProjectList",
    path: "/agent-project-list",
    component: AgentProjectList,
  },
  {
    name: "AgentPreview",
    path: "/agent-preview",
    component: AgentPreview,
  },
  {
    name: "AgentTaskList",
    path: "/project/:projectId/task-list",
    component: AgentTaskList,
  },
  {
    name: "Workspace",
    path: "/project/:projectId/workspace/:taskId",
    component: Workspace,
  },
  {
    name: "Template",
    path: "/templates",
    component: Templates,
    meta: {
      requiresAuth: true,
      roles: ["manager", "compliance", "projectOwner"],
    },
  },
  {
    name: "Suppression Files",
    path: "/files",
    component: SuppressionFilesList,
  },
  {
    name: "Master Database",
    path: "/master/:tab",
    component: DetailMaster,
  },
];
