<template>
  <v-container fluid class="accounts pl-0">
    <!-- UI loading Black Screen -->
    <v-overlay :value="false" opacity="0.9">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- counters  -->
    <v-row>
      <v-col class="py-0">
        <div>
          <v-slide-group
            mandatory
            class="counter_stats"
            v-model="activeTaskState"
            active-class="activeDisposition"
            show-arrows
          >
            <v-slide-item
              v-for="(value, name) in stateCount"
              :key="value.id"
              v-slot="{ active, toggle }"
            >
              <v-card
                class="ml-2 counter"
                height="80"
                width="125"
                :color="active ? '#205fac' : 'FFFFFF'"
                @click="filterTaskByStates(name)"
                v-on:click="toggle"
              >
                <h4 class="counter_value">
                  {{ value.count | humanizeNumber }}
                </h4>
                <h4 class="counter_title">{{ name }}</h4>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </div>
      </v-col>
    </v-row>

    <!-- DataGrid -->
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            height="68vh"
            style="white-space: nowrap"
            fixed-header
            :headers="headers"
            :items="taskList"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalCount"
            loading-text="Loading... Please wait"
            class="elevation-1"
            dense
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'navigate_before',
              nextIcon: 'navigate_next',
              'items-per-page-options': [5, 10, 20, 40, 60],
            }"
          >
            <!-- Workspace -->
            <template v-slot:[`item.id`]="{ item }">
              <v-icon
                right
                class="pa-0 ma-0"
                @click="redirectToWorkSpace(item)"
              >
                open_in_new
              </v-icon>
            </template>

            <!-- Account Name Google Search -->
            <template v-slot:[`item.Accounts[0].name`]="{ value }">
              <a
                :href="searchAccountNameUrl"
                target="_blank"
                v-on:click.stop=""
                @click="searchByAccountName(value)"
              >
                {{ value | checkEmpty | markEmpty }}
              </a>
            </template>

            <!-- Account Website Search -->
            <template v-slot:[`item.Accounts[0].website`]="{ value }">
              <a
                v-if="value"
                :href="searchAccountWebsiteUrl"
                target="_blank"
                v-on:click.stop=""
                @click="searchByAccountWebsite(value)"
              >
                {{ value | checkEmpty | markEmpty }}
              </a>
              <span v-else style="font: black">---</span>
            </template>

            <!-- Task Priority -->
            <template v-slot:[`item.priority`]="{ value }">
              {{ value | checkEmpty | markEmpty }}
            </template>

            <!-- Task Disposition -->
            <template v-slot:[`item.Accounts[0].disposition`]="{ value }">
              {{ value | checkEmpty | markEmpty }}
            </template>

            <!-- Account Employee Size -->
            <template v-slot:[`item.Accounts[0].employeeSize`]="{ value }">
              {{ value | checkEmpty | markEmpty }}
            </template>

            <!-- Account Revenue -->
            <template v-slot:[`item.Accounts[0].revenue`]="{ value }">
              {{ value | checkEmpty | markEmpty }}
            </template>

            <!-- Task Status -->
            <template v-slot:[`item.status`]="{ value }">
              {{ value | checkEmpty | markEmpty }}
            </template>

            <!-- Task Type / Activity -->
            <template v-slot:[`item.TaskType.type`]="{ value }">
              {{ value | checkEmpty | markEmpty }}
            </template>

            <!-- Task Created Data in Human Readable Format createdAt -->
            <template v-slot:[`item.createdAt`]="{ value }">
              {{ value | checkEmpty | markEmpty | formatDate }}
            </template>

            <!-- Task Due Data in Human Readable Format dueDate -->
            <template v-slot:[`item.dueDate`]="{ value }">
              {{ value | checkEmpty | markEmpty | formatDate }}
            </template>

            <!-- Account potential -->
            <template v-slot:[`item.Accounts[0].potential`]="{ value }">
              {{ value | checkEmpty | markEmpty }}
            </template>

            <!-- Account qualifiedContacts -->
            <template v-slot:[`item.Accounts[0].qualifiedContacts`]="{ value }">
              {{ value | checkEmpty | markEmpty }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import _ from "lodash";
import Api from "../services/Api";

export default {
  name: "AgentTaskListView",
  components: {},
  props: {
    projectId: String,
    projectName: String,
  },
  data() {
    return {
      loading: false,
      totalCount: 0,
      taskList: [],
      stateCount: {
        Total: {
          count: 0,
          subState: {},
        },
        Completed: {
          count: 0,
          subState: {},
        },
        Pending: {
          count: 0,
          subState: {
            Overdue: 0,
            Upcoming: 0,
          },
        },
        Working: {
          count: 0,
          subState: {},
        },
      },
      taskStateMapping: {
        Overdue: "Pending",
        Upcoming: "Pending",
      },
      subStateCount: {},
      indexOfTaskState: {},
      activeTaskState: null,
      activeSubTaskState: null,
      styleOfSubStates: {},
      isPageRendering: true,
      searchAccountNameUrl: "",
      searchAccountWebsiteUrl: "",
      options: {},
      headers: [],
      sortFieldHeaderMapping: {
        "Accounts[0].name": "accountName",
        "Accounts[0].website": "accountWebsite",
        "Accounts[0].disposition": "accountDisposition",
        "Accounts[0].qualifiedContacts": "qualifiedContacts",
        "Accounts[0].potential": "potential",
        priority: "taskPriority",
        dueDate: "taskDueDate",
        createdAt: "taskCreatedAtDate",
      },
    };
  },
  computed: {
    filter() {
      return this.$store.state.taskFilter;
    },
    sort() {
      return this.$store.state.taskListSort;
    },
    appliedOptions: function () {
      return this.$store.state.agentTaskListOptions;
    },
  },
  watch: {
    filter() {
      this.options.page = 1;
      this.fetchTaskLists();
    },
    sort() {
      this.options.page = 1;
      this.fetchTaskLists();
    },
    options: function () {
      let options = _.cloneDeep(this.options);
      this.$store.commit("setAgentTaskListOptions", options);
      this.fetchTaskLists();
    },
  },
  mounted: async function () {
    this.options = _.cloneDeep(this.appliedOptions);
    if (!this.filter.status) {
      this.filter.status = "Pending";
    }
    await this.fetchGridConfig();
  },
  methods: {
    redirectToWorkSpace(task) {
      this.$router
        .push({
          name: "Workspace",
          params: {
            taskId: task.id,
            projectId: task.Project.id,
          },
        })
        .catch((e) => e);
    },
    searchByAccountName(accountName) {
      this.searchAccountNameUrl =
        `https://www.google.com/search?q=` + encodeURIComponent(accountName);
    },
    searchByAccountWebsite(website) {
      this.searchAccountWebsiteUrl =
        this.$constants().URL_DOUBLE_SLASH + encodeURIComponent(website);
    },
    pageRefresh: async function () {
      if (!this.filter.status) {
        this.filter.status = "Pending";
      }
      await Promise.all([this.fetchGridConfig(), this.fetchTaskLists()]);
    },
    async fetchGridConfig() {
      try {
        this.loading = true;
        let gridConfigResultDTO = await Api.getGridConfigOfTemplate("01");
        let { gridConfig } = gridConfigResultDTO.data;
        this.headers = gridConfig.agentsTaskListGrid.headers || [];
        this.loading = false;
      } catch (error) {
        this.loading = false;
        let notification = {
          type: "error",
          showMessage: true,
          message: "Could Not Load Grid Config",
        };
        this.setNotification(notification);
      }
    },
    async fetchTaskLists() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let sort = {};
      let order = "";

      if (sortDesc && Array.isArray(sortDesc) && sortDesc[0]) {
        order = "asc";
      } else {
        order = "desc";
      }

      if (sortBy && Array.isArray(sortBy) && sortBy[0]) {
        var key = _.get(this.sortFieldHeaderMapping, sortBy[0]);
        sort[key] = order;
      } else {
        sort = { updatedAt: order };
      }

      var filter = _.cloneDeep(this.filter);
      var selectedStatus = filter.status || "Total";
      filter.status = this.convertStateToFilterStateArray(selectedStatus);

      var param = {
        pageNo: page - 1,
        pageSize: itemsPerPage,
        filter: filter,
        sort: sort,
      };

      return Api.getAllTask(this.projectId, param)
        .then((response) => {
          this.loading = false;
          this.taskList = response.data.docs;
          this.totalCount = response.data.totalCount;
          this.isPageRendering = false;
          let self = this;

          self.generateStateCount(response.data.counts);
          self.setActiveState(selectedStatus);
        })
        .catch(() => {
          this.loading = false;
          this.isPageRendering = false;
          let notification = {
            type: "error",
            showMessage: true,
            message: this.$notifications().TASK_LIST_ERROR,
          };

          this.setNotification(notification);
        });
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    openWorkSpace: function ({ id: taskId }) {
      this.$router
        .push({
          name: "Workspace",
          params: { taskId: taskId, projectId: this.$route.params.projectId },
        })
        .catch((e) => e);
    },
    filterTaskByStates: function (selectedStatus) {
      let filter = Object.assign({}, this.filter);
      filter.status = selectedStatus;
      this.$store.dispatch("selectTaskListFilter", {
        filter: filter,
      });
    },
    setStyleOfSubStates(mainState) {
      switch (mainState) {
        case "Pending":
          this.styleOfSubStates.margin = "0 0 0 325px";
          break;
        default:
          this.styleOfSubStates.margin = "0 0 0 150px";
      }
    },
    setSubStateCount(state) {
      this.subStateCount = {};
      var subState = this.stateCount[state].subState;
      if (subState && Object.keys(subState).length) {
        this.setStyleOfSubStates(state);
        this.subStateCount = subState;
      }
    },
    setActiveState: function (selectedStatus) {
      if (!this.stateCount[selectedStatus]) {
        var mainState = this.taskStateMapping[selectedStatus];
        this.activeTaskState = this.indexOfTaskState[mainState];
        this.activeSubTaskState = this.indexOfTaskState[selectedStatus];
        return;
      }
      this.activeSubTaskState = null;
      this.activeTaskState = this.indexOfTaskState[selectedStatus];
    },
    convertStateToFilterStateArray: function (selectedStatus) {
      if (!selectedStatus || selectedStatus === "Total") {
        return [];
      }
      if (!this.stateCount[selectedStatus]) {
        return [selectedStatus];
      }
      var filterStatus = selectedStatus;
      if (!Array.isArray(filterStatus)) {
        filterStatus = [filterStatus];
      }

      return filterStatus;
    },
    generateStateCount(taskState) {
      var self = this;

      let stateIndex = 0;
      let subStateIndex = 0;
      this.indexOfTaskState = {};

      for (var status in self.stateCount) {
        self.stateCount[status]["count"] = 0;
      }
      taskState.forEach(function (item) {
        let mainState = self.taskStateMapping[item.status];
        if (mainState) {
          self.stateCount[mainState].count =
            self.stateCount[mainState].count + parseInt(item.count);

          if (!self.indexOfTaskState[mainState]) {
            self.indexOfTaskState[mainState] = stateIndex;
            stateIndex += 1;
          }

          self.stateCount[mainState].subState[item.status] = item.count;
          if (!self.indexOfTaskState[item.status]) {
            self.indexOfTaskState[item.status] = subStateIndex;
            subStateIndex += 1;
          }
        } else {
          self.stateCount[item.status].count = parseInt(item.count);
          if (!self.indexOfTaskState[mainState]) {
            self.indexOfTaskState[item.status] = stateIndex;
            stateIndex += 1;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.accounts {
  width: 100%;
}
.counter_stats {
  float: left;
  width: 1300px;
  margin-left: 0px;
  margin-bottom: 10px;
}

.counter_stats::-webkit-scrollbar {
  -webkit-appearance: none;
}

.counter_stats::-webkit-scrollbar:horizontal {
  height: 11px;
}

.counter_stats::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: #8b83ba;
}
.counter_stats .counter {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}
.counter_stats .counter .counter_title {
  font-weight: 400;
  margin: 10px 0 15px;
  font-family: "Lato";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
  max-height: 40px;
  overflow: hidden;
}
.counter_stats .counter .counter_value {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #1c76d2;
  margin: 0;
  text-align: center;
}
.account_filter {
  width: 24%;
  float: left;
  margin-left: 10px;
}
.account_filter h4 {
  text-align: center;
}
.account_list {
  float: left;
  margin: 5px 0 20px;
}

.stat_item {
  display: inline-block;
  margin: 0 15px;
  width: 160px;
}
.stat_item h6 {
  font-weight: 500;
  font-size: 14px;
}

.account_list_headers {
  background: #ffffff;
  padding: 5px 10px;
  padding-left: 0;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
}

.account_list_headers h6 {
  font-family: "Lato";
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
  text-transform: uppercase !important;
}
.account_list_headers .field.account_name_flex,
.list_item .field.account_name_flex {
  width: 280px;
}

.account_list_headers .field,
.list_item .field {
  width: 175px;
  padding: 5px;
}

.account_list_headers .field.sm,
.list_item .field.sm {
  width: 150px;
}

.list_item .field h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  color: #25213b;
}
.task_import_download_buttons {
  position: absolute;
  top: 45px;
  right: 25px;
}
.notification {
  position: absolute;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.field a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
}
/* .list_item .field h4 a {
  text-overflow: ellipsis;
  width: 95%;
  display: inline-block;
  overflow: hidden;
} */

.v-data-table >>> th:nth-child(1),
.v-data-table >>> td:nth-child(1) {
  position: sticky !important;
  left: 0;
  background: white;
  z-index: 888;
}
.v-data-table >>> th:first-child {
  z-index: 999;
}
.v-data-table >>> thead th {
  font-size: 14px !important;
}
.counter_stats .counter.activeDisposition {
  border-radius: 4px;
}

.counter_stats .counter.activeDisposition .counter_value,
.counter_stats .counter.activeDisposition .counter_title,
.counter_stage .counter.active .counter_title,
.counter_stage .counter.active .counter_value {
  color: white;
}
</style>
