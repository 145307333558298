<template>
  <div class="text-center">
    <!-- dialog -->
    <v-dialog v-model="dialog" persistent width="1000" scrollable>
      <v-card>
        <v-toolbar dark color="#7c7c7c">
          <!-- title -->
          <v-toolbar-title>{{
            accountCheckResultValue | checkEmpty | markEmpty
          }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <!-- close icon  -->
          <v-toolbar-items>
            <v-btn icon dark @click="cancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <!-- sub-headers -->
        <v-card-title style="display: inline-flex">
          <div style="font-family: Lato; font-size: 17px">
            <span> Matched Type </span> :
            <span style="color: red">{{ matchType }} </span>
          </div>

          <div class="pl-4" style="font-family: Lato; font-size: 17px">
            <span> Matched By </span> :
            <span style="color: red"> {{ matchCase }} </span>
          </div>
        </v-card-title>

        <!-- table -->
        <v-card-text style="padding-top: 5px; max-height: 300px" class="pt-4">
          <v-simple-table fixed-header dense>
            <template v-slot:default>
              <!-- table header -->
              <thead>
                <tr>
                  <th class="text-left" style="color: black">Name</th>
                  <th class="text-left" style="color: black">Website</th>
                  <th class="text-left" style="color: black">Domain</th>
                  <th class="text-left" style="color: black">PhoneHQ</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, index) in data" :key="index">
                  <!-- name -->
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{
                            item.name
                              | checkEmpty
                              | markEmpty
                              | truncate($constants().COLUMN_LIMIT20)
                          }}
                        </span>
                      </template>

                      <span>{{ item.name }}</span>
                    </v-tooltip>
                  </td>

                  <!-- website -->
                  <td>
                    <a
                      v-if="item.website"
                      :href="websiteUrl"
                      target="_blank"
                      v-on:click.stop=""
                      @click="makeWebSiteUrl(item.website)"
                    >
                      {{ item.website | truncate($constants().COLUMN_LIMIT20) }}
                    </a>

                    <span v-else
                      >{{ item.website | checkEmpty | markEmpty }}
                    </span>
                  </td>

                  <!-- domain -->
                  <td>
                    <a
                      v-if="item.domain"
                      :href="domainUrl"
                      target="_blank"
                      v-on:click.stop=""
                      @click="makeDomainUrl(item.domain)"
                    >
                      {{ item.domain | truncate($constants().COLUMN_LIMIT20) }}
                    </a>

                    <span v-else
                      >{{ item.domain | checkEmpty | markEmpty }}
                    </span>
                  </td>

                  <!-- phone -->
                  <td>
                    {{ item.phoneHQ | checkEmpty | markEmpty }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "accountCheckDialogBox",
  data() {
    return {
      dialog: false,
      accountCheckResultValue: "",
      matchCase: "",
      matchType: "",
      data: [],
      domainUrl: "",
      websiteUrl: "",
    };
  },
  computed: {
    accountCheckResult: function () {
      return this.$store.state.workspace.accountCheckResult;
    },
    account() {
      return this.$store.state.workspace.account;
    },
  },
  watch: {
    accountCheckResult: function (value) {
      this.setDialogData(value);
    },
  },
  methods: {
    cancel: function () {
      this.dialog = false;
      this.data = [];
    },
    makeDomainUrl(value) {
      this.domainUrl =
        this.$constants().URL_DOUBLE_SLASH + encodeURIComponent(value);
    },
    makeWebSiteUrl(value) {
      this.websiteUrl =
        this.$constants().URL_DOUBLE_SLASH + encodeURIComponent(value);
    },
    setDialogData(value) {
      if (!value.matchType) {
        return;
      }

      this.data = [];

      //Duplicate
      if (value && _.get(value, "matchType", "") === "duplicate") {
        this.accountCheckResultValue = "Duplicate Account";
      }

      //Suppression
      if (value && _.get(value, "matchType", "") === "suppressed") {
        this.accountCheckResultValue = "Suppressed Account";
      }
      this.matchType = "Exact";
      this.matchCase = value.matchCase;

      let matchWith = _.get(value, "matchWith", {});

      if (!_.isEmpty(matchWith)) {
        this.data.push(matchWith);
      }

      this.dialog = true;
    },
  },
};
</script>

<style scoped></style>
