<template>
  <v-main>
    <Notification />
  </v-main>
</template>

<style scoped></style>

<script>
import Notification from "./notification.vue";
import { getRoles } from "../roles.js";
export default {
  components: {
    Notification,
  },
  name: "Layout",
  mounted: function () {
    this.setRole();
  },
  methods: {
    async setRole() {
      let role = await getRoles();
      if (role.indexOf("manager") > -1) {
        return this.$router.push({ name: "PROJECTList" }).catch((e) => e);
      }
      if (role.indexOf("agent") > -1) {
        return this.$router.push({ name: "AgentProjectList" }).catch((e) => e);
      }
    },
  },
};
</script>
