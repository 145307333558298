<template>
  <v-container fluid class="content">
    <div class="header">
      <h1 class="ml-6">Data Project Request</h1>
    </div>

    <v-container fluid class="toolbar">
      <GeneralizedFilter
        :inputFilterObject="inputFilterObject"
        v-on:filterObject="applyFilter($event)"
        v-on:searchValue="fetchListWithSearchParam($event)"
        v-on:fetchFilters="fetchAllProjectFacets($event)"
      />

      <CreatePROJECT />
    </v-container>

    <v-row>
      <v-col cols="12" sm="12">
        <div class="ml-4 mb-6" v-if="Object.keys(filterForChips).length">
          <h5 class="text-overline">Applied Filter:</h5>
          <v-chip-group column>
            <span
              v-for="(subProps, key) in filterForChips"
              :key="key"
              style="display: inline"
            >
              <span
                v-if="Array.isArray(subProps.value) && subProps.value.length"
              >
                <v-chip
                  close
                  label
                  @click:close="removeFilter(key, subValue)"
                  v-for="(subValue, index) in subProps.value"
                  :key="index"
                >
                  <span style="padding-right: 5px"> {{ subProps.text }}: </span>
                  <span
                    v-if="
                      subProps.operator !== operatorEqualTo &&
                      subProps.operator !== operatorBetween
                    "
                    style="padding-right: 2px"
                  >
                    {{ subProps.operator }}
                  </span>
                  <span v-if="subValue.length"> {{ subValue }}</span>
                </v-chip>
              </span>
              <span v-else>
                <v-chip close label @click:close="removeFilter(key)">
                  <span style="padding-right: 5px"> {{ subProps.text }}: </span>
                  <span
                    v-if="
                      subProps.operator !== operatorEqualTo &&
                      subProps.operator !== operatorBetween
                    "
                    style="padding-right: 2px"
                  >
                    {{ subProps.operator }}
                  </span>
                  <span v-if="subProps.value.length">
                    {{ subProps.value }}</span
                  >
                </v-chip>
              </span>
            </span>
          </v-chip-group>
        </div>
      </v-col>
    </v-row>

    <!-- dataGrid -->
    <v-row>
      <v-col cols="12" sm="12" class="px-8">
        <DataGrid
          gridId="pmProjectPageId"
          gridRef="pmProjectPageRef"
          gridHeight="53vh"
          :headers="headers"
          :itemList="projectList"
          :loading="loading"
          :totalCount="totalProjectsCount"
          :pageNo="pageNumber"
          @fetchGrideData="fetchProjectData($event)"
          @fetchButtonClickAction="getProjectDetails($event)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import Api from "../services/Api";
import DataGrid from "./dataGrid.vue";
import CreatePROJECT from "./CreatePROJECT.vue";
import GeneralizedFilter from "./GeneralizedFilters.vue";
import { getRoles } from "../roles.js";

export default {
  components: {
    CreatePROJECT,
    DataGrid,
    GeneralizedFilter,
  },
  name: "PROJECTList",
  props: {},
  data() {
    return {
      loading: false,
      sort: {},
      projectList: [],
      headers: [],
      limitOfFilterList: 20,
      totalProjectsCount: 0,
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE,
      operatorEqualTo: "=",
      operatorBetween: "between",
      filter: {},
      filterForChips: {},
      // List of columns, its types and its values for filters
      inputFilterObject: {},
      allowedSearchColumnForAutoComplete: ["project", "aliasName"],
    };
  },
  created: async function () {
    let role = await getRoles();
    this.setupForProjectOwner(
      role.indexOf(this.$constants().USER_ROLES.PROJECT_OWNER) > -1
    );
  },
  mounted: async function () {
    this.fetchProjectList();
    this.fetchClientForFilter();
  },
  methods: {
    setupForProjectOwner: function (isOwner) {
      let headers = [
        {
          value: "projectId",
          text: "Project Detail",
          divider: true,
          sortable: false,
          width: "125px",
          type: "button",
          icon: "open_in_new",
        },
        {
          value: "projectName",
          text: "Project Name",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "aliasName",
          text: "Alias Name",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "client",
          text: "Client",
          sortable: true,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "createdAt",
          text: "Start Date (Created At)",
          sortable: false,
          divider: true,
          width: "150px",
          type: "date",
          googleSearch: false,
        },
        {
          value: "dueDate",
          text: "Due Date",
          sortable: true,
          divider: true,
          width: "150px",
          type: "date",
          googleSearch: false,
        },
        {
          value: "updatedAt",
          text: "Last Modified Date (Updated At)",
          sortable: false,
          divider: true,
          width: "150px",
          type: "date",
          googleSearch: false,
        },
        {
          value: "status",
          text: "Status",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "targetAccount",
          text: "Target Account",
          sortable: false,
          divider: true,
          width: "115px",
          type: "number",
          googleSearch: false,
        },
        {
          value: "targetContact",
          text: "Target Contact",
          sortable: false,
          divider: true,
          width: "115px",
          type: "number",
          googleSearch: false,
        },
      ];
      let filters = {
        dueDate: {
          text: "Due Date",
          type: "date",
          operators: ["BETWEEN"],
          values: [],
        },
        updatedAt: {
          text: "Last Modified Date (Updated At)",
          type: "date",
          operators: ["BETWEEN"],
          values: [],
        },
        status: {
          text: "Status",
          type: "string",
          operators: ["IS"],
          values: ["Yet to Start", "Active", "On Hold", "Completed"],
        },
        client: {
          text: "Client Name",
          type: "string",
          operators: ["IS"],
          values: [],
          itemText: "name",
          itemValue: "id",
        },
        project: {
          text: "Project Name",
          type: "string",
          operators: ["IS"],
          values: [],
          itemText: "name",
          itemValue: "id",
          search: true,
        },
        aliasName: {
          text: "Alias Name",
          type: "string",
          operators: ["IS"],
          values: [],
          itemText: "name",
          itemValue: "id",
          search: true,
        },
      };

      if (!isOwner) {
        _.remove(headers, {
          value: "projectName",
        });
        delete filters.project;
      }
      this.inputFilterObject = filters;
      this.headers = headers;
    },
    removeFilter: async function (filterKey, subValue) {
      this.pageNumber = 1;
      if (!subValue) {
        delete this.filterForChips[filterKey];
        delete this.filter[filterKey];
      } else {
        const filterForChipsValues = _.get(
          this.filterForChips,
          [filterKey, "value"],
          []
        );
        const filterValues = _.get(this.filter, [filterKey, "value"], []);
        if (filterForChipsValues.length <= 1 && filterValues.length <= 1) {
          delete this.filterForChips[filterKey];
          delete this.filter[filterKey];
        } else {
          const filterForChipsValueIndex = _.indexOf(
            filterForChipsValues,
            subValue
          );
          if (filterForChipsValueIndex > -1) {
            this.filterForChips[filterKey]["value"].splice(
              filterForChipsValueIndex,
              1
            );
          }
          const filterValueIndex = _.indexOf(filterValues, subValue);
          if (filterValueIndex > -1) {
            this.filter[filterKey]["value"].splice(filterValueIndex, 1);
          }
        }
      }
      await this.fetchProjectList();
    },
    applyFilter: async function (filterApplied) {
      const keyOfFilterApplied = Object.keys(filterApplied)[0];
      this.filterForChips[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      this.modifyFilterForChips(keyOfFilterApplied);
      filterApplied[keyOfFilterApplied] = _.omit(
        filterApplied[keyOfFilterApplied],
        ["text", "itemText", "itemValue", "search", "type"]
      );
      this.filter[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      this.pageNumber = 1;
      await this.fetchProjectList();
    },
    modifyFilterForChips: function (keyOfFilterApplied) {
      const valueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["value"];
      const operatorOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["operator"];
      const itemTextOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemText"];
      const itemValueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemValue"];
      if (
        operatorOfFilteredForChips === "between" &&
        Array.isArray(valueOfFilteredForChips)
      ) {
        this.filterForChips[keyOfFilterApplied]["value"] =
          this.filterForChips[keyOfFilterApplied]["value"].join(" ~ ");
      }
      if (itemTextOfFilteredForChips && itemValueOfFilteredForChips) {
        let valuesOfFilteredId = _.map(
          this.inputFilterObject[keyOfFilterApplied].values,
          (item) => {
            if (item[itemValueOfFilteredForChips] === valueOfFilteredForChips) {
              return item[itemTextOfFilteredForChips];
            }
          }
        );
        valuesOfFilteredId = _.compact(valuesOfFilteredId);
        this.filterForChips[keyOfFilterApplied]["value"] =
          valuesOfFilteredId[0];
      }
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    fetchProjectData(options) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = options;

      this.pageNumber = page;
      this.pageSize = itemsPerPage;

      this.sort = {};
      if (sortBy[0]) {
        var key = sortBy[0];
        this.sort = {
          [key]: sortDesc[0] ? "asc" : "desc",
        };
      }

      //api call
      this.fetchProjectList();
      this.loading = false;
    },
    fetchProjectList: function () {
      this.loading = true;
      Api.getProjectList({
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
        filter: JSON.stringify(this.filter),
        sort: this.sort,
      })
        .then((response) => {
          this.loading = false;
          this.totalProjectsCount = response.data.totalCount;
          this.projectList = response.data.docs;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 401) {
            let logoutDomain =
              location.protocol + "//" + location.host + "/auth/login.html";
            window.auth0Client.logout({
              returnTo: logoutDomain,
              clientId: process.env.VUE_APP_OAUTHCLIENTID,
            });
            localStorage.clear();
          }
          let notification = {
            type: "error",
            showMessage: true,
            message: this.$notifications().FETCH_PROJECT_ERROR,
          };
          this.setNotification(notification);
          console.log(error);
        });
    },
    fetchAllProjectFacets: function (field) {
      if (this.allowedSearchColumnForAutoComplete.includes(field)) {
        const searchValue = "";
        this.fetchProjectListForFilter(field, searchValue);
      }
    },
    fetchProjectListForFilter: function (searchColumnName, searchValue) {
      var param = {
        pageNo: 0,
        pageSize: this.limitOfFilterList,
      };
      param["searchValue"] = searchValue;
      param["searchColumn"] = searchColumnName;

      Api.getProjectList(param)
        .then((response) => {
          this.inputFilterObject[searchColumnName]["values"] =
            response.data.docs;
        })
        .catch((error) => console.log(error));
    },
    fetchClientForFilter: function () {
      Api.getClients()
        .then((response) => {
          this.inputFilterObject.client["values"] = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchListWithSearchParam: function (data) {
      if (this.allowedSearchColumnForAutoComplete.includes(data.column)) {
        this.fetchProjectListForFilter(data.column, data.param);
      }
    },
    getProjectDetails: async function (data) {
      const projectId = data.value;
      const project = _.find(this.projectList, { projectId });
      const clientId = project.clientId;
      this.$store.dispatch("setProjectClientId", clientId);
      this.$router
        .push({
          name: "PROJECTDetail",
          params: { id: projectId, tab: "overview" },
        })
        .catch((e) => e);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  padding-left: 55px;
}
.list_item,
.project_list_headers {
  background: #ffffff;
  padding: 5px 10px;
  padding-left: 0;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
}

.header {
  margin: 15px 0 10px;
}
.header,
.toolbar,
.project_list,
.project_list .list_item {
  float: left;
  display: block;
  /*width: 100%;*/
}

button.btn {
  float: right;
  background: #ffffff;
  border: 1px solid #c6c2de;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 3px;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  padding: 5px 15px;
  cursor: pointer;
}

.header button {
  margin-right: 15px;
}

.toolbar {
  margin-left: 5px;
}
.toolbar > div {
  display: inline-block;
  margin-right: 15px;
  float: left;
}

.toolbar > div.create_project_wrapper {
  margin-right: 0;
}

.header h1 {
  float: left;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}

.project_list .v-expansion-panels {
  margin-left: 15px;
}
.list_item > div {
  display: block;
}
.list_item .list_item_stats p-2 bd-highlight {
  display: inline-block;
}
.list_item h4,
.list_item h5,
.list_item h6 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.list_item .selection input {
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.list_item .selection {
  width: 50px;
  height: 50px;
  text-align: center;
  position: relative;
}
.list_item .title_section {
  width: 450px;
  margin-right: 30px;
}
.list_item .title_section h4 {
  font-weight: 500;
}
.list_item .title_section h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #075ab1;
}
.stat_item {
  display: inline-block;
  margin: 0 15px;
}
.stat_item h6 {
  font-weight: 400;
  font-size: 13px;
  color: #075ab1;
}

.list_item div.time {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}
.switch {
  margin-top: 10px;
  margin-right: 25px;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00c537;
}

input:focus + .slider {
  outline: none;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.project_status h6 {
  width: 125px;
  padding: 15px;
  font-size: 18px;
}

.project_list_headers h6 {
  font-family: "Lato";
  font-size: 15px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase !important;
}
.list_item h6 {
  font-family: "Lato";
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
}
.list_item h6 {
  display: none;
  font-family: "Lato";
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase !important;
}
.list_item .panel_content h4 {
  text-align: center;
}
.list_item.v-item--active h6 {
  display: block;
}
.list_item h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  color: #25213b;
}
.project_list_headers .v-expansion-panel-header,
.list_item .v-expansion-panel-header,
.list_item .v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 5px 15px !important;
}

.panel_content {
  margin-left: -10px;
}
.v-expansion-panels {
  margin: 10px 30px 10px 30px;
}

.field {
  width: 180px;
}

.circle {
  height: 45px;
  width: 45px;
  background-color: #075ab1;
  border-radius: 50%;
  text-align: center;
  padding: 9px 0;
  float: left;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  color: white !important;
}

.project_list .list_item .v-expansion-panel-header__icon {
  left: 5px;
  position: absolute;
}

.project_link {
  right: 60px;
  top: 15px;
  position: absolute;
}

.project_name_flex {
  width: 350px;
}

@media (min-width: 1100px) and (max-width: 1250px) {
  .field.project_name_flex {
    width: 270px;
  }

  .list_item h4 {
    font-size: 15px;
  }

  .field {
    width: 160px;
  }

  .project_list_headers h6 {
    font-size: 14px;
  }
}
.notification {
  position: absolute;
}

@media (min-width: 1250px) and (max-width: 1350px) {
  .field.project_name_flex {
    width: 280px;
  }

  .list_item h4 {
    font-size: 16px;
  }

  .field {
    width: 170px;
  }

  .project_list_headers h6 {
    font-size: 15px;
  }
}
</style>
