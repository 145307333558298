import _ from "lodash";
import humanize from "humanize";
import moment from "moment";
import parsePhoneNumber from "libphonenumber-js";

let DASHES = "- - -";
let DOTS = "...";
let EMPTY_ADDRESSES = ", , ";

export default {
  install(Vue) {
    Vue.filter("truncate", function (value, limit) {
      if (value) {
        let length = value.length;

        if (length > limit) {
          return value.slice(0, limit) + DOTS;
        } else {
          return value;
        }
      }
    });

    Vue.filter("humanizeNumber", function (number) {
      let parsedNumber = parseInt(number);

      if (_.isNaN(parsedNumber)) {
        return number;
      }
      return humanize.numberFormat(number, 0);
    });

    Vue.filter("formatDate", function (value) {
      if (!value || value === DASHES) {
        return value;
      } else {
        return moment(String(value)).format("ll");
      }
    });

    Vue.filter("momentFormatDate", function (value) {
      if (!value || value === DASHES) {
        return value;
      } else {
        return moment(String(value)).fromNow(true) + " ago";
      }
    });

    Vue.filter("momentFormatDateAndTime", function (value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY hh:mm");
      }
    });

    Vue.filter("shortName", function (name) {
      let shortName = [];

      if (name) {
        if (name.includes("_")) {
          name = name.split("_");
        } else if (name.includes(" ")) {
          name = name.split(" ");
        }

        for (var i = 0; i < 2; i++) {
          shortName[i] = name[i][0].toUpperCase();
        }

        return shortName.join("");
      }
      return name;
    });

    Vue.filter("checkEmpty", function (value) {
      if (!value) {
        return "";
      }
      if (typeof value === "string") {
        return value.trim();
      }
      return value;
    });

    Vue.filter("markEmpty", function (value) {
      if (value === "") {
        return DASHES;
      } else {
        return value;
      }
    });

    Vue.filter("convertArrayToString", function (value) {
      if (_.isArray(value)) {
        return value.join(" ~ ");
      }
      return value;
    });

    Vue.filter("checkAddress", function (value) {
      if (value === EMPTY_ADDRESSES) {
        return DASHES;
      } else if (value.includes(undefined)) {
        return value.replace(/undefined/gi, DASHES);
      } else {
        return value;
      }
    });

    Vue.filter("convertReadableDateTime", function (_date, getTime = true) {
      let date = _date;
      if (!date) {
        return date;
      }
      date = new Date(date);
      let humanReadableDate = date.toLocaleDateString();
      if (getTime) {
        const currentOffset = date.getTimezoneOffset();

        const ISTOffset = 330; // IST offset UTC +5:30

        const ISTTime = new Date(
          date.getTime() + (ISTOffset + currentOffset) * 60000
        );

        // ISTTime now represents the time in IST coordinates

        let hoursIST = ISTTime.getHours();
        const ampm = hoursIST >= 12 ? "PM" : "AM";
        hoursIST %= 12;
        hoursIST = hoursIST || 12; // the hour '0' should be '12'
        if (hoursIST < 10) {
          hoursIST = `0${hoursIST}`;
        }

        let minutesIST = ISTTime.getMinutes();
        if (minutesIST < 10) {
          minutesIST = `0${minutesIST}`;
        }
        humanReadableDate = {
          date: humanReadableDate,
          time: `${hoursIST}:${minutesIST} ${ampm}`,
        };
      }
      return humanReadableDate.date + " " + humanReadableDate.time;
    });

    Vue.filter("formatPhone", function (number) {
      if (!number) {
        return number;
      }
      let parsedNumber = parsePhoneNumber(number);

      if (parsedNumber) {
        return parsedNumber.formatNational();
      }
      return number;
    });
  },
};
