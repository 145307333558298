<template>
  <v-menu
    :close-on-content-click="false"
    :nudge-width="600"
    offset-y
    back
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="#8B83BA"
        depressed
        dark
        v-bind="attrs"
        v-on="on"
        @click="resetFilterForm()"
      >
        Filters
        <span class="material-icons"> filter_alt </span>
      </v-btn>
    </template>

    <v-form
      ref="TaskFilterForm"
      @submit.prevent="buildFilterObject()"
      lazy-validation
    >
      <h3 class="filterHeadersTitle pt-3">Filter & Search</h3>
      <v-list class="pa-4">
        <v-row class="filterHeaders mt-0" style="border-bottom: 1px solid #eee">
          <v-col class="py-1"><span>field to search</span></v-col>
          <v-col class="py-1"><span>type of search</span></v-col>
          <v-col class="py-1"><span>search for...</span></v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="filterBy"
              item-text="text"
              item-value="value"
              :items="filterableFields"
              label="Filter By"
              data-vv-name="select"
              :rules="notEmptyRule"
              required
            ></v-select>
          </v-col>

          <v-col>
            <v-select
              v-model="operator"
              item-text="text"
              item-value="value"
              :items="operators"
              label="Operator"
              data-vv-name="select"
              :rules="notEmptyRule"
              required
            ></v-select>
          </v-col>

          <v-col>
            <v-text-field
              v-model="filterValue"
              label="Value"
              :disabled="disableFilterValue"
              :rules="notEmptyRule"
              required
            ></v-text-field
          ></v-col>
        </v-row>

        <v-list-item class="d-flex flex-row-reverse">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="ml-2 white--text"
              color="#8B83BA"
              depressed
              type="submit"
            >
              Apply
            </v-btn>
          </v-card-actions>
        </v-list-item>
      </v-list>
    </v-form>
  </v-menu>
</template>

<script>
export default {
  name: "TaskFilter",
  props: {
    clearField: Array,
  },
  data: () => ({
    filterableFields: [
      { text: "AccountName", value: "account.name" },
      { text: "Disposition", value: "account.disposition" },
      { text: "EmployeeSize", value: "account.employeeSize" },
      { text: "Priority", value: "task.priority" },
      { text: "Potential", value: "account.potential" },
      { text: "Revenue", value: "account.revenue" },
      { text: "Qualified Contacts", value: "account.qualifiedContacts" },
      { text: "Website", value: "account.website" },
    ],
    operators: [{ text: "IS", value: "is" }],
    taskPriorities: [
      { text: "Highest", value: "highest" },
      { text: "High", value: "high" },
      { text: "Standard", value: "standard" },
      { text: "Medium", value: "medium" },
      { text: "Low", value: "low" },
      { text: "Lowest", value: "lowest" },
      { text: "Overtime", value: "overtime" },
    ],
    taskStatuses: [
      { text: "Pending", value: "Pending" },
      { text: "In-Active", value: "In-Active" },
      { text: "Completed", value: "Completed" },
    ],
    notEmptyRule: [(v) => !!v || "This is required"],
    filterBy: "",
    operator: { text: "IS", value: "is" },
    filterValue: "",
    priority: "",
    isPrioritySelected: false,
    disableFilterValue: false,
  }),
  mounted: function () {
    this.filter = Object.assign({}, this.taskFilter);
    this.buildFilterObject();
  },
  computed: {
    taskFilter() {
      return this.$store.state.taskFilter;
    },
  },
  watch: {
    taskFilter() {
      this.filter = Object.assign({}, this.taskFilter);
    },
  },
  methods: {
    resetFilterForm: function () {
      if (!this.$refs.TaskFilterForm) return;
      this.$refs.TaskFilterForm.reset();
      this.resetFilter();
    },
    resetFilter: function () {
      this.filterBy = "";
      this.operator = { text: "IS", value: "is" };
      this.filterValue = "";
      this.priority = "";
      this.isPrioritySelected = false;
      this.disableFilterValue = false;
    },
    buildFilterObject: function () {
      if (!this.$refs.TaskFilterForm) return;
      const isValid = this.$refs.TaskFilterForm.validate();
      if (!isValid) return;

      let filter = Object.assign({}, this.taskFilter);

      switch (this.operator.value) {
        case "is":
          filter[this.filterBy] = this.filterValue;
          break;
        default:
          console.log("[TASK_FILTER] : No Operator Selected");
          break;
      }

      this.$store.dispatch("selectTaskListFilter", { filter: filter });
      this.$refs.TaskFilterForm.reset();
      this.resetFilter();
    },
    applyFilter: function () {
      let applyFilter = Object.assign({}, this.filter);
      if (this.filter.accountName) {
        applyFilter.accountName = this.filter.accountName;
      }
      if (this.filter.priority) {
        applyFilter.priority = this.filter.priority;
      }
      this.$store.dispatch("selectTaskListFilter", { filter: applyFilter });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.accordion {
  width: 550px;
}

.dateError {
  width: 358px;
  color: red;
  margin-right: 400px;
}

.lbl {
  width: 125px;
}

.date {
  width: 200px;
}

.filterHeaders span {
  color: #857db1;
  font-weight: 800;
  text-transform: uppercase;
}

.filterHeadersTitle {
  padding-left: 15px;
  font-size: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  color: #00458d;
  background: repeating-linear-gradient(350deg, #f7f9fb, transparent 5px);
}
</style>
