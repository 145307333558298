<template>
  <v-container fluid class="content">
    <v-overlay :value="overlay" opacity="1">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <div>
      <div class="header">
        <h1>Data Projects</h1>
      </div>

      <div>
        <v-slide-group mandatory class="counter_stats" multiple show-arrows>
          <div v-for="stateCount in stateCounts" :key="stateCount.status">
            <v-card class="ml-2 counter" height="80" width="125">
              <h4 class="counter_value">
                {{ stateCount.count | humanizeNumber }}
              </h4>
              <h4 class="counter_title">{{ stateCount.status }}</h4>
            </v-card>
          </div>
        </v-slide-group>
      </div>

      <v-container fluid class="task_state pl-0">
        <v-expansion-panels multiple>
          <v-expansion-panel class="task_state_headers" :readonly="true">
            <v-expansion-panel-header hide-actions>
              <div class="d-flex justify-space-right">
                <div class="field project_name_flex">
                  <h6>Project Name</h6>
                </div>

                <div class="field">
                  <h6>Total Task</h6>
                </div>
              </div>
              <div class="field">
                <h6>Project Type</h6>
              </div>
            </v-expansion-panel-header>
          </v-expansion-panel>
          <v-expansion-panel
            class="list_item"
            v-for="taskState in taskStateList"
            :key="taskState.ProjectId"
            :readonly="true"
          >
            <v-expansion-panel-header hide-actions>
              <div
                class="d-flex justify-space-right mb-1"
                @click="
                  details(taskState.ProjectId, taskState.projectAliasName)
                "
              >
                <div class="field project_name_flex">
                  <h6>Project Name</h6>
                  <h4>
                    {{ taskState.projectAliasName | checkEmpty | markEmpty }}
                  </h4>
                </div>

                <div class="field">
                  <h6>Total Task</h6>
                  <h4>
                    {{ taskState.taskCount | humanizeNumber }}
                  </h4>
                </div>
              </div>
              <div class="field md-12">
                <h6>Project Type</h6>
                <h4>{{ taskState.projectType | checkEmpty | markEmpty }}</h4>
              </div>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>

      <div class="text-center">
        <v-pagination
          v-model="pageNumber"
          :length="Math.ceil(totalCount / pageSize)"
          :total-visible="$constants().TOTAL_VISIBLE_PAGES"
          circle
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="getTaskState()"
        ></v-pagination>
      </div>
    </div>
  </v-container>
</template>

<script>
import Api from "../services/Api";

export default {
  name: "AgentProjectList",
  props: {},
  data() {
    return {
      taskStateList: [],
      stateCounts: [],
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE,
      totalCount: 0,
      overlay: true,
    };
  },
  mounted: async function () {
    this.getTaskState();
  },
  methods: {
    getTaskState: function () {
      Api.getTaskStats({
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
      })
        .then((response) => {
          this.taskStateList = response.data.docs;
          this.stateCounts = response.data.counts;
          this.totalCount = response.data.totalCount;
          this.overlay = false;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              let logoutDomain =
                location.protocol + "//" + location.host + "/auth/login.html";
              window.auth0Client.logout({
                returnTo: logoutDomain,
                clientId: process.env.VUE_APP_OAUTHCLIENTID,
              });
              localStorage.clear();
            }

            let notification = {
              type: "error",
              showMessage: true,
              message: this.$notifications().FETCH_PROJECT_ERROR,
            };
            this.setNotification(notification);

            console.log(error);
          }
        });
    },

    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    details(projectId, projectAliasName) {
      this.$store.dispatch("selectProjectForAgent", {
        id: projectId,
        name: projectAliasName,
      });

      this.$router
        .push({
          name: "AgentTaskList",
          params: { projectId: projectId },
        })
        .catch((e) => e);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.content {
  padding-left: 55px;
  background: repeating-linear-gradient(350deg, #f7f9fb, transparent 5px);
}
.counter_stats {
  float: left;
  width: 1300px;
  margin-left: 10px;
  margin-bottom: 15px;
}

.counter_stats::-webkit-scrollbar {
  -webkit-appearance: none;
}

.counter_stats::-webkit-scrollbar:horizontal {
  height: 11px;
}

.counter_stats::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: #8b83ba;
}
.counter_stats .counter {
  box-shadow: none !important;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}
.counter_stats .counter .counter_title {
  font-weight: 400;
  margin: 10px 0 15px;
  font-family: "Lato";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
  max-height: 40px;
  overflow: hidden;
}
.counter_stats .counter .counter_value {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #1c76d2;
  margin: 0;
  text-align: center;
}

.list_item,
.task_state_headers {
  background: #ffffff;
  padding: 5px 10px;
  padding-left: 0;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
}

.header {
  margin: 10px 15px 20px;
}

.task_state,
.task_state .list_item {
  display: block;
  /*width: 100%;*/
}

.header h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}

.task_state .v-expansion-panels {
  margin-left: 15px;
}
.list_item > div {
  display: block;
}
.list_item .list_item_stats p-2 bd-highlight {
  display: inline-block;
}
.list_item h4,
.list_item h5,
.list_item h6 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.list_item .selection input {
  position: absolute;
  left: 40%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.list_item .selection {
  width: 50px;
  height: 50px;
  text-align: center;
  position: relative;
}
.list_item .title_section {
  width: 450px;
  margin-right: 30px;
}
.list_item .title_section h4 {
  font-weight: 500;
}
.list_item .title_section h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #075ab1;
}

.list_item div.time {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00c537;
}

input:focus + .slider {
  outline: none;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.task_state_headers h6 {
  font-family: "Lato";
  font-size: 15px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase !important;
}
.list_item h6 {
  font-family: "Lato";
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
}
.list_item h6 {
  display: none;
  font-family: "Lato";
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase !important;
}
.list_item.v-item--active h6 {
  display: block;
}
.list_item h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  color: #25213b;
}
.task_state_headers .v-expansion-panel-header,
.list_item .v-expansion-panel-header,
.list_item .v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 5px 15px !important;
}

.v-expansion-panels {
  margin: 10px 30px 10px 30px;
}

.field {
  width: 180px;
}

.circle {
  height: 45px;
  width: 45px;
  background-color: #075ab1;
  border-radius: 50%;
  text-align: center;
  padding: 9px 0;
  float: left;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  color: white !important;
}

.task_state .list_item .v-expansion-panel-header__icon {
  left: 5px;
  position: absolute;
}

.project_name_flex {
  width: 350px;
}

@media (min-width: 1100px) and (max-width: 1250px) {
  .field.project_name_flex {
    width: 270px;
  }

  .list_item h4 {
    font-size: 15px;
  }

  .field {
    width: 160px;
  }

  .task_state_headers h6 {
    font-size: 14px;
  }
}
.notification {
  position: absolute;
}

@media (min-width: 1250px) and (max-width: 1350px) {
  .field.project_name_flex {
    width: 280px;
  }

  .list_item h4 {
    font-size: 16px;
  }

  .field {
    width: 170px;
  }

  .task_state_headers h6 {
    font-size: 15px;
  }
}
</style>
