<template>
  <div>
    <!-- Data-Gride -->
    <v-data-table
      :height="gridHeight"
      style="white-space: nowrap"
      fixed-header
      :id="gridId"
      :ref="gridRef"
      :headers="headers"
      :items="itemList"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalCount"
      loading-text="Loading... Please wait"
      class="elevation-1"
      dense
      :multi-sort="allowMultiSort"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'navigate_before',
        nextIcon: 'navigate_next',
        'items-per-page-options': itemsPerPage,
      }"
      :items-per-page="defaultItemsPerPage"
    >
      <template v-slot:top>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Update Agent</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <h4>
                      <i class="d-block">Account Name:-</i>
                      {{ editedItem.accountName }}
                    </h4>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <h4>
                      <i class="d-block">Assigned Agent:-</i>
                      {{ editedItem.agentName }}
                    </h4>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selectedAgent"
                      :search-input.sync="search"
                      :items="agentList"
                      item-text="fullName"
                      outlined
                      return-object
                      hide-details
                      label="Select from Agent"
                      dense
                      @focus="setAgentList()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="updateAgent(editedItem.id)"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="600px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to remove this Assignment?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:body="props">
        <!-- loop through items(rows) -->
        <tr v-for="(item, itemIndex) in props.items" :key="itemIndex">
          <!-- loop through headers -->
          <td v-for="(header, headerIndex) in headers" :key="headerIndex">
            <!-- Formate Date data into the humanReadable -->
            <span class="item" v-if="header.value === 'actions'">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </span>

            <span class="item" v-else-if="header.type === 'suggestActions'">
              <v-icon
                v-if="!item.flag"
                v-bind="attrs"
                v-on="on"
                @click="enqueueItem(item)"
                class="mr-5"
                color="error"
              >
                person_remove
              </v-icon>
              <v-icon
                v-if="!item.flag"
                v-bind="attrs"
                v-on="on"
                @click="suggestItem(item)"
                color="primary"
              >
                person_add
              </v-icon>
              <v-btn
                block
                color="primary"
                small
                disabled
                v-if="item.flag === 'added'"
              >
                Used
              </v-btn>
            </span>

            <span class="item" v-else-if="header.type === 'fileAction'">
              <v-tooltip
                bottom
                v-if="item[header.value] === 'view'"
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="viewFile(item)">
                    preview
                  </v-icon>
                </template>
                <span>View Projects</span>
              </v-tooltip>
              <v-tooltip
                bottom
                v-if="item[header.value] === 'delete'"
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" @click="deleteFile(item)">
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete File</span>
              </v-tooltip>
            </span>

            <span class="item" v-else-if="header.type === 'date'">
              {{ item[header.value] | checkEmpty | markEmpty | formatDate }}
            </span>

            <!-- Formate Date data into the humanReadable momentFormatDate-->
            <span class="item" v-else-if="header.type === 'momentFormatDate'">
              {{
                item[header.value] | checkEmpty | markEmpty | momentFormatDate
              }}
            </span>

            <!-- Formate Number data into the  humanReadable -->
            <span class="item" v-else-if="header.type === 'number'">
              {{ item[header.value] | checkEmpty | markEmpty | humanizeNumber }}
            </span>

            <!-- Tooltip -->
            <span class="item" v-else-if="header.tooltip">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    item[header.value]
                      | truncate(header.tooltip)
                      | checkEmpty
                      | markEmpty
                  }}</span>
                </template>
                <span>{{ item[header.value] }}</span>
              </v-tooltip>
            </span>

            <!-- extrenal url -->
            <span v-else-if="header.type === 'link'">
              <a
                v-if="item[header.value]"
                class="item"
                :href="makeExternalURL(item[header.value])"
                target="_blank"
              >
                {{ item[header.value] | truncate($constants().COLUMN_LIMIT20) }}
              </a>

              <span class="item" v-else
                >{{ item[header.value] | checkEmpty | markEmpty }}
              </span>
            </span>

            <!-- url Search
            <span v-else-if="header.type === 'link'">
              <a
                v-if="item[header.value]"
                class="item"
                :href="urlSearch"
                target="_blank"
                v-on:click.stop=""
                @click="makeSearchUrl(item[header.value])"
              >
                {{ item[header.value] | truncate($constants().COLUMN_LIMIT20) }}
              </a>

              <span class="item" v-else
                >{{ item[header.value] | checkEmpty | markEmpty }}
              </span>
            </span> -->

            <!-- Google Search -->
            <span v-else-if="header.googleSearch">
              <a
                v-if="item[header.value]"
                class="item"
                :href="googleSearchUrl"
                target="_blank"
                v-on:click.stop=""
                @click="googleSearch(item[header.value])"
              >
                {{ item[header.value] | truncate($constants().COLUMN_LIMIT20) }}
              </a>

              <span class="item" v-else
                >{{ item[header.value] | checkEmpty | markEmpty }}
              </span>
            </span>

            <!-- Icon -->
            <span
              class="item"
              v-else-if="checkIsButton(header.type, header.icon)"
            >
              <v-icon
                right
                class="ml-8 center"
                @click="buttonClickAction(header.text, item[header.value])"
                :disabled="
                  _.get(item, 'itemInfo.isDisableButton', null) &&
                  _.get(item, 'itemInfo.header', null) === header.text
                "
              >
                {{ header.icon }}
              </v-icon>
            </span>

            <span class="item" v-else>{{
              item[header.value] | checkEmpty | markEmpty
            }}</span>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from "lodash";
import Api from "../services/Api";

export default {
  name: "dataGride",
  components: {},
  props: {
    gridId: String,
    gridRef: String,
    gridHeight: String,
    headers: Array,
    itemList: Array,
    loading: Boolean,
    totalCount: Number,
    pageNo: Number,
    limit: Number,
    allowAction: Boolean,
    allowMultiSort: Boolean,
  },
  data() {
    return {
      selectedAgent: "",
      itemsPerPage: [5, 10, 20, 40],
      defaultItemsPerPage: this.limit || 40,
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: this.limit || 40,
        multiSort: false,
        mustSort: false,
        page: this.pageNo,
        sortBy: [],
        sortDesc: [],
      },
      urlSearch: "",
      googleSearchUrl: "",
      editedIndex: -1,
      editedItem: {},
      dialog: false,
      dialogDelete: false,
      agentList: [],
      search: "",
    };
  },
  computed: {
    _() {
      return _;
    },
  },
  watch: {
    pageNo: function () {
      this.options.page = this.pageNo;
    },
    options: function (oldValue, newValue) {
      if (!_.isEqual(oldValue, newValue)) {
        this.fetchGrideData(this.options);
      }
    },
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
  },
  mounted: async function () {
    if (this.allowAction) {
      this.headers.push({ text: "Actions", value: "actions", sortable: false });
    }
    this.querySelections("a");
  },
  methods: {
    setAgentList() {
      if (!this.agentList.length) {
        this.querySelections("a");
      }
    },
    deleteItemConfirm() {
      this.$emit("deleteTask", this.editedItem);
      this.itemList.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    updateAgent(id) {
      this.close(this.editedItem);
      this.$emit("updateAgent", {
        id: id,
        agent: this.selectedAgent,
      });
      this.selectedAgent = "";
    },
    enqueueItem(item) {
      this.$emit("enqueueItem", item);
    },
    suggestItem(item) {
      this.$emit("suggestItem", item);
    },
    editItem(item) {
      this.editedIndex = this.itemList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    fetchGrideData(options) {
      this.$emit("fetchGrideData", options);
    },
    googleSearch(value) {
      let finalValue =
        `https://www.google.com/search?q=` + encodeURIComponent(value);
      this.googleSearchUrl = finalValue;
    },
    makeSearchUrl(value) {
      this.urlSearch =
        this.$constants().URL_DOUBLE_SLASH + encodeURIComponent(value);
    },
    makeExternalURL(url) {
      url = _.trim(url);
      if (!_.startsWith(url, "http") && !_.startsWith(url, "//")) {
        url = `//${url}`;
      }
      return url;
    },
    buttonClickAction(headerName, value) {
      this.$emit("fetchButtonClickAction", { headerName, value });
    },
    checkIsButton(type, icon) {
      if (icon && type === "button") {
        return true;
      }
      return false;
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.editedItem = Object.assign({}, item);
      this.$emit("deleteItem", item);
      this.editedIndex = this.itemList.indexOf(item);
    },
    viewFile(item) {
      this.$emit("viewFile", item);
    },
    deleteFile(item) {
      this.$emit("deleteFile", item);
    },
    querySelections(v) {
      let self = this;
      // Simulated ajax query
      self.agentList = [];
      const userRole = "agent";
      return Api.autoCompleteInterFace("userSearch", {
        param: v,
        userRole: userRole,
      }).then(function (response) {
        self.agentList = _.map(response.data, function (user) {
          user.fullName = user.firstName + " " + user.lastName;
          return user;
        });
      });
    },
  },
};
</script>

<style scoped>
.item {
  position: relative;
  left: 10px;
  float: left;
  margin: 6px 6px 6px;
}
</style>
