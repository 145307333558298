<template>
  <div>
    <div class="taskStats">
      <v-row class="counter" no-gutters>
        <v-col>
          <h4>Total: {{ total }}</h4>
        </v-col>
        <v-col>
          <h4>Completed: {{ completed }}</h4>
        </v-col>
        <v-col>
          <h4>Overdue: {{ overdue }}</h4>
        </v-col>
        <v-col>
          <h4>Upcoming: {{ upcoming }}</h4>
        </v-col>
        <!-- <v-btn icon color="white" @click="refreshState()">
          <v-icon>mdi-cached</v-icon>
        </v-btn> -->
      </v-row>
    </div>
  </div>
</template>

<script>
import Api from "../services/Api";
export default {
  data() {
    return {
      upcoming: 0,
      completed: 0,
      total: 0,
      overdue: 0,
    };
  },
  mounted: function () {
    this.fetchTaskStats();
  },
  methods: {
    fetchTaskStats() {
      Api.getTaskStatsForAgent(this.$route.params.projectId)
        .then((response) => {
          this.countStats(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    countStats(stats) {
      this.total = 0;
      this.pending = 0;
      this.completed = 0;
      for (let index = 0; index < stats.length; index++) {
        if (stats[index].status == "Completed") {
          this.completed = stats[index].count;
          this.total = this.total + parseInt(stats[index].count);
        } else if (stats[index].status == "Overdue") {
          this.overdue = stats[index].count;
          this.total = this.total + parseInt(stats[index].count);
        } else if (stats[index].status == "Upcoming") {
          this.upcoming = stats[index].count;
          this.total = this.total + parseInt(stats[index].count);
        }
      }
    },
    refreshState() {
      this.fetchTaskStats();
    },
  },
};
</script>

<style scoped>
.taskStats {
  height: 100%;
  width: 100%;
  background: repeating-linear-gradient(330deg, #353232, #444141 30px);
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  color: white;
}
.counter {
  padding: 10px 12px;
  height: 48px;
}
</style>
