<template>
  <div class="create_project_wrapper float-right">
    <div v-if="isDisplayDialog">
      <v-btn color="#8B83BA" dark depressed rounded @click="openPROJECTForm()">
        Create Data Project
        <span class="material-icons">add</span>
      </v-btn>
    </div>

    <div v-if="showDialog" class="dialog-wrap">
      <div class="dialog-main">
        <div class="main-content">
          <div class="main-header">
            <div class="header_title">Create Data Project</div>
            <div class="close-btn" @click="clearProjectForm()">×</div>
          </div>

          <v-overlay :value="showLoader" opacity="0.9">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>

          <div class="main-body" method="post">
            <v-form ref="form" v-model="valid" lazy-validation>
              <!-- ClientName -->
              <v-row>
                <v-col>
                  <div>
                    <label class="label">
                      Client Name<span style="color: red">*</span>
                    </label>
                  </div>
                </v-col>

                <v-col class="d-flex" cols="12" sm="7">
                  <v-combobox
                    v-on:click.native="(event) => fetchClient()"
                    item-text="name"
                    item-value="id"
                    v-model="client"
                    :items="clientList"
                    :rules="clientNameRules"
                    @keydown="changedClient()"
                    @input="changedClient()"
                    label="Enter Client"
                    required
                    clearable
                    deletable-chips
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>

              <!-- project Name -->
              <v-row>
                <v-col class="d-flex" cols="12" sm="5">
                  <label class="label">
                    Project Name<span style="color: red">*</span>
                  </label>
                </v-col>
                <v-col class="d-flex" cols="12" sm="7">
                  <v-text-field
                    label="Project Name"
                    v-model="project.name"
                    required
                    :rules="projectNameRules"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- aliasProject Name -->
              <v-row>
                <v-col class="d-flex" cols="12" sm="5">
                  <label class="label">
                    Alias Project Name<span style="color: red">*</span>
                  </label>
                </v-col>
                <v-col class="d-flex" cols="12" sm="7">
                  <v-text-field
                    label="Alias Project Name"
                    v-model="project.aliasName"
                    required
                    :rules="aliasProjectNameRules"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- Project Type -->
              <v-row>
                <v-col class="d-flex" cols="12" sm="5">
                  <label class="label">
                    Project Type<span style="color: red">*</span>
                  </label>
                </v-col>
                <v-col class="d-flex" cols="12" sm="7">
                  <v-select
                    v-model="project.projectTypeId"
                    :items="projectType"
                    item-value="id"
                    item-text="type"
                    :rules="[(v) => !!v || 'Project Type is required']"
                    label="Select Project Type"
                    required
                    outlined
                    dense
                    clearable
                  ></v-select>
                </v-col>
              </v-row>

              <!-- Template Type -->
              <v-row>
                <v-col class="d-flex" cols="12" sm="5">
                  <label class="label">
                    Template Type<span style="color: red">*</span>
                  </label>
                </v-col>
                <v-col class="d-flex" cols="12" sm="7">
                  <v-select
                    v-model="project.templateId"
                    :items="templateList"
                    item-value="id"
                    item-text="name"
                    :rules="[(v) => !!v || 'Template Type is required']"
                    label="Select Template Type"
                    required
                    outlined
                    dense
                    clearable
                  ></v-select>
                </v-col>
              </v-row>

              <!-- Due Date -->
              <v-row>
                <v-col class="d-flex" cols="12" sm="5">
                  <label class="label">Due Date</label>
                </v-col>
                <v-col class="d-flex" cols="12" sm="7">
                  <DatePickerMenu
                    :datePlaceHolder="dueDateLabel"
                    v-model="project.dueDate"
                    :selectedDate="project.dueDate"
                    :disablePastDate="true"
                    v-on:selectDate="project.dueDate = $event"
                  />
                </v-col>
              </v-row>

              <v-btn
                color="primary"
                @click="submitForm"
                :disabled="disableSaveBtn"
                large
                depressed
              >
                Save
              </v-btn>

              <v-alert
                outlined
                type="error"
                v-if="showClientAndProjectErrorMsg"
                style="margin-top: 10px; margin-bottom: 0"
              >
                {{ clientAndProjectErrorMsg }}
              </v-alert>
            </v-form>
          </div>
        </div>
      </div>
      <div class="dialog-mask" @click="showDialog = false"></div>
    </div>
  </div>
</template>

<script>
import Api from "../services/Api";
import DatePickerMenu from "./DatePickerMenu.vue";
import { getRoles } from "../roles.js";

export default {
  name: "CreatePROJECT",
  components: {
    DatePickerMenu,
  },
  props: {
    projectId: String,
  },
  data() {
    return {
      isDisplayDialog: true,
      showLoader: false,
      newClient: {},
      showClientAndProjectErrorMsg: false,
      clientAndProjectErrorMsg: null,
      newClientValid: false,
      disableSaveBtn: false,
      valid: false,
      projectType: [],
      showDialog: false,
      clientList: [],
      projectList: [],
      project: {
        name: null,
        clientId: null,
        projectTypeId: null,
        dueDate: null,
        templateId: null,
      },
      dateMenu: false,
      todayDate: new Date().toISOString().slice(0, 10),
      dueDateLabel: "Select Due Date",
      autoCompleteSelected: null,
      loading: false,
      client: null,
      clientNameRules: [
        (v) => !!v || "Client is required",
        (v) =>
          (v && typeof v === "string" && !!v.trim()) ||
          (v && typeof v === "object" && !!v.name.trim()) ||
          "Client is required",
      ],
      projectNameRules: [
        (v) => !!v || "Project Name is required",
        (v) => (v && !!v.trim()) || "Project Name is required",
      ],
      aliasProjectNameRules: [
        (v) => !!v || "Alias Project Name is required",
        (v) => (v && !!v.trim()) || "Alias Project Name is required",
      ],
      templateList: [],
    };
  },
  mounted: async function () {
    this.fetchProjectType();
    this.fetchTemplates();
    let role = await getRoles();
    this.isDisplayDialog =
      role.indexOf(this.$constants().USER_ROLES.PROJECT_OWNER) > -1;
  },
  watch: {
    showDialog(value) {
      if (value == false) {
        this.client = "";
      }
    },
  },
  methods: {
    changedClient() {
      this.showClientAndProjectErrorMsg = false;
    },
    submitForm() {
      var formValid = this.$refs.form.validate();
      if (formValid) {
        this.showLoader = true;
        this.disableSaveBtn = true;
        this.projectFormData();
      }
    },
    projectFormData() {
      let self = this;
      if (this.client.id) {
        this.$store.dispatch("setProjectClientId", this.client.id);
        this.project.clientId = this.client.id;
        this.createProject(this.project);
      } else {
        this.createNewClient()
          .then((response) => {
            if (
              response.data.code === "CLIENT_WITH_NAME_ALREADY_EXISTS" ||
              response.data.code === "CLIENT_WITH_PSEUDONYM_ALREADY_EXISTS"
            ) {
              if (response.data.code === "CLIENT_WITH_NAME_ALREADY_EXISTS") {
                this.clientAndProjectErrorMsg =
                  response.data.message + " (" + self.newClient.name + ")";
              }
              if (
                response.data.code === "CLIENT_WITH_PSEUDONYM_ALREADY_EXISTS"
              ) {
                this.clientAndProjectErrorMsg =
                  response.data.message + " (" + self.newClient.pseudonym + ")";
              }
              this.showLoader = false;
              this.showClientAndProjectErrorMsg = true;
              this.showDialog = true;
              this.disableSaveBtn = false;
              return;
            }
            this.project.clientId = response.data.id;
            this.createProject(this.project);
          })
          .catch((err) => {
            this.showLoader = false;
            this.clientAndProjectErrorMsg = this.$notifications().CLIENT_ERROR;
            this.showClientAndProjectErrorMsg = true;
            this.showDialog = true;
            this.disableSaveBtn = false;
            console.log(err);
          });
      }
    },
    createProject(project) {
      Api.createProject(project)
        .then((response) => {
          this.showLoader = false;
          this.disableSaveBtn = false;

          this.$router
            .push({
              name: "PROJECTDetail",
              params: {
                id: response.data.project.id,
                edit: true,
                tab: "overview",
              },
            })
            .catch((e) => e);
        })
        .catch((err) => {
          this.showLoader = false;
          this.clientAndProjectErrorMsg = this.$notifications().PROJECT_ERROR;
          this.showClientAndProjectErrorMsg = true;
          this.showDialog = true;
          this.disableSaveBtn = false;
          console.log(err);
        });
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    fetchClient: function () {
      Api.getClients()
        .then((response) => {
          this.clientList = response.data;
          console.dir(response.data);
        })
        .catch((error) => console.log(error));
    },
    fetchProjectType: function () {
      Api.getProjectTypes()
        .then((response) => {
          this.projectType = response.data;
          console.dir(response.data);
        })
        .catch((error) => console.log(error));
    },
    openPROJECTForm: function () {
      this.showLoader = false;
      this.disableSaveBtn = false;
      this.showClientAndProjectErrorMsg = false;
      this.showDialog = true;
      this.project = {
        name: null,
        clientId: null,
        projectTypeId: null,
        dueDate: null,
      };
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    createNewClient() {
      let trimedClient = this.client.trim();

      this.newClient.name = trimedClient;
      this.newClient.pseudonym = trimedClient.substring(0, 3);

      return Api.createClient(this.newClient);
    },
    clearProjectForm() {
      this.showDialog = false;
      this.client = "";
    },
    fetchTemplates: function () {
      let self = this;
      return Api.getTemplateNames({})
        .then((response) => {
          self.templateList = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped>
.fade-enter .dialog-main,
.fade-leave-to .dialog-main {
  opacity: 0;
  transform: scale3d(1.05, 1.05, 1.05);
}

.fade-enter .dialog-mask,
.fade-leave-to .dialog-mask {
  opacity: 0;
}

.dialog-wrap,
.dialog-mask {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease;
  z-index: 1000;
}

.dialog-wrap,
.dialog-mask {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.dialog-mask {
  background-color: #000;
  opacity: 0.5;
  z-index: 999;
}

.dialog-main {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  transition: all 0.2s ease;
  z-index: 9999;
}

.main-content {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 20px 10px;
  width: 600px;
}

.main-header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f0f1f4;
}

.main-header .header_title {
  font-size: 30px;
  flex: 1;
  align-self: center;
  color: #585353;
  font-weight: 500;
  padding: 10px;
}

.main-header .close-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8b83ba;
  border-radius: 100px;
  height: 30px;
  width: 30px;
  color: white;
  font-weight: 700;
  margin-top: 13px;
}

.main-body {
  height: auto;
  padding: 40px 10px;
  overflow-y: auto;
}
.create_project_wrapper button.btn {
  padding: 5px 20px;
  background: #205fac;
  border-radius: 4px;
  color: white;
  outline: 0;
  border: 0;
  height: 35px;
  cursor: pointer;
}

.create_project_wrapper label {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
}

.create_project_wrapper .row {
  margin: -20px 0;
}
.notification {
  position: absolute;
}
</style>
