var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{staticClass:"ml-1 pl-2",attrs:{"dense":"","label":"Filter List By Last Name","clearable":"","outlined":""},on:{"click:clear":function($event){_vm.search = ''}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-list-item-title',[_c('p',{staticClass:"text-left ml-1 pl-2 list_header"},[_vm._v("Name, Disposition")])]),_c('v-virtual-scroll',{attrs:{"bench":_vm.benched,"items":_vm.filteredContacts,"height":_vm.contactListHeight,"item-height":"64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.id,class:{ selected_contact: item.id === _vm.formContact.id }},[_c('v-list-item-content',{staticClass:"py-0",staticStyle:{"cursor":"pointer","height":"63px"},on:{"click":function($event){return _vm.selectContact(item)}}},[_c('p',{staticClass:"list_name"},[_vm._v(" "+_vm._s(item.firstName + " " + item.lastName)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(
                      !item.disposition &&
                      item.label !== 'suppressed' &&
                      item.label !== 'Exact Suppressed' &&
                      item.label !== 'duplicate' &&
                      item.label !== 'Fuzzy Suppressed'
                    )?_c('v-badge',{staticClass:"float-right",attrs:{"color":"blue","dot":"","inline":""}},[_vm._v(" Saved")]):_vm._e()],1)]}}],null,true)},[_c('span',[_c('h4',[_vm._v("Contact Only Saved")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(
                      item.complianceStatus &&
                      item.complianceStatus !== 'Compliant'
                    )?_c('v-badge',{staticClass:"float-right",attrs:{"color":"black","dot":"","inline":""}},[_vm._v(" Non-Comp")]):_vm._e()],1)]}}],null,true)},[_c('span',[_c('h4',[_vm._v("Contact Non-Compliant")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(item.label === 'duplicate')?_c('v-badge',{staticClass:"float-right",attrs:{"color":"red","dot":"","inline":""}},[_vm._v(" Dup")]):(
                      item.label === 'suppressed' ||
                      item.label === 'Exact Suppressed'
                    )?_c('v-badge',{staticClass:"float-right",attrs:{"color":"orange","dot":"","inline":""}},[_vm._v(" Exact-Sup")]):(item.label === 'Fuzzy Suppressed')?_c('v-badge',{staticClass:"float-right",attrs:{"color":"#936827","dot":"","inline":""}},[_vm._v(" Fuzzy-Sup")]):(item.label.match(/^Within/))?_c('v-badge',{staticClass:"float-right",attrs:{"color":"green","dot":"","inline":""}},[_vm._v(" Reused")]):_vm._e()],1)]}}],null,true)},[_c('span',[(item.label === 'duplicate')?_c('h4',[_vm._v("Contact Duplicate")]):(
                    item.label === 'suppressed' ||
                    item.label === 'Exact Suppressed'
                  )?_c('h4',[_vm._v(" Contact Exact Suppressed ")]):(item.label === 'Fuzzy Suppressed')?_c('h4',[_vm._v(" Contact FuzzySuppressed ")]):(item.label.match(/^Within/))?_c('h4',[_vm._v(" Contact Reused ")]):_vm._e()])])],1),_c('p',{staticClass:"list_title"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"display":"inline-block"}},'span',attrs,false),on),[(item.TaskLink && item.TaskLink.disposition)?_c('p',[_vm._v(" "+_vm._s(item.TaskLink.disposition)+" ")]):(item.disposition)?_c('p',[_vm._v(" "+_vm._s(item.disposition)+" ")]):_vm._e()])]}}],null,true)},[_c('span',[_c('h4',[_vm._v("Disposition")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"display":"inline-block"}},'span',attrs,false),on),[(item.TaskLink && item.TaskLink.researchStatus)?_c('p',[_vm._v(" , "+_vm._s(item.TaskLink.researchStatus)+" ")]):(
                      item.researchStatus && item.researchStatus !== 'Pending'
                    )?_c('p',[_vm._v(" , "+_vm._s(item.researchStatus)+" ")]):_vm._e()])]}}],null,true)},[_c('span',[_c('h4',[_vm._v("Research Status")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"display":"inline-block"}},'span',attrs,false),on),[(item.TaskLink && item.TaskLink.complianceStatus)?_c('p',[_vm._v(" , "+_vm._s(item.TaskLink.complianceStatus)+" ")]):(item.complianceStatus)?_c('p',[_vm._v(" , "+_vm._s(item.complianceStatus)+" ")]):_vm._e()])]}}],null,true)},[_c('span',[_c('h4',[_vm._v("Compliance Status")])])])],1)])],1),_c('v-divider')]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }