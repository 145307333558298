<template>
  <div class="create_client_wrapper float-right">
    <div>
      <v-btn
        class="mr-8"
        color="#8B83BA"
        dark
        depressed
        v-if="openCreateClientForm"
        rounded
        @click="openClientForm()"
      >
        Create Client
        <span class="material-icons">add</span>
      </v-btn>

      <span
        class="material-icons"
        style="color: #2a66b0; cursor: pointer"
        dark
        depressed
        v-if="openEditClientForm"
        @click="openClientForm()"
      >
        edit
      </span>
    </div>

    <div v-show="showDialog" class="dialog-wrap">
      <div class="dialog-main">
        <div class="main-content">
          <div class="main-header">
            <div class="header_title" v-if="openCreateClientForm">
              Create Client
            </div>
            <div class="header_title" v-if="openEditClientForm">
              Update Client
            </div>
            <div class="close-btn" @click="showDialog = false">×</div>
          </div>

          <v-overlay :value="showLoader" opacity="0.9">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>

          <div class="main-body" method="post">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col class="d-flex" cols="12" sm="5">
                  <label class="label">
                    Client Name<span style="color: red">*</span>
                  </label>
                </v-col>
                <v-col class="d-flex" cols="12" sm="7">
                  <v-text-field
                    v-model="client.name"
                    required
                    :rules="clientNameRules"
                    @keydown="changedClient()"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex" cols="12" sm="5">
                  <label class="label">
                    Pseudonym<span style="color: red">*</span>
                  </label>
                </v-col>
                <v-col class="d-flex" cols="12" sm="7">
                  <v-text-field
                    v-model="client.pseudonym"
                    required
                    :rules="pseudonymRules"
                    @keydown="changedClient()"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-btn
                color="primary"
                @click="submitForm"
                :disabled="disableSaveBtn"
                large
                depressed
                style="float: right"
              >
                Save
              </v-btn>

              <v-alert
                outlined
                type="error"
                v-if="showClientErrorMsg"
                style="margin-top: 60px; margin-bottom: 0"
              >
                {{ clientErrorMsg }}
              </v-alert>
            </v-form>
          </div>
        </div>
      </div>
      <div class="dialog-mask" @click="showDialog = false"></div>
    </div>
  </div>
</template>

<script>
import Api from "../services/Api";

export default {
  name: "ClientForm",
  props: {
    openCreateClientForm: Boolean,
    openEditClientForm: Boolean,
    clientId: String,
    clientName: String,
    clientPseudonym: String,
  },
  data() {
    return {
      showLoader: false,
      showClientErrorMsg: false,
      clientErrorMsg: null,
      valid: false,
      showDialog: false,
      disableSaveBtn: false,
      client: {
        name: null,
        pseudonym: null,
      },
      dateMenu: false,
      todayDate: new Date().toISOString().slice(0, 10),
      clientNameRules: [
        (v) => !!v || "Client is required",
        (v) => (v && !!v.trim()) || "Client is required",
      ],
      pseudonymRules: [
        (v) => !!v || "Pseudonym is required",
        (v) => (v && !!v.trim()) || "Pseudonym is required",
      ],
    };
  },
  mounted: function () {},
  methods: {
    changedClient() {
      this.showClientErrorMsg = false;
    },
    submitForm() {
      var formValid = this.$refs.form.validate();
      if (formValid) {
        this.showLoader = true;
        this.disableSaveBtn = true;
        if (this.openCreateClientForm) {
          this.createClient();
        }
        if (this.openEditClientForm) {
          this.editClient();
        }
      }
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    createClient() {
      let self = this;
      this.client.name = this.client.name.trim();
      this.client.pseudonym = this.client.pseudonym.trim();
      Api.createClient(this.client)
        .then((response) => {
          this.showLoader = false;
          if (
            response.data.code === "CLIENT_WITH_NAME_ALREADY_EXISTS" ||
            response.data.code === "CLIENT_WITH_PSEUDONYM_ALREADY_EXISTS"
          ) {
            if (response.data.code === "CLIENT_WITH_NAME_ALREADY_EXISTS") {
              this.clientErrorMsg =
                response.data.message + " (" + this.client.name + ")";
            }
            if (response.data.code === "CLIENT_WITH_PSEUDONYM_ALREADY_EXISTS") {
              this.clientErrorMsg =
                response.data.message + " (" + this.client.pseudonym + ")";
            }
            this.showClientErrorMsg = true;
            self.showDialog = true;
            self.disableSaveBtn = false;
            return;
          }

          let notification = {
            type: "success",
            showMessage: true,
            message: self.$notifications().CLIENT_CREATED,
          };
          self.setNotification(notification);

          self.showDialog = false;
          self.disableSaveBtn = false;

          self.$emit("fetchClients");
        })
        .catch((err) => {
          this.showLoader = false;
          this.clientErrorMsg = self.$notifications().CLIENT_ERROR;
          this.showClientErrorMsg = true;
          self.showDialog = true;
          self.disableSaveBtn = false;
          console.log(err);
        });
    },
    editClient() {
      let self = this;
      this.client.name = this.client.name.trim();
      this.client.pseudonym = this.client.pseudonym.trim();
      Api.editClient(this.clientId, this.client)
        .then((response) => {
          this.showLoader = false;
          if (
            response.data.code === "CLIENT_WITH_NAME_ALREADY_EXISTS" ||
            response.data.code === "CLIENT_WITH_PSEUDONYM_ALREADY_EXISTS"
          ) {
            if (response.data.code === "CLIENT_WITH_NAME_ALREADY_EXISTS") {
              this.clientErrorMsg =
                response.data.message + " (" + this.client.name + ")";
            }
            if (response.data.code === "CLIENT_WITH_PSEUDONYM_ALREADY_EXISTS") {
              this.clientErrorMsg =
                response.data.message + " (" + this.client.pseudonym + ")";
            }
            this.showClientErrorMsg = true;
            self.showDialog = true;
            self.disableSaveBtn = false;
            return;
          }

          let notification = {
            type: "success",
            showMessage: true,
            message: self.$notifications().CLIENT_UPDATED,
          };
          self.setNotification(notification);

          self.showDialog = false;
          self.disableSaveBtn = false;

          self.$emit("fetchClients");
        })
        .catch((error) => {
          this.showLoader = false;
          this.clientErrorMsg = self.$notifications().CLIENT_UPDATE_ERROR;
          this.showClientErrorMsg = true;
          self.showDialog = true;
          self.disableSaveBtn = false;
          console.log(error);
        });
    },
    openClientForm: function () {
      this.showLoader = false;
      this.showDialog = true;
      this.showClientErrorMsg = false;
      if (this.openCreateClientForm) {
        this.client = {
          name: null,
          pseudonym: null,
        };
      }
      if (this.openEditClientForm) {
        this.client = {
          name: this.clientName,
          pseudonym: this.clientPseudonym,
        };
      }
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.fade-enter .dialog-main,
.fade-leave-to .dialog-main {
  opacity: 0;
  transform: scale3d(1.05, 1.05, 1.05);
}

.fade-enter .dialog-mask,
.fade-leave-to .dialog-mask {
  opacity: 0;
}

.dialog-wrap,
.dialog-mask {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease;
  z-index: 1000;
}

.dialog-wrap,
.dialog-mask {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.dialog-mask {
  background-color: #000;
  opacity: 0.5;
  z-index: 999;
}

.dialog-main {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  transition: all 0.2s ease;
  z-index: 9999;
}

.main-content {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 20px 10px;
  width: 600px;
}

.main-header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #f0f1f4;
}

.main-header .header_title {
  font-size: 30px;
  flex: 1;
  align-self: center;
  color: #585353;
  font-weight: 500;
  padding: 10px;
  text-align: left;
}

.main-header .close-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8b83ba;
  border-radius: 100px;
  height: 30px;
  width: 30px;
  color: white;
  font-weight: 700;
  margin-top: 13px;
}

.main-body {
  height: auto;
  padding: 40px 10px;
  overflow-y: auto;
}
.create_client_wrapper button.btn {
  padding: 5px 20px;
  background: #205fac;
  border-radius: 4px;
  color: white;
  outline: 0;
  border: 0;
  height: 35px;
  cursor: pointer;
}

.create_client_wrapper label {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
}

.create_client_wrapper .row {
  margin: -20px 0;
}
.notification {
  position: absolute;
}
</style>
