import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import Api from "../services/Api";
import MasterApi from "../services/MasterApi";
import createPersistedState from "vuex-persistedstate";
import sanitize from "../components/sanitizer";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    notification: {},
    contactId: "",
    currentWorkspaceAccount: {},
    currentWorkspaceContact: {},
    workspace: {
      resetContactDisposeForm: true,
      task: {},
      yield: {},
      specifications: [],
      account: {
        segment_technology: [],
        addressHQ: {
          address1HQ: "",
          address2HQ: "",
          cityHQ: "",
          stateHQ: "",
          countryHQ: "",
          zipCodeHQ: "",
        },
        TaskLink: {},
      }, // does not take care of mutiple accounts: account Identification
      contacts: {},
      selectedContact: {
        address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
        previous_address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
      },
      inActionDisposeMenu: "",
      contactCheckResult: {},
      accountCheckResult: {},
      fieldCustomization: {
        accountFields: [],
        contactFields: [],
        contactReadOnlyFields: [],
      },
    },
    agentPreviewFilter: null,
    agentPreviewOptions: {
      itemsPerPage: 40,
    },
    agentTaskListOptions: {
      page: 1,
      itemsPerPage: 40,
    },
    taskFilter: {},
    taskListSort: null,
    taskListpageNumber: 1,
    agentsSelectedProject: {},
    contactSuppressionFlag: false,
    contactReusedFlag: false,
    contactDuplicateFlag: true,
    contactFuzzySuppressedFlag: false,
    zeroBounce: {
      address: "",
      status: "",
      subStatus: "",
      freeEmail: "",
      processedAt: "",
      internalError: "",
    },
    accountDisposeError: {},
    isWorkSpaceLoading: true,
    isContactFormValid: true,
    isEmailValid: false,
    resetGoogleAddressSearch: false,
    template: {
      accountFields: [],
      contactFields: [],
      contactReadOnlyFields: [],
      TaskDownloadCsv: [],
      AccountDownloadCsv: [],
      ContactDownloadCsv: [],
    },
    projectClientId: "",
    jobLevel: [],
    jobDepartment: [],
    // SAMPLE AGENT CONTACT STATE DATA
    // "{projectId}": {
    //   "{accountId}": {
    //     filter: {
    //       selectedJobLevel: "<value>",
    //     },
    //   },
    // },
    agentContactState: {},
  },
  getters: {},
  mutations: {
    changeResetContactDisposeFormValue(state) {
      state.workspace.resetContactDisposeForm =
        !state.workspace.resetContactDisposeForm;
    },
    changeAccountDisposeError(state, payload) {
      state.accountDisposeError = payload.error;
    },
    changeCurrentWorkspaceAccount(state, payload) {
      state.currentWorkspaceAccount = payload;
    },
    changeCurrentWorkspaceContact(state, payload) {
      state.currentWorkspaceContact = payload;
    },
    updateContactCheckResult(state, payload) {
      state.workspace.contactCheckResult = payload.result;
    },
    publishNotification(state, notification) {
      state.notification = notification;
    },
    clearNotification(state) {
      state.notification = {};
    },
    selectWorkspaceContact(state, selectedContact) {
      state.workspace.selectedContact = selectedContact;
    },
    populateWorkspace(state, payload) {
      state.workspace.task = payload.task;
      state.workspace.account = payload.account;
      state.workspace.contacts = payload.contacts;
      state.workspace.yield = payload.yield;
      state.workspace.specifications = payload.specifications;
      state.workspace.fieldCustomization.accountFields = payload.accountFields;
      state.workspace.fieldCustomization.contactFields = payload.contactFields;
      state.workspace.fieldCustomization.contactReadOnlyFields =
        payload.contactReadOnlyFields;
    },
    populateTemplate(state, payload) {
      state.template.accountFields = payload.accountFields;
      state.template.contactFields = payload.contactFields;
      state.template.contactReadOnlyFields = payload.contactReadOnlyFields;
      state.template.TaskDownloadCsv = payload.TaskDownloadCsv;
      state.template.AccountDownloadCsv = payload.AccountDownloadCsv;
      state.template.ContactDownloadCsv = payload.ContactDownloadCsv;
    },
    populateWorkspaceContacts(state, payload) {
      state.workspace.contacts = payload.contacts;
    },
    populateWorkspaceYields(state, payload) {
      state.workspace.yield = payload.yield;
    },
    populateWorkspaceTasks(state, payload) {
      state.workspace.task = payload.task;
    },
    selectTaskFilters(state, filter) {
      state.taskFilter = filter;
    },
    selectSortForTaskList(state, sort) {
      state.taskListSort = sort;
    },
    selectpageNumberForTaskList(state, pageNumber) {
      state.taskListpageNumber = pageNumber;
    },
    selectedAgentProject(state, project) {
      if (state.agentsSelectedProject.id === project.id) {
        return;
      }
      state.taskFilter = {};
      state.taskListSort = null;
      state.taskListpageNumber = 1;
      state.agentsSelectedProject.id = project.id;
      state.agentsSelectedProject.name = project.name;
    },
    updateContactSuppressionFlag(state, flagValue) {
      state.contactSuppressionFlag = flagValue;
    },
    updatecontactFuzzySuppressedFlag(state, flagValue) {
      state.contactFuzzySuppressedFlag = flagValue;
    },
    updateContactReusedFlag(state, flagValue) {
      state.contactReusedFlag = flagValue;
    },
    updateContactDuplicateFlag(state, flagValue) {
      state.contactDuplicateFlag = flagValue;
    },
    populateZeroBounceResult(state, payload) {
      state.zeroBounce.address = payload.address;
      state.zeroBounce.status = payload.status;
      state.zeroBounce.subStatus = payload.sub_status;
      state.zeroBounce.freeEmail = payload.free_email;
      state.zeroBounce.processedAt = payload.processed_at;
    },
    populateZeroBounceResultInternalError(state, payload) {
      state.zeroBounce.internalError = payload.internalError;
    },
    populateIsWorkSpaceLoading(state, flagValue) {
      state.isWorkSpaceLoading = flagValue;
    },
    resetWorkspaceAndloader(state, payload) {
      state.workspace = payload;
      state.isWorkSpaceLoading = true;
    },
    populateIsContactFormValid(state, flagValue) {
      state.isContactFormValid = flagValue;
    },
    updateResetGoogleAddressSearch(state, payload) {
      state.resetGoogleAddressSearch = payload;
    },
    setInActionDisposeMenu(state, payload) {
      state.workspace.inActionDisposeMenu = payload;
    },
    setProjectClientId(state, payload) {
      state.projectClientId = payload;
    },
    setAgentPreviewFilter(state, payload) {
      state.agentPreviewFilter = payload;
    },
    setAgentTaskListOptions(state, payload) {
      state.agentTaskListOptions = payload;
    },
    setAgentPreviewOptions(state, payload) {
      state.agentPreviewOptions = payload;
    },
    populateWorkspaceAccount(state, account) {
      var taskLink = state.workspace.account.TaskLink || {};
      if (!account.TaskLink || !Object.keys(taskLink).length) {
        account.TaskLink = taskLink;
      }
      state.workspace.account = account;
    },
    updateAccountCheckResult(state, payload) {
      state.workspace.accountCheckResult = payload;
    },
    populateWorkspaceAccountFromMaster(state, masterAccount) {
      var taskLink = state.workspace.account.TaskLink || {};
      if (!masterAccount.TaskLink || !Object.keys(taskLink).length) {
        masterAccount.TaskLink = taskLink;
      }

      state.workspace.account = _.cloneDeep(
        Object.assign(state.workspace.account, masterAccount)
      );
    },
    populateMasterJobLevel(state, payload) {
      state.jobLevel = payload;
    },
    populateMasterJobDepartment(state, payload) {
      state.jobDepartment = payload;
    },
    setAgentContactState(state, payload) {
      state.agentContactState = payload;
    },
  },
  actions: {
    async fetchWorkspaceData(context, payload) {
      var notification;
      let pageSize = 1000;

      try {
        var taskId = payload.taskId;
        context.dispatch("checkDataAvailablity", taskId);

        var fetchProjectId = payload.projectId;
        context.dispatch("checkDataAvailablity", fetchProjectId);

        var taskLists = await Api.getTaskById(taskId, fetchProjectId);
        if (!taskLists.data.length) {
          notification = {
            type: "error",
            showMessage: true,
            message: "Current Task Is Not Assigned To Current User",
          };
          context.commit("publishNotification", notification);
          return;
        }

        var task = await _.map(taskLists.data, function (task) {
          if (task.id === taskId) {
            return {
              data: task,
            };
          }
        });
        task = task[0];
        if (payload.fetchOnlyTask) {
          context.commit("populateWorkspaceTasks", {
            task: sanitize(task.data),
          });
          return;
        }
        // task data will have input accounts and contacts
        var projectId = task.data.Project.id;
        context.dispatch("checkDataAvailablity", projectId);

        var accountObj = await _.map(task.data.Accounts, function (account) {
          if (account.TaskLink.linkType === "input") {
            return account;
          }
        });
        var accountId = accountObj[0].id;
        context.dispatch("checkDataAvailablity", accountId);

        var contacts = await Api.getContactsOfAccount(projectId, accountId, {
          pageSize,
        });

        contacts.data.docs = _.map(contacts.data.docs, function (item) {
          return _.merge(item, _.find(task.data.Contacts, { id: item.id }));
        });

        contacts.data.docs.sort(function (a, b) {
          console.log(b.disposition);
          if (
            !a.disposition &&
            a.label !== "suppressed" &&
            a.label !== "Exact Suppressed" &&
            a.label !== "duplicate" &&
            a.label !== "Fuzzy Suppressed"
          ) {
            return 1 * -1;
          } else {
            return -1 * -1;
          }
        });

        var account = await Api.getAccountById(projectId, accountId);
        const { parentDomain } = account.data;

        delete account.data.parentDomain;
        account.data.parentWebsite = parentDomain;
        context.dispatch("updateCurrentWorkspaceAccount", account.data);

        var accountItem = task.data.Accounts.find(
          (item) => item.id === accountId
        );
        if (accountItem) {
          account.data.TaskLink = accountItem.TaskLink;
        }

        var specifications = await Api.getSpecs(projectId);
        if (!specifications || !specifications.data.length) {
          specifications.data.push("N/A");
        }

        var yields = await context.dispatch(
          "preProcessForGettingAgentsLiveCounts",
          {
            projectId: projectId,
            accountId: accountId,
          }
        );

        // GetTemplates
        let AgentContactWorkspace, AgentAccountWorkspace, AgentContactReadOnly;
        let fieldCustomization = await Api.getTemplates(projectId);

        //ContactForm
        if (fieldCustomization.data.config.forms.AgentContactWorkspace.config) {
          AgentContactWorkspace =
            fieldCustomization.data.config.forms.AgentContactWorkspace.config;
        }
        //AccountForm
        if (fieldCustomization.data.config.forms.AgentAccountWorkspace.config) {
          AgentAccountWorkspace =
            fieldCustomization.data.config.forms.AgentAccountWorkspace.config;
        }
        //AgentContactReadOnly
        if (fieldCustomization.data.config.forms.AgentContactReadOnly.config) {
          AgentContactReadOnly =
            fieldCustomization.data.config.forms.AgentContactReadOnly.config;
        }

        if (!account.data.addressHQ) {
          account.data.addressHQ = {
            address1HQ: "",
            address2HQ: "",
            cityHQ: "",
            stateHQ: "",
            countryHQ: "",
            zipCodeHQ: "",
          };
        }

        var _payload = {
          task: sanitize(task.data),
          yield: sanitize(yields.data),
          specifications: sanitize(specifications.data),
          account: sanitize(account.data),
          contacts: sanitize(contacts.data),
          accountFields: sanitize(AgentAccountWorkspace),
          contactFields: sanitize(AgentContactWorkspace),
          contactReadOnlyFields: sanitize(AgentContactReadOnly),
        };

        context.commit("populateWorkspace", _payload);
        context.commit("populateIsWorkSpaceLoading", false);
      } catch (error) {
        notification = {
          type: "error",
          showMessage: true,
          message: "Internal Error",
        };
        context.commit("publishNotification", notification);
      }
    },
    async setTemplateData(context, payload) {
      var AgentContactWorkspace,
        AgentAccountWorkspace,
        AgentContactReadOnly,
        TaskDownloadCsv,
        AccountDownloadCsv,
        ContactDownloadCsv;

      AgentContactWorkspace = payload.config.forms.AgentContactWorkspace.config;
      AgentAccountWorkspace = payload.config.forms.AgentAccountWorkspace.config;
      AgentContactReadOnly = payload.config.forms.AgentContactReadOnly.config;
      TaskDownloadCsv = payload.config.csv.TaskDownload.config;
      AccountDownloadCsv = payload.config.csv.AccountDownload.config;
      ContactDownloadCsv = payload.config.csv.ContactDownload.config;

      var _payload = {
        accountFields: sanitize(AgentAccountWorkspace),
        contactFields: sanitize(AgentContactWorkspace),
        contactReadOnlyFields: sanitize(AgentContactReadOnly),
        TaskDownloadCsv: sanitize(TaskDownloadCsv),
        AccountDownloadCsv: sanitize(AccountDownloadCsv),
        ContactDownloadCsv: sanitize(ContactDownloadCsv),
      };

      context.commit("populateTemplate", _payload);
    },
    checkDataAvailablity(context, data) {
      if (!data) {
        throw new Error();
      }
    },
    async fetchSelectedWorspaceContactData(context, payload) {
      try {
        var selectedContact = payload.selectContact;
        if (!selectedContact.address) {
          selectedContact.address = {
            street1: "",
            street2: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
          };
        }
        if (
          !selectedContact.address.street1 &&
          selectedContact.address.address1
        ) {
          selectedContact.address.street1 = selectedContact.address.address1;
        }
        if (
          !selectedContact.address.street2 &&
          selectedContact.address.address2
        ) {
          selectedContact.address.street2 = selectedContact.address.address2;
        }
        if (!selectedContact.previous_address) {
          selectedContact.previous_address = {
            street1: "",
            street2: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
          };
        }
        if (
          !selectedContact.previous_address.street1 &&
          selectedContact.previous_address.address1
        ) {
          selectedContact.previous_address.street1 =
            selectedContact.previous_address.address1;
        }
        if (
          !selectedContact.previous_address.street2 &&
          selectedContact.previous_address.address2
        ) {
          selectedContact.previous_address.street2 =
            selectedContact.previous_address.address2;
        }
        if (selectedContact.label === "duplicate") {
          context.commit("updateContactDuplicateFlag", true);
        } else {
          context.commit("updateContactDuplicateFlag", false);
        }
        if (
          selectedContact.label === "suppressed" ||
          selectedContact.label === "Exact Suppressed"
        ) {
          context.commit("updateContactSuppressionFlag", true);
        } else {
          context.commit("updateContactSuppressionFlag", false);
        }
        if (selectedContact.label === "Fuzzy Suppressed") {
          context.commit("updatecontactFuzzySuppressedFlag", true);
        } else {
          context.commit("updatecontactFuzzySuppressedFlag", false);
        }
        if (selectedContact.label && selectedContact.label.match(/^Within/)) {
          context.commit("updateContactReusedFlag", true);
        } else {
          context.commit("updateContactReusedFlag", false);
        }

        context.commit(
          "selectWorkspaceContact",
          Object.assign({}, selectedContact)
        );
        context.dispatch("updateCurrentWorkspaceContact", selectedContact);
      } catch (error) {
        var notification = {
          type: "error",
          showMessage: true,
          message: "Could Not Get Contact",
        };
        context.commit("publishNotification", notification);
      }
    },
    async saveContact(context, payload) {
      var notification;
      var disposeContact;
      let pageSize = 1000;

      try {
        var formContact = payload.formContact;
        context.dispatch("checkDataAvailablity", formContact);

        var projectId = payload.projectId;
        context.dispatch("checkDataAvailablity", projectId);

        var taskId = payload.taskId;
        context.dispatch("checkDataAvailablity", taskId);

        var accountId = payload.accountId;
        context.dispatch("checkDataAvailablity", accountId);

        disposeContact = payload.disposeContact;

        var taskLink = payload.taskLink;

        if (disposeContact) {
          context.dispatch("checkDataAvailablity", taskLink);
        }

        var clientId = _.get(this.state.workspace, "task.Project.ClientId", "");
        context.dispatch("checkDataAvailablity", clientId);

        let contactExpiry = _.get(
          this.state.workspace,
          "task.Project.contactExpiry",
          ""
        );

        var contactSaveResponse = await Api.saveContact(
          projectId,
          accountId,
          formContact,
          disposeContact,
          taskLink,
          contactExpiry,
          clientId
        );
        var contactId = contactSaveResponse.data.contactId;

        var taskLists = await Api.getTaskById(taskId, projectId);
        var task = await _.map(taskLists.data, function (task) {
          if (task.id === taskId) {
            return {
              data: task,
            };
          }
        });
        task = task[0];

        var contact = await Api.getContactById(projectId, accountId, contactId);

        var contactItem = task.data.Contacts.find(
          (item) => item.id === contactId
        );
        if (contactItem) {
          contact.data.TaskLink = contactItem.TaskLink;
        }

        context.dispatch("fetchSelectedWorspaceContactData", {
          selectContact: contact.data,
        });

        var contacts = await Api.getContactsOfAccount(projectId, accountId, {
          pageSize,
        });

        contacts.data.docs = _.map(contacts.data.docs, function (item) {
          return _.merge(item, _.find(task.data.Contacts, { id: item.id }));
        });

        contacts.data.docs.sort(function (a, b) {
          console.log(b.disposition);
          if (
            !a.disposition &&
            a.label !== "suppressed" &&
            a.label !== "Exact Suppressed" &&
            a.label !== "duplicate" &&
            a.label !== "Fuzzy Suppressed"
          ) {
            return 1 * -1;
          } else {
            return -1 * -1;
          }
        });

        var _payload = {
          contacts: contacts.data,
        };
        context.commit("populateWorkspaceContacts", _payload);
        if (disposeContact) {
          notification = {
            type: "success",
            showMessage: true,
            message: "Contact Disposed Successfully",
          };
        } else {
          notification = {
            type: "success",
            showMessage: true,
            message: "Contact Saved Successfully",
          };
        }
        context.commit("publishNotification", notification);
        if (disposeContact) {
          var yields = await context.dispatch(
            "preProcessForGettingAgentsLiveCounts",
            {
              projectId: projectId,
              accountId: accountId,
            }
          );
          context.commit("populateWorkspaceYields", {
            yield: sanitize(yields.data),
          });
          context.dispatch("setResetGoogleAddressSearchValue", true);
        }
      } catch (error) {
        if (disposeContact) {
          notification = {
            type: "error",
            showMessage: true,
            message: "Could Not Dispose Contact",
          };
        } else {
          notification = {
            type: "error",
            showMessage: true,
            message: "Could Not Save Contact",
          };
        }
        context.commit("publishNotification", notification);
      }

      if (disposeContact) {
        const changedContactData = payload.changedContactData;
        try {
          var contactPayload = payload.formContact;
          var accountDomain = payload.accountDomain;

          await MasterApi.saveVerifyContact(
            contactPayload,
            changedContactData,
            accountDomain
          );
        } catch (error) {
          console.error("Save Verify Contact failed", error);
        }
      }
    },
    async saveAccount(context, payload) {
      try {
        var accountSaveResponse = await Api.saveAccount(
          payload.projectId,
          payload.accountId,
          payload.account
        );

        if (!accountSaveResponse) return;

        var notification;
        // populate workspace account
        context.commit(
          "populateWorkspaceAccount",
          accountSaveResponse.data.account
        );

        //populate account check result
        context.commit(
          "updateAccountCheckResult",
          accountSaveResponse.data.checkResult
        );

        payload.contacts.docs = _.map(payload.contacts.docs, function (item) {
          item.Account = _.merge(
            item.Account,
            accountSaveResponse.data.account
          );
          return item;
        });
        context.commit("populateWorkspaceContacts", {
          contacts: sanitize(payload.contacts),
        });
        payload.selectedContact.Account = _.merge(
          payload.selectedContact.Account,
          accountSaveResponse.data.account
        );
        context.commit(
          "selectWorkspaceContact",
          Object.assign({}, payload.selectedContact)
        );
        await context.dispatch("fetchWorkspaceData", {
          projectId: payload.projectId,
          taskId: payload.taskId,
          fetchOnlyTask: true,
        });
        notification = {
          type: "success",
          showMessage: true,
          message: "Account Saved Successfully",
        };
        context.commit("publishNotification", notification);
      } catch (error) {
        let notification = {
          type: "error",
          showMessage: true,
          message: "Could Not Save Account",
        };
        context.commit("publishNotification", notification);
        return;
      }
    },
    async disposeAccount(context, payload) {
      var notification;
      var isDisposeAccount;

      try {
        var _disposeAccount = payload.disposeAccount;
        context.dispatch("checkDataAvailablity", _disposeAccount);

        var projectId = payload.projectId;
        context.dispatch("checkDataAvailablity", projectId);

        isDisposeAccount = payload.isDisposeAccount;

        var taskLink = payload.taskLink;
        if (isDisposeAccount) {
          context.dispatch("checkDataAvailablity", taskLink);
        }

        await Api.disposeAccount(projectId, _disposeAccount, taskLink);
        var yields = await context.dispatch(
          "preProcessForGettingAgentsLiveCounts",
          {
            projectId: projectId,
            accountId: _disposeAccount.id,
          }
        );
        context.commit("populateWorkspaceYields", {
          yield: sanitize(yields.data),
        });

        if (isDisposeAccount) {
          notification = {
            type: "success",
            showMessage: true,
            message: "Account Disposed Successfully",
          };
        } else {
          notification = {
            type: "success",
            showMessage: true,
            message: "Account Created Successfully",
          };
        }
        context.commit("publishNotification", notification);
      } catch (error) {
        if (isDisposeAccount) {
          notification = {
            type: "error",
            showMessage: true,
            message: "Could Not Dispose Account",
          };
        } else {
          notification = {
            type: "error",
            showMessage: true,
            message: "Could Not Save Account",
          };
        }
        throw error;
      }

      const changedAccountData = payload.changedAccountData;
      try {
        var accountPayload = payload.accountPayload;

        let response = await MasterApi.saveVerifyAccount(
          accountPayload,
          changedAccountData
        );
        const now = new Date();
        if (response.data.updateDate) {
          await Api.saveAccount(projectId, _disposeAccount.id, {
            masterUpdatedAt: now,
            type: _disposeAccount.type,
          });
        }
        return;
      } catch (error) {
        console.error("Save Verify Account failed", error);
      }
    },
    async contactCheck(context, payload) {
      var notification;
      try {
        var formContact = payload.formContact;
        context.dispatch("checkDataAvailablity", formContact);

        var projectId = payload.projectId;
        context.dispatch("checkDataAvailablity", projectId);

        var accountName = payload.accountName;
        formContact.companyName = accountName;

        var contactCheckResult = {
          isSuppressed: false,
        };

        contactCheckResult = await Api.contactCheck(projectId, formContact);
        contactCheckResult = contactCheckResult.data;

        switch (contactCheckResult.matchType) {
          // Case 1 : Contact is Suppressed
          case "Exact Suppressed":
            formContact.label = "suppressed";
            context.dispatch("fetchSelectedWorspaceContactData", {
              selectContact: formContact,
            });

            context.dispatch("populateContactCheckResult", {
              result: contactCheckResult,
            });

            context.commit("updateContactSuppressionFlag", true);
            break;

          // Case 2 : Contact is duplicate
          case "duplicate":
            formContact.label = "duplicate";
            context.dispatch("fetchSelectedWorspaceContactData", {
              selectContact: formContact,
            });
            context.dispatch("populateContactCheckResult", {
              result: contactCheckResult,
            });
            context.commit("updateContactDuplicateFlag", true);
            break;

          // Case 3 : Contact is FuzzySuppressed
          case "Fuzzy Suppressed":
            formContact.label = "Fuzzy Suppressed";
            context.dispatch("fetchSelectedWorspaceContactData", {
              selectContact: formContact,
            });
            context.dispatch("populateContactCheckResult", {
              result: contactCheckResult,
            });
            context.commit("updatecontactFuzzySuppressedFlag", true);
            if (payload.checkReusable) {
              payload.formContact = formContact;
              await context.dispatch("checkReusable", payload);
            }
            break;

          // Default case : Contact is not suppressed nor duplicate
          default:
            formContact.label = "inclusion";
            context.dispatch("fetchSelectedWorspaceContactData", {
              selectContact: formContact,
            });
            context.commit("updateContactDuplicateFlag", false);
            notification = {
              type: "success",
              showMessage: true,
              message: "Contact Is Not Suppressed And Not Duplicate",
            };
            context.commit("publishNotification", notification);
            if (payload.checkReusable) {
              await context.dispatch("checkReusable", payload);
            }
            break;
        }
        return contactCheckResult;
      } catch (error) {
        console.log(error);
        notification = {
          type: "error",
          showMessage: true,
          message: "Could Not Check Suppression For Contact",
        };
        context.commit("publishNotification", notification);
        return false;
      }
    },
    async checkReusable(context, payload) {
      var notification;
      try {
        var formContact = payload.formContact;
        context.dispatch("checkDataAvailablity", formContact);

        var clientId = _.get(this.state.workspace, "task.Project.ClientId", "");
        context.dispatch("checkDataAvailablity", clientId);

        var templateId = _.get(
          this.state.workspace,
          "task.Project.TemplateId",
          ""
        );
        context.dispatch("checkDataAvailablity", templateId);

        var projectId = payload.projectId;
        context.dispatch("checkDataAvailablity", projectId);

        var accountName = payload.accountName;
        var accountDomain = payload.accountDomain;

        let contactExpiry = _.get(
          this.state.workspace,
          "task.Project.contactExpiry",
          ""
        );

        let finalPayload = {
          id: formContact.id,
          firstName: formContact.firstName,
          lastName: formContact.lastName,
          email: formContact.email,
          companyName: accountName,
          companyDomain: accountDomain,
          projectId,
          templateId,
          contactExpiry,
        };

        let reusableResult = await Api.checkReusable(clientId, finalPayload);
        reusableResult = reusableResult.data;

        var selectedContact = _.get(
          this.state.workspace,
          "selectedContact",
          {}
        );

        if (_.isEmpty(reusableResult.matchWith)) {
          return;
        }
        formContact = reusableResult.matchWith;
        if (selectedContact.id) {
          formContact.id = selectedContact.id;
        }
        if (reusableResult.matchType) {
          if (!selectedContact.label || selectedContact.label === "inclusion") {
            formContact.label = reusableResult.matchType;
            context.commit("updateContactReusedFlag", true);
          } else {
            formContact.label = selectedContact.label;
          }
          formContact.researchStatus = "QR";
        } else {
          formContact.label = selectedContact.label;
        }

        context.dispatch("fetchSelectedWorspaceContactData", {
          selectContact: formContact,
        });
      } catch (error) {
        console.log(error);
        notification = {
          type: "error",
          showMessage: true,
          message: "Could Not Check Reusable For Contact",
        };
        context.commit("publishNotification", notification);
      }
    },
    async selectTaskListFilter(context, payload) {
      context.commit("selectTaskFilters", payload.filter);
    },
    async selectTaskListSort(context, payload) {
      context.commit("selectSortForTaskList", payload.sort);
    },
    async selectTaskListpageNumber(context, payload) {
      context.commit("selectpageNumberForTaskList", payload.pageNumber);
    },
    async selectProjectForAgent(context, payload) {
      context.commit("selectedAgentProject", payload);
    },
    async checkZeroBounce(context, payload) {
      try {
        var taskId = payload.taskId;
        context.dispatch("checkDataAvailablity", taskId);

        var emailId = payload.emailId;
        context.dispatch("checkDataAvailablity", emailId);

        var formContact = payload.formContact;

        var selectedContact = _.get(
          this.state.workspace,
          "selectedContact",
          {}
        );
        var selectedContactLabel = _.get(selectedContact, "label", "");

        let allowZb = true;
        switch (selectedContactLabel) {
          case "Fuzzy Suppressed":
            allowZb = selectedContact.researchStatus === "QR" ? false : true;
            break;
          case "duplicate":
          case "suppressed":
          case selectedContactLabel.match(/^Within/)?.input:
            allowZb = false;
            break;
          default:
            allowZb = true;
            break;
        }

        if (!allowZb) {
          return;
        }

        var zbResult = await Api.checkZeroBounce(taskId, emailId);
        formContact.zb = zbResult.data.status;
        formContact.zbDateAndTime = zbResult.data.processed_at;

        if (zbResult.data.status !== "invalid") {
          var verifiedData = {};
          verifiedData.type = "ZB";
          verifiedData.lastVerifiedDateAndTime =
            zbResult.data.processed_at || "";

          try {
            await Api.addDynamicEmailPattern(
              emailId,
              formContact.firstName,
              formContact.middleName,
              formContact.lastName,
              verifiedData
            );
          } catch (error) {
            console.log("Dynamic Email Pattern is not Saved");
            console.log(error);
          }
        }

        context.dispatch("fetchSelectedWorspaceContactData", {
          selectContact: formContact,
        });
        context.commit("populateZeroBounceResult", zbResult.data);
        context.commit("populateZeroBounceResultInternalError", {});
      } catch (error) {
        var _payload = {
          internalError: "Could Not Check ZeroBounce !! Please Try Again Later",
        };
        context.commit("populateZeroBounceResult", {});
        context.commit("populateZeroBounceResultInternalError", _payload);
        var notification = {
          type: "error",
          showMessage: true,
          message: "Could Not Check ZeroBounce",
        };
        context.commit("publishNotification", notification);
      }
    },
    async populateContactLists(context, payload) {
      let pageSize = 1000;
      try {
        var taskId = payload.taskId;
        context.dispatch("checkDataAvailablity", taskId);
        var fetchProjectId = payload.projectId;
        context.dispatch("checkDataAvailablity", fetchProjectId);

        var taskLists = await Api.getTaskById(taskId, fetchProjectId);
        var task = await _.map(taskLists.data, function (task) {
          if (task.id === taskId) {
            return {
              data: task,
            };
          }
        });
        task = task[0];

        var accountObj = await _.map(task.data.Accounts, function (account) {
          if (account.TaskLink.linkType === "input") {
            return account;
          }
        });
        var accountId = accountObj[0].id;

        context.dispatch("checkDataAvailablity", accountId);

        var contacts = await Api.getContactsOfAccount(
          fetchProjectId,
          accountId,
          { pageSize }
        );

        contacts.data.docs = _.map(contacts.data.docs, function (item) {
          return _.merge(item, _.find(task.data.Contacts, { id: item.id }));
        });
        contacts.data.docs.sort(function (a, b) {
          console.log(b.disposition);
          if (
            !a.disposition &&
            a.label !== "suppressed" &&
            a.label !== "Exact Suppressed" &&
            a.label !== "duplicate" &&
            a.label !== "Fuzzy Suppressed"
          ) {
            return 1 * -1;
          } else {
            return -1 * -1;
          }
        });

        var _payload = {
          contacts: contacts.data,
        };
        context.commit("populateWorkspaceContacts", _payload);
      } catch (error) {
        var notification = {
          type: "error",
          showMessage: true,
          message: "Could Not Populate Contact List",
        };
        context.commit("publishNotification", notification);
      }
    },
    async resetZbResult(context, payload) {
      context.commit("populateZeroBounceResult", payload);
      context.commit("populateZeroBounceResultInternalError", payload);
    },
    async updateZeroBounceResult(context, payload) {
      context.commit("populateZeroBounceResult", payload);
    },
    async updateCurrentWorkspaceAccount(context, payload) {
      context.commit("changeCurrentWorkspaceAccount", payload);
    },
    async updateCurrentWorkspaceContact(context, payload) {
      context.commit("changeCurrentWorkspaceContact", payload);
    },
    async populateAccountDisposeError(context, payload) {
      context.commit("changeAccountDisposeError", payload);
    },
    async populateContactCheckResult(context, payload) {
      context.commit("updateContactCheckResult", payload);
    },
    async resetWorkspace(context, payload) {
      context.commit("resetWorkspaceAndloader", payload);
    },
    async setIsContactFormValid(context, payload) {
      context.commit("populateIsContactFormValid", payload);
    },
    async setResetGoogleAddressSearchValue(context, payload) {
      context.commit("updateResetGoogleAddressSearch", payload);
    },
    async setContactSuppressionFlag(context, payload) {
      context.commit("updateContactSuppressionFlag", payload);
    },
    async setFuzzyContactSuppressionFlag(context, payload) {
      context.commit("updateContactSuppressionFlag", payload);
    },
    async setContactDuplicateFlag(context, payload) {
      context.commit("updateContactDuplicateFlag", payload);
    },
    async setInActionDisposeMenu(context, payload) {
      context.commit("setInActionDisposeMenu", payload);
    },
    async preProcessForGettingAgentsLiveCounts(context, payload) {
      var todayDate = new Date();
      var projectId = payload.projectId;
      var accountId = payload.accountId;
      todayDate =
        todayDate.getMonth() +
        1 +
        "/" +
        todayDate.getDate() +
        "/" +
        todayDate.getFullYear();
      var filter = {};
      filter.updatedAt = [todayDate, todayDate];

      var yields = await Api.getTasksLiveCountsForAgent({
        filter: JSON.stringify(filter),
        projectId: projectId,
        accountId: accountId,
        countsToCalculate: ["contacts", "accounts"],
      });

      var projectLevelYields = await Api.getSettings(projectId);

      yields.data.positiveDisposedContactsCounts = yields.data
        .positiveDisposedContactsCounts
        ? yields.data.positiveDisposedContactsCounts
        : 0;
      yields.data.positiveDisposedContactsOverallCounts = yields.data
        .positiveDisposedContactsOverallCounts
        ? yields.data.positiveDisposedContactsOverallCounts
        : 0;
      yields.data.disposedAccountCounts = yields.data.disposedAccountCounts
        ? yields.data.disposedAccountCounts
        : 0;
      yields.data.disposedAccountOverallCounts = yields.data
        .disposedAccountOverallCounts
        ? yields.data.disposedAccountOverallCounts
        : 0;
      yields.data.negativeDisposedAccountCounts = yields.data
        .negativeDisposedAccountCounts
        ? yields.data.negativeDisposedAccountCounts
        : 0;
      yields.data.negativeDisposedAccountOverallCounts = yields.data
        .negativeDisposedAccountOverallCounts
        ? yields.data.negativeDisposedAccountOverallCounts
        : 0;
      yields.data.positiveDisposedContactsCountsOfAnAccount = yields.data
        .positiveDisposedContactsCountsOfAnAccount
        ? yields.data.positiveDisposedContactsCountsOfAnAccount
        : 0;

      yields.data.accountTarget = projectLevelYields.data.target
        ? projectLevelYields.data.target.accountTarget
          ? projectLevelYields.data.target.accountTarget
          : 0
        : 0;
      yields.data.contactTarget = projectLevelYields.data.target
        ? projectLevelYields.data.target.contactTarget
          ? projectLevelYields.data.target.contactTarget
          : 0
        : 0;
      yields.data.contactsPerAccount = projectLevelYields.data
        .contactsPerAccount
        ? projectLevelYields.data.contactsPerAccount
          ? projectLevelYields.data.contactsPerAccount
          : 0
        : 0;

      return yields;
    },
    async setProjectClientId(context, payload) {
      context.commit("setProjectClientId", payload);
    },
    async populateAccountCheckResult(context, payload) {
      if (!payload) {
        return;
      }

      try {
        let projectId = payload.projectId;
        let account = payload.account;
        let params = { checkSuppression: true, checkDuplicate: true };

        let response = await Api.checkAccount(projectId, account, params);

        context.commit("updateAccountCheckResult", response.data);
      } catch (error) {
        var notification = {
          type: "error",
          showMessage: true,
          message: "Error While Checking Account Check",
        };
        context.commit("publishNotification", notification);
      }
    },
    async fetchMasterAccountByDomain(context, payload) {
      var notification;
      try {
        let domain = payload.domain;
        context.dispatch("checkDataAvailablity", domain);

        let accountId = payload.accountId;
        context.dispatch("checkDataAvailablity", accountId);

        let response = await MasterApi.fetchAccount(domain);
        const masterAccount = response.data;
        if (masterAccount) {
          notification = {
            type: "success",
            showMessage: true,
            message: "Account Fetched",
          };
          masterAccount.id = accountId;
          masterAccount.emailDomain = domain;
          context.commit("populateWorkspaceAccountFromMaster", masterAccount);
        } else {
          notification = {
            type: "warning",
            showMessage: true,
            message: "No Account Data Found !",
          };
        }
        context.commit("publishNotification", notification);
      } catch (error) {
        notification = {
          type: "error",
          showMessage: true,
          message: "Can Not Fetch Account Data",
        };
        context.commit("publishNotification", notification);
        console.log(error);
      }
    },
    async fetchMasterJobLevels(context) {
      try {
        let response = await MasterApi.getJobLevels();
        const jobLevel = response.data;

        if (jobLevel) {
          context.commit("populateMasterJobLevel", jobLevel);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchMasterJobDepartments(context) {
      try {
        let response = await MasterApi.getJobDepartments();
        const jobDepartments = response.data;

        if (jobDepartments) {
          context.commit("populateMasterJobDepartment", jobDepartments);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async setAgentContactStateAction(context, payload) {
      if (typeof payload === "object") {
        context.commit("setAgentContactState", payload);
      }
    },
  },
  plugins: [createPersistedState()],
});
