<template>
  <div class="worspace_wrapper">
    <v-overlay :value="isWorkSpaceLoading" opacity="1">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <div class="topbar">
      <taskStats />
      <shortKeyMenu />
    </div>

    <div class="task_detail">
      <TaskMiniView
        @checkAccountDisposition="displayAccountDisposeWarning($event)"
      />
    </div>

    <div>
      <v-dialog v-model="isAccountDisposeWarningDialog" max-width="440">
        <v-card>
          <v-card-title class="text-h5"> Account Is Not Disposed </v-card-title>

          <v-card-text>Are you sure you want to go back ?</v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="isAccountDisposeWarningDialog = false"
            >
              No
            </v-btn>

            <v-btn color="green darken-1" text @click="pushRouter()">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <div class="workspace">
      <v-row>
        <div class="header_nav">
          <div class="breadcrumb">
            <div class="header">
              <v-row class="mb-1 px-4">
                <div class="mb-1 mt-3 pl-3" style="height: 50px">
                  <!-- Need to change the onError image -->
                  <img
                    :src="clearBitLogo"
                    style="max-width: 100%; max-height: 50px"
                    onerror="this.src='http://static1.squarespace.com/static/56fc1937c6fc08ffa7bc857a/591280c5579fb3effabdc016/59238e2f9f74568c561ae325/1503633039209/ThumbnailImage.png?format=1500w'"
                  />
                </div>

                <h1 class="my-0 mx-3" style="position: relative">
                  <a
                    :href="searchAccountNameUrl"
                    target="_blank"
                    @click="searchByAccountName(accountName)"
                    >{{ accountName | checkEmpty | markEmpty }}</a
                  >
                  <span
                    v-if="accountLastUpdatedAt"
                    style="
                      font-size: 11px;
                      color: darkgoldenrod;
                      position: absolute;
                      bottom: 0px;
                      left: 0;
                      font-family: 'Lato';
                      width: 400px;
                    "
                    >Last Modified: {{ accountLastUpdatedAt }} ago</span
                  >

                  <v-icon
                    style="margin-left: 10px"
                    @click="copyToClipBoard(accountName)"
                    >content_copy</v-icon
                  >

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-badge
                          class="account_disposed_badge"
                          color="success"
                          inline
                          :content="accountDispositionName"
                          v-if="accountDispositionName"
                        ></v-badge>
                      </span>
                    </template>
                    <span> <h4>Disposed</h4></span>
                  </v-tooltip>
                </h1>

                <ContactMasterSuggestions ref="SuggestionsRef" />

                <AccountDispose
                  ref="AccountDisposeRef"
                  :changedAccountData="changedAccountData"
                  @disposition="getDispostion($event)"
                  style="float: right"
                />

                <AccountDisposeError />

                <ContactCheck />
              </v-row>
            </div>
          </div>

          <div class="worspace_tabs">
            <div class="tabs">
              <ul v-if="minimized">
                <li :class="{ 'is-active': 'contact' == showTab }">
                  <a @click="switchTab('contact')">
                    <span>Contact</span>
                  </a>
                </li>
                <li :class="{ 'is-active': 'detail' == showTab }">
                  <a @click="editAccount()">
                    <span>Account</span>
                  </a>
                </li>
              </ul>

              <hr />

              <div class="tab-details">
                <div v-show="'contact' == showTab">
                  <div class="account_banner" v-if="minimized">
                    <v-btn
                      fab
                      small
                      style="color: #8b83ba"
                      class="ml-4 float-right editAccount"
                      color="white"
                      @click="editAccount()"
                      depressed
                      v-shortkey.once="['ctrl', 'alt', 'j']"
                      @shortkey="debounceOnShortKey()"
                    >
                      <span class="material-icons"> edit </span>
                    </v-btn>
                    <accountMiniView
                      v-if="workspace.task.id"
                      v-on:clearBitLogo="getClearBitLogoInfo($event)"
                    />
                  </div>
                  <hr v-if="minimized" />
                  <v-row>
                    <v-col
                      cols="12"
                      :md="contactFormLayout.list"
                      class="mt-3"
                      style="border-right: 1px solid #eee; position: relative"
                    >
                      <contactList v-if="projectId && accountId" />
                    </v-col>
                    <v-col
                      cols="12"
                      :md="contactFormLayout.form"
                      style="position: relative"
                      class="pt-0 mt-3 gradient_bg"
                    >
                      <v-btn
                        icon
                        class="expand_account"
                        v-bind:class="{ shift: minimized }"
                        style="background: white; border: 1px solid #ddd"
                      >
                        <v-icon
                          v-if="minimized"
                          @click="toggleAccountMiniView()"
                          >mdi-chevron-up</v-icon
                        >
                        <v-icon v-else @click="toggleAccountMiniView()"
                          >mdi-chevron-down</v-icon
                        >
                      </v-btn>

                      <div
                        class="action_btn float-left col-md-12 pa-0"
                        style="display: block; padding: 10px !important"
                      >
                        <!-- create new & edit button -->
                        <template>
                          <!-- createNew -->
                          <v-btn
                            class="float-left"
                            depressed
                            color="primary"
                            dark
                            @click="resetContactForm(false, true)"
                            v-shortkey.once="['ctrl', 'alt', 'n']"
                            @shortkey="
                              contactCreateNewDebounceOnShortKey('createNew')
                            "
                          >
                            Create New <v-icon right>add</v-icon>
                          </v-btn>

                          <!-- Edit -->
                          <v-btn
                            v-show="showEditButton"
                            color="primary"
                            class="float-right"
                            dark
                            @click="editSelectedContact()"
                          >
                            Edit <v-icon right>edit</v-icon>
                          </v-btn>
                        </template>

                        <!-- Check button -->
                        <v-btn
                          class="float-right mr-4"
                          color="primary"
                          ref="checkBtn"
                          v-shortkey.once="['ctrl', 'alt', ',']"
                          depressed
                          :loading="isChecking"
                          :disabled="isChecking"
                          @click="checkDupSup()"
                          @shortkey="contactCheckDebounceOnShortKey('check')"
                        >
                          Check <v-icon right>find_in_page</v-icon>
                        </v-btn>

                        <!-- contact form full screen -->
                        <contactFormFullscreen
                          :isNonEditable="isNonEditable"
                          @isNonEditabeUpdate="updateIsNonEditable()"
                          ref="contactFormFullscreenRef"
                          v-if="projectId && accountId"
                        />
                      </div>

                      <contactFormReadOnly
                        :isChecking="isChecking"
                        @updateIsChecking="updateIsChecking"
                        ref="contactFormReadOnlyRef"
                        :formMinimized="minimized"
                        v-if="projectId && accountId"
                      />
                    </v-col>
                  </v-row>
                </div>
                <div v-show="'detail' == showTab">
                  <div class="action_btn" style="display: block">
                    <!-- Save Account Button -->
                    <v-btn
                      class="float-right mr-8 mb-3"
                      color="primary"
                      ref="saveAccountBtn"
                      :loading="isSavingAccount"
                      :disabled="isSavingAccount"
                      depressed
                      @click="saveAccount()"
                      v-shortkey.once="['ctrl', 'alt', 'k']"
                      @shortkey="accountDebounceOnShortKey('save')"
                    >
                      Save
                    </v-btn>

                    <!-- Check Account Button-->
                    <v-btn
                      class="float-right mr-5"
                      color="primary"
                      ref="checkAccountBtn"
                      depressed
                      :loading="isCheckingAccount"
                      :disabled="isCheckingAccount"
                      @click="checkAccount()"
                    >
                      Check
                    </v-btn>

                    <!-- Back Button -->
                    <v-btn
                      style="margin-left: 20px"
                      ref="backToContactFormBtn"
                      depressed
                      outlined
                      @click="backToContactForm()"
                      v-shortkey.once="['ctrl', 'alt', ';']"
                      @shortkey="accountDebounceOnShortKey('back')"
                    >
                      Back
                    </v-btn>

                    <!-- Account Duplicate badge -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-badge
                            style="margin-left: 10px"
                            color="red"
                            inline
                            content="Duplicate"
                            v-if="account.label === 'duplicate'"
                          ></v-badge>
                        </span>
                      </template>
                      <span> <h4>Account Duplicate</h4></span>
                    </v-tooltip>

                    <!-- Account Suppressed badge  -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          <v-badge
                            style="margin-left: 10px"
                            color="orange"
                            inline
                            content="Suppressed"
                            v-if="account.label === 'suppressed'"
                          ></v-badge>
                        </span>
                      </template>
                      <span> <h4>Account Suppressed</h4></span>
                    </v-tooltip>
                  </div>

                  <AccountForm
                    :isChecking="isCheckingAccount"
                    :isSaving="isSavingAccount"
                    @isCheckingUpdate="updateIsCheckingAccount"
                    @isSavingUpdate="updateIsSavingAccount"
                    ref="AccountFormRef"
                    @changeTab="changeToContactTab($event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import accountMiniView from "./accountMiniView.vue";
import TaskMiniView from "./TaskMiniView.vue";
import taskStats from "./taskStats.vue";
import contactList from "./contactList.vue";
import contactFormReadOnly from "./contactFormReadOnly.vue";
import AccountDispose from "./AccountDispose.vue";
import AccountDisposeError from "./AccountDisposeError.vue";
import ContactCheck from "./ContactCheck.vue";
import AccountForm from "./AccountForm.vue";
import shortKeyMenu from "./shortKeyMenu.vue";
import ContactMasterSuggestions from "./ContactMasterSuggestions.vue";
import sanitize from "./sanitizer";
import difference from "./differenceBetweenObject";
import contactFormFullscreen from "./contactFormFullscreen.vue";

export default {
  name: "Workspace",
  props: {},
  components: {
    accountMiniView,
    TaskMiniView,
    taskStats,
    contactList,
    contactFormReadOnly,
    AccountDisposeError,
    ContactCheck,
    AccountDispose,
    AccountForm,
    shortKeyMenu,
    ContactMasterSuggestions,
    contactFormFullscreen,
  },
  data() {
    return {
      accountLastUpdatedAt: "",
      contactFormLayout: {
        list: "3",
        form: "9",
        maximize: false,
      },
      accountDisposeError: {},
      showTab: "contact",
      taskId: "",
      contactToBeSelectedId: "",
      updatedContactList: [],
      clearBitLogo: "",
      domainAnchor: "",
      accountDisposition: "",
      parentWebsite: "",
      accountComment: "",
      accountSource: "",
      defaultImage: "@/assets/defaultLogoImage.png",
      resetWorkspace: {
        resetContactDisposeForm: true,
        task: {},
        yield: {},
        specifications: [],
        account: {
          segment_technology: [],
          addressHQ: {
            address1HQ: "",
            address2HQ: "",
            cityHQ: "",
            stateHQ: "",
            countryHQ: "",
            zipCodeHQ: "",
          },
          TaskLink: {},
        }, // does not take care of multiple accounts: account Identification
        contacts: {},
        selectedContact: {
          address: {
            street1: "",
            street2: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
          },
          previous_address: {
            street1: "",
            street2: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
          },
        },
        inActionDisposeMenu: "",
        accountCheckResult: {},
        contactCheckResult: {},
        fieldCustomization: {
          accountFields: [],
          contactFields: [],
          contactReadOnlyFields: [],
        },
      },
      minimized: true,
      searchAccountNameUrl: "",
      isAccountDisposeWarningDialog: false,
      isLeaveWorkSpace: false,
      pushRouterTo: "",
      changedAccountData: {},
      isChecking: false,
      isNonEditable: false,
      isCheckingAccount: false,
      isSavingAccount: false,
    };
  },
  created: async function () {
    this.taskId = this.$route.params.taskId;
    this.contactToBeSelectedId = this.$route.query.contactId;
    return this.$store.dispatch("fetchWorkspaceData", {
      taskId: this.taskId,
      projectId: this.$route.params.projectId,
    });
  },
  mounted: function () {
    this.showRefreshDate();
  },
  computed: {
    selectedContact() {
      return _.get(this.$store.state, "workspace.selectedContact");
    },
    showEditButton() {
      let selectedContactId = _.get(this.selectedContact, "id");
      return selectedContactId && !this.isNonEditable;
    },
    workspace() {
      return this.$store.state.workspace;
    },
    workspaceAccount() {
      return this.$store.state.workspace.account;
    },
    currentWorkspaceAccount() {
      return this.$store.state.currentWorkspaceAccount;
    },
    projectId() {
      if (this.$store.state.workspace && this.$store.state.workspace.task) {
        return _.get(this.$store.state.workspace.task, "Project.id", null);
      }
      return null;
    },
    accountId() {
      if (_.has(this.$store.state, "workspace.task")) {
        return _.get(this.$store.state.workspace.task, "Accounts[0].id", null);
      }
      return null;
    },
    account() {
      return _.get(this.$store.state.workspace, "account", null);
    },
    accountDispositionName() {
      if (
        this.$store.state.workspace.account.TaskLink.disposition === "Pending"
      ) {
        return null;
      }
      return this.$store.state.workspace.account.TaskLink.disposition;
    },
    accountName() {
      if (this.$store.state.workspace && this.$store.state.workspace.account) {
        return this.$store.state.workspace.account.name;
      }
      return null;
    },
    storeAccountDisposition() {
      if (this.$store.state.workspace && this.$store.state.workspace.account) {
        return this.$store.state.workspace.account.disposition;
      }
      return null;
    },
    isWorkSpaceLoading() {
      return this.$store.state.isWorkSpaceLoading;
    },
    contactList() {
      return sanitize(this.$store.state.workspace.contacts.docs);
    },
  },
  watch: {
    workspaceAccount: function () {
      this.showRefreshDate();
    },
    contactList: function (value) {
      const self = this;
      self.updatedContactList = value;
      if (self.contactToBeSelectedId) {
        let contactsList = _.map(self.updatedContactList, function (contact) {
          if (contact.id === self.contactToBeSelectedId) {
            return contact;
          }
        });
        contactsList = _.compact(contactsList);
        const contactSelected = contactsList[0];
        self.$store.dispatch("fetchSelectedWorspaceContactData", {
          selectContact: contactSelected,
        });
      }
    },
  },
  destroyed: async function () {
    this.$store.dispatch("resetWorkspace", this.resetWorkspace);
  },
  beforeRouteLeave(to, from, next) {
    if (this.storeAccountDisposition !== "Pending" || this.isLeaveWorkSpace) {
      return next();
    } else {
      this.pushRouterTo = to.name;
      this.isAccountDisposeWarningDialog = true;
    }
  },
  methods: {
    showRefreshDate() {
      if (this.account.masterUpdatedAt) {
        var now = moment();
        this.accountLastUpdatedAt = now.diff(
          this.account.masterUpdatedAt,
          "minutes"
        );
        this.accountLastUpdatedAt = moment
          .duration(this.accountLastUpdatedAt, "minutes")
          .humanize();
      }
    },
    pushRouter() {
      this.isAccountDisposeWarningDialog = false;
      this.isLeaveWorkSpace = true;

      if (this.pushRouterTo) {
        this.$router
          .push({
            name: this.pushRouterTo,
          })
          .catch((e) => e);
      }
    },
    displayAccountDisposeWarning(value) {
      this.pushRouterTo = "AgentTaskList";
      this.isAccountDisposeWarningDialog = value;
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    copyToClipBoard(value) {
      let self = this;
      if (!value) {
        let notification = {
          type: "error",
          showMessage: true,
          message: self.$notifications().COPY_CLIPBOARD_ERROR,
        };
        self.setNotification(notification);
      }

      this.$copyText(value).then(
        function (e) {
          console.log(e);
          let notification = {
            type: "success",
            showMessage: true,
            message: self.$notifications().COPY_CLIPBOARD_SUCCESS,
          };
          self.setNotification(notification);
        },
        function (e) {
          console.log(e);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().COPY_CLIPBOARD_ERROR,
          };
          self.setNotification(notification);
        }
      );
    },
    searchByAccountName(accountName) {
      this.searchAccountNameUrl =
        `https://www.google.com/search?q=` + encodeURIComponent(accountName);
    },
    debounceOnShortKey: _.debounce(async function () {
      // await this.$refs.editAccountBtn.$el.focus();
      await this.editAccount();
    }, 1000),
    toggleAccountMiniView() {
      this.minimized = !this.minimized;
    },
    editAccount() {
      let accountPayload = this.$refs.AccountFormRef.fetchAccountForm();
      let { changedAccountData } = this.compareAccounts(
        accountPayload,
        this.workspaceAccount
      );
      this.changedAccountData = changedAccountData;
      this.switchTab("detail");
    },
    changeToContactTab(value) {
      this.switchTab(value);
    },
    switchTab(payload) {
      this.showTab = payload;
    },
    getClearBitLogoInfo(value) {
      this.clearBitLogo = value.clearBitLogo;
      this.domainAnchor = value.domainAnchor;
    },
    getDispostion(value) {
      let self = this;
      if (value) {
        self.accountDisposition = value.disposition;
        self.parentWebsite = value.parentWebsite;
        self.accountComment = value.comments;
        self.accountSource = value.source;
        self.disposeAccount();
      }
    },
    compareAccounts(newAccount, oldAccount) {
      const changedAccountData = difference(newAccount, oldAccount);
      if (_.isEmpty(changedAccountData)) {
        return { isAccountChanged: false };
      }
      return {
        isAccountChanged: true,
        changedAccountData,
      };
    },
    disposeAccount: async function () {
      var accountDispositionRes;
      let accountPayload = this.$refs.AccountFormRef.fetchAccountForm();
      accountPayload["disposition"] = this.accountDisposition;
      if (this.accountComment) {
        accountPayload["comments"] = this.accountComment;
      }
      if (this.accountSource) {
        accountPayload["source"] = this.accountSource;
      }
      if (this.parentWebsite) {
        accountPayload["parentWebsite"] = this.parentWebsite;
      }
      let { changedAccountData } = this.compareAccounts(
        accountPayload,
        this.currentWorkspaceAccount
      );

      /* Set data for VerfifyAccount */
      accountPayload =
        this.$refs.AccountFormRef.setMasterAccountDefaultData(accountPayload);
      await new Promise((resolve) => setTimeout(resolve, 0));
      if (!this.$refs.AccountFormRef.checkFormIsValid()) {
        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().FORM_VALIDATION_ERROR,
        };
        this.setNotification(notification);
        return accountDispositionRes;
      }
      await this.$refs.AccountFormRef.saveAccount();
      /* End - Set data for VerfifyAccount */

      let disposeAccount = {
        id: this.account.id,
        type: accountPayload.type,
        disposition: this.accountDisposition,
        parentWebsite: this.parentWebsite,
        comments: this.accountComment,
        source: this.accountSource,
      };

      let taskLink = {
        TaskId: this.taskId,
        ObjectId: this.account.id,
        disposition: this.accountDisposition,
        comments: this.accountComment,
      };

      try {
        /* Adding Domain in Payload for VerifyAccounts */
        if (!changedAccountData) {
          changedAccountData = {};
        }

        changedAccountData["domain"] = this.account.domain;
        changedAccountData["disposition"] = this.accountDisposition;

        accountDispositionRes = await this.$store.dispatch("disposeAccount", {
          disposeAccount: disposeAccount,
          projectId: this.projectId,
          isDisposeAccount: true,
          taskLink: taskLink,
          changedAccountData,
          accountPayload,
        });
        this.isLeaveWorkSpace = true;
        this.$router
          .push({
            name: "AgentTaskList",
            params: { projectId: this.projectId },
          })
          .catch((e) => e);
        return accountDispositionRes;
      } catch (error) {
        this.isLeaveWorkSpace = false;
        this.accountDisposeError = error.response.data;
        this.$store.dispatch("populateAccountDisposeError", {
          error: this.accountDisposeError,
        });
      }
    },
    checkDupSup() {
      const contactFormReadOnlyRef = this.$refs.contactFormReadOnlyRef;
      if (contactFormReadOnlyRef) {
        contactFormReadOnlyRef.checkDupSup();
      }
    },
    contactCheckDebounceOnShortKey: _.debounce(async function (value) {
      switch (value) {
        case "check":
          await this.$refs.checkBtn.$el.focus();
          await this.checkDupSup();
          break;
      }
    }, 1000),
    updateIsChecking(value) {
      this.isChecking = value;
    },
    resetContactForm(closed, resetPhoneNubers) {
      const contactFormFullscreenRef = this.$refs.contactFormFullscreenRef;
      if (contactFormFullscreenRef) {
        contactFormFullscreenRef.resetContactForm(closed, resetPhoneNubers);
      }
    },
    contactCreateNewDebounceOnShortKey(value) {
      const contactFormFullscreenRef = this.$refs.contactFormFullscreenRef;
      if (contactFormFullscreenRef) {
        contactFormFullscreenRef.debounceOnShortKey(value);
      }
    },
    editSelectedContact() {
      const contactFormFullscreenRef = this.$refs.contactFormFullscreenRef;
      if (contactFormFullscreenRef) {
        contactFormFullscreenRef.editSelectedContact();
      }
    },
    updateIsNonEditable(value) {
      this.isNonEditable = value;
    },
    saveAccount() {
      const AccountFormRef = this.$refs.AccountFormRef;
      if (AccountFormRef) {
        AccountFormRef.saveAccount();
      }
    },
    checkAccount() {
      const AccountFormRef = this.$refs.AccountFormRef;
      if (AccountFormRef) {
        AccountFormRef.checkAccount();
      }
    },
    backToContactForm() {
      const AccountFormRef = this.$refs.AccountFormRef;
      if (AccountFormRef) {
        AccountFormRef.backToContactForm();
      }
    },
    accountDebounceOnShortKey: _.debounce(async function (value) {
      const AccountFormRef = this.$refs.AccountFormRef;

      if (!AccountFormRef) {
        return;
      }

      switch (value) {
        case "save":
          if (!this.isSavingAccount) {
            await this.$refs.saveAccountBtn.$el.focus();
            await AccountFormRef.saveAccount();
          }
          break;
        case "back":
          await this.$refs.backToContactFormBtn.$el.focus();
          await AccountFormRef.backToContactForm();
          break;
      }
    }, 1000),
    updateIsCheckingAccount(value) {
      this.isCheckingAccount = value;
    },
    updateIsSavingAccount(value) {
      this.isSavingAccount = value;
    },
  },
};
</script>

<style scoped>
.worspace_wrapper {
  margin-left: 56px;
}
.topbar {
  width: 100%;
  height: 48px;
  position: absolute;
  top: 0;
  left: 56px;
}
.task_list {
  width: 30%;
}
.task_detail {
  width: 270px;
  float: left;
}
.workspace {
  width: calc(100% - 292px);
  margin-left: 10px;
  margin-top: 70px;
  float: left;
  padding-left: 7px;
}
.header_nav {
  width: 100%;
}
.account_banner {
  position: relative;
}
.form_actions {
}
.agent_form {
}

.header {
  position: relative;
}
.header h1 {
  /* float: left; */
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
  padding: 15px 0px 10px;
}
.tabs li {
  list-style: none;
}
.tabs li a {
  align-items: center;
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  margin-bottom: -1px;
  padding: 0.5em 1em;
  vertical-align: top;
  cursor: pointer;
  font-size: 16px;
}
.tabs ul {
  align-items: center;
  border-bottom-color: #dbdbdb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: flex-start;
  padding-left: 0;
}
.tabs li.is-active a {
  border-bottom-color: #3273dc;
  color: #3273dc;
}
.tab-details {
  padding-top: 10px;
}

.worspace_tabs {
  border-right: 1px solid #aaa;
}
.breadcrumb,
.worspace_tabs {
  display: block;
  float: left;
  width: 100%;
  border-right: 1px solid #aaa;
}
.breadcrumb {
  border-right: 1px solid #aaa;
}

.editAccount {
  position: absolute;
  right: 5px;
  top: 2px;
}
.expand_list {
  position: absolute;
  top: 20px;
  left: -20px;
  cursor: pointer;
}
.expand_account.shift {
  top: -15px;
}
.expand_account {
  position: absolute;
  top: -20px;
  left: 33%;
}
.account_disposed_badge {
  position: absolute;
  top: 13px;
}
</style>
