<template>
  <div>
    <v-row>
      <v-btn text color="info" @click="download_csv_file()"
        >Download Headers</v-btn
      >
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    fileType: String,
    importType: String,
    filteredMappingFields: Array,
    type: String,
  },
  data() {
    return {};
  },
  methods: {
    getHeaders: function () {
      let headers = this.filteredMappingFields.map((mappingField) => {
        if (
          this.importType === "Account" &&
          (this.fileType === "Inclusion" || this.fileType === "Import") &&
          mappingField["type"].includes("account")
        ) {
          return mappingField.label;
        }
        if (
          this.importType === "Contact" &&
          (this.fileType === "Inclusion" || this.fileType === "Import") &&
          (mappingField["type"].includes("account") ||
            mappingField["type"].includes("contact"))
        ) {
          return mappingField.label;
        }
        if (
          this.importType === "Account" &&
          this.fileType === "Suppression" &&
          mappingField["type"].includes("accountSuppression")
        ) {
          return mappingField.label;
        }
        if (
          this.importType === "Contact" &&
          this.fileType === "Suppression" &&
          mappingField["type"].includes("contactSuppression")
        ) {
          return mappingField.label;
        }
        if (
          this.importType === "Task" &&
          this.fileType === "Import" &&
          mappingField["type"].includes("task")
        ) {
          return mappingField.label;
        }
        if (
          this.importType === "Contact" &&
          this.fileType === "Import" &&
          this.type === "Master" &&
          (mappingField["type"].includes("contact") ||
            mappingField["type"].includes("location"))
        ) {
          return mappingField.label;
        }
      });

      return headers;
    },
    download_csv_file: function () {
      var csvHeaders = this.getHeaders();

      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv;charset=utf-8," + encodeURI(csvHeaders);
      hiddenElement.target = "_blank";

      hiddenElement.download = `${this.importType}_${this.fileType}_Headers.csv`;
      hiddenElement.click();
    },
  },
};
</script>
