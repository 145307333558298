<template>
  <v-container fluid class="overview pa-0">
    <!-- Save Specifications -->
    <v-btn
      class="ml-2 spec_save_btn white--text"
      :loading="isSaving"
      :disabled="isSaving"
      color="#8B83BA"
      @click="saveSpec()"
      rounded
      v-show="EditMode"
    >
      Save Specifications
      <v-icon medium right>save</v-icon>
    </v-btn>

    <!-- Edit Specifications -->
    <v-btn
      class="spec_save_btn"
      color="#8B83BA"
      depressed
      dark
      rounded
      @click="editSpec()"
      id="spec.id"
      v-show="!EditMode"
    >
      Edit Specifications
      <v-icon medium right>edit</v-icon>
    </v-btn>

    <v-container fluid class="pt-0">
      <h5 class="spec_heading mx-0 text-left pl-4">Targets</h5>
      <v-row class="mb-6 px-4">
        <v-col class="py-2">
          <div class="counterTitles">
            <v-row>
              <v-col class="pa-2">
                <h6 v-bind:class="{ 'mt-2': EditMode }">Accounts</h6>
                <v-text-field
                  outlined
                  dense
                  v-show="EditMode"
                  v-model="projectSetting.target.accountTarget"
                ></v-text-field>
                <div v-show="!EditMode" class="counterNumber">
                  <p>
                    {{ projectSetting.target.accountTarget | humanizeNumber }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col class="py-2">
          <div class="counterTitles">
            <v-row>
              <v-col class="pa-2">
                <h6 v-bind:class="{ 'mt-2': EditMode }">Contacts</h6>
                <v-text-field
                  outlined
                  dense
                  v-show="EditMode"
                  v-model="projectSetting.target.contactTarget"
                ></v-text-field>
                <div v-show="!EditMode" class="counterNumber">
                  <p>
                    {{ projectSetting.target.contactTarget | humanizeNumber }}
                  </p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col class="py-2">
          <v-row>
            <v-col class="pa-2">
              <div class="counterTitles">
                <h6 v-bind:class="{ 'mt-2': EditMode }">Contacts/Account</h6>
                <v-text-field
                  outlined
                  dense
                  v-show="EditMode"
                  v-model="projectSetting.contactsPerAccount"
                ></v-text-field>
                <div v-show="!EditMode" class="counterNumber">
                  <p>
                    {{ projectSetting.contactsPerAccount | humanizeNumber }}
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <h5 class="spec_heading mx-0 text-left pl-4">Supporting Documents</h5>
      <v-row>
        <v-col class="py-2 px-6">
          <div class="counterTitles">
            <v-row class="fileContainerTitle">
              <v-col class="pa-2">
                <h6 class="mt-1">Suppression</h6>
              </v-col>
              <v-col class="pa-2 text-right">
                <div class="mr-1 d-inline-block">
                  <FileLink :projectId="projectId" @getFileList="listFiles()" />
                </div>
                <FileUpload
                  fileType="Suppression"
                  :projectId="projectId"
                  :projectName="projectName"
                  @uploaded="listFiles"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col class="fileContainer">
                <span
                  style="font-weight: bold"
                  v-if="suppressionList.length"
                  class="filename"
                  >Uploaded files
                </span>
                <v-list
                  class="pa-0"
                  v-bind:class="{ showMore: isSuppressionShowMore }"
                >
                  <div
                    class="downloadSection"
                    :key="file.id"
                    v-for="file in suppressionList"
                    @uploaded="listFiles"
                  >
                    <span class="filename">{{
                      file.name | truncate($constants().COLUMN_LIMIT35)
                    }}</span>
                    <v-btn
                      icon
                      fab
                      dark
                      small
                      depressed
                      color="indigo"
                      class="float-right mr-2"
                      @click="download({ id: file.id, name: file.name })"
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                    <ConfirmationDialog
                      btnClass="float-right mr-2"
                      icon="link_off"
                      tooltip="Unlink File"
                      v-bind:display="true"
                      v-on:agreement="
                        unlinkFile(
                          { id: file.id, projectId: projectId },
                          $event
                        )
                      "
                      :isDelete="false"
                    />

                    <v-progress-circular
                      v-show="currentDownloadingIndex === file.id && isDownload"
                      class="float-right mr-2"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </div>
                </v-list>
                <span
                  class="showMoreSpan"
                  v-show="isSuppressionShowMore"
                  @click="changeStyle('suppression')"
                  v-if="suppressionList.length > 3"
                  ><v-icon>expand_more</v-icon> Show more
                </span>
                <span
                  class="showMoreSpan"
                  v-show="!isSuppressionShowMore"
                  @click="changeStyle('suppression')"
                  ><v-icon>expand_less</v-icon> Show less
                </span>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col class="py-2 px-6">
          <div class="counterTitles">
            <v-row class="fileContainerTitle">
              <v-col class="pa-2">
                <h6 class="mt-1">Inclusion</h6>
              </v-col>
              <v-col class="pa-2 text-right">
                <FileUpload
                  fileType="Inclusion"
                  :projectId="projectId"
                  :projectName="projectName"
                  @uploaded="listFiles"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="fileContainer pa-2">
                <span
                  style="font-weight: bold"
                  v-if="inclusionList.length"
                  class="filename"
                  >Uploaded files</span
                >
                <v-list
                  class="pa-0"
                  v-bind:class="{ showMore: isInclusionShowMore }"
                >
                  <div
                    class="downloadSection"
                    :key="file.id"
                    v-for="file in inclusionList"
                    @uploaded="listFiles"
                  >
                    <span class="filename">{{
                      file.name | truncate($constants().COLUMN_LIMIT35)
                    }}</span>

                    <v-btn
                      icon
                      fab
                      dark
                      small
                      depressed
                      color="indigo"
                      class="float-right mr-2"
                      @click="download({ id: file.id, name: file.name })"
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                    <v-progress-circular
                      v-show="currentDownloadingIndex === file.id && isDownload"
                      class="float-right mr-2"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </div>
                </v-list>
                <span
                  class="showMoreSpan"
                  v-show="isInclusionShowMore"
                  @click="changeStyle('inclusion')"
                  v-if="inclusionList.length > 3"
                  ><v-icon>expand_more</v-icon> Show more</span
                >
                <span
                  class="showMoreSpan"
                  v-show="!isInclusionShowMore"
                  @click="changeStyle('inclusion')"
                  ><v-icon>expand_less</v-icon> Show less</span
                >
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col class="py-2 px-6">
          <div class="counterTitles">
            <v-row class="fileContainerTitle">
              <v-col class="pa-2">
                <h6>Other Documents</h6>
              </v-col>
              <v-col class="pa-2 text-right">
                <FileUpload
                  fileType="Supporting Document"
                  :projectId="projectId"
                  :projectName="projectName"
                  @uploaded="listFiles"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="fileContainer pa-2">
                <span
                  style="font-weight: bold"
                  v-if="supportingList.length"
                  class="filename"
                  >Uploaded files</span
                >
                <v-list
                  class="pa-0"
                  v-bind:class="{ showMore: isSupportingDocsShowMore }"
                >
                  <div
                    class="downloadSection"
                    :key="file.id"
                    v-for="file in supportingList"
                    @uploaded="listFiles"
                  >
                    <span class="filename">{{
                      file.name | truncate($constants().COLUMN_LIMIT35)
                    }}</span>
                    <v-btn
                      icon
                      fab
                      dark
                      small
                      depressed
                      color="indigo"
                      class="float-right mr-2"
                      @click="download({ id: file.id, name: file.name })"
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                    <ConfirmationDialog
                      btnClass="float-right mr-2"
                      icon="delete"
                      tooltip="Delete File"
                      v-bind:display="true"
                      v-on:agreement="
                        deleteFile({ id: file.id, name: file.name }, $event)
                      "
                      :isDelete="true"
                    />
                    <v-progress-circular
                      v-show="currentDownloadingIndex === file.id && isDownload"
                      class="float-right mr-2"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </div>
                </v-list>
                <span
                  class="showMoreSpan"
                  v-show="isSupportingDocsShowMore"
                  @click="changeStyle('supportingDocs')"
                  v-if="supportingList.length > 3"
                  ><v-icon>expand_more</v-icon> Show more</span
                >
                <span
                  class="showMoreSpan"
                  v-show="!isSupportingDocsShowMore"
                  @click="changeStyle('supportingDocs')"
                  ><v-icon>expand_less</v-icon> Show less</span
                >
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <h5 class="spec_heading mx-0 text-left pl-4">Project Specs</h5>

    <v-container fluid class="specs_list">
      <v-btn
        color="#8B83BA"
        depressed
        dark
        block
        large
        class="mb-6"
        @click="addSpec()"
        v-show="EditMode"
      >
        Add New Spec
        <v-icon right> add </v-icon>
      </v-btn>
      <v-form ref="form" v-model="valid" lazy-validation>
        <div
          class="specs_item"
          v-for="(spec, index) in projectSpecs"
          :key="spec.id"
        >
          <section>
            <v-row>
              <v-col cols="12" md="2">
                <v-subheader
                  ><b>Spec name</b
                  ><span v-show="EditMode" style="color: red; font-size: 23px"
                    >*</span
                  >:
                </v-subheader>
              </v-col>
              <v-col cols="12" md="10">
                <v-text-field
                  v-show="EditMode"
                  outlined
                  dense
                  id="spec_name"
                  v-model="spec.name"
                  required
                  :rules="[(v) => !!v || 'Spec Name is required']"
                  type="text"
                >
                </v-text-field>
                <p v-show="!EditMode">
                  {{ spec.name }}
                </p>
              </v-col>
            </v-row>
          </section>

          <div v-show="EditMode">
            <h4 class="spec_heading">Account Fields</h4>
            <section>
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-subheader>Employee Count: </v-subheader>
                </v-col>
                <v-col cols="12" md="10" class="py-0">
                  <v-combobox
                    @focus="
                      (event) => changeAutoComplete(event, 'employeeCount')
                    "
                    v-model="spec.values.employee_count"
                    :loading="loading"
                    @change="selectAllValues(items, 'employee_count', index)"
                    :items="items"
                    :search-input.sync="search"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
            </section>

            <section>
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-subheader>Company Revenue : </v-subheader>
                </v-col>
                <v-col cols="12" md="10" class="py-0">
                  <v-combobox
                    @focus="
                      (event) => changeAutoComplete(event, 'companyRevenue')
                    "
                    v-model="spec.values.company_revenue"
                    :loading="loading"
                    :items="items"
                    :search-input.sync="search"
                    @change="selectAllValues(items, 'company_revenue', index)"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
            </section>

            <section>
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-subheader class="pt-4">Industry : </v-subheader>
                </v-col>
                <v-col cols="12" md="10"
                  ><v-combobox
                    @focus="(event) => changeAutoComplete(event, 'industry')"
                    v-model="spec.values.industry"
                    @change="selectAllValues(items, 'industry', index)"
                    :loading="loading"
                    :items="items"
                    :search-input.sync="search"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
            </section>

            <section>
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-subheader class="pt-2">Sub Industry : </v-subheader>
                </v-col>
                <v-col cols="12" md="10" class="py-0">
                  <v-combobox
                    @focus="
                      (event) =>
                        changeAutoComplete(event, 'subIndustry', {
                          industrys: spec.values.industry,
                        })
                    "
                    v-model="spec.values.sub_industry"
                    @change="selectAllValues(items, 'sub_industry', index)"
                    :loading="loading"
                    :items="items"
                    :search-input.sync="search"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
            </section>

            <section class="noChip">
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-subheader>Geography/Location : </v-subheader>
                </v-col>
                <v-col cols="12" md="10" class="py-0">
                  <v-combobox
                    v-model="spec.values.geography"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
            </section>

            <section class="noChip">
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-subheader>Note : </v-subheader>
                </v-col>
                <v-col cols="12" md="10" class="py-0">
                  <!-- <v-textarea
                    id="note"
                    v-show="EditMode"
                    v-model="spec.values.note"
                    outlined
                    dense
                  >
                  </v-textarea> -->
                  <vue-editor
                    id="note"
                    v-show="EditMode"
                    @blur="processNotes(index, 'note')"
                    v-model="spec.values.note"
                  ></vue-editor>
                </v-col>
              </v-row>
            </section>

            <h4 class="spec_heading">Contact Fields</h4>
            <section v-if="spec.values.job_level">
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-subheader>Job Level : </v-subheader>
                </v-col>
                <v-col cols="12" md="10" class="py-0">
                  <v-combobox
                    v-show="EditMode"
                    v-model="spec.values.job_level"
                    :loading="loading"
                    :items="items"
                    @focus="(event) => changeAutoComplete(event, 'jobLevel')"
                    @change="selectAllValues(items, 'job_level', index)"
                    :search-input.sync="search"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
            </section>
            <section>
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-subheader>Job Department : </v-subheader>
                </v-col>
                <v-col cols="12" md="10" class="py-0">
                  <v-combobox
                    @focus="
                      (event) => changeAutoComplete(event, 'jobDepartment')
                    "
                    v-model="spec.values.job_department"
                    :loading="loading"
                    @change="selectAllValues(items, 'job_department', index)"
                    :items="items"
                    :search-input.sync="search"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
            </section>

            <section>
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-subheader>Job Function : </v-subheader>
                </v-col>
                <v-col cols="12" md="10" class="py-0">
                  <v-combobox
                    @focus="
                      (event) =>
                        changeAutoComplete(event, 'jobFunction', {
                          departments: spec.values.job_department,
                        })
                    "
                    v-model="spec.values.job_function"
                    @change="selectAllValues(items, 'job_function', index)"
                    :loading="loading"
                    :items="items"
                    :search-input.sync="search"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
            </section>

            <section>
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-subheader>Job Title : </v-subheader>
                </v-col>
                <v-col cols="12" md="10" class="py-0">
                  <v-combobox
                    v-model="spec.values.job_title"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
            </section>

            <section>
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-subheader>Excluded Job Title : </v-subheader>
                </v-col>
                <v-col cols="12" md="10" class="py-0">
                  <v-combobox
                    v-model="spec.values.excluded_job_title"
                    v-on:paste.native="processPastedValue(index, $event)"
                    @change="processTypedValue(index)"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-col>
              </v-row>
            </section>

            <h4 class="spec_heading">Custom Fields</h4>
            <section
              v-for="specItem in customFields[spec.id]"
              :key="specItem.key"
            >
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-subheader>{{ specItem.key }} : </v-subheader>
                </v-col>

                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    :id="specItem.key"
                    v-model="specItem.value"
                    @input="
                      editCustomField(index, specItem.key, specItem.value)
                    "
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="1">
                  <ConfirmationDialog
                    :display="EditMode"
                    tooltip="Remove Field"
                    icon="clear"
                    v-on:agreement="
                      remove(index, specItem.key, spec.id, $event)
                    "
                    :isDelete="true"
                  />
                </v-col>
              </v-row>
            </section>

            <section v-if="showCustomField" v-show="EditMode">
              <v-row
                v-for="(field, index) in newCustomFields"
                :key="index"
                v-show="newCustomFields[index].specId === spec.id"
              >
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="field.customKey"
                    label="Label"
                    outlined
                    dense
                    required
                    class="pl-3"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="10" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    v-model="field.customValue"
                    label="Value"
                  ></v-text-field>
                </v-col>
              </v-row>
            </section>
          </div>

          <h4
            class="spec_heading read_view_spec"
            v-show="
              !EditMode &&
              projectSpecs[index] &&
              projectSpecs[index].accountValueKeys &&
              projectSpecs[index].accountValueKeys.length
            "
          >
            Account Fields
          </h4>
          <div
            v-for="valuesKey in projectSpecs[index].accountValueKeys"
            :key="valuesKey"
            v-show="!EditMode"
          >
            <div v-if="projectSpecs[index].newValues[valuesKey]">
              <div v-if="accountFields.includes(valuesKey)">
                <v-row class="read_view_spec">
                  <v-col cols="12" md="2" class="py-0">
                    <v-subheader for="key">{{ valuesKey }} :</v-subheader>
                  </v-col>
                  <v-col cols="12" md="10">
                    <p for="value">
                      <span v-html="projectSpecs[index].newValues[valuesKey]">{{
                        projectSpecs[index].newValues[valuesKey]
                      }}</span>
                    </p>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>

          <h4
            class="spec_heading read_view_spec"
            v-show="
              !EditMode &&
              projectSpecs[index] &&
              projectSpecs[index].contactValueKeys &&
              projectSpecs[index].contactValueKeys.length
            "
          >
            Contact Fields
          </h4>
          <div
            v-for="valuesKey in projectSpecs[index].contactValueKeys"
            :key="valuesKey"
            v-show="!EditMode"
          >
            <div v-if="projectSpecs[index].newValues[valuesKey]">
              <div v-if="contactFields.includes(valuesKey)">
                <v-row class="read_view_spec">
                  <v-col cols="12" md="2" class="py-0">
                    <v-subheader for="key">{{ valuesKey }} :</v-subheader>
                  </v-col>
                  <v-col cols="12" md="10">
                    <p for="value">
                      <span v-html="projectSpecs[index].newValues[valuesKey]">{{
                        projectSpecs[index].newValues[valuesKey]
                      }}</span>
                    </p>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>

          <h4
            class="spec_heading read_view_spec"
            v-show="
              !EditMode &&
              projectSpecs[index] &&
              projectSpecs[index].customValueKeys &&
              projectSpecs[index].customValueKeys.length
            "
          >
            Custom Fields
          </h4>
          <div
            v-for="valuesKey in projectSpecs[index].customValueKeys"
            :key="valuesKey"
            v-show="!EditMode"
          >
            <div v-if="projectSpecs[index].newValues[valuesKey]">
              <div
                v-if="
                  !contactFields.includes(valuesKey) &&
                  !accountFields.includes(valuesKey)
                "
              >
                <v-row class="read_view_spec">
                  <v-col cols="12" md="2" class="py-0">
                    <v-subheader for="key">{{ valuesKey }} :</v-subheader>
                  </v-col>
                  <v-col cols="12" md="10">
                    <p for="value">
                      <span v-html="projectSpecs[index].newValues[valuesKey]">{{
                        projectSpecs[index].newValues[valuesKey]
                      }}</span>
                    </p>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
          <v-btn
            class="addSpecProperty"
            color="#8B83BA"
            depressed
            dark
            rounded
            @click="appendField(projectSpecs[index].id)"
            v-show="EditMode"
          >
            Add New Field
            <v-icon right>add</v-icon>
          </v-btn>

          <div v-show="EditMode" class="specNote">
            Note: * are mandatory fields
          </div>

          <div class="form_actions" v-show="EditMode">
            <v-row class="actions_btns">
              <v-col cols="12" md="9">
                <v-btn-toggle>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="#8B83BA"
                        fab
                        @click="cancel(index)"
                        v-bind="attrs"
                        v-on="on"
                        v-show="EditMode"
                      >
                        <v-icon>cancel</v-icon>
                      </v-btn>
                    </template>
                    <span>Cancel Editing</span>
                  </v-tooltip>
                  <ConfirmationDialog
                    :display="EditMode"
                    tooltip="Remove Spec"
                    icon="delete"
                    v-on:agreement="deleteSpec(index, $event)"
                    :isDelete="true"
                  />
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" md="3" class="text-right mt-1">
                <v-btn
                  class="ml-2 white--text"
                  color="#8B83BA"
                  :loading="isSaving"
                  :disabled="isSaving"
                  @click="saveSpec()"
                  rounded
                  v-show="EditMode"
                >
                  Save Specifications
                  <v-icon medium right>save</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import Api from "../services/Api";
import ConfirmationDialog from "./ConfirmationDialog";
import _ from "lodash";
import FileUpload from "./fileUpload";
import FileLink from "./linkFile";
import difference from "./differenceBetweenObject";
import { VueEditor } from "vue2-editor";

export default {
  name: "PROJECTSpecifications",
  props: {
    projectId: String,
    projectName: String,
    edit: Boolean,
  },
  components: {
    ConfirmationDialog,
    FileUpload,
    FileLink,
    VueEditor,
  },
  data() {
    return {
      isSaving: false,
      valid: false,
      currentDownloadingIndex: null,
      isDownload: false,
      power: 10,
      disableSaveSpecBtn: false,
      isSuppressionShowMore: true,
      isInclusionShowMore: true,
      selectedIndustrys: [],
      isSupportingDocsShowMore: true,
      autoCompleteSelected: null,
      loading: false,
      items: [],
      search: null,
      select: null,
      uploadDialog: false,
      newSpecCount: 0,
      EditMode: false,
      selectedUploadOption: null,
      editSelected: null,
      projectSpecs: [],
      projectSpecsPrev: [],
      projectSetting: {
        target: {},
      },
      projectSettingPrev: [],
      suppressionList: [],
      inclusionList: [],
      supportingList: [],
      specAddButton: false,
      showCustomFieldTag: false,
      FILE_TYPES: {
        SUPPRESSION: "Suppression",
        INCLUSION: "Inclusion",
        SUPPORTING_DOCS: "Supporting Document",
      },
      OPERATION_NAMES: {
        CONTACT_UPLOAD: "contactImport",
        ACCOUNT_UPLOAD: "accountImport",
      },
      newCustomFields: [],
      showCustomField: false,
      customFields: [],
      fieldList: [
        "job_level",
        "job_department",
        "job_function",
        "job_title",
        "excluded_job_title",
        "employee_count",
        "company_revenue",
        "industry",
        "sub_industry",
        "geography",
        "contacts_per_account",
        "note",
      ],
      contactFields: [
        "Job Level",
        "Job Department",
        "Job Function",
        "Job Title",
        "Excluded Job Title",
      ],
      accountFields: [
        "Employee Count",
        "Company Revenue",
        "Industry",
        "Sub Industry",
        "Geography",
        "Contacts Per Account",
        "Note",
      ],
      showSaveSucces: false,
      suppressionFab: false,
      fab: false,
    };
  },
  mounted: function () {
    var self = this;
    this.fetchProjectSpec(function () {
      if (self.edit) {
        self.editSpec();
      }
    });
  },
  computed: {
    clientId() {
      return this.$store.state.projectClientId;
    },
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
  },
  methods: {
    processNotes: function (index, notesKey) {
      const self = this;
      // Replace more than 3 continuous tilde (~) signs with exactly 3 tilda (~) signs
      self.projectSpecs[index].values[notesKey] = self.projectSpecs[
        index
      ].values[notesKey].replace(/~~~+/g, "~~~");

      self.projectSpecs[index].values[notesKey] = self.projectSpecs[
        index
      ].values[notesKey]
        .replace(/~~~.*?<\/p>/g, "~~~</p>")
        .replace(/<((?!<p>).)*~~~/g, "<p>~~~");

      // Retrive all strings encoded in 3 tilda (~) signs
      let matches =
        self.projectSpecs[index].values[notesKey].match(/~~~.+?~~~/g);

      // Iterate over each match for processing
      _.forEach(matches, (match) => {
        // Replace 3 tilde (~) signs with empty string and retrieve exact portion for processing
        let stringToProcess = match.replace(/['~~~]+/g, "");
        self.projectSpecs[index].values[notesKey] = self.projectSpecs[
          index
        ].values[notesKey].replace(stringToProcess, "~~~");
        // Parse html string
        stringToProcess = stringToProcess
          .replace(/<p>/g, "")
          .replace(/<\/p>/g, ", ");
        stringToProcess = stringToProcess.substr(0, stringToProcess.length - 1);
        stringToProcess = stringToProcess.substring(1);
        // Replace old string with parsed html string
        self.projectSpecs[index].values[notesKey] = self.projectSpecs[
          index
        ].values[notesKey].replace("~~~~~~~~~", stringToProcess);
      });
    },
    revert: function (value) {
      if (value) {
        return false;
      }
      return true;
    },
    changeStyle: function (type) {
      if (type === "suppression") {
        this.isSuppressionShowMore = this.revert(this.isSuppressionShowMore);
      }
      if (type === "supportingDocs") {
        this.isSupportingDocsShowMore = this.revert(
          this.isSupportingDocsShowMore
        );
      }
      if (type === "inclusion") {
        this.isInclusionShowMore = this.revert(this.isInclusionShowMore);
      }
    },
    listFiles: function () {
      let self = this;
      Api.getFiles(this.projectId)
        .then((response) => {
          let suppressionFiles = response.data.filter(
            (file) => file.type === self.FILE_TYPES.SUPPRESSION
          );
          let supportingDocFiles = response.data.filter(
            (file) => file.type === self.FILE_TYPES.SUPPORTING_DOCS
          );
          let inclusionFiles = response.data.filter(
            (file) => file.type === self.FILE_TYPES.INCLUSION
          );
          self.suppressionList = suppressionFiles;
          self.supportingList = supportingDocFiles;
          self.inclusionList = inclusionFiles;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteSpec: function (index, agreementValue) {
      if (agreementValue) {
        var specId = this.projectSpecs[index].id;
        if (specId) {
          delete this.customFields[specId];
          Api.deleteSpec(this.projectId, specId).then(function (res) {
            console.log(res);
          });
        }
        this.changeState(false);
      }
      this.$forceUpdate();
      this.fetchProjectSpec();
    },
    changeAutoComplete: function (event, label, paramValue = {}) {
      this.items = [];
      let defaultQ = "";
      this.autoCompleteSelected = label;
      this.selectedIndustrys = paramValue.industrys || [];
      this.selectedDepartments = paramValue.departments || [];

      switch (label) {
        case "companyRevenue":
        case "employeeCount":
          defaultQ = 0;
          break;
        default:
          defaultQ = "";
          break;
      }
      if (label) {
        this.querySelections(defaultQ);
      }
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    download: function (fileData) {
      const self = this;
      this.currentDownloadingIndex = fileData.id;
      this.isDownload = true;
      return Api.downloadFile(fileData.id)
        .then((res) => {
          let fileDownloadUrl = _.get(res, "data");
          if (!fileDownloadUrl) throw new Error("Could Not Download File");
          window.open(fileDownloadUrl, "_blank");
          let notification = {
            type: "success",
            showMessage: true,
            message: self.$notifications().FILE_DOWNLOAD_SUCCESS,
          };
          self.setNotification(notification);
          self.currentDownloadingIndex = null;
          self.isDownload = false;
        })
        .catch((error) => {
          self.currentDownloadingIndex = null;
          self.isDownload = false;
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().FILE_DOWNLOAD_ERROR,
          };
          self.setNotification(notification);
        });
    },
    deleteFile: function (fileData, agreement) {
      const self = this;
      if (agreement) {
        Api.deleteFile(fileData.id)
          .then((result) => {
            console.log(result);
            let notification = {
              type: "success",
              showMessage: true,
              message: self.$notifications().FILE_DELETE,
            };
            self.setNotification(notification);
            self.fetchProjectSpec(this.projectId);
          })
          .catch((error) => {
            console.log(error);
            let notification = {
              type: "error",
              showMessage: true,
              message: self.$notifications().FILE_DELETE_ERROR,
            };
            self.setNotification(notification);
          });
      }
    },
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      const self = this;

      var queryParams = {};
      queryParams.param = v;

      if (this.autoCompleteSelected == "subIndustry") {
        queryParams.industrys = this.selectedIndustrys;
      }
      if (this.autoCompleteSelected == "jobFunction") {
        queryParams.departments = this.selectedDepartments;
      }
      if (this.autoCompleteSelected) {
        self.items = ["Select All"];
      }
      Api.autoCompleteInterFace(this.autoCompleteSelected, queryParams).then(
        function (response) {
          self.items = _.concat(self.items, response.data);
        }
      );

      this.loading = false;
    },
    processPastedValue: function (specIndex, event) {
      let copiedExclusionData = (
        event.clipboardData || window.clipboardData
      ).getData("text");
      copiedExclusionData = copiedExclusionData.replace(/\n/g, "~");
      event.preventDefault();
      this.processTypedValue(specIndex, copiedExclusionData);
    },
    processTypedValue: function (specIndex, copiedExclusionData) {
      let self = this;
      const formatedCopiedData = copiedExclusionData
        ? copiedExclusionData.split("~")
        : [];
      self.projectSpecs[specIndex].values["excluded_job_title"] = _.concat(
        self.projectSpecs[specIndex].values["excluded_job_title"],
        formatedCopiedData
      );
      self.projectSpecs[specIndex].values["excluded_job_title"] = _.uniq(
        self.projectSpecs[specIndex].values["excluded_job_title"]
      );
      self.projectSpecs[specIndex].values["excluded_job_title"] = _.compact(
        self.projectSpecs[specIndex].values["excluded_job_title"]
      );
      copiedExclusionData = "";
    },
    remove: function (index, specItemKey, specId, agreementValue) {
      if (agreementValue) {
        delete this.projectSpecs[index].values[specItemKey];
        delete this.projectSpecs[index].newValues;
        delete this.projectSpecs[index].valuesKeys;
        delete this.projectSpecs[index].contactValueKeys;
        delete this.projectSpecs[index].accountValueKeys;
        delete this.projectSpecs[index].customValueKeys;
        var customFieldsIndexed = this.customFields[specId];
        for (var i = 0; i < customFieldsIndexed.length; i++) {
          if (customFieldsIndexed[i].key === specItemKey) {
            customFieldsIndexed.splice(i, 1);
          }
        }
        this.showCustomFieldTag =
          this.customFields[this.projectSpecs[index].id].length;
        this.renameKeys();
      }
      this.$forceUpdate();
    },
    editCustomField: function (index, specItemKey, specItemValue) {
      this.projectSpecs[index].values[specItemKey] = specItemValue;
      delete this.projectSpecs[index].newValues;
      delete this.projectSpecs[index].valuesKeys;
      delete this.projectSpecs[index].contactValueKeys;
      delete this.projectSpecs[index].accountValueKeys;
      delete this.projectSpecs[index].customValueKeys;
    },
    appendField: function (specId) {
      this.specAddButton = true;
      this.showCustomField = true;
      this.newCustomFields.push({
        specId: specId,
        customKey: null,
        customValue: null,
      });
    },
    changeState: function (status) {
      this.EditMode = status;
      this.newCustomFields = [];
    },
    editSpec: function () {
      this.disableSaveSpecBtn = false;
      this.specAddButton = false;
      this.changeState(true);
    },
    cancel: function (index) {
      this.showCustomField = false;
      if (!this.projectSpecs[index].id) {
        this.projectSpecs.splice(index, 1);
      }
      this.specAddButton = false;
      this.changeState(false);
      this.fetchProjectSpec();
    },
    saveSpec: function (projectId) {
      this.isSaving = true;
      this.$refs.form.validate();
      let self = this;
      let projectSetting = {
        target: self.projectSetting.target,
        contactsPerAccount: self.projectSetting.contactsPerAccount,
        status: self.projectSetting.status,
        priority: self.projectSetting.priority,
        users: self.project.Users,
      };
      const projectSettingDiff = difference(
        self.projectSetting,
        self.projectSettingPrev
      );
      var promiseArray = [];
      var promiseArrayCheck = true;
      if (Object.keys(projectSettingDiff).length !== 0) {
        promiseArray.push(this.settingApiCall(projectSetting));
      }
      for (let index = 0; index < self.projectSpecs.length; index++) {
        if (!self.projectSpecs[index].name) {
          promiseArray.splice(0, promiseArray.length);
          promiseArrayCheck = false;
          break;
        }
        promiseArray.push(this.saveEditSpec(index));
      }
      if (promiseArray.length || promiseArrayCheck) {
        Promise.allSettled(promiseArray)
          .then(function (res) {
            console.log(res);
            let notification = {
              type: "success",
              showMessage: true,
              message: self.$notifications().PROJECT_SPEC_SUCCESS,
            };
            self.setNotification(notification);
            self.EditMode = false;
            self.isSaving = false;
            self.fetchProjectSpec(projectId);
          })
          .catch(function (err) {
            console.log(err);

            let notification = {
              type: "error",
              showMessage: true,
              message: self.$notifications().PROJECT_SPEC_ERROR,
            };
            self.setNotification(notification);

            self.isSaving = false;
          });
      } else {
        let notification = {
          type: "error",
          showMessage: true,
          message: self.$notifications().PROJECT_SPEC_ERROR_REASON,
        };
        self.setNotification(notification);
        self.showCustomField = true;
        self.isSaving = false;
      }
    },
    settingApiCall: function (projectSetting) {
      return Api.editSettingCounter(this.projectId, projectSetting);
    },
    saveEditSpec: function (index) {
      this.showCustomField = false;
      let projectSpec = this.projectSpecs[index];
      this.projectSpecs[index] = this.validateSpec(projectSpec);
      let validProjectSpec = this.projectSpecs[index];
      if (!projectSpec.invalid) {
        if (this.newCustomFields.length !== 0) {
          for (let index = 0; index < this.newCustomFields.length; index++) {
            if (
              this.newCustomFields[index].customKey !== null &&
              this.newCustomFields[index].specId === projectSpec.id
            ) {
              validProjectSpec.values[this.newCustomFields[index].customKey] =
                this.newCustomFields[index].customValue;
            }
          }
        }
        if (projectSpec && projectSpec.id) {
          const checkDiff = difference(
            this.projectSpecs[index],
            this.projectSpecsPrev[index - this.newSpecCount]
          );

          if (checkDiff !== {}) {
            return Api.editSpec(
              this.projectId,
              projectSpec.id,
              validProjectSpec
            );
          }
        }
        return Api.createSpec(this.projectId, validProjectSpec);
      } else {
        this.specAddButton = false;
      }
    },
    updateSpec(index, model, value) {
      this.projectSpecs[index].values[model] = value;
    },
    addSpec: function () {
      let projectSpec = {
        name: null,
        values: {
          job_level: [],
          job_department: [],
          company_revenue: [],
          industry: [],
          sub_industry: [],
          geography: null,
          employee_count: [],
          job_function: [],
          job_title: [],
          excluded_job_title: [],
          contacts_per_account: [],
          note: null,
        },
      };
      this.changeState(true);
      this.projectSpecs.unshift(projectSpec);
      this.showCustomFieldTag = false;
      this.customKey = null;
      this.customValue = null;
      this.newSpecCount++;
    },
    fetchProject: function () {
      var self = this;
      return Api.getProject(this.projectId)
        .then((response) => {
          self.project = response.data;
          return self.project;
        })
        .catch((error) => {
          console.log(error);

          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().PROJECT_NOT_FOUNDED,
          };
          self.setNotification(notification);
        });
    },
    selectAllValues: function (items, field, specIndex) {
      var selectAllIndexNew = _.indexOf(
        this.projectSpecs[specIndex].values[field],
        "Select All"
      );
      if (selectAllIndexNew > -1) {
        this.projectSpecs[specIndex].values[field].splice(selectAllIndexNew, 1);
        var selectAllIndex = _.indexOf(items, "Select All");
        if (selectAllIndex > -1) {
          items.splice(selectAllIndex, 1);
          this.projectSpecs[specIndex].values[field] = _.concat(
            this.projectSpecs[specIndex].values[field],
            items
          );
        }
      }
      this.projectSpecs[specIndex].values[field] = _.uniq(
        this.projectSpecs[specIndex].values[field]
      );
      var difference = _.difference(
        items,
        this.projectSpecs[specIndex].values[field]
      );
      if (difference.length) {
        var selectAllPos = _.indexOf(items, "Select All");
        if (selectAllPos < 0) {
          items.unshift("Select All");
        }
      }
      this.projectSpecs[specIndex].values[field] = _.uniq(
        this.projectSpecs[specIndex].values[field]
      );
      items = _.uniq(items);
    },
    fetchProjectSpec: function () {
      //paramtert(cb)
      let self = this;
      let promiseArray = [];
      // TODO: use Promise.All to resolve all Api promise togather
      self.fetchProject();
      promiseArray.push(Api.getSpecs(this.projectId));
      promiseArray.push(Api.getSettings(this.projectId));
      promiseArray.push(Api.getFiles(this.projectId));
      console.log("promiseArray : ", promiseArray);
      Promise.allSettled(promiseArray)
        .then((responses) => {
          self.projectSetting = responses[1].value.data;
          if (_.isEmpty(self.projectSetting.target)) {
            self.projectSetting.target = { contactTarget: 0, accountTarget: 0 };
          }
          if (_.isEmpty(self.projectSetting.contactsPerAccount)) {
            self.projectSetting.contactsPerAccount = 0;
          }
          self.projectSettingPrev = JSON.parse(
            JSON.stringify(self.projectSetting)
          );
          self.projectSpecs = responses[0].value.data;
          self.renameKeys();
          self.changeState(false);
          let suppressionFiles = responses[2].value.data.filter(
            (file) => file.type === self.FILE_TYPES.SUPPRESSION
          );
          let supportingDocFiles = responses[2].value.data.filter(
            (file) => file.type === self.FILE_TYPES.SUPPORTING_DOCS
          );
          let inclusionFiles = responses[2].value.data.filter(
            (file) => file.type === self.FILE_TYPES.INCLUSION
          );
          self.suppressionList = suppressionFiles;
          self.supportingList = supportingDocFiles;
          self.inclusionList = inclusionFiles;
          // if (cb) {
          //   cb();
          // }
        })
        .catch((error) => {
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().PROJECT_SPEC_NOTFOUNDED,
          };
          self.setNotification(notification);

          console.log(error);
        });
    },
    validateSpec: function (spec) {
      spec.invalid = false;
      if (spec.name == null || spec.name == "") {
        spec.invalid = true;
      }
      return spec;
    },
    renameKeys: function () {
      let jsonMap = {
        job_level: "Job Level",
        job_department: "Job Department",
        job_function: "Job Function",
        job_title: "Job Title",
        excluded_job_title: "Excluded Job Title",
        employee_count: "Employee Count",
        company_revenue: "Company Revenue",
        industry: "Industry",
        sub_industry: "Sub Industry",
        geography: "Geography",
        contacts_per_account: "Contacts Per Account",
        note: "Note",
      };
      for (var i = 0; i < this.projectSpecs.length; i++) {
        this.projectSpecs[i].newValues = Object.assign(
          {},
          this.projectSpecs[i].values
        );
        var specValuesKeys = Object.keys(this.projectSpecs[i].newValues);
        for (var j = 0; j < specValuesKeys.length; j++) {
          var keyToCheck = specValuesKeys[j];
          if (
            this.projectSpecs[i].newValues[keyToCheck] &&
            typeof this.projectSpecs[i].newValues[keyToCheck] === "object"
          ) {
            this.projectSpecs[i].newValues[keyToCheck] = _.compact(
              this.projectSpecs[i].newValues[keyToCheck]
            );
            this.projectSpecs[i].newValues[keyToCheck] =
              this.projectSpecs[i].newValues[keyToCheck].join(", ");
          }
          var index = _.indexOf(this.fieldList, keyToCheck);
          if (index > -1) {
            var newKey = jsonMap[keyToCheck];
            this.projectSpecs[i].newValues[newKey] =
              this.projectSpecs[i].newValues[keyToCheck];
            delete this.projectSpecs[i].newValues[keyToCheck];
          }
        }
        this.projectSpecs[i].valuesKeys = Object.keys(
          this.projectSpecs[i].newValues
        );
        var validKeys = Object.keys(
          _.pickBy(this.projectSpecs[i].newValues, _.identity)
        );
        this.projectSpecs[i].contactValueKeys = _.intersection(
          validKeys,
          this.contactFields
        );
        this.projectSpecs[i].accountValueKeys = _.intersection(
          validKeys,
          this.accountFields
        );
        var contactAccountValueKeys = _.concat(
          this.projectSpecs[i].contactValueKeys,
          this.projectSpecs[i].accountValueKeys
        );
        this.projectSpecs[i].customValueKeys = _.difference(
          validKeys,
          contactAccountValueKeys
        );
      }

      this.loadFields(this.projectSpecs);
    },
    loadFields: function (specs) {
      specs.forEach((spec) => {
        var keys = Object.keys(spec.values);
        var unique = _.difference(keys, this.fieldList);
        this.customFields[spec.id] = unique.map((item) => {
          return {
            key: item,
            value: spec.values[item],
          };
        });
      });
      this.projectSpecsPrev = Object.assign({}, specs);
    },
    unlinkFile: function (fileData, agreement) {
      const self = this;

      if (agreement) {
        Api.unlinkSharedFile(this.clientId, fileData.projectId, fileData.id)
          .then((result) => {
            console.log(result);
            let notification = {
              type: "success",
              showMessage: true,
              message: self.$notifications().UNLINK_FILE,
            };
            self.setNotification(notification);
            self.fetchProjectSpec(this.projectId);
          })
          .catch((error) => {
            console.log(error);
            let notification = {
              type: "error",
              showMessage: true,
              message: self.$notifications().UNLINK_FILE_ERROR,
            };
            self.setNotification(notification);
          });
      }
    },
  },
};
</script>

<style scoped>
.specs_list {
  display: block;
  margin-top: 15px;
}

.read_view_spec {
  align-items: center !important;
}

.add_spec[disabled] {
  cursor: not-allowed;
  background: #eee;
}
.add_spec {
  font-weight: 500;
  font-size: 16px;
  padding: 10px 0;
  margin: 30px 0;
  color: #125ba7;
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
  border: 2px dashed #00458d;
  box-sizing: border-box;
  cursor: pointer;
  background: white;
}
.specs_item {
  border: 1px solid #6e6893;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 20px 10px 10px;
  margin-bottom: 20px;
  position: relative;
}

.showMore div:nth-child(n + 4) {
  display: none;
}

.showMore div div:nth-child(n + 4) {
  display: block;
}

.specs_item input[readonly] {
  background: #fafafa;
  border: none;
}

/* .specs_item section {
  padding: 5px;
} */

.specs_item section label,
.specs_item div.v-subheader {
  display: inline-block;
  min-width: 35%;
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  height: auto;
  text-transform: uppercase !important;
}

.counterNumber p,
.specs_item p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #25213b;
  margin-bottom: 5px;
}

.specs_item section input {
  height: 25px;
  margin-left: 15px;
  min-width: 60%;
  padding-left: 10px;
}
.noChip {
  margin: 15px 0 25px;
}

.counterTitles {
  margin-bottom: 15px;
}

.counterTitles h6 {
  font-family: "Lato";
  font-size: 16px;
  letter-spacing: 0.05em;
  color: #205fac;
  font-weight: 500;
  text-transform: uppercase !important;
}

.addSpecProperty {
  margin-left: 15px;
  margin-bottom: 15px;
}

.specNote {
  color: red;
  font-size: 15px;
  margin-left: 18px;
  margin-bottom: 15px;
}

.counters span span {
  display: inline-block;
  height: 28px;
  width: 168px;
}

.uploadSection {
  height: 120px;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px; /* line-height: 24px; */
  display: flex;
  align-items: center;
  color: #574f86;
}

.uploadSection span {
  margin-left: 110px;
  margin-right: 34px;
  display: inline-block;
  text-align: center;
}
.uploadSection input {
  background: #125ba7;
  color: #ffffff;
}
.uploadSection button {
  font-size: 13px;
  background: #125ba7;
  color: #ffffff;
}
/*
.downloadSection i {
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 5px;
  right: 5px;
}
*/
.downloadBar {
  width: 50%;
}
.downloadSection {
  text-align: left;
  background-color: #fff;
  color: #125ba7;
  border-top: 1px solid #ccc;
  margin-top: 5px;
  position: relative;
}
.spec_heading {
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
  border-top: 1px solid #ccc;
  margin: 15px 15px 25px;
  text-transform: uppercase !important;
  letter-spacing: 0.5px;
}

h5.spec_heading {
  border: 0;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
}
.edit_btn {
  position: absolute;
  right: 5px;
  top: 5px;
}
.theme--light.v-subheader {
  color: rgba(0, 0, 0, 1);
}
.actions_btns {
  border-top: 1px solid #ccc;
  margin-top: 3px;
}
.spec_save_btn {
  position: absolute;
  top: 45px;
  right: 25px;
}

.edit_mode_switch {
  position: absolute;
  top: 32px;
  right: 300px;
}
.showMoreSpan {
  cursor: pointer;
  padding-left: 10px;
  padding-top: 10px;
  display: block;
  border-top: 1px solid #ccc;
}
.showMoreSpan i {
  margin-left: -5px;
  margin-top: -3px;
}

.downloadSection span.filename {
  font-size: 16px;
  max-height: 40px;
  margin-top: 5px;
  max-width: 260px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (min-width: 1250px) and (max-width: 1400px) {
  .counterTitles h6 {
    font-size: 15px;
  }
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.fileContainerTitle {
  background: repeating-linear-gradient(350deg, #f7f9fb, transparent 5px);
  border: 1px solid #eee;
}
.fileContainer {
  border: 1px solid #eee;
  border-radius: 5px;
  border-top: 0;
}
</style>
