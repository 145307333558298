<template>
  <div class="content">
    <div class="header">
      <h1 class="mt-4">Agent List</h1>
      <div class="agent_action_buttons mt-4">
        <v-btn
          color="#8B83BA"
          class="ml-2 white--text"
          :loading="isDownloading"
          :disabled="isDownloading"
          rounded
          @click="downloadFile()"
        >
          Download <v-icon right>download</v-icon>
        </v-btn>
        <v-btn
          color="#8B83BA"
          class="ml-2 white--text"
          :loading="isRefreshing"
          :disabled="isRefreshing"
          rounded
          @click="fetchAgents()"
          >refresh <v-icon>mdi-cached</v-icon></v-btn
        >
      </div>
    </div>

    <div class="toolbar"></div>

    <div class="agent">
      <div class="agent_list">
        <v-expansion-panels class="mt-4" multiple readonly>
          <v-expansion-panel class="agent_list_headers" :readonly="true">
            <v-expansion-panel-header hide-actions>
              <div class="d-flex justify-space-right">
                <div class="field agent_name_flex">
                  <h6>Username</h6>
                </div>

                <div class="field">
                  <h6>First Name</h6>
                </div>

                <div class="field">
                  <h6>Last Name</h6>
                </div>

                <div class="field text-center">
                  <h6>Email</h6>
                </div>
              </div>
            </v-expansion-panel-header>
          </v-expansion-panel>

          <v-expansion-panel
            class="list_item"
            v-for="agent in agentList"
            :key="agent.id"
            :readonly="true"
          >
            <v-expansion-panel-header hide-actions>
              <div class="d-flex justify-space-right">
                <div
                  class="field agent_name_flex justify-space-between"
                  style="display: inherit"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <h4 v-bind="attrs" v-on="on">
                        {{
                          agent.userName
                            | checkEmpty
                            | markEmpty
                            | truncate($constants().COLUMN_LIMIT20)
                        }}
                      </h4>
                    </template>
                    <span
                      ><h4>{{ agent.userName }}</h4></span
                    >
                  </v-tooltip>

                  <v-icon
                    style="margin-right: 15px; color: #2462ae"
                    @click="copyToClipBoard(agent.userName)"
                    >content_copy</v-icon
                  >
                </div>

                <div class="field">
                  <h4>
                    {{ agent.firstName | checkEmpty | markEmpty }}
                  </h4>
                </div>

                <div class="field">
                  <h4>
                    {{ agent.lastName | checkEmpty | markEmpty }}
                  </h4>
                </div>

                <div class="field">
                  <h4>
                    {{ agent.gmailId | checkEmpty | markEmpty }}
                  </h4>
                </div>

                <div class="field" style="width: 100px"></div>
              </div>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div class="text-center">
        <v-pagination
          v-model="pageNumber"
          :length="Math.ceil(totalCount / pageSize)"
          :total-visible="$constants().TOTAL_VISIBLE_PAGES"
          circle
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          @input="fetchAgents()"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../services/Api";
var fileDownload = require("js-file-download");

export default {
  mounted: async function () {
    this.fetchAgents();
  },

  data() {
    return {
      isDownloading: false,
      isRefreshing: false,
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE_20,
      totalCount: 0,
      agentList: [],
      disableRefreshBtn: false,
    };
  },
  methods: {
    fetchAgents: function () {
      var self = this;
      self.isRefreshing = true;
      Api.getAgents({
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
        role: "agent",
      })
        .then((response) => {
          self.isRefreshing = false;
          self.agentList = response.data.docs;
          self.totalCount = response.data.totalCount;
          console.dir(response.data);
        })
        .catch((error) => {
          self.isRefreshing = false;
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().FETCH_CLIENT_ERROR,
          };
          self.setNotification(notification);
        });
    },
    downloadFile() {
      let self = this;
      this.isDownloading = true;
      Api.getAgents({
        role: "agent",
        download: true,
      })
        .then((response) => {
          let downloadFileName = "agentList" + Date.now() + ".csv";
          fileDownload(response.data, downloadFileName);
          let notification = {
            type: "success",
            showMessage: true,
            message: self.$notifications().FILE_SUCCESS,
          };
          this.isDownloading = false;
          self.setNotification(notification);
        })
        .catch((error) => {
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().FILE_ERROR,
          };
          this.isDownloading = false;
          self.setNotification(notification);
        });
    },
    getAgentList() {
      this.fetchAgents();
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    copyToClipBoard(value) {
      let self = this;
      if (!value) {
        let notification = {
          type: "error",
          showMessage: true,
          message: self.$notifications().COPY_CLIPBOARD_ERROR,
        };
        self.setNotification(notification);
      }

      this.$copyText(value).then(
        function (e) {
          console.log(e);
          let notification = {
            type: "success",
            showMessage: true,
            message: self.$notifications().COPY_CLIPBOARD_SUCCESS,
          };
          self.setNotification(notification);
        },
        function (e) {
          console.log(e);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().COPY_CLIPBOARD_ERROR,
          };
          self.setNotification(notification);
        }
      );
    },
  },
};
</script>

<style scoped>
.agent_list button.v-expansion-panel-header {
  cursor: auto !important;
}

.content {
  margin-left: 70px;
}

.header {
  margin: 15px 0 10px;
}

.header button {
  margin-right: 15px;
}

.header h1 {
  float: left;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}

.agents {
  width: 100%;
}
.counter_stats {
  float: left;
  width: 1300px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.counter_stats::-webkit-scrollbar {
  -webkit-appearance: none;
}

.counter_stats::-webkit-scrollbar:horizontal {
  height: 11px;
}

.counter_stats::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: #8b83ba;
}
.counter_stats .counter {
  margin: 10px 20px;
  flex: 0 0 auto;
}
.counter_stats .counter .counter_title {
  font-weight: 400;
  margin: 10px 0 15px;
  font-family: "Lato";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
  text-transform: uppercase !important;
}
.counter_stats .counter .counter_value {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #1c76d2;
  margin: 0;
  text-align: center;
}
.agent_list {
  width: 99%;
  float: left;
  margin: 5px 0 20px;
}

.stat_item {
  display: inline-block;
  margin: 0 15px;
  width: 160px;
}
.stat_item h6 {
  font-weight: 500;
  font-size: 14px;
}

.agent_list_headers {
  background: #ffffff;
  padding: 5px 10px;
  padding-left: 0;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
}

.agent_list_headers h6 {
  font-family: "Lato";
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
  text-transform: uppercase !important;
}
.agent_list_headers .field.agent_name_flex,
.list_item .field.agent_name_flex {
  width: 300px;
}

.agent_list_headers .field,
.list_item .field {
  width: 175px;
  padding: 5px;
}

.list_item .field h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #25213b;
}
.agent_import_download_buttons {
  position: absolute;
  top: 45px;
  right: 25px;
}
.notification {
  position: absolute;
}

.agent_action_buttons {
  position: absolute;
  right: 25px;
}
@media (min-width: 1250px) and (max-width: 1400px) {
  .list_item .field h4 {
    font-size: 16px;
  }
  .agent_list_headers .field.agent_name_flex,
  .list_item .field.agent_name_flex {
    width: 300px;
  }
  .agent_list_headers .field,
  .list_item .field {
    width: 185px;
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
