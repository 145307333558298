<template>
  <div class="contactSuggestions">
    <!-- Contact Form Dialog -->
    <v-dialog
      persistent
      v-model="contactSuggestionDialog"
      fullscreen
      hide-overlay
      style="z-index: 1050"
      transition="dialog-bottom-transition"
    >
      <!-- Master Contacts -->
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="float-left"
          depressed
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="resetContactSuggestions()"
        >
          Master Contacts <v-icon right>contact_phone</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Master Contacts</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-toolbar-items>
            <!-- refreshButton -->
            <v-btn
              v-if="showRefresh"
              color="primary"
              ref="refreshButton"
              depressed
            >
              <span style="text-transform: capitalize"
                >Status: {{ job.status }} &nbsp;&nbsp;-&nbsp;&nbsp; Imported:
                {{ job.imported }}
              </span>
              <v-progress-circular
                indeterminate
                color="white"
                small
                class="ml-5"
              ></v-progress-circular>
            </v-btn>

            <specification />

            <!-- select contact dropdown -->
            <v-row class="pt-3 px-3">
              <v-col cols="12" sm="12">
                <v-select
                  label="Job Level"
                  placeholder="Fetch Contacts by Job Level"
                  v-model="selectedJobLevel"
                  :items="jobLevelList"
                  outlined
                  dense
                  hide-details
                  clearable
                  @change="searchAndImportContacts()"
                ></v-select>
              </v-col>
            </v-row>

            <!-- Load More Contacts -->
            <v-btn
              :disabled="selectedJobLevel && contactList.length"
              color="primary"
              ref="refreshButton"
              depressed
              @click="importAndFetchMasterContacts()"
            >
              Load More Contacts <v-icon right>group_add</v-icon>
            </v-btn>

            <!-- refresh -->
            <v-btn
              :disabled="!selectedJobLevel"
              icon
              dark
              @click="fetchContactsSuggestions(true)"
            >
              <v-icon>refresh</v-icon>
            </v-btn>

            <!-- close -->
            <v-btn icon dark @click="closeSuggestions()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <div class="gradient_bg">
          <ContactFormFullscreen ref="contacFormFullScreenMasterIntgration" />

          <accountMiniView class="pt-4" id="accountMiniView" />

          <DataGrid
            v-if="contactList.length"
            gridId="contactSuggestionPageId"
            gridRef="contactSuggestionPageRef"
            gridHeight="75vh"
            :headers="headers"
            :itemList="contactList"
            :loading="loading"
            :totalCount="contactListCount"
            :pageNo="pageNumber"
            @fetchGrideData="fetchContactsSuggestions($event)"
            :allowMultiSort="true"
            @suggestItem="addContact($event)"
            @enqueueItem="removeContact($event)"
            @fetchButtonClickAction="googleSearch($event)"
          />

          <!-- loading -->
          <v-card v-else-if="!contactList.length && showRefresh">
            <v-card-title
              ><v-icon color="primary" left>autorenew</v-icon> Contacts Import
              in Progress</v-card-title
            >
            <v-card-subtitle
              >Please wait, Contacts will be visible soon...</v-card-subtitle
            >
          </v-card>

          <!-- No Contact Found -->
          <v-card v-else-if="!contactList.length && showImport">
            <v-card-title
              ><v-icon color="primary" left>info</v-icon> No Contacts found for
              this Job level.
            </v-card-title>
            <v-card-subtitle>Click on Import to Fetch Contacts</v-card-subtitle>
          </v-card>

          <v-card class="mx-3" v-else>
            <v-card-title
              ><v-icon color="primary" left>info</v-icon> Click on Fetch
              Contacts to select Job Level</v-card-title
            >
            <v-card-subtitle>
              Choose Job Level to view Contacts</v-card-subtitle
            >
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "../services/Api";
import _ from "lodash";
import DataGrid from "./dataGrid.vue";
import accountMiniView from "./accountMiniView.vue";
import specification from "./specification.vue";
import ContactFormFullscreen from "./contactFormFullscreen.vue";
import sanitize from "./sanitizer";

export default {
  props: {},
  components: {
    DataGrid,
    accountMiniView,
    specification,
    ContactFormFullscreen,
  },
  data() {
    return {
      showImport: false,
      showRefresh: false,
      fetchMenu: false,
      loading: false,
      job: {},
      jobLevelList: [],
      contactSuggestionDialog: false,
      headers: [
        {
          value: "Add Contact",
          text: "Actions",
          sortable: false,
          divider: true,
          width: "100px",
          type: "suggestActions",
          googleSearch: false,
        },
        {
          value: "googleSearchContact",
          text: "Search Contact",
          sortable: false,
          divider: true,
          width: "50px",
          googleSearch: false,
          type: "button",
          icon: "find_in_page",
        },
        {
          value: "name",
          text: "Name",
          sortable: false,
          divider: true,
          width: "150px",
          type: "string",
          googleSearch: true,
          tooltip: this.$constants().COLUMN_LIMIT20,
        },
        {
          value: "jobTitle",
          text: "Job Title",
          sortable: false,
          divider: true,
          width: "100px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "jobLevel",
          text: "Job Level",
          sortable: false,
          divider: true,
          width: "100px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "workEmail",
          text: "Email",
          sortable: false,
          divider: true,
          width: "170px",
          type: "string",
          googleSearch: false,
          tooltip: this.$constants().COLUMN_LIMIT20,
        },
        {
          value: "zbStatus",
          text: "ZB Status",
          sortable: false,
          divider: true,
          width: "100px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "linkedinHandle",
          text: "LinkedIn Profile",
          sortable: false,
          divider: true,
          width: "150px",
          type: "link",
          googleSearch: false,
        },
        {
          value: "otherSourceLink",
          text: "Other Source",
          sortable: false,
          divider: true,
          width: "150px",
          type: "link",
          googleSearch: false,
          tooltip: this.$constants().COLUMN_LIMIT20,
        },
        {
          value: "updatedAt",
          text: "Last Modified Date",
          sortable: false,
          divider: true,
          width: "150px",
          type: "date",
          googleSearch: false,
        },
      ],
      contactList: [],
      contactListCount: 0,
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE,
      selectedJobLevel: "",
      potential: "",
    };
  },
  mounted: async function () {},
  computed: {
    projectId() {
      return _.get(this.$store, "state.workspace.task.Project.id", null);
    },
    taskId() {
      return _.get(this.$store, "state.workspace.task.id", "");
    },
    workspaceAccount() {
      return _.get(this.$store, "state.workspace.account", {});
    },
    clientId() {
      return _.get(this.$store, "state.workspace.task.Project.ClientId", "");
    },
    agentContactState() {
      return _.get(this.$store.state, "agentContactState", {});
    },
    // select added contact on the basis of latest contact added
    accountContactList() {
      let contactDocs = _.get(this.$store, "state.workspace.contacts.docs");
      if (!contactDocs) {
        return undefined;
      }
      let accountContactList = sanitize(contactDocs);
      if (accountContactList?.length > 0) {
        return accountContactList;
      }
      return undefined;
    },
  },
  methods: {
    storedSelectedJobLevel() {
      return (
        _.get(
          this.$store.state,
          `agentContactState.${this.projectId}.${this.workspaceAccount.id}.filter.selectedJobLevel`
        ) || ""
      );
    },
    openContactFormFullScreen() {
      const contactFormFullScreen = _.get(
        this.$refs,
        "contacFormFullScreenMasterIntgration"
      );
      if (contactFormFullScreen) {
        contactFormFullScreen.contactFormDialog = true;
      }
    },
    fetchProject: async function (projectId) {
      var self = this;

      return Api.getSettings(projectId)
        .then((response) => {
          var emptyCheck = _.isEmpty(response.data);
          if (!response.data.masterImportOperationParam) {
            response.data.masterImportOperationParam = {};
          }
          var importParamsEmptyCheck = _.isEmpty(
            response.data.masterImportOperationParam.contact
          );
          if (!emptyCheck && !importParamsEmptyCheck) {
            self.jobLevelList =
              response.data.masterImportOperationParam.contact.filter.jobLevel.value;

            self.potential =
              response.data.masterImportOperationParam.contact.limit;
          }
        })
        .catch((error) => console.log(error));
    },
    resetContactSuggestions: async function () {
      await new Promise((resolve) => setTimeout(resolve, 10));
      this.selectedJobLevel = this.storedSelectedJobLevel();
      await this.fetchProject(this.projectId);
      await this.fetchContactListData();
      this.contactSuggestionDialog = true;
      this.showRefresh = false;
      this.showImport = false;
      this.contactList = [];
    },
    closeSuggestions: async function () {
      this.contactSuggestionDialog = false;
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    removeContact: async function (payload) {
      let self = this;
      return Api.addContactToGoldmine(
        this.projectId,
        payload.id,
        "removed",
        this.workspaceAccount.id,
        this.clientId
      )
        .then(function () {
          let notification = {
            type: "success",
            showMessage: true,
            message: "Contact Removed",
          };
          self.setNotification(notification);
          self.fetchContactsSuggestions();
          self.isSaving = false;
        })
        .catch((error) => {
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: "Failed Removing Contact",
          };
          self.setNotification(notification);
          self.isSaving = false;
        });
    },
    populateContactList: async function () {
      await this.$store.dispatch("populateContactLists", {
        taskId: this.taskId,
        projectId: this.projectId,
      });
    },
    getAddedContact: function (payload) {
      // this function will revese the contact list and then filter it on the bais of name
      let reverseAccountContactList = _.reverse(this.accountContactList);

      if (!reverseAccountContactList) {
        return undefined;
      }

      let filterdAccountContactList = reverseAccountContactList.filter(
        (contact) => {
          let name = `${contact.firstName}  ${contact.lastName}`;
          return name.toLocaleLowerCase() === payload.name.toLocaleLowerCase();
        }
      );

      if (filterdAccountContactList.length > 0) {
        return filterdAccountContactList[0];
      }

      return undefined;
    },
    addContact: async function (payload) {
      let self = this;
      return Api.addContactToGoldmine(
        this.projectId,
        payload.id,
        "added",
        this.workspaceAccount.id,
        this.clientId
      )
        .then(async () => {
          let notification = {
            type: "success",
            showMessage: true,
            message: "Contact Added to Goldmine",
          };
          self.setNotification(notification);
          self.fetchContactsSuggestions();
          await self.populateContactList();

          const addedContact = self.getAddedContact(payload);
          if (addedContact) {
            await self.$store.dispatch("fetchSelectedWorspaceContactData", {
              selectContact: addedContact,
            });
            self.openContactFormFullScreen();
          }

          self.isSaving = false;
        })
        .catch((error) => {
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: "Failed Adding Contact to Goldmine",
          };
          self.setNotification(notification);
          self.isSaving = false;
        });
    },
    importAndFetchMasterContacts: async function () {
      let self = this;
      let filter = {
        jobLevel: {
          operator: "=",
          value: [self.selectedJobLevel],
        },
      };
      this.saveFilterToVuex();
      try {
        await Api.importAndFetchMasterContacts(self.projectId, {
          accountId: self.workspaceAccount.id,
          startImport: true,
          filter,
          limit: self.potential,
          accountDomain: self.workspaceAccount.domain,
        });
        self.showRefresh = true;
        await self.refreshStatus();
        self.showImport = false;
      } catch (e) {
        console.log(e);
        let notification = {
          type: "error",
          showMessage: true,
          message: "Contacts Master Import Failed",
        };
        self.setNotification(notification);
      }
    },
    saveFilterToVuex: function () {
      let tempAgentContactState = this.agentContactState;
      _.set(
        tempAgentContactState,
        `${this.projectId}.${this.workspaceAccount.id}.filter.selectedJobLevel`,
        this.selectedJobLevel
      );
      this.$store.dispatch("setAgentContactStateAction", tempAgentContactState);
    },
    fetchContactsSuggestions: async function (allowRefresh) {
      let self = this;
      let filter = {
        jobLevel: {
          operator: "=",
          value: [self.selectedJobLevel],
        },
      };
      let pageNo = this.pageNumber - 1;
      let pageSize = this.pageSize;
      this.loading = true;

      this.saveFilterToVuex();

      return Api.fetchMasterContacts(
        self.projectId,
        self.workspaceAccount.domain,
        filter,
        pageNo,
        pageSize
      )
        .then(function (response) {
          self.contactList = [];
          self.contactListCount = response.data.totalCount;
          self.contactList = response.data.contacts;
          self.loading = false;
          self.fetchMenu = false;
          self.showImport = false;
          if (!self.contactList.length && !allowRefresh) {
            self.fetchMenu = true;
            self.showImport = true;
          }
          if (allowRefresh) {
            self.refreshStatus();
          }
        })
        .catch((error) => {
          self.fetchMenu = false;
          let notification = {
            type: "error",
            showMessage: true,
            message: "Contacts Fetch Failed",
          };
          self.loading = false;
          self.setNotification(notification);
          console.log(error);
        });
    },
    refreshStatus: async function () {
      let self = this;
      return Api.refreshMasterContactsStatus(
        this.projectId,
        this.workspaceAccount.id
      )
        .then(function (response) {
          self.job = response.data;
          if (self.job.status === "Completed") {
            self.showRefresh = false;
          }
          self.fetchContactsSuggestions();
        })
        .catch((error) => {
          let notification = {
            type: "error",
            showMessage: true,
            message: "Fetch Contacts Master Import Status Failed",
          };
          self.setNotification(notification);
          console.log(error);
        });
    },
    googleSearch: function (data) {
      let finalValue =
        `https://www.google.com/search?q=` + encodeURIComponent(data.value);
      let googleSearchUrl = finalValue;
      window.open(googleSearchUrl, "_blank", "noreferrer");
    },
    fetchContactListData: async function () {
      if (!this.jobLevelList.length) {
        return;
      }

      this.selectedJobLevel = this.selectedJobLevel || this.jobLevelList[0];

      this.searchAndImportContacts();
    },
    searchAndImportContacts: async function () {
      await this.fetchContactsSuggestions();

      if (!this.contactList.length) {
        await this.importAndFetchMasterContacts();
      }
    },
  },
};
</script>

<style scoped>
.contactSuggestions {
  position: absolute;
  top: 10px;
  right: 245px;
}
.gradient_bg {
  padding: 25px 15px;
}
.addContact {
  position: absolute;
  right: 10px;
}
.contactPagination {
  background: white;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  border-radius: 5px;
}

#accountMiniView {
  margin: 12px;
  border: 1px solid lightgrey;
  border-style: double;
}

/* #accountMiniView .counter_stats {
  margin: 0px;
  padding: 0px !important;
} */
</style>
