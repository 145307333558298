<template>
  <div class="notification">
    <v-snackbar
      :timeout="timeout"
      v-model="notification.showMessage"
      top
      :color="notification.type"
    >
      {{ notification.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="notification.showMessage = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "notification",
  computed: {
    notification() {
      return this.$store.state.notification;
    },
  },
  data() {
    return {
      timeout: 3000,
    };
  },
  beforeCreate() {
    // before create notification on refresh, clears the previous Notification Message
    this.$store.commit("clearNotification");
  },
};
</script>
<style scoped>
.notification {
  z-index: 10000;
}
</style>
