<template>
  <div>
    <div class="form pt-2">
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="orderSection">
          <!-- Detail Section -->
          <div
            v-if="sectionKeyBy.Detail && sectionKeyBy.Detail.display"
            v-bind:style="{
              order: sectionKeyBy.Detail.order,
            }"
            class="border"
          >
            <v-row class="col-md-12">
              <v-col
                v-if="sectionKeyBy.Detail.fields['account.name']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Detail.fields["account.name"].label }}
                </h4>

                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.name }"
                  v-model="account.name"
                  :tabindex="sectionKeyBy.Detail.fields['account.name'].order"
                  outlined
                  dense
                  hide-details
                  required
                ></v-text-field>
              </v-col>

              <v-col
                v-if="sectionKeyBy.Detail.fields['account.website']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Detail.fields["account.website"].label }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-model="account.website"
                  v-bind:class="{ highlight: !account.website }"
                  :tabindex="
                    sectionKeyBy.Detail.fields['account.website'].order
                  "
                  outlined
                  dense
                  hide-details
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="col-md-12 mt-0 pt-0">
              <v-col
                v-if="sectionKeyBy.Detail.fields['account.emailDomain']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Detail.fields["account.emailDomain"].label }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.emailDomain }"
                  v-model="account.emailDomain"
                  :tabindex="
                    sectionKeyBy.Detail.fields['account.emailDomain'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col v-if="sectionKeyBy.Detail.fields['account.custom1']">
                <h4 class="text-uppercase mb-2">
                  {{ this.sectionKeyBy.Detail.fields["account.custom1"].label }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.custom1 }"
                  v-model="account.custom1"
                  :tabindex="
                    sectionKeyBy.Detail.fields['account.custom1'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <!-- Technology-->
          <div
            v-if="sectionKeyBy.Technology && sectionKeyBy.Technology.display"
            v-bind:style="{
              order: sectionKeyBy.Technology.order,
            }"
            class="orderFields border"
          >
            <v-row class="col-md-12">
              <v-col
                v-if="
                  sectionKeyBy.Technology.fields['account.segment_technology']
                "
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Technology.fields['account.segment_technology']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Technology.fields["account.segment_technology"]
                      .label
                  }}
                </h4>
                <v-combobox
                  class="input_data"
                  v-bind:class="{
                    highlight: !isAccountSegmentHighlight,
                  }"
                  v-model="account.segment_technology"
                  :tabindex="
                    sectionKeyBy.Technology.fields['account.segment_technology']
                      .order
                  "
                  chips
                  clearable
                  deletable-chips
                  multiple
                  outlined
                  dense
                  hide-details
                ></v-combobox>
              </v-col>
            </v-row>
          </div>

          <!-- Industry -->
          <div
            v-if="sectionKeyBy.Industry && sectionKeyBy.Industry.display"
            v-bind:style="{
              order: sectionKeyBy.Industry.order,
            }"
            class="orderFields border"
          >
            <v-row class="col-md-12">
              <v-col
                v-if="sectionKeyBy.Industry.fields['account.industry']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order: sectionKeyBy.Industry.fields['account.industry'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Industry.fields["account.industry"].label }}
                </h4>
                <v-combobox
                  class="input_data"
                  v-bind:class="{ highlight: !account.industry }"
                  v-model="account.industry"
                  :tabindex="
                    sectionKeyBy.Industry.fields['account.industry'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-combobox>
              </v-col>

              <v-col
                v-if="sectionKeyBy.Industry.fields['account.subIndustry']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Industry.fields['account.subIndustry'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Industry.fields["account.subIndustry"].label
                  }}
                </h4>
                <v-combobox
                  class="input_data"
                  v-bind:class="{ highlight: !account.subIndustry }"
                  v-model="account.subIndustry"
                  :tabindex="
                    sectionKeyBy.Industry.fields['account.subIndustry'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-combobox>
              </v-col>

              <v-col
                v-if="sectionKeyBy.Industry.fields['account.sicCode']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order: sectionKeyBy.Industry.fields['account.sicCode'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Industry.fields["account.sicCode"].label }}
                </h4>
                <v-combobox
                  class="input_data"
                  v-bind:class="{ highlight: !account.sicCode }"
                  v-model="account.sicCode"
                  :tabindex="
                    sectionKeyBy.Industry.fields['account.sicCode'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-combobox>
              </v-col>
              <v-col
                v-if="sectionKeyBy.Industry.fields['account.sicDescription']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Industry.fields['account.sicDescription']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Industry.fields["account.sicDescription"].label
                  }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.sicDescription }"
                  v-model="account.sicDescription"
                  :tabindex="
                    sectionKeyBy.Industry.fields['account.sicDescription'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col
                v-if="sectionKeyBy.Industry.fields['account.naicsCode']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Industry.fields['account.naicsCode'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Industry.fields["account.naicsCode"].label }}
                </h4>
                <v-combobox
                  class="input_data"
                  v-bind:class="{ highlight: !account.naicsCode }"
                  v-model="account.naicsCode"
                  :tabindex="
                    sectionKeyBy.Industry.fields['account.naicsCode'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-combobox>
              </v-col>

              <v-col
                v-if="sectionKeyBy.Industry.fields['account.naicsDescription']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Industry.fields['account.naicsDescription']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Industry.fields["account.naicsDescription"]
                      .label
                  }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.naicsDescription }"
                  v-model="account.naicsDescription"
                  :tabindex="
                    sectionKeyBy.Industry.fields['account.naicsDescription']
                      .order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <!-- address -->
          <div
            v-if="sectionKeyBy.Address && sectionKeyBy.Address.display"
            v-bind:style="{
              order: sectionKeyBy.Address.order,
            }"
            class="orderFields border"
          >
            <v-row class="col-md-12 mx-0 pa-0 mt-0">
              <v-col class="pt-0 mt-2 pb-0" cols="12" sm="12">
                <h4 class="text-uppercase col-md-12 pa-0">Address Search</h4>
              </v-col>

              <v-col
                v-if="
                  sectionKeyBy.Address.fields['account.addressHQ.address1HQ']
                "
                class="d-flex pt-0 pb-1 pr-8"
                style="position: relative"
                cols="12"
                sm="12"
              >
                <addressSearch
                  addressId="accountFormId"
                  addressRef="accountFormRef"
                  :tabIndex="
                    sectionKeyBy.Address.fields['account.addressHQ.address1HQ']
                      .order
                  "
                  class="py-2 addressWrapper"
                />

                <v-icon> search </v-icon>
              </v-col>

              <v-col
                v-if="
                  sectionKeyBy.Address.fields['account.addressHQ.address1HQ']
                "
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['account.addressHQ.address1HQ']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Address.fields["account.addressHQ.address1HQ"]
                      .label
                  }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.addressHQ.address1HQ }"
                  v-model="account.addressHQ.address1HQ"
                  :tabindex="
                    sectionKeyBy.Address.fields['account.addressHQ.address1HQ']
                      .order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col
                v-if="
                  sectionKeyBy.Address.fields['account.addressHQ.address2HQ']
                "
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['account.addressHQ.address2HQ']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Address.fields["account.addressHQ.address2HQ"]
                      .label
                  }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.addressHQ.address2HQ }"
                  v-model="account.addressHQ.address2HQ"
                  :tabindex="
                    sectionKeyBy.Address.fields['account.addressHQ.address2HQ']
                      .order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col
                v-if="sectionKeyBy.Address.fields['account.addressHQ.cityHQ']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['account.addressHQ.cityHQ']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Address.fields["account.addressHQ.cityHQ"]
                      .label
                  }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.addressHQ.cityHQ }"
                  v-model="account.addressHQ.cityHQ"
                  :tabindex="
                    sectionKeyBy.Address.fields['account.addressHQ.cityHQ']
                      .order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                v-if="sectionKeyBy.Address.fields['account.addressHQ.stateHQ']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['account.addressHQ.stateHQ']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Address.fields["account.addressHQ.stateHQ"]
                      .label
                  }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.addressHQ.stateHQ }"
                  v-model="account.addressHQ.stateHQ"
                  :tabindex="
                    sectionKeyBy.Address.fields['account.addressHQ.stateHQ']
                      .order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col
                v-if="
                  sectionKeyBy.Address.fields['account.addressHQ.countryHQ']
                "
                class="pt-0 mt-2 pb-0"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['account.addressHQ.countryHQ']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Address.fields["account.addressHQ.countryHQ"]
                      .label
                  }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.addressHQ.countryHQ }"
                  v-model="account.addressHQ.countryHQ"
                  :tabindex="
                    sectionKeyBy.Address.fields['account.addressHQ.countryHQ']
                      .order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <v-col
                v-if="
                  sectionKeyBy.Address.fields['account.addressHQ.zipCodeHQ']
                "
                class="pt-0 mt-2 pb-0"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['account.addressHQ.zipCodeHQ']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Address.fields["account.addressHQ.zipCodeHQ"]
                      .label
                  }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.addressHQ.zipCodeHQ }"
                  v-model="account.addressHQ.zipCodeHQ"
                  :tabindex="
                    sectionKeyBy.Address.fields['account.addressHQ.zipCodeHQ']
                      .order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <!-- Phone-->
          <div
            v-if="sectionKeyBy.Phone && sectionKeyBy.Phone.display"
            v-bind:style="{
              order: sectionKeyBy.Phone.order,
            }"
            class="orderFields border"
          >
            <v-row class="col-md-12" style="padding-bottom: 0 !important">
              <v-col
                v-if="sectionKeyBy.Phone.fields['account.phoneHQ']"
                class="pt-0 pb-1"
                cols="12"
                sm="6"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Phone.fields["account.phoneHQ"].label }}
                </h4>

                <vue-phone-number-input
                  v-bind:class="{ highlight: !account.phoneHQ }"
                  v-model="account.phoneHQ"
                  default-country-code="US"
                  :error="false"
                  :tabindex="sectionKeyBy.Phone.fields['account.phoneHQ'].order"
                />
              </v-col>
            </v-row>
          </div>

          <!-- Misc(miscellaneous) -->
          <div
            v-if="sectionKeyBy.Misc && sectionKeyBy.Misc.display"
            v-bind:style="{
              order: sectionKeyBy.Misc.order,
            }"
            class="border"
          >
            <v-row class="col-md-12">
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-for="(field, index) in miscellaneousFields"
                :key="field.id"
                v-show="miscellaneousFields[index]"
              >
                <h4 class="text-uppercase mb-2">
                  {{ field.label }}
                </h4>

                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !field.id }"
                  v-model="account[field.id]"
                  :type="field.type"
                  :tabindex="field.order"
                  outlined
                  dense
                  hide-details
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import addressSearch from "./AddressSearch.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  props: {},
  components: { addressSearch, VuePhoneNumberInput },
  data() {
    return {
      valid: false,
      account: {
        segment_technology: [],
        addressHQ: {
          address1HQ: "",
          address2HQ: "",
          cityHQ: "",
          stateHQ: "",
          countryHQ: "",
          zipCodeHQ: "",
          regionHQ: "",
        },
        TaskLink: {},
      },
      sectionKeyBy: [],
      miscellaneousFields: [],
    };
  },
  mounted: function () {
    this.accountFormFieldCustomization(this.accountFields);
  },
  computed: {
    accountFields() {
      return this.$store.state.template.accountFields;
    },
  },
  watch: {
    accountFields: function (value) {
      this.accountFormFieldCustomization(value);
    },
  },
  methods: {
    accountFormFieldCustomization: function (value) {
      this.sectionKeyBy = _.keyBy(value, "section");

      this.customizationAccountFormFields(this.sectionKeyBy);
      for (const key in this.sectionKeyBy) {
        let section = this.sectionKeyBy[key];
        let sectionFields = section.fields;

        let fieldKeyBy = _.keyBy(sectionFields, "id");
        this.sectionKeyBy[key].fields = fieldKeyBy;
      }
    },
    customizationAccountFormFields(value) {
      for (const property in value) {
        if (property === "Misc") {
          this.miscellaneousFields = value.Misc.fields;
        }
      }
    },
  },
};
</script>

<style scoped>
.border {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px !important;
}

.orderFields {
  display: flex;
  flex-direction: row;
}

.orderSection {
  display: flex;
  flex-direction: column;
}

.highlight {
  background: lightyellow;
}

.form h4 {
  font-family: Lato;
  font-size: 14px;
  color: #6e6893;
  font-weight: 400;
}
.scroller {
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 30px;
  height: calc(100vh - 245px);
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
} /* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} /* Handle */
::-webkit-scrollbar-thumb {
  background: #8b83ba;
  border-radius: 10px;
} /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #686094;
}

/* Buttons */

::-webkit-scrollbar-button:single-button {
  background-color: #686094;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
} /* Up
*/
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
}
::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
} /* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
}
::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.addressWrapper .address-container {
  width: 100%;
  height: 45px;
  padding: 8px 0;
  padding-left: 8px;
  border: 1px solid #ccc;
}

.addressWrapper + i {
  right: 40px;
  font-size: 25px;
  position: absolute;
  top: 15px;
}

.addressWrapper {
  width: 100%;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
