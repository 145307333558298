<template>
  <div>
    <div>
      <v-row class="mb-2 px-4 counter_stats">
        <v-col class="py-0">
          <div>
            <p class="account_title mb-2">Contacts</p>
            <p class="account_value">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span style="cursor: default" v-bind="attrs" v-on="on">
                    {{ totalContact }}
                  </span>
                </template>
                <span> <h4>Contacts Built</h4></span>
              </v-tooltip>
              <span v-if="qualifiedContactCount || qualifiedContactCount === 0">
                /
              </span>
              <v-tooltip
                bottom
                v-if="qualifiedContactCount || qualifiedContactCount === 0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span style="cursor: default" v-bind="attrs" v-on="on">
                    {{ qualifiedContactCount | humanizeNumber }}
                  </span>
                </template>
                <span> <h4>Qualified Contacts</h4></span>
              </v-tooltip>
              <span v-if="potential || potential === 0"> / </span>
              <v-tooltip bottom v-if="potential || potential === 0">
                <template v-slot:activator="{ on, attrs }">
                  <span style="cursor: default" v-bind="attrs" v-on="on">
                    {{ potential | humanizeNumber }}
                  </span>
                </template>
                <span> <h4>Potential</h4></span>
              </v-tooltip>
            </p>
          </div>
        </v-col>

        <v-col class="py-0">
          <p class="account_title mb-2">Domain</p>
          <a :href="domainAnchor" target="_blank" class="account_value">{{
            domain | checkEmpty | markEmpty
          }}</a>
        </v-col>

        <v-col class="py-0">
          <div>
            <p class="account_title mb-2">Employee Size</p>
            <p class="account_value">
              {{ employeeSizeGeneral | checkEmpty | markEmpty }}
            </p>
          </div>
        </v-col>

        <v-col class="py-0">
          <div>
            <p class="account_title mb-2">Revenue</p>
            <p class="account_value">
              {{ revenueGeneral | checkEmpty | markEmpty }}
            </p>
          </div>
        </v-col>

        <v-col class="py-0">
          <div>
            <div>
              <p class="account_title mb-2">Address</p>
              <p class="account_value">
                <span>
                  {{ city | checkEmpty | markEmpty }},
                  {{ state | checkEmpty | markEmpty }},
                  {{ country | checkEmpty | markEmpty }}
                </span>
              </p>
            </div>
          </div>
        </v-col>

        <v-col class="py-0">
          <div>
            <div>
              <p class="account_title mb-2">Phone</p>
              <p class="account_value">
                {{ account.phoneHQ | checkEmpty | markEmpty }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  components: {},
  props: {},
  data() {
    return {
      projectId: "",
      accountId: "",
      city: "",
      state: "",
      country: "",
      domain: "",
      clearBitLogo: "",
      domainAnchor: "",
      revenueGeneral: "",
      qualifiedContactCount: 0,
      employeeSizeGeneral: "",
    };
  },
  computed: {
    potential() {
      return this.getPotential(this.$store.state.workspace.yield);
    },
    totalContact() {
      return this.$store.state.workspace.contacts.totalCount;
    },
    taskId() {
      return this.$store.state.workspace.task.id;
    },
    account() {
      return this.getAccountById(this.$store.state.workspace.account);
    },
  },
  methods: {
    getAccountById: function (account) {
      this.domain = account.domain;

      this.clearBitLogo = this.$constants().CLEAR_BIT_LOGO + this.domain;
      this.domainAnchor =
        this.$constants().URL_DOUBLE_SLASH + encodeURIComponent(this.domain);

      let logo = {
        clearBitLogo: this.clearBitLogo,
        domainAnchor: this.domainAnchor,
      };

      this.$emit("clearBitLogo", logo);

      if (account.addressHQ) {
        this.city = account.addressHQ.cityHQ || "";
        this.state = account.addressHQ.stateHQ || "";
        this.country = account.addressHQ.countryHQ || "";
      } else {
        this.city = "";
        this.state = "";
        this.country = "";
      }
      this.revenueGeneral = account.revenue || account.revenue_M_B_K;
      this.revenueGeneral = _.trim(this.revenueGeneral);
      this.employeeSizeGeneral =
        account.employeeSize ||
        account.employeeSizeLI ||
        account.employeeSizeZ_plus ||
        account.employeeSizeFinalBucket ||
        account.employeeSize_others ||
        account.employeeRangeLI;
      this.employeeSizeGeneral = _.trim(this.employeeSizeGeneral);
      return account;
    },
    getPotential: function (value) {
      this.qualifiedContactCount = 0;
      if (value) {
        if (value.contactsPerAccount) {
          let updatedContactsPerAccount = value.contactsPerAccount.toString();
          updatedContactsPerAccount = updatedContactsPerAccount.replaceAll(
            ",",
            ""
          );
          updatedContactsPerAccount = parseInt(updatedContactsPerAccount);
          if (
            updatedContactsPerAccount &&
            value.positiveDisposedContactsCountsOfAnAccount
          ) {
            this.qualifiedContactCount = parseInt(
              value.positiveDisposedContactsCountsOfAnAccount.toString()
            );
            const potentialVal =
              updatedContactsPerAccount -
              parseInt(
                value.positiveDisposedContactsCountsOfAnAccount.toString()
              );
            if (potentialVal < 0) {
              return 0;
            }
            return potentialVal;
          }
          this.qualifiedContactCount = parseInt(
            value.positiveDisposedContactsCountsOfAnAccount.toString()
          );
          return value.contactsPerAccount;
        }
      }
      return 0;
    },
  },
};
</script>

<style>
.account_title {
  font-weight: 500;
  margin: 10px 0 15px;
  font-family: "Lato";
  font-size: 16px;
  line-height: 21px;
  color: #6e6893;
  max-height: 40px;
  overflow: hidden;
}

.account_value {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #00458d;
}
</style>
