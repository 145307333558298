import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import routes from "./routes.js";
import Filters from "./filters.js";
import { store } from "./store/index.js";

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
Vue.use(Vuetify);

import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.css";
Vue.use(VueMaterial);

import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);

import Constants from "@/plugins/Constants.js";
Vue.use(Constants);

import Notifications from "@/plugins/Notifications.js";
Vue.use(Notifications);

Vue.use(require("vue-shortkey"));

import VueClipboard from "vue-clipboard2";
import { getRolesSync } from "./roles";
Vue.use(VueClipboard);

Vue.config.productionTip = false;
Vue.use(VueRouter);

const router = new VueRouter({
  routes: routes,
});

// this will stop unauthorized access
router.beforeEach(async (to, from, next) => {
  const canAccess = (to, userRoles) => {
    if (!to.meta.requiresAuth) return true;

    if (to.meta.roles) {
      return userRoles.some((role) => to.meta.roles.includes(role));
    }

    return false;
  };

  const roles = getRolesSync().split(",");

  if (canAccess(to, roles)) {
    next();
  } else {
    next(from);
  }
});

Vue.use(Filters);

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: (h) => h(App),
}).$mount("#app");
