<template>
  <div>
    <div class="form pt-2">
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="orderSection">
          <!-- Name -->
          <div
            v-if="sectionKeyBy.Name && sectionKeyBy.Name.display"
            v-bind:style="{
              order: sectionKeyBy.Name.order,
            }"
            class="border"
          >
            <v-row class="col-md-12 mx-0 pa-0 mb-2">
              <!-- prefix -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Name.fields['contact.prefix']"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Name.fields["contact.prefix"].label
                  }}<span style="color: #ff0000"></span>
                  <span
                    class="previousTitles"
                    v-if="formContact.previous_prefix !== formContact.prefix"
                  >
                    {{ formContact.previous_prefix | checkEmpty }}
                  </span>
                </h4>
                <v-combobox
                  class="input_data"
                  v-bind:class="{ highlight: !formContact.prefix }"
                  v-model="formContact.prefix"
                  ref="prefixTxtField"
                  :tabindex="sectionKeyBy.Name.fields['contact.prefix'].order"
                  hide-details
                  outlined
                  dense
                ></v-combobox>
              </v-col>

              <!-- firstName -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Name.fields['contact.firstName']"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Name.fields["contact.firstName"].label }}
                  <span style="color: #ff0000">*</span>
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_firstName !== formContact.firstName
                    "
                  >
                    {{ formContact.previous_firstName | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !formContact.firstName }"
                  v-model="formContact.firstName"
                  required
                  hide-details
                  :tabindex="
                    sectionKeyBy.Name.fields['contact.firstName'].order
                  "
                  dense
                  outlined
                ></v-text-field>
              </v-col>

              <!-- Split Name -->
              <v-col
                class="pt-0 mt-2 pb-1 mt-8"
                cols="12"
                sm="2"
                v-if="sectionKeyBy.Name.fields['contact.firstName']"
              >
                <v-btn
                  depressed
                  block
                  color="primary"
                  :tabindex="
                    sectionKeyBy.Name.fields['contact.firstName'].order
                  "
                >
                  Split Name <v-icon right>call_split</v-icon>
                </v-btn>
              </v-col>

              <!-- middleName -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Name.fields['contact.middleName']"
              >
                <h4 class="text-uppercase">
                  {{ sectionKeyBy.Name.fields["contact.middleName"].label }}
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_middleName !== formContact.middleName
                    "
                  >
                    {{ formContact.previous_middleName | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !formContact.middleName }"
                  v-model="formContact.middleName"
                  :tabindex="
                    sectionKeyBy.Name.fields['contact.middleName'].order
                  "
                  outlined
                  hide-details
                  dense
                ></v-text-field>
              </v-col>

              <!-- lastName -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Name.fields['contact.lastName']"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Name.fields["contact.lastName"].label }}
                  <span style="color: #ff0000">*</span>
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_lastName !== formContact.lastName
                    "
                    >{{ formContact.previous_lastName }}</span
                  >
                </h4>
                <v-text-field
                  v-model="formContact.lastName"
                  v-bind:class="{ highlight: !formContact.lastName }"
                  required
                  hide-details
                  :tabindex="sectionKeyBy.Name.fields['contact.lastName'].order"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <!-- Email -->
          <div
            v-if="sectionKeyBy.Email && sectionKeyBy.Email.display"
            v-bind:style="{
              order: sectionKeyBy.Email.order,
            }"
            class="orderFields border"
          >
            <v-row class="col-md-12 mx-0 px-0 pb-0">
              <!-- email -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Email.fields['contact.email']"
                v-bind:style="{
                  order: sectionKeyBy.Email.fields['contact.email'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Email.fields["contact.email"].label }}
                  <span
                    class="previousTitles"
                    v-if="formContact.previous_email !== formContact.email"
                    >{{ formContact.previous_email | checkEmpty }}</span
                  >
                </h4>
                <v-combobox
                  class="input_data"
                  v-bind:class="{ highlight: !formContact.email }"
                  hide-selected
                  v-model="formContact.email"
                  outlined
                  :tabindex="sectionKeyBy.Email.fields['contact.email'].order"
                  dense
                  hide-details
                >
                </v-combobox>
              </v-col>

              <!-- zb -->
              <v-col
                class="pt-0 pb-1 mt-8"
                cols="12"
                sm="1"
                v-if="sectionKeyBy.Email.fields['contact.zb']"
                v-bind:style="{
                  order: sectionKeyBy.Email.fields['contact.zb'].order,
                }"
              >
                <v-btn
                  v-show="true"
                  color="primary"
                  depressed
                  block
                  :tabindex="sectionKeyBy.Email.fields['contact.zb'].order"
                >
                  {{ sectionKeyBy.Email.fields["contact.zb"].label }}
                </v-btn>
              </v-col>

              <!-- zbBox -->
              <v-col
                class="pt-0 pb-2"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Email.fields['contact.zb']"
                v-bind:style="{
                  order: sectionKeyBy.Email.fields['contact.zb'].order,
                }"
              >
                <v-card
                  class="zerobounce-wrapper mt-5 mb-1 float-left col-md-12 pa-0"
                  outlined
                  :tabindex="sectionKeyBy.Email.fields['contact.zb'].order"
                >
                  <v-list-item two-line class="px-3">
                    <v-list-item-content class="pt-2 pb-1">
                      <div class="mb-1">
                        <p>
                          {{ sectionKeyBy.Email.fields["contact.zb"].label }}
                          Status:
                          <b
                            v-if="formContact.zb === 'valid'"
                            style="color: #00ae31"
                            >{{ formContact.zb }}</b
                          >
                          <b
                            v-else-if="formContact.zb === 'catch-all'"
                            style="color: #daa520"
                            >{{ formContact.zb }}</b
                          >
                          <b v-else style="color: #ff0000">{{
                            formContact.zb
                          }}</b>
                        </p>
                      </div>
                      <div class="mb-1">
                        <p>
                          Last Verified:
                          <b style="color: #00ae31">{{
                            formContact.zbDateAndTime
                              | checkEmpty
                              | markEmpty
                              | momentFormatDate
                          }}</b>
                        </p>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>

              <!-- mailTesterStatus -->
              <v-col
                class="pt-0 pb-1 mt-2"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Email.fields['contact.mailTesterStatus']"
                v-bind:style="{
                  order:
                    sectionKeyBy.Email.fields['contact.mailTesterStatus'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{
                    sectionKeyBy.Email.fields["contact.mailTesterStatus"].label
                  }}

                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_mailTesterStatus !==
                      formContact.mailTesterStatus
                    "
                  >
                    {{ formContact.previous_mailTesterStatus | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  v-model="formContact.mailTesterStatus"
                  v-bind:class="{
                    highlight: !formContact.mailTesterStatus,
                  }"
                  :tabindex="
                    sectionKeyBy.Email.fields['contact.mailTesterStatus'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- gmailStatus -->
              <v-col
                class="pt-0 pb-1 mt-2"
                cols="12"
                sm="2"
                v-if="sectionKeyBy.Email.fields['contact.gmailStatus']"
                v-bind:style="{
                  order: sectionKeyBy.Email.fields['contact.gmailStatus'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Email.fields["contact.gmailStatus"].label }}
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_gmailStatus !==
                      formContact.gmailStatus
                    "
                  >
                    {{ formContact.previous_gmailStatus | checkEmpty }}
                  </span>
                </h4>
                <v-select
                  v-model="formContact.gmailStatus"
                  v-bind:class="{ highlight: !formContact.gmailStatus }"
                  :tabindex="
                    sectionKeyBy.Email.fields['contact.gmailStatus'].order
                  "
                  outlined
                  dense
                  hide-details
                  clearable
                ></v-select>
              </v-col>

              <!-- genericEmail -->
              <v-col
                class="pt-0 pb-1 mt-2"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Email.fields['contact.genericEmail']"
                v-bind:style="{
                  order:
                    sectionKeyBy.Email.fields['contact.genericEmail'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Email.fields["contact.genericEmail"].label }}

                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_genericEmail !==
                      formContact.genericEmail
                    "
                  >
                    {{ formContact.previous_genericEmail | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  v-model="formContact.genericEmail"
                  v-bind:class="{
                    highlight: !formContact.genericEmail,
                  }"
                  :tabindex="
                    sectionKeyBy.Email.fields['contact.genericEmail'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <!-- Job -->
          <div
            v-if="sectionKeyBy.Job && sectionKeyBy.Job.display"
            v-bind:style="{
              order: sectionKeyBy.Job.order,
            }"
            class="orderFields border"
          >
            <v-row class="col-md-12 mx-0 px-0 pb-1">
              <!-- jobTitle -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Job.fields['contact.jobTitle']"
                v-bind:style="{
                  order: sectionKeyBy.Job.fields['contact.jobTitle'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Job.fields["contact.jobTitle"].label }}
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_jobTitle !== formContact.jobTitle
                    "
                  >
                    {{ formContact.previous_jobTitle | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  v-model="formContact.jobTitle"
                  v-bind:class="{ highlight: !formContact.jobTitle }"
                  outlined
                  dense
                  hide-details
                  :tabindex="sectionKeyBy.Job.fields['contact.jobTitle'].order"
                >
                  outlined dense ></v-text-field
                >
              </v-col>

              <!-- custom1 -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Job.fields['contact.custom1']"
                v-bind:style="{
                  order: sectionKeyBy.Job.fields['contact.custom1'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Job.fields["contact.custom1"].label }}
                  <span
                    class="previousTitles"
                    v-if="formContact.previous_custom1 !== formContact.custom1"
                  >
                    {{ formContact.previous_custom1 | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  v-model="formContact.custom1"
                  v-bind:class="{ highlight: !formContact.custom1 }"
                  outlined
                  dense
                  hide-details
                  :tabindex="sectionKeyBy.Job.fields['contact.custom1'].order"
                >
                  outlined dense ></v-text-field
                >
              </v-col>

              <!-- jobLevel -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Job.fields['contact.jobLevel']"
                v-bind:style="{
                  order: sectionKeyBy.Job.fields['contact.jobLevel'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Job.fields["contact.jobLevel"].label
                  }}<span
                    class="previousTitles"
                    v-if="
                      formContact.previous_jobLevel !== formContact.jobLevel
                    "
                  >
                    {{ formContact.previous_jobLevel | checkEmpty }}
                  </span>
                </h4>
                <v-combobox
                  v-bind:class="{ highlight: !formContact.jobLevel }"
                  v-model="formContact.jobLevel"
                  outlined
                  :tabindex="sectionKeyBy.Job.fields['contact.jobLevel'].order"
                  dense
                  hide-details
                ></v-combobox>
              </v-col>

              <!-- jobDepartment -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Job.fields['contact.jobDepartment']"
                v-bind:style="{
                  order: sectionKeyBy.Job.fields['contact.jobDepartment'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Job.fields["contact.jobDepartment"].label }}
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_jobDepartment !==
                      formContact.jobDepartment
                    "
                  >
                    {{ formContact.previous_jobDepartment | checkEmpty }}
                  </span>
                </h4>
                <v-combobox
                  v-bind:class="{
                    highlight: !formContact.jobDepartment,
                  }"
                  v-model="formContact.jobDepartment"
                  :tabindex="
                    sectionKeyBy.Job.fields['contact.jobDepartment'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-combobox>
              </v-col>

              <!-- functions -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Job.fields['contact.functions']"
                v-bind:style="{
                  order: sectionKeyBy.Job.fields['contact.functions'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Job.fields["contact.functions"].label }}
                  <span class="previousTitles">
                    {{ formContact.previous_functions | checkEmpty }}
                  </span>
                </h4>
                <v-combobox
                  v-bind:class="{ highlight: !formContact.functions }"
                  v-model="formContact.functions"
                  outlined
                  :tabindex="sectionKeyBy.Job.fields['contact.functions'].order"
                  dense
                  hide-details
                ></v-combobox>
              </v-col>
            </v-row>
          </div>

          <!-- Address -->
          <div
            v-if="sectionKeyBy.Address && sectionKeyBy.Address.display"
            v-bind:style="{
              order: sectionKeyBy.Address.order,
            }"
            class="orderFields border"
          >
            <v-row class="col-md-12 mx-0 px-0 pb-0">
              <!-- Copy HQ Info -->
              <v-col
                class="pt-0 pb-1 mt-8"
                cols="12"
                sm="2"
                v-if="sectionKeyBy.Address.fields['contact.address.street1']"
              >
                <v-btn
                  depressed
                  block
                  color="primary"
                  :tabindex="
                    sectionKeyBy.Address.fields['contact.address.street1'].order
                  "
                >
                  Copy HQ Info
                  <v-icon right>content_copy</v-icon>
                </v-btn>
              </v-col>

              <!-- Copy Contact Info -->
              <v-col
                class="pt-0 pb-1 mt-2"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Address.fields['contact.address.street1']"
              >
                <h4 class="text-uppercase col-md-12 pa-0">Copy Contact Info</h4>
                <v-autocomplete
                  class="input_data"
                  hide-selected
                  :tabIndex="
                    sectionKeyBy.Address.fields['contact.address.street1'].order
                  "
                  outlined
                  dense
                  hide-details
                  clearable
                >
                </v-autocomplete>
              </v-col>

              <!-- addressSearch -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="4"
                v-if="sectionKeyBy.Address.fields['contact.address.street1']"
              >
                <h4 class="text-uppercase col-md-12 pa-0">Address Search</h4>
                <v-col
                  class="d-flex pt-0 pb-1 px-0"
                  style="position: relative"
                  cols="12"
                  sm="12"
                >
                  <addressSearch
                    addressId="contactFormId"
                    addressRef="contactFormRef"
                    :tabIndex="
                      sectionKeyBy.Address.fields['contact.address.street1']
                        .order
                    "
                    class="py-0 addressWrapper"
                  />
                  <v-icon> search </v-icon>
                </v-col>
              </v-col>

              <!-- street1 -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Address.fields['contact.address.street1']"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['contact.address.street1']
                      .order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{
                    sectionKeyBy.Address.fields["contact.address.street1"].label
                  }}
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_address.street1 !==
                      formContact.address.street1
                    "
                  >
                    {{ formContact.previous_address.street1 | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  v-bind:class="{
                    highlight: !formContact.address.street1,
                  }"
                  v-model="formContact.address.street1"
                  :tabindex="
                    sectionKeyBy.Address.fields['contact.address.street1'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- street2 -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Address.fields['contact.address.street2']"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['contact.address.street2']
                      .order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{
                    sectionKeyBy.Address.fields["contact.address.street2"].label
                  }}
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_address.street2 !==
                      formContact.address.street2
                    "
                  >
                    {{ formContact.previous_address.street2 | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  v-bind:class="{
                    highlight: !formContact.address.street2,
                  }"
                  v-model="formContact.address.street2"
                  :tabindex="
                    sectionKeyBy.Address.fields['contact.address.street2'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- city -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-if="sectionKeyBy.Address.fields['contact.address.city']"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['contact.address.city'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{
                    sectionKeyBy.Address.fields["contact.address.city"].label
                  }}
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_address.city !==
                      formContact.address.city
                    "
                  >
                    {{ formContact.previous_address.city | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  v-bind:class="{
                    highlight: !formContact.address.city,
                  }"
                  v-model="formContact.address.city"
                  :tabindex="
                    sectionKeyBy.Address.fields['contact.address.city'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- state -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="2"
                v-if="sectionKeyBy.Address.fields['contact.address.state']"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['contact.address.state'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{
                    sectionKeyBy.Address.fields["contact.address.state"].label
                  }}
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_address.state !==
                      formContact.address.state
                    "
                  >
                    {{ formContact.previous_address.state | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  v-bind:class="{
                    highlight: !formContact.address.state,
                  }"
                  v-model="formContact.address.state"
                  :tabindex="
                    sectionKeyBy.Address.fields['contact.address.state'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- country -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="2"
                v-if="sectionKeyBy.Address.fields['contact.address.country']"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['contact.address.country']
                      .order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{
                    sectionKeyBy.Address.fields["contact.address.country"].label
                  }}
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_address.country !==
                      formContact.address.country
                    "
                  >
                    {{ formContact.previous_address.country | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  v-bind:class="{
                    highlight: !formContact.address.country,
                  }"
                  v-model="formContact.address.country"
                  :tabindex="
                    sectionKeyBy.Address.fields['contact.address.country'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- zipCode -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="2"
                v-if="sectionKeyBy.Address.fields['contact.address.zipCode']"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['contact.address.zipCode']
                      .order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{
                    sectionKeyBy.Address.fields["contact.address.zipCode"].label
                  }}
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_address.zipCode !==
                      formContact.address.zipCode
                    "
                  >
                    {{ formContact.previous_address.zipCode | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  v-model="formContact.address.zipCode"
                  v-bind:class="{
                    highlight: !formContact.address.zipCode,
                  }"
                  :tabindex="
                    sectionKeyBy.Address.fields['contact.address.zipCode'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <!-- Phone -->
          <div
            v-if="sectionKeyBy.Phone && sectionKeyBy.Phone.display"
            v-bind:style="{
              order: sectionKeyBy.Phone.order,
            }"
            class="orderFields border"
          >
            <v-row class="col-md-12 mx-0 px-0 pb-0">
              <!-- Copy PhoneHQ -->
              <v-col
                class="pt-0 pb-1 mt-8"
                cols="12"
                sm="2"
                v-if="sectionKeyBy.Phone.fields['contact.phone']"
              >
                <v-btn
                  depressed
                  block
                  color="primary"
                  :tabindex="sectionKeyBy.Phone.fields['contact.phone'].order"
                >
                  Copy PhoneHQ <v-icon right>content_copy</v-icon>
                </v-btn>
              </v-col>

              <!-- phone -->
              <v-col
                v-bind:class="{ highlight: !phoneNumber }"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="4"
                v-if="sectionKeyBy.Phone.fields['contact.phone']"
                v-bind:style="{
                  order: sectionKeyBy.Phone.fields['contact.phone'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Phone.fields["contact.phone"].label }}
                  <span
                    class="previousTitles"
                    v-if="formContact.previous_phone !== formContact.phone"
                  >
                    {{ formContact.previous_phone | checkEmpty }}
                  </span>
                </h4>
                <vue-phone-number-input
                  v-model="phoneNumber"
                  default-country-code="US"
                  :error="false"
                  :tabindex="sectionKeyBy.Phone.fields['contact.phone'].order"
                />
              </v-col>

              <!-- phoneExtension -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="2"
                v-if="sectionKeyBy.Phone.fields['contact.phoneExtension']"
                v-bind:style="{
                  order:
                    sectionKeyBy.Phone.fields['contact.phoneExtension'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{
                    sectionKeyBy.Phone.fields["contact.phoneExtension"].label
                  }}
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_phoneExtension !==
                      formContact.phoneExtension
                    "
                  >
                    {{ formContact.previous_phoneExtension | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  v-bind:class="{
                    highlight: !formContact.phoneExtension,
                  }"
                  v-model="formContact.phoneExtension"
                  :tabindex="
                    sectionKeyBy.Phone.fields['contact.phoneExtension'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- directPhoneNumber -->
              <v-col
                v-bind:class="{ highlight: !directPhoneNumber }"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="4"
                v-if="sectionKeyBy.Phone.fields['contact.directPhone']"
                v-bind:style="{
                  order: sectionKeyBy.Phone.fields['contact.directPhone'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Phone.fields["contact.directPhone"].label }}
                  <span
                    class="previousTitles"
                    v-if="
                      formContact.previous_directPhone !==
                      formContact.directPhone
                    "
                  >
                    {{ formContact.previous_directPhone | checkEmpty }}
                  </span>
                </h4>
                <vue-phone-number-input
                  v-model="directPhoneNumber"
                  default-country-code="US"
                  :error="false"
                  :tabindex="
                    sectionKeyBy.Phone.fields['contact.directPhone'].order
                  "
                />
              </v-col>

              <!-- mobile -->
              <v-col
                v-bind:class="{ highlight: !mobileNumber }"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="4"
                v-if="sectionKeyBy.Phone.fields['contact.mobile']"
                v-bind:style="{
                  order: sectionKeyBy.Phone.fields['contact.mobile'].order,
                }"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ sectionKeyBy.Phone.fields["contact.mobile"].label }}
                  <span
                    class="previousTitles"
                    v-if="formContact.previous_mobile !== formContact.mobile"
                  >
                    {{ formContact.previous_mobile | checkEmpty }}
                  </span>
                </h4>
                <vue-phone-number-input
                  v-model="mobileNumber"
                  default-country-code="US"
                  :error="false"
                  :tabindex="sectionKeyBy.Phone.fields['contact.mobile'].order"
                />
              </v-col>
            </v-row>
          </div>

          <!-- Misc -->
          <div
            v-if="sectionKeyBy.Misc && sectionKeyBy.Misc.display"
            v-bind:style="{
              order: sectionKeyBy.Misc.order,
            }"
            class="border"
          >
            <v-row class="col-md-12 mx-0 px-0 pb-0">
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="3"
                v-for="miscField in sectionKeyBy.Misc.fields"
                :key="miscField.id"
              >
                <h4 class="text-uppercase col-md-12 pa-0">
                  {{ miscField.label }}
                  <span
                    class="previousTitles"
                    v-if="
                      formContact[`previous_${miscField.id}`] !==
                      formContact[miscField.id]
                    "
                  >
                    {{ formContact[`previous_${miscField.id}`] | checkEmpty }}
                  </span>
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{
                    highlight: !formContact[miscField.id],
                  }"
                  v-model="formContact[miscField.id]"
                  :tabindex="miscField.order"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import addressSearch from "./AddressSearch.vue";

export default {
  props: {},
  components: { VuePhoneNumberInput, addressSearch },
  data() {
    return {
      formContact: {
        address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
        previous_address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
      },
      phoneNumber: "",
      directPhoneNumber: "",
      mobileNumber: "",
      sectionKeyBy: {},
      valid: false,
    };
  },
  mounted: function () {
    this.contactFormFieldCustomization(this.contactFields);
  },
  computed: {
    contactFields() {
      return this.$store.state.template.contactFields;
    },
  },
  watch: {
    contactFields: function (value) {
      this.contactFormFieldCustomization(value);
    },
  },
  methods: {
    contactFormFieldCustomization: function (contactFormFields) {
      this.sectionKeyBy = _.keyBy(contactFormFields, "section");
      for (const key in this.sectionKeyBy) {
        let section = this.sectionKeyBy[key];
        let sectionFields = section.fields;

        let fieldKeyBy = _.keyBy(sectionFields, "id");
        this.sectionKeyBy[key].fields = fieldKeyBy;
      }
    },
  },
};
</script>

<style scoped>
.orderFields {
  display: flex;
  flex-direction: row;
}

.border {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px !important;
}

.orderSection {
  display: flex;
  flex-direction: column;
}

.v-input.highlight,
.highlight .input-tel__input {
  background: lightyellow;
}

.previousTitles {
  font-size: 12px;
  margin-top: 5px;
  text-transform: none;
  position: absolute;
  right: 0;
  bottom: 2px;
}
.addressWrapper .address-container {
  width: 100%;
  height: 45px;
  padding: 8px 0;
  padding-left: 8px;
  border: 1px solid #ccc;
}

.addressWrapper + i {
  right: 5px;
  font-size: 25px;
  position: absolute;
  top: 8px;
}

.addressWrapper {
  width: 100%;
}

.contact_form hr {
  margin: 15px 10px 2px 10px;
  border-color: white;
}
.contact_form {
  padding: 10px;
}
.form h4 {
  font-family: Lato;
  font-size: 16px;
  color: #6e6893;
  font-weight: 400;
  position: relative;
}
.zerobounce-wrapper {
  width: 90%;
  float: right;
  margin-right: 0 !important;
}

.zerobounce-wrapper p {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #00458d;
}
.zerobounce-wrapper p b {
  float: right;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.right {
  float: right;
}
/* Buttons */

::-webkit-scrollbar-button:single-button {
  background-color: #686094;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
} /* Up
*/
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
}
::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
} /* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
}
::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.v-input {
  background: white;
}
</style>
