<template>
  <div class="content">
    <div class="download_master_import_download_buttons">
      <!-- Refresh Button -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2 white--text"
            :loading="loading"
            :disabled="loading"
            color="#8B83BA"
            fab
            small
            @click="pageRefresh()"
            ><v-icon v-bind="attrs" v-on="on">mdi-cached</v-icon></v-btn
          >
        </template>
        <span>Refresh</span>
      </v-tooltip>
    </div>

    <v-container fluid class="downloadMaster">
      <!-- dataGrid -->
      <v-row>
        <v-col cols="12" sm="12">
          <DataGrid
            gridId="downloadMasterPageId"
            gridRef="downloadMasterPageRef"
            gridHeight="75vh"
            :headers="headers"
            :itemList="downloadMasterList"
            :loading="loading"
            :totalCount="downloadMasterListTotalCounts"
            :pageNo="pageNumber"
            @fetchGrideData="fetchDownloadMasterData($event)"
            @fetchButtonClickAction="fileDownloadByHeader($event)"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import Api from "../services/MasterApi";
import DataGrid from "./dataGrid.vue";

export default {
  name: "DownloadMaster",
  components: {
    DataGrid,
  },
  mounted: async function () {
    await this.pageRefresh();
  },
  data() {
    return {
      loading: false,
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE,
      filter: {},
      sort: {
        createdAt: "desc",
      },
      downloadMasterListTotalCounts: 0,
      downloadMasterList: [],
      //To set column size set header width large : 200px,common : 150px, small : 125px
      headers: [
        {
          value: "fileName",
          text: "File Name",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "operationName",
          text: "File Type",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "createdAt",
          text: "Requested Time",
          sortable: false,
          divider: true,
          width: "150px",
          type: "date",
          googleSearch: false,
        },
        {
          value: "status",
          text: "Status",
          divider: true,
          sortable: false,
          width: "125px",
          type: "string",
        },
        {
          value: "jobId",
          text: "File Link",
          divider: true,
          sortable: false,
          width: "115px",
          type: "button",
          icon: "file_download",
        },
      ],
    };
  },
  methods: {
    pageRefresh: async function () {
      this.loading = true;
      await this.fetchMasterDownloadList();
      this.loading = false;
    },
    fetchMasterDownloadList: async function () {
      Api.getMasterJobs({
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
        sort: JSON.stringify(this.sort),
        jobType: ["download"],
      })
        .then(async (response) => {
          this.downloadMasterListTotalCounts = response.data.totalCount;
          this.downloadMasterList = response.data.docs;
          this.downloadMasterList = await this.refreshDownloadJobStatus(
            this.downloadMasterList
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refreshDownloadJobStatus: async function (jobs) {
      let modifiedJobs = [];
      modifiedJobs = _.map(jobs, function (job) {
        job.itemInfo = {};
        job.itemInfo.header = "File Link";
        if (_.get(job, "status", "").toLowerCase() === "completed") {
          job.itemInfo.isDisableButton = false;
        } else {
          job.itemInfo.isDisableButton = true;
          job.createdAt = "";
        }
        return job;
      });
      return modifiedJobs;
    },
    fetchDownloadMasterData: async function (options) {
      this.loading = true;
      const { page, itemsPerPage } = options;

      this.pageNumber = page;
      this.pageSize = itemsPerPage;

      //api call
      await this.fetchMasterDownloadList();
      this.loading = false;
    },
    fileDownloadByHeader: async function (data) {
      let jobId = data.value,
        headerName = data.headerName;
      if (headerName === "File Link") {
        await this.downloadMasterFile(jobId);
      }
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    downloadMasterFile: async function (jobId) {
      try {
        const response = await this.downloadMasterJobFile(jobId);
        this.downloadLink = response.data.url;
        window.open(this.downloadLink, "_blank");
        let notification = {
          type: "success",
          showMessage: true,
          message: this.$notifications().FILE_DOWNLOAD_SUCCESS,
        };
        this.setNotification(notification);
      } catch (error) {
        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().FILE_DOWNLOAD_ERROR,
        };
        this.setNotification(notification);
      }
    },
    downloadMasterJobFile: async function (jobId) {
      return Api.downloadMasterJobFile(jobId);
    },
  },
};
</script>

<style scoped>
.download_master_import_download_buttons {
  position: absolute;
  top: 60px;
  right: 15px;
}

.downloadMaster {
  width: 100%;
  position: relative;
}

.content {
  margin-left: 0 !important;
}

.download_master_import_download_buttons h1 {
  float: left;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}
</style>
