<template>
  <div>
    <v-text-field
      dense
      label="Filter List By Last Name"
      class="ml-1 pl-2"
      v-model="search"
      clearable
      outlined
      @click:clear="search = ''"
    ></v-text-field>
    <v-list-item-title>
      <p class="text-left ml-1 pl-2 list_header">Name, Disposition</p>
    </v-list-item-title>
    <v-virtual-scroll
      :bench="benched"
      :items="filteredContacts"
      :height="contactListHeight"
      item-height="64"
    >
      <template v-slot:default="{ item }">
        <v-list-item
          :class="{ selected_contact: item.id === formContact.id }"
          :key="item.id"
        >
          <v-list-item-content
            class="py-0"
            style="cursor: pointer; height: 63px"
            @click="selectContact(item)"
          >
            <p class="list_name">
              {{ item.firstName + " " + item.lastName }}

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-badge
                      color="blue"
                      class="float-right"
                      dot
                      inline
                      v-if="
                        !item.disposition &&
                        item.label !== 'suppressed' &&
                        item.label !== 'Exact Suppressed' &&
                        item.label !== 'duplicate' &&
                        item.label !== 'Fuzzy Suppressed'
                      "
                    >
                      Saved</v-badge
                    >
                  </span>
                </template>
                <span> <h4>Contact Only Saved</h4></span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-badge
                      color="black"
                      class="float-right"
                      dot
                      inline
                      v-if="
                        item.complianceStatus &&
                        item.complianceStatus !== 'Compliant'
                      "
                    >
                      Non-Comp</v-badge
                    >
                  </span>
                </template>
                <span> <h4>Contact Non-Compliant</h4></span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-badge
                      color="red"
                      class="float-right"
                      dot
                      inline
                      v-if="item.label === 'duplicate'"
                    >
                      Dup</v-badge
                    >
                    <v-badge
                      color="orange"
                      class="float-right"
                      dot
                      inline
                      v-else-if="
                        item.label === 'suppressed' ||
                        item.label === 'Exact Suppressed'
                      "
                    >
                      Exact-Sup</v-badge
                    >

                    <v-badge
                      color="#936827"
                      class="float-right"
                      dot
                      inline
                      v-else-if="item.label === 'Fuzzy Suppressed'"
                    >
                      Fuzzy-Sup</v-badge
                    >

                    <v-badge
                      color="green"
                      class="float-right"
                      dot
                      inline
                      v-else-if="item.label.match(/^Within/)"
                    >
                      Reused</v-badge
                    >
                  </span>
                </template>
                <span>
                  <h4 v-if="item.label === 'duplicate'">Contact Duplicate</h4>
                  <h4
                    v-else-if="
                      item.label === 'suppressed' ||
                      item.label === 'Exact Suppressed'
                    "
                  >
                    Contact Exact Suppressed
                  </h4>
                  <h4 v-else-if="item.label === 'Fuzzy Suppressed'">
                    Contact FuzzySuppressed
                  </h4>
                  <h4 v-else-if="item.label.match(/^Within/)">
                    Contact Reused
                  </h4>
                </span>
              </v-tooltip>
            </p>

            <p class="list_title">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span style="display: inline-block" v-bind="attrs" v-on="on">
                    <p v-if="item.TaskLink && item.TaskLink.disposition">
                      {{ item.TaskLink.disposition }}
                    </p>
                    <p v-else-if="item.disposition">
                      {{ item.disposition }}
                    </p>
                  </span>
                </template>
                <span> <h4>Disposition</h4></span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" style="display: inline-block">
                    <p v-if="item.TaskLink && item.TaskLink.researchStatus">
                      , {{ item.TaskLink.researchStatus }}
                    </p>
                    <p
                      v-else-if="
                        item.researchStatus && item.researchStatus !== 'Pending'
                      "
                    >
                      , {{ item.researchStatus }}
                    </p>
                  </span>
                </template>
                <span> <h4>Research Status</h4></span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" style="display: inline-block">
                    <p v-if="item.TaskLink && item.TaskLink.complianceStatus">
                      , {{ item.TaskLink.complianceStatus }}
                    </p>
                    <p v-else-if="item.complianceStatus">
                      , {{ item.complianceStatus }}
                    </p>
                  </span>
                </template>
                <span> <h4>Compliance Status</h4></span>
              </v-tooltip>
            </p>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
import _ from "lodash";
import sanitize from "./sanitizer";

export default {
  props: {},
  data() {
    return {
      search: "",
      benched: 0,
      contactListHeight: 0,
    };
  },
  mounted: async function () {
    this.contactListHeight = document.body.offsetHeight - 360;
    if (this.contactList && this.contactList.length) {
      if (this.formContact.id) {
        this.selectContact(this.formContact);
      } else {
        this.selectContact(this.contactList[0]);
      }
      this.$router
        .replace({
          name: "Workspace",
          params: {
            taskId: this.taskId,
            projectId: this.projectId,
          },
        })
        .catch((e) => e);
    }
  },
  computed: {
    contactList() {
      return sanitize(this.$store.state.workspace.contacts.docs);
    },
    totalCount() {
      return this.$store.state.workspace.contacts.totalCount;
    },
    formContact() {
      return this.$store.state.workspace.selectedContact;
    },
    taskId() {
      return this.$store.state.workspace.task.id;
    },
    projectId() {
      if (this.$store.state.workspace && this.$store.state.workspace.task) {
        return _.get(this.$store.state.workspace.task, "Project.id", null);
      }
      return null;
    },
    filteredContacts() {
      var self = this;
      return self.contactList.filter(function (contact) {
        // Clearable V-Text-Field turns its v-model to null when clear icon is clicked
        // which cause self.search to be null, so resetting search to empty string if nullish value appear
        self.search = self.search ? self.search : "";
        let lastName = _.get(contact, "lastName", "") || "";
        return lastName.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
      });
    },
  },
  methods: {
    selectContact: async function (selectedContact) {
      await this.$store.dispatch("populateContactLists", {
        taskId: this.taskId,
        projectId: this.projectId,
      });
      var _payload = {
        status: selectedContact.zb,
        processed_at: selectedContact.zbDateAndTime,
      };
      await this.$store.dispatch("updateZeroBounceResult", _payload);
      this.$store.dispatch("setResetGoogleAddressSearchValue", true);
      var dispatchRes = await this.$store.dispatch(
        "fetchSelectedWorspaceContactData",
        {
          selectContact: selectedContact,
        }
      );
      this.$store.commit("changeResetContactDisposeFormValue");
      return dispatchRes;
    },
  },
};
</script>

<style scoped>
.contacts {
  width: 100%;
  position: relative;
  float: left;
}
.contact_list {
  float: left;
}
.list_header {
  font-size: 15px;
  line-height: 24px;
  color: #585353;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.list_name {
  font-family: "Lato";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #25213b;
}
.list_title {
  font-family: "Lato";
  line-height: 20px;
  color: #25213b;
  margin-bottom: 5px;
}

.selected_contact {
  background: #f8fbff;
  border-left: 3px solid #8b83ba;
  border-top: 4px solid transparent;
}
</style>
