<template>
  <div class="content">
    <v-container fluid class="contactsMaster">
      <v-row>
        <v-col cols="12" sm="12" class="pt-0">
          <DataGrid
            gridId="contactMasterPageId"
            gridRef="contactMasterPageRef"
            gridHeight="75vh"
            :headers="headers"
            :itemList="contactMasterList"
            :loading="loading"
            :totalCount="verifyContactMasterListTotalCounts"
            :pageNo="pageNumber"
            @fetchGrideData="fetchVerifyContactData($event)"
            :allowMultiSort="true"
          />
        </v-col>
      </v-row>
    </v-container>

    <div class="contact_master_import_download_buttons">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2 white--text"
            color="#8B83BA"
            fab
            small
            :loading="isDownloading"
            :disabled="isDownloading"
            v-bind="attrs"
            v-on="on"
            @click="fileNameInputDialog = true"
            ><v-icon>{{ downloadDeliverableIcon }}</v-icon></v-btn
          >
        </template>
        <span>Download</span>
      </v-tooltip>

      <!-- File Name Input Dialog -->
      <v-dialog
        v-model="fileNameInputDialog"
        max-width="400"
        @click:outside="userProvidedFileName = ''"
      >
        <v-card>
          <v-form ref="fileNameInputDialogForm">
            <v-container class="pt-8">
              <v-text-field
                :rules="fileNameRules"
                v-model="userProvidedFileName"
                label="File Name"
                placeholder="Enter File Name Here"
                outlined
                autofocus
              ></v-text-field>
            </v-container>

            <v-card-actions class="pb-6">
              <v-spacer></v-spacer>

              <v-btn
                class="ml-2 white--text"
                color="#8B83BA"
                @click="verifyContactDownload()"
                :disabled="isDisable(userProvidedFileName)"
              >
                Ok
              </v-btn>

              <v-btn
                class="ml-2 white--text"
                color="#8B83BA"
                @click="
                  fileNameInputDialog = false;
                  userProvidedFileName = '';
                "
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <!-- Refresh Button -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2 white--text"
            :loading="loading"
            :disabled="loading"
            fab
            small
            color="#8B83BA"
            @click="pageRefresh()"
            ><v-icon v-bind="attrs" v-on="on">mdi-cached</v-icon></v-btn
          >
        </template>
        <span>Refresh</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
var fileDownload = require("js-file-download");
import Api from "../services/MasterApi";
import DataGrid from "./dataGrid.vue";
import { getRoles } from "../roles.js";

export default {
  name: "ContactMaster",
  components: {
    DataGrid,
  },
  mounted: async function () {
    await this.pageRefresh();
    let role = await getRoles();
    if (role.indexOf("compliance") > -1) {
      this.allowAccess = true;
    }
  },
  data() {
    return {
      fileNameInputDialog: false,
      userProvidedFileName: "",
      fileNameRules: [(v) => !!_.trim(v) || "Please Enter Valid File Name"],
      allowAccess: false,
      operatorEqualTo: "=",
      operatorBetween: "between",
      downloadDeliverableIcon: "file_download",
      isDownloading: false,
      loading: false,
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE,
      filter: {},
      sort: {},
      verifyContactMasterListTotalCounts: 0,
      contactMasterList: [],
      //To set column size set header width large : 200px,common : 150px, small : 125px
      headers: [
        {
          value: "name",
          text: "Name",
          sortable: true,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT100,
          googleSearch: false,
        },
        {
          value: "jobTitle",
          text: "Job Title",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT100,
          googleSearch: false,
        },
        {
          value: "jobLevel",
          text: "Job Level",
          sortable: true,
          divider: true,
          width: "150px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "jobDepartment",
          text: "Job Department",
          sortable: false,
          divider: true,
          width: "150px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT35,
          googleSearch: false,
        },
        {
          value: "personalEmail",
          text: "Personal Email",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT35,
          googleSearch: false,
        },
        {
          value: "directDial",
          text: "Direct Dial",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT100,
          googleSearch: false,
        },
        {
          value: "disposition",
          text: "Disposition",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT100,
          googleSearch: false,
        },
        {
          value: "accountDomain",
          text: "Account Domain",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT100,
          googleSearch: false,
        },
        {
          value: "createdUserEmail",
          text: "Created User Email",
          sortable: false,
          divider: true,
          width: "115px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT100,
          googleSearch: false,
        },
        {
          value: "updatedAt",
          text: "Last Modified Date",
          sortable: true,
          divider: true,
          width: "150px",
          type: "date",
          googleSearch: false,
        },
      ],
    };
  },
  methods: {
    isDisable: (input) => !input || _.trim(input) === "",
    pageRefresh: async function () {
      await this.fetchVerifyContactList();
    },
    fetchVerifyContactList: async function () {
      Api.getVerifyContacts({
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
      })
        .then((response) => {
          this.verifyContactMasterListTotalCounts = response.data.totalCount;
          this.contactMasterList = response.data.docs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    fetchVerifyContactData: async function (options) {
      const { page, itemsPerPage } = options;

      this.pageNumber = page;
      this.pageSize = itemsPerPage;
      //api call
      this.loading = true;
      await this.fetchVerifyContactList();
      this.loading = false;
    },
    changeIconOfDownloadBtn: function (disable) {
      if (disable) {
        this.downloadDeliverableIcon = "downloading";
      } else {
        this.downloadDeliverableIcon = "file_download";
      }
    },
    verifyContactDownload: function () {
      const self = this;
      this.fileNameInputDialog = false;
      this.isDownloading = true;
      this.changeIconOfDownloadBtn(true);
      let exportFileName = this.userProvidedFileName;
      this.userProvidedFileName = "";
      this.$refs.fileNameInputDialogForm.resetValidation();
      Api.getVerifyContacts({
        download: true,
        userProvidedFileName: exportFileName,
      })
        .then((response) => {
          let notification = {
            type: "success",
            showMessage: true,
          };
          this.isDownloading = false;
          this.changeIconOfDownloadBtn(false);
          const responseContentType = response.headers["content-type"];
          if (responseContentType === "application/csv") {
            notification.message = self.$notifications().FILE_SUCCESS;
            self.setNotification(notification);
            return fileDownload(response.data, exportFileName);
          }

          notification.message =
            self.$notifications().DOWNLOAD_JOB_SUBMISSION_SUCCESS;
          self.setNotification(notification);
          return;
        })
        .catch((error) => {
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().FILE_ERROR,
          };

          self.setNotification(notification);
          this.isDownloading = false;
          this.changeIconOfDownloadBtn(false);
        });
    },
  },
};
</script>

<style scoped>
.contact_master_import_download_buttons {
  position: absolute;
  top: 60px;
  right: 15px;
}

.contactsMaster {
  width: 100%;
  position: relative;
}

.content {
  margin-left: 0 !important;
}

.contact_master_import_download_buttons h1 {
  float: left;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #00458d;
  text-align: left;
  margin-top: 0;
  margin-left: 10px;
}
</style>
