var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"accounts pl-0",attrs:{"fluid":""}},[_c('v-overlay',{attrs:{"value":false,"opacity":"0.9"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('div',[_c('v-slide-group',{staticClass:"counter_stats",attrs:{"mandatory":"","active-class":"activeDisposition","show-arrows":""},model:{value:(_vm.activeTaskState),callback:function ($$v) {_vm.activeTaskState=$$v},expression:"activeTaskState"}},_vm._l((_vm.stateCount),function(value,name){return _c('v-slide-item',{key:value.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ml-2 counter",attrs:{"height":"80","width":"125","color":active ? '#205fac' : 'FFFFFF'},on:{"click":[function($event){return _vm.filterTaskByStates(name)},toggle]}},[_c('h4',{staticClass:"counter_value"},[_vm._v(" "+_vm._s(_vm._f("humanizeNumber")(value.count))+" ")]),_c('h4',{staticClass:"counter_title"},[_vm._v(_vm._s(name))])])]}}],null,true)})}),1)],1)])],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"white-space":"nowrap"},attrs:{"height":"68vh","fixed-header":"","headers":_vm.headers,"items":_vm.taskList,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalCount,"loading-text":"Loading... Please wait","dense":"","footer-props":{
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'navigate_before',
            nextIcon: 'navigate_next',
            'items-per-page-options': [5, 10, 20, 40, 60],
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"pa-0 ma-0",attrs:{"right":""},on:{"click":function($event){return _vm.redirectToWorkSpace(item)}}},[_vm._v(" open_in_new ")])]}},{key:"item.Accounts[0].name",fn:function(ref){
          var value = ref.value;
return [_c('a',{attrs:{"href":_vm.searchAccountNameUrl,"target":"_blank"},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.searchByAccountName(value)}]}},[_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(value)))+" ")])]}},{key:"item.Accounts[0].website",fn:function(ref){
          var value = ref.value;
return [(value)?_c('a',{attrs:{"href":_vm.searchAccountWebsiteUrl,"target":"_blank"},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.searchByAccountWebsite(value)}]}},[_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(value)))+" ")]):_c('span',{staticStyle:{"font":"black"}},[_vm._v("---")])]}},{key:"item.priority",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(value)))+" ")]}},{key:"item.Accounts[0].disposition",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(value)))+" ")]}},{key:"item.Accounts[0].employeeSize",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(value)))+" ")]}},{key:"item.Accounts[0].revenue",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(value)))+" ")]}},{key:"item.status",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(value)))+" ")]}},{key:"item.TaskType.type",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(value)))+" ")]}},{key:"item.createdAt",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm._f("markEmpty")(_vm._f("checkEmpty")(value))))+" ")]}},{key:"item.dueDate",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm._f("markEmpty")(_vm._f("checkEmpty")(value))))+" ")]}},{key:"item.Accounts[0].potential",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(value)))+" ")]}},{key:"item.Accounts[0].qualifiedContacts",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(value)))+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }