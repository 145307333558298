<template>
  <v-container fluid class="tasks">
    <div class="task_import_download_buttons" v-show="isManager">
      <FileUpload
        fileType="Import"
        :projectId="projectId"
        :projectName="projectName"
        importType="Task"
      />

      <v-menu>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                class="ml-2 white--text"
                color="#8B83BA"
                :loading="isDownloading"
                :disabled="isDownloading"
                v-bind="attrs"
                fab
                small
                v-on="{ ...menu, ...tooltip }"
                ><v-icon>download</v-icon>
              </v-btn>
            </template>
            <span>Download</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                style="cursor: pointer"
                @click="tasksDownload('Today Task')"
                >Today Task
                <v-icon style="float: right" right>
                  {{ iconOfDownloadTodayCompletedTask }}
                </v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                style="cursor: pointer"
                @click="tasksDownload('All Task')"
                >All Task
                <v-icon style="float: right" right>{{
                  iconOfDownloadAllTask
                }}</v-icon></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                style="cursor: pointer"
                @click="tasksDownload('Compliance Task')"
                >Compliance Task
                <v-icon style="float: right" right>{{
                  iconOfDownloadAllTask
                }}</v-icon></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                style="cursor: pointer"
                @click="tasksDownload('Non-Compliant Task')"
                >Non-Compliant Task
                <v-icon style="float: right" right>{{
                  iconOfDownloadAllTask
                }}</v-icon></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                style="cursor: pointer"
                @click="tasksDownload('Filtered Task')"
                >Filtered Task
                <v-icon style="float: right" right>{{
                  iconOfDownloadAllTask
                }}</v-icon></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-2 white--text"
            color="#8B83BA"
            fab
            small
            :loading="loading"
            :disabled="loading"
            @click="pageRefresh()"
            ><v-icon>mdi-cached</v-icon></v-btn
          >
        </template>
        <span>Refresh</span>
      </v-tooltip>
      <GeneralizedFilter
        :inputFilterObject="inputFilterObject"
        v-on:filterObject="applyFilter($event)"
        v-on:fetchFilters="fetchAllTaskFacets($event)"
      />
      <AssignTasks
        pageName="task"
        :projectId="projectId"
        :filter="filter"
        :sort="sort"
        ref="AssignTasks"
        :maxBlockSize="totalCount"
      />
    </div>

    <div style="margin-bottom: 20px">
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <div>
            <v-slide-group
              mandatory
              class="counter_stats"
              v-model="activeTaskState"
              active-class="activeDisposition"
              show-arrows
            >
              <v-slide-item
                v-for="(value, name) in stateCount"
                :key="value.id"
                v-slot="{ active, toggle }"
              >
                <v-card
                  class="ml-2 counter"
                  height="100"
                  width="160"
                  :color="active ? '#205fac' : 'FFFFFF'"
                  @click="filterTaskByStates(name)"
                  v-on:click="toggle"
                >
                  <h4 class="counter_value">
                    {{ value.count | humanizeNumber }}
                  </h4>
                  <h4 class="counter_title">{{ name }}</h4>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </div>
          <div>
            <v-slide-group
              class="counter_stats"
              v-model="activeSubTaskState"
              v-bind:style="styleOfSubStates"
              active-class="activeDisposition"
              show-arrows
            >
              <v-slide-item
                v-for="(value, name) in subStateCount"
                :key="value.id"
                v-slot="{ active, toggle }"
              >
                <v-card
                  class="ml-2 counter"
                  height="100"
                  width="160"
                  :color="active ? '#205fac' : 'FFFFFF'"
                  @click="filterTaskByStates(name)"
                  v-on:click="toggle"
                >
                  <h4 class="counter_value">
                    {{ value | humanizeNumber }}
                  </h4>
                  <h4 class="counter_title">{{ name }}</h4>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="Object.keys(filterForChips).length">
        <v-col cols="12" sm="12" class="pb-0">
          <v-card elevation="1" class="px-3">
            <h5
              class="text-overline pl-2"
              style="border-bottom: 1px solid #ddd"
            >
              Applied Filter:
              <span
                data-v-41106904=""
                style="
                  margin-left: 10px;
                  font-family: 'Lato';
                  text-transform: none;
                  color: rgb(32, 95, 172);
                  cursor: pointer;
                  letter-spacing: 0px;
                  font-size: 13px;
                "
                @click="clearAllFilters()"
                >( Clear all )</span
              >
            </h5>
            <v-chip-group column>
              <span
                v-for="(subProps, key) in filterForChips"
                :key="key"
                style="display: inline"
              >
                <span
                  v-if="Array.isArray(subProps.value) && subProps.value.length"
                >
                  <v-chip
                    close
                    label
                    @click:close="removeFilter(key, subValue)"
                    v-for="(subValue, index) in subProps.value"
                    :key="index"
                  >
                    <span style="padding-right: 5px">
                      {{ subProps.text }}:
                    </span>
                    <span
                      v-if="
                        subProps.operator !== operatorEqualTo &&
                        subProps.operator !== operatorBetween
                      "
                      style="padding-right: 2px"
                    >
                      {{ subProps.operator }}
                    </span>
                    <span v-if="subValue.length"> {{ subValue }}</span>
                  </v-chip>
                </span>
                <span v-else>
                  <v-chip close label @click:close="removeFilter(key)">
                    <span style="padding-right: 5px">
                      {{ subProps.text }}:
                    </span>
                    <span
                      v-if="
                        subProps.operator !== operatorEqualTo &&
                        subProps.operator !== operatorBetween
                      "
                      style="padding-right: 2px"
                    >
                      {{ subProps.operator }}
                    </span>
                    <span v-if="subProps.value.length">
                      {{ subProps.value }}</span
                    >
                  </v-chip>
                </span>
              </span>
            </v-chip-group>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div>
      <DataGrid
        gridId="pmTasksPageId"
        gridRef="pmTasksPageRef"
        gridHeight="68vh"
        :headers="headers"
        :itemList="taskList"
        :loading="loading"
        :totalCount="totalCount"
        :pageNo="pageNumber"
        @fetchGrideData="fetchTaskData($event)"
        :allowMultiSort="true"
      />
    </div>
  </v-container>
</template>
<script>
import _ from "lodash";
import Api from "../services/Api";
import FileUpload from "./fileUpload";
import { getRoles } from "./../roles.js";
import DataGrid from "./dataGrid.vue";
import GeneralizedFilter from "./GeneralizedFilters.vue";
import AssignTasks from "./AssignTasks.vue";

var fileDownload = require("js-file-download");

export default {
  name: "PROJECTTask",
  components: {
    FileUpload,
    DataGrid,
    GeneralizedFilter,
    AssignTasks,
  },
  props: {
    projectId: String,
    projectName: String,
  },
  data() {
    return {
      stateCount: {
        Total: {
          count: 0,
          subState: {},
        },
        Completed: {
          count: 0,
          subState: {},
        },
        Pending: {
          count: 0,
          subState: {
            Overdue: 0,
            Upcoming: 0,
          },
        },
        Working: {
          count: 0,
          subState: {},
        },
      },
      taskStateMapping: {
        Overdue: "Pending",
        Upcoming: "Pending",
      },
      subStateCount: {},
      indexOfTaskState: {},
      activeTaskState: null,
      activeSubTaskState: null,
      styleOfSubStates: {},
      loading: false,
      operatorEqualTo: "=",
      operatorBetween: "between",
      isDownloading: false,
      totalCount: 0,
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE,
      taskList: [],
      isManager: false,
      iconOfDownloadTodayCompletedTask: "file_download",
      iconOfDownloadAllTask: "file_download",
      searchAccountNameUrl: "",
      searchAccountWebsiteUrl: "",
      //To set column size set header width large : 200px,common : 150px, small : 125px
      headers: [
        {
          value: "accountName",
          text: "Account Name",
          sortable: true,
          divider: true,
          width: "150px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "contactEmail",
          text: "Contact Email",
          sortable: true,
          divider: true,
          width: "150px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "website",
          text: "Website",
          sortable: false,
          divider: true,
          width: "150px",
          type: "link",
          googleSearch: false,
        },
        {
          value: "userName",
          text: "User Name (Email)",
          sortable: true,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "status",
          text: "Status",
          sortable: true,
          divider: true,
          width: "115px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "priority",
          text: "Priority",
          sortable: true,
          divider: true,
          width: "115px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "accountDisposition",
          text: "Account Disposition",
          sortable: true,
          divider: true,
          width: "115px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "contactDisposition",
          text: "Contact Disposition",
          sortable: true,
          divider: true,
          width: "115px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "accountFinalDisposition",
          text: "Account Final Disposition",
          sortable: true,
          divider: true,
          width: "115px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "potential",
          text: "Potential",
          sortable: true,
          divider: true,
          width: "150px",
          type: "number",
          googleSearch: false,
        },
        {
          value: "taskCreatedDate",
          text: "Task Created Date",
          sortable: false,
          divider: true,
          width: "150px",
          type: "date",
          googleSearch: false,
        },
        {
          value: "taskUpdatedDate",
          text: "Task Updated Date",
          sortable: false,
          divider: true,
          width: "150px",
          type: "date",
          googleSearch: false,
        },
      ],
      // List of columns, its types and its values for filters
      inputFilterObject: {
        taskCreatedDate: {
          text: "Task Created Date",
          type: "date",
          operators: ["BETWEEN"],
          values: [],
        },
        taskUpdatedDate: {
          text: "Task Updated Date",
          type: "date",
          operators: ["BETWEEN"],
          values: [],
        },
        potential: {
          text: "Potential",
          type: "number",
          operators: ["IS", "LESS THAN", "GREATER THAN"],
          values: [],
        },
        accountDisposition: {
          text: "Account Disposition",
          type: "array",
          operators: ["IS", "IS NULL"],
          values: [],
          search: true,
        },
        contactDisposition: {
          text: "Contact Disposition",
          type: "array",
          operators: ["IS", "IS NULL"],
          values: [],
          search: true,
        },
        accountFinalDisposition: {
          text: "Account Final Disposition",
          type: "array",
          operators: ["IS", "IS NULL"],
          values: [],
          search: true,
        },
        priority: {
          text: "Priority",
          type: "string",
          operators: ["IS"],
          values: [
            "Highest",
            "High",
            "Standard",
            "Medium",
            "Low",
            "Lowest",
            "Overtime",
          ],
        },
        accountName: {
          text: "Account Name",
          type: "string",
          operators: ["IS", "IS NULL"],
          values: [],
        },
        contactEmail: {
          text: "Contact Email",
          type: "string",
          operators: ["IS", "IS NULL"],
          values: [],
        },
        userName: {
          text: "User Name (Email)",
          type: "array",
          operators: ["IS"],
          values: [],
          search: true,
        },
      },
      filter: {},
      filterForChips: {},
      downloadFilter: {},
      sort: {},
      counts: [],
    };
  },
  computed: {},
  watch: {},
  mounted: async function () {
    await this.pageRefresh();
    await this.fetchAllTaskFacets();
    this.$refs.AssignTasks.getButtonText({
      callApi: true,
      fromPage: "task",
    });
  },
  methods: {
    clearAllFilters: async function () {
      this.loading = true;
      this.filterForChips = {};
      this.filter = {};
      await this.fetchTaskLists();
      this.loading = false;
    },
    removeFilter: async function (filterKey, subValue) {
      this.resetStatusStatsFilter();
      this.pageNumber = 1;
      if (!subValue) {
        delete this.filterForChips[filterKey];
        delete this.filter[filterKey];
      } else {
        const filterForChipsValues = _.get(
          this.filterForChips,
          [filterKey, "value"],
          []
        );
        const filterValues = _.get(this.filter, [filterKey, "value"], []);
        if (filterForChipsValues.length <= 1 && filterValues.length <= 1) {
          delete this.filterForChips[filterKey];
          delete this.filter[filterKey];
        } else {
          const filterForChipsValueIndex = _.indexOf(
            filterForChipsValues,
            subValue
          );
          if (filterForChipsValueIndex > -1) {
            this.filterForChips[filterKey]["value"].splice(
              filterForChipsValueIndex,
              1
            );
          }
          const filterValueIndex = _.indexOf(filterValues, subValue);
          if (filterValueIndex > -1) {
            this.filter[filterKey]["value"].splice(filterValueIndex, 1);
          }
        }
      }
      await this.fetchTaskLists();
    },
    applyFilter: async function (filterApplied) {
      this.resetStatusStatsFilter();
      const keyOfFilterApplied = Object.keys(filterApplied)[0];
      this.filterForChips[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      this.modifyFilterForChips(keyOfFilterApplied);
      filterApplied[keyOfFilterApplied] = _.omit(
        filterApplied[keyOfFilterApplied],
        ["text", "itemText", "itemValue", "search", "type"]
      );
      this.filter[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      this.pageNumber = 1;
      await this.fetchTaskLists();
    },
    modifyFilterForChips: function (keyOfFilterApplied) {
      const valueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["value"];
      const operatorOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["operator"];
      const itemTextOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemText"];
      const itemValueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemValue"];
      if (
        operatorOfFilteredForChips === "between" &&
        Array.isArray(valueOfFilteredForChips)
      ) {
        this.filterForChips[keyOfFilterApplied]["value"] =
          this.filterForChips[keyOfFilterApplied]["value"].join(" ~ ");
      }
      if (itemTextOfFilteredForChips && itemValueOfFilteredForChips) {
        let valuesOfFilteredId = _.map(
          this.inputFilterObject[keyOfFilterApplied].values,
          (item) => {
            if (item[itemValueOfFilteredForChips] === valueOfFilteredForChips) {
              return item[itemTextOfFilteredForChips];
            }
          }
        );
        valuesOfFilteredId = _.compact(valuesOfFilteredId);
        this.filterForChips[keyOfFilterApplied]["value"] =
          valuesOfFilteredId[0];
      }
    },
    resetStatusStatsFilter: function () {
      delete this.filter["status"];
      delete this.filter["dueDate"];
    },
    generateSortPayload(sortBy, sortDesc) {
      var sortPayload = {};
      sortBy.forEach(function (item, index) {
        sortPayload[item] = sortDesc[index] ? "asc" : "desc";
      });
      return sortPayload;
    },
    fetchTaskData(options) {
      const { sortBy, sortDesc, page, itemsPerPage } = options;

      this.pageNumber = page;
      this.pageSize = itemsPerPage;

      this.sort = this.generateSortPayload(sortBy, sortDesc);
      this.fetchTaskLists();
    },
    searchByAccountName(accountName) {
      this.searchAccountNameUrl =
        `https://www.google.com/search?q=` + encodeURIComponent(accountName);
    },
    searchByAccountWebsite(website) {
      this.searchAccountWebsiteUrl =
        this.$constants().URL_DOUBLE_SLASH + encodeURIComponent(website);
    },
    setRole: async function () {
      let role = await getRoles();
      if (role.indexOf("manager") > -1) {
        this.isManager = true;
        return;
      }
    },
    pageRefresh: async function () {
      await this.fetchTaskLists();
      this.$refs.AssignTasks.getButtonText({
        callApi: true,
        fromPage: "task",
      });
    },
    async fetchTaskLists() {
      this.loading = true;
      await this.setRole();

      var filter = _.cloneDeep(this.filter);
      var selectedStatusValue = "Total";
      if (filter && filter.status) {
        selectedStatusValue = filter.status.value || "Total";
      }
      if (selectedStatusValue === "Total") {
        this.setActiveState(selectedStatusValue);
        delete filter.status;
      }

      await this.fetchTaskStatsOfAProject(filter);

      return Api.getAllTask(this.projectId, {
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
        filter: JSON.stringify(filter),
        sort: this.sort,
      })
        .then((response) => {
          this.loading = false;
          this.taskList = response.data.docs;
          this.totalCount = response.data.totalCount;

          this.generateStateCount(this.counts);
          var mainState = this.stateCount[selectedStatusValue]
            ? selectedStatusValue
            : this.taskStateMapping[selectedStatusValue];
          this.setSubStateCount(mainState);
        })
        .catch((error) => {
          this.loading = false;
          let notification = {
            type: "error",
            showMessage: true,
            message: this.$notifications().TASK_LIST_ERROR,
          };

          this.setNotification(notification);
          console.log(error);
        });
    },
    fetchTaskStatsOfAProject: async function (_filter) {
      const self = this;
      return Api.getTaskStatsOfAProject(this.projectId, {
        filter: JSON.stringify(_filter),
      })
        .then((response) => {
          // Note :: Sequence for the Status matters in the response (i.e., Status must be in same order as 'stateCount' in the response)
          self.counts = response.data;
        })
        .catch((error) => {
          self.loading = false;
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().TASK_STATS_ERROR,
          };

          self.setNotification(notification);
          console.log(error);
        });
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    fetchAllTaskFacets: async function (field) {
      switch (field) {
        case "accountFinalDisposition":
          await Api.getAccountDispositions(this.projectId)
            .then((response) => {
              this.inputFilterObject.accountFinalDisposition.values = _.compact(
                response.data
              );
            })
            .catch((error) => console.log(error));
          break;
        case "accountDisposition":
          await Api.getTaskFacets(this.projectId, {
            field: "disposition",
          })
            .then((response) => {
              this.inputFilterObject.accountDisposition.values = _.compact(
                response.data
              );
            })
            .catch((error) => console.log(error));
          break;
        case "userName":
          await Api.getTaskFacets(this.projectId, {
            field: "userName",
          })
            .then((response) => {
              this.inputFilterObject.userName.values = _.compact(response.data);
            })
            .catch((error) => console.log(error));
          break;
        case "contactDisposition":
          await Api.getContactFacets(this.projectId, {
            field: "disposition",
          })
            .then((response) => {
              this.inputFilterObject.contactDisposition.values = _.compact(
                response.data
              );
            })
            .catch((error) => console.log(error));
          break;
        default:
          break;
      }
    },
    setFilterForTypeOfDownloadFile: function (typeOfDownloadFile) {
      this.downloadFilter = {};
      switch (typeOfDownloadFile) {
        case "Today Task":
          var todayDate = new Date();
          var startDate = new Date(todayDate).setHours(0, 0, 0, 0);
          var endDate = new Date(todayDate).setHours(23, 59, 59, 999);
          this.downloadFilter["updatedAt"] = {
            operator: "between",
            value: [startDate, endDate],
          };
          break;
        case "Compliance Task":
          this.downloadFilter["contactStage"] = {
            operator: "=",
            value: "compliance",
          };
          break;
        case "Non-Compliant Task":
          this.downloadFilter["contactComplianceStatus"] = {
            operator: "=",
            value: "non-compliant",
          };
          break;
        case "Filtered Task":
          this.downloadFilter = _.cloneDeep(this.filter);
          break;
      }
    },
    changeIconOfDownloadBtn: function (disable) {
      if (disable) {
        this.iconOfDownloadTodayCompletedTask = "downloading";
      } else {
        this.iconOfDownloadTodayCompletedTask = "file_download";
      }
    },
    tasksDownload: function (typeOfDownloadFile) {
      if (!this.isManager) {
        return;
      }
      const self = this;
      this.isDownloading = true;
      this.changeIconOfDownloadBtn(true);
      this.setFilterForTypeOfDownloadFile(typeOfDownloadFile);

      var filter = _.cloneDeep(this.downloadFilter);

      Api.downloadAllTask(this.projectId, {
        download: true,
        filter: JSON.stringify(filter),
      })
        .then((response) => {
          var downloadFileName;
          const processedProjectName = this.projectName
            .trim()
            .split(" ")
            .join("_");
          switch (typeOfDownloadFile) {
            case "Today Task":
              downloadFileName = `${processedProjectName}_today_task.csv`;
              break;
            case "All Task":
              downloadFileName = `${processedProjectName}_all_task.csv`;
              break;
            case "Compliance Task":
              downloadFileName = `${processedProjectName}_compliance_task.csv`;
              break;
            case "Non-Compliant Task":
              downloadFileName = `${processedProjectName}_non_compliant_task.csv`;
              break;
            case "Filtered Task":
              downloadFileName = `${processedProjectName}_filtered_task.csv`;
              break;
          }

          const responseContentType = response.headers["content-type"];

          let notification = {
            type: "success",
            showMessage: true,
          };
          this.isDownloading = false;
          this.changeIconOfDownloadBtn(false);

          if (responseContentType === "application/csv") {
            notification.message = self.$notifications().FILE_SUCCESS;
            self.setNotification(notification);
            return fileDownload(response.data, downloadFileName);
          }

          notification.message =
            self.$notifications().DOWNLOAD_JOB_SUBMISSION_SUCCESS;
          self.setNotification(notification);
          return;
        })
        .catch((error) => {
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().FILE_ERROR,
          };
          self.setNotification(notification);
          this.isDownloading = false;
          this.changeIconOfDownloadBtn(false);
        });
    },
    filterTaskByStates: async function (selectedStatus) {
      this.setActiveState(selectedStatus);
      delete this.filter.status;
      if (selectedStatus !== "Total") {
        this.filter["status"] = {
          operator: "=",
          value: selectedStatus,
        };
      }
      const subStatusOperators = {
        Overdue: "<",
        Upcoming: ">=",
      };
      const subStatusIndex = _.indexOf(
        Object.keys(subStatusOperators),
        selectedStatus
      );
      if (subStatusIndex >= 0) {
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        this.filter["dueDate"] = {
          operator: subStatusOperators[selectedStatus],
          value: todayDate,
        };
        this.filter["status"].value = "Pending";
      } else {
        delete this.filter["dueDate"];
      }
      await this.fetchTaskLists();
    },
    setStyleOfSubStates(mainState) {
      switch (mainState) {
        case "Pending":
          this.styleOfSubStates.margin = "0 0 0 325px";
          break;
        default:
          this.styleOfSubStates.margin = "0 0 0 150px";
      }
    },
    setSubStateCount(state) {
      this.subStateCount = {};
      var subState = this.stateCount[state].subState;
      if (subState && Object.keys(subState).length) {
        this.setStyleOfSubStates(state);
        this.subStateCount = subState;
      }
    },
    setActiveState: function (selectedStatus) {
      if (!this.stateCount[selectedStatus]) {
        var mainState = this.taskStateMapping[selectedStatus];
        this.activeTaskState = this.indexOfTaskState[mainState];
        this.activeSubTaskState = this.indexOfTaskState[selectedStatus];
        return;
      }
      this.activeSubTaskState = null;
      this.activeTaskState = this.indexOfTaskState[selectedStatus];
    },
    generateStateCount(taskState) {
      var self = this;

      let stateIndex = 0;
      let subStateIndex = 0;
      this.indexOfTaskState = {};

      for (var status in self.stateCount) {
        self.stateCount[status]["count"] = 0;
      }
      taskState.forEach(function (item) {
        let mainState = self.taskStateMapping[item.status];
        if (mainState) {
          self.stateCount[mainState].count =
            self.stateCount[mainState].count + parseInt(item.count);

          if (!self.indexOfTaskState[mainState]) {
            self.indexOfTaskState[mainState] = stateIndex;
            stateIndex += 1;
          }

          self.stateCount[mainState].subState[item.status] = item.count;
          if (!self.indexOfTaskState[item.status]) {
            self.indexOfTaskState[item.status] = subStateIndex;
            subStateIndex += 1;
          }
        } else {
          self.stateCount[item.status].count = parseInt(item.count);
          if (!self.indexOfTaskState[mainState]) {
            self.indexOfTaskState[item.status] = stateIndex;
            stateIndex += 1;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.v-chip-group >>> .v-slide-group__content {
  display: inline;
}

.counter_stats {
  float: left;
  width: 1300px;
  margin-left: -10px;
  margin-bottom: 10px;
}

.counter_stats::-webkit-scrollbar {
  -webkit-appearance: none;
}

.counter_stats::-webkit-scrollbar:horizontal {
  height: 11px;
}

.counter_stats::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: #8b83ba;
}
.counter_stats .counter {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 5px;
  height: 100px !important;
}
.counter_stats .counter .counter_title {
  font-weight: 400;
  margin: 10px 0 15px;
  font-family: "Lato";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
  max-height: 40px;
  overflow: hidden;
}
.counter_stats .counter .counter_value {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #1c76d2;
  margin: 0;
  margin-top: 10px;
  text-align: center;
}

.tasks {
  width: 100%;
}

.account_filter {
  width: 24%;
  float: left;
  margin-left: 10px;
}
.account_filter h4 {
  text-align: center;
}
.account_list {
  float: left;
  margin: 5px 0 20px;
}

.stat_item {
  display: inline-block;
  margin: 0 15px;
  width: 160px;
}
.stat_item h6 {
  font-weight: 500;
  font-size: 14px;
}

.account_list_headers {
  background: #ffffff;
  padding: 5px 10px;
  padding-left: 0;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
}

.account_list_headers h6 {
  font-family: "Lato";
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
  text-transform: uppercase !important;
}
.account_list_headers .field.account_name_flex,
.list_item .field.account_name_flex {
  width: 280px;
}

.account_list_headers .field,
.list_item .field {
  width: 175px;
  padding: 5px;
}

.account_list_headers .field.sm,
.list_item .field.sm {
  width: 150px;
}

.list_item .field h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
  color: #25213b;
}
.task_import_download_buttons {
  position: absolute;
  top: 45px;
  right: 25px;
}
.notification {
  position: absolute;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

.counter_stats .counter.activeDisposition {
  border-radius: 4px;
}

.counter_stats .counter.activeDisposition .counter_value,
.counter_stats .counter.activeDisposition .counter_title {
  color: white;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.field a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 27px;
}
/* .list_item .field h4 a {
  text-overflow: ellipsis;
  width: 95%;
  display: inline-block;
  overflow: hidden;
} */
</style>
