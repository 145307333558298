<template>
  <div>
    <!-- FileUpload Download Refresh Buttons-->
    <div class="contact_import_download_buttons">
      <FileUpload
        fileType="Import"
        :projectId="projectId"
        :projectName="projectName"
        importType="Contact"
      />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-2 white--text"
            color="#8B83BA"
            fab
            small
            :loading="isDownloading"
            :disabled="isDownloading"
            @click="contactDownload(selectedStage)"
          >
            <v-icon>download</v-icon>
          </v-btn>
        </template>
        <span>Download</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="ml-2 white--text"
            color="#8B83BA"
            :loading="loading"
            :disabled="loading"
            fab
            small
            @click="pageRefresh()"
            ><v-icon>mdi-cached</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>

      <GeneralizedFilter
        :inputFilterObject="inputFilterObject"
        v-on:filterObject="applyFilter($event)"
        v-on:fetchFilters="fetchContactFacets($event)"
      />

      <AssignTasks
        pageName="contact"
        :projectId="projectId"
        :filter="filter"
        ref="AssignTasks"
        :maxBlockSize="contactsTotalCount"
      />
    </div>

    <v-container fluid class="contacts">
      <!-- researchStatusList -->
      <v-row>
        <v-col cols="12" sm="11" class="py-0">
          <v-slide-group
            v-model="mainDisposition"
            class="counter_stats pr-6"
            active-class="activeDisposition"
            show-arrows
          >
            <v-slide-item
              v-for="researchStatus in researchStatusList"
              :key="researchStatus.id"
              v-slot="{ active, toggle }"
            >
              <v-card
                class="ml-2 counter"
                height="80"
                width="150"
                :color="active ? '#205fac' : 'FFFFFF'"
                v-on:click="toggle"
                @click="assignStyletoSplitResearchStatus(researchStatus.status)"
              >
                <h4 class="counter_value pt-2">
                  {{ researchStatus.count | humanizeNumber }}
                </h4>
                <h4
                  class="counter_title"
                  v-if="researchStatusMapping[researchStatus.status]"
                >
                  {{ researchStatusMapping[researchStatus.status] }}
                </h4>
                <h4 class="counter_title" v-else-if="researchStatus.status">
                  {{ researchStatus.status }}
                </h4>
                <h4 class="counter_title" v-else>Pending</h4>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-col>
        <v-col cols="12" sm="1"> </v-col>
      </v-row>

      <!-- styleSplitResearchStatus -->
      <v-row>
        <v-col cols="12" sm="11" class="pb-0">
          <v-slide-group
            class="counter_stats pr-6"
            v-bind:style="styleSplitResearchStatus"
            active-class="activeDisposition"
          >
            <v-slide-item
              v-for="(value, name) in splittedResearchStatus"
              :key="value.id"
              show-arrows
              v-slot="{ active, toggle }"
            >
              <v-card
                class="ml-2 mb-2 counter"
                height="80"
                width="150"
                :color="active ? '#205fac' : 'FFFFFF'"
                v-on:click="toggle"
                @click="filterListByResearchStatus(name)"
              >
                <h4 class="counter_value pt-2">
                  {{ value | humanizeNumber }}
                </h4>
                <h4 class="counter_title">{{ name }}</h4>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-col>
      </v-row>

      <v-row v-if="Object.keys(filterForChips).length">
        <v-col cols="12" sm="11" class="pb-0">
          <v-card elevation="1" class="px-3">
            <h5
              class="text-overline pl-2"
              style="border-bottom: 1px solid #ddd"
            >
              Applied Filter:
              <span
                data-v-41106904=""
                style="
                  margin-left: 10px;
                  font-family: 'Lato';
                  text-transform: none;
                  color: rgb(32, 95, 172);
                  cursor: pointer;
                  letter-spacing: 0px;
                  font-size: 13px;
                "
                @click="clearAllFilters()"
                >( Clear all )</span
              >
            </h5>
            <v-chip-group column>
              <span
                v-for="(subProps, key) in filterForChips"
                :key="key"
                style="display: inline"
              >
                <span
                  v-if="Array.isArray(subProps.value) && subProps.value.length"
                >
                  <v-chip
                    close
                    label
                    @click:close="removeFilter(key, subValue)"
                    v-for="(subValue, index) in subProps.value"
                    :key="index"
                  >
                    <span style="padding-right: 5px">
                      {{ subProps.text }}:
                    </span>
                    <span
                      v-if="
                        subProps.operator !== operatorEqualTo &&
                        subProps.operator !== operatorBetween
                      "
                      style="padding-right: 2px"
                    >
                      {{ subProps.operator }}
                    </span>
                    <span v-if="subValue.length"> {{ subValue }}</span>
                  </v-chip>
                </span>
                <span v-else>
                  <v-chip close label @click:close="removeFilter(key)">
                    <span style="padding-right: 5px">
                      {{ subProps.text }}:
                    </span>
                    <span
                      v-if="
                        subProps.operator !== operatorEqualTo &&
                        subProps.operator !== operatorBetween
                      "
                      style="padding-right: 2px"
                    >
                      {{ subProps.operator }}
                    </span>
                    <span v-if="subProps.value.length">
                      {{ subProps.value }}</span
                    >
                  </v-chip>
                </span>
              </span>
            </v-chip-group>
          </v-card>
        </v-col>
      </v-row>

      <!-- DataGrid -->
      <v-row>
        <v-col cols="12" sm="11">
          <DataGrid
            gridId="pmContactPageId"
            gridRef="pmContactPageRef"
            gridHeight="53vh"
            :headers="headers"
            :itemList="contactList"
            :loading="loading"
            :totalCount="contactsTotalCount"
            :pageNo="pageNumber"
            @fetchGrideData="fetchTaskData($event)"
            :allowMultiSort="true"
          />
        </v-col>
      </v-row>

      <v-row class="container-fluid">
        <v-col cols="12" sm="1">
          <div class="counter_stage" style="margin-top: 120px">
            <v-icon class="arrow_forward_ios">arrow_forward_ios</v-icon>
            <v-icon class="arrow_back_ios">arrow_back_ios</v-icon>
            <div
              class="counter"
              v-bind:class="{ active: 'Total' === selectedStage }"
              @click="applyStageAsFilter('Total')"
            >
              <h4 class="counter_value">
                {{ stagesTotalCount | humanizeNumber }}
              </h4>
              <h4 class="counter_title">Total</h4>
            </div>
            <div
              class="counter"
              v-for="stage in stages"
              :key="stage.id"
              @click="applyStageAsFilter(stage.stage)"
              v-bind:class="{ active: stage.stage === selectedStage }"
            >
              <h4 class="counter_value">{{ stage.count | humanizeNumber }}</h4>

              <h4 class="counter_title" v-if="stage.stage">
                {{ stage.stage }}
              </h4>
              <h4 class="counter_title" v-else>Pending</h4>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import _ from "lodash";
import Api from "../services/Api";
import FileUpload from "./fileUpload";
import DataGrid from "./dataGrid.vue";
import GeneralizedFilter from "./GeneralizedFilters.vue";
var fileDownload = require("js-file-download");
import AssignTasks from "./AssignTasks.vue";

export default {
  components: {
    FileUpload,
    DataGrid,
    GeneralizedFilter,
    AssignTasks,
  },
  name: "PROJECTContact",
  props: {
    projectId: String,
    projectName: String,
  },
  data() {
    return {
      mainDisposition: 0,
      subDisposition: null,
      loading: false,
      isDownloading: false,
      contactList: [],
      contactFilters: {},
      totalCount: "",
      pageNumber: 1,
      pageSize: this.$constants().PAGE_SIZE_20,
      researchStatus: [],
      stages: [],
      researchStatusList: [],
      downloadInProgressIcon: "file_download",
      downloadDeliverableIcon: "file_download",
      disabledDownloadInProgressBtn: false,
      disabledDownloadDeliverableBtn: false,
      accountDownloadErrorTimeOut: 5000,
      badResearchStatusSplit: {},
      researchStatusMapping: {
        Pending: "Pending",
        QA: "Newly Built",
        Q: "Verified",
        QF: "Modified",
        NQ: "Disqualified",
        NF: "Bad",
        D: "Bad",
        Dup: "Duplicate",
        QR: "Modified",
      },
      operatorEqualTo: "=",
      operatorBetween: "between",
      splittedResearchStatus: {},
      styleSplitResearchStatus: {
        margin: "0 0 0 150px",
      },
      filter: {},
      selectedStage: "Total",
      stageOrdered: [
        "Pending",
        "Rework",
        "Re-work",
        "Review",
        "Compliance",
        "Ready",
        "Delivered",
        "Bad",
        "Discarded",
        "Working",
      ],
      stagesTotalCount: "",
      //To set column size set header width large : 200px,common : 150px, small : 125px
      headers: [
        {
          value: "contactFullName",
          text: "Name",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "jobTitle",
          text: "JobTitle",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "companyName",
          text: "Company Name",
          sortable: true,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "domain",
          text: "Domain",
          sortable: true,
          divider: true,
          width: "200px",
          type: "string",
          tooltip: this.$constants().COLUMN_LIMIT20,
          googleSearch: false,
        },
        {
          value: "disposition",
          text: "Disposition",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "researchStatus",
          text: "Research Status",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "stage",
          text: "Stage",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "complianceStatus",
          text: "Compliance Status",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "accountLabel",
          text: "Account Label",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "contactLabel",
          text: "Contact Label",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "updatedBy",
          text: "Contact Updated By",
          sortable: false,
          divider: true,
          width: "200px",
          type: "string",
          googleSearch: false,
        },
        {
          value: "updatedAt",
          text: "Contact Updated Date",
          sortable: false,
          divider: true,
          width: "200px",
          type: "date",
          googleSearch: false,
        },
      ],
      inputFilterObject: {
        companyName: {
          text: "Company Name",
          type: "string",
          operators: ["IS", "IS NULL"],
          values: [],
        },
        domain: {
          text: "Domain",
          type: "string",
          operators: ["IS", "IS NULL"],
          values: [],
        },
        accountLabel: {
          text: "Account Label",
          type: "string",
          operators: ["IS"],
          values: ["suppressed", "inclusion", "duplicate"],
        },
        contactLabel: {
          text: "Contact Label",
          type: "string",
          operators: ["IS"],
          values: [
            "suppressed",
            "inclusion",
            "duplicate",
            "Fuzzy Suppressed",
            "Exact Suppressed",
          ],
        },
        updatedBy: {
          text: "Contact Updated By",
          type: "array",
          operators: ["IS"],
          values: [],
          search: true,
        },
        updatedAt: {
          text: "Contact Updated Date",
          type: "date",
          operators: ["BETWEEN"],
          values: [],
        },
      },
      contactsTotalCount: 0,
      filterForChips: {},
      sort: {},
    };
  },
  mounted: function () {
    this.pageRefresh();
  },
  methods: {
    clearAllFilters: async function () {
      this.loading = true;
      this.filterForChips = {};
      this.filter = {};
      await this.resetDispositionAndStageFilter();
      await this.fetchContactList();
      await this.fetchStats();
      this.loading = false;
    },
    fetchContactFacets: async function (field) {
      let self = this;
      switch (field) {
        case "updatedBy":
          await Api.getContactFacets(self.projectId, {
            field: "updatedBy",
          })
            .then((response) => {
              self.inputFilterObject.updatedBy.values = _.compact(
                response.data
              );
            })
            .catch((error) => console.log(error));
          break;
        default:
          break;
      }
    },
    removeFilter: async function (filterKey, subValue) {
      this.pageNumber = 1;
      this.loading = true;
      if (!subValue) {
        delete this.filterForChips[filterKey];
        delete this.filter[filterKey];
      } else {
        const filterForChipsValues = _.get(
          this.filterForChips,
          [filterKey, "value"],
          []
        );
        const filterValues = _.get(this.filter, [filterKey, "value"], []);
        if (filterForChipsValues.length <= 1 && filterValues.length <= 1) {
          delete this.filterForChips[filterKey];
          delete this.filter[filterKey];
        } else {
          const filterForChipsValueIndex = _.indexOf(
            filterForChipsValues,
            subValue
          );
          if (filterForChipsValueIndex > -1) {
            this.filterForChips[filterKey]["value"].splice(
              filterForChipsValueIndex,
              1
            );
          }
          const filterValueIndex = _.indexOf(filterValues, subValue);
          if (filterValueIndex > -1) {
            this.filter[filterKey]["value"].splice(filterValueIndex, 1);
          }
        }
      }
      await this.resetDispositionAndStageFilter();
      await this.fetchContactList();
      await this.fetchStats();
      this.loading = false;
    },
    orderStages() {
      let self = this;

      let c = self.stageOrdered
        .map((i) => self.stages.find((j) => j.stage === i))
        .filter((x) => x !== null && typeof x !== "undefined");

      return c;
    },
    applyStageAsFilter(value) {
      this.loading = true;
      this.selectedStage = value;
      delete this.filter["stage"];
      if (value && value !== "Total") {
        this.filter["stage"] = {
          operator: "=",
          value: value,
        };
      }
      this.pageNumber = 1;
      this.fetchContactList();
      this.loading = false;
    },
    applyFilter: async function (filterApplied) {
      this.loading = true;
      this.pageNumber = 1;
      const keyOfFilterApplied = Object.keys(filterApplied)[0];
      this.filterForChips[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      this.modifyFilterForChips(keyOfFilterApplied);
      filterApplied[keyOfFilterApplied] = _.omit(
        filterApplied[keyOfFilterApplied],
        ["text", "itemText", "itemValue", "search", "type"]
      );
      this.filter[keyOfFilterApplied] = _.cloneDeep(
        filterApplied[keyOfFilterApplied]
      );
      await this.resetDispositionAndStageFilter();
      await this.fetchContactList();
      await this.fetchStats();
      this.loading = false;
    },
    modifyFilterForChips: function (keyOfFilterApplied) {
      const valueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["value"];
      const operatorOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["operator"];
      const itemTextOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemText"];
      const itemValueOfFilteredForChips =
        this.filterForChips[keyOfFilterApplied]["itemValue"];
      if (
        operatorOfFilteredForChips === "between" &&
        Array.isArray(valueOfFilteredForChips)
      ) {
        this.filterForChips[keyOfFilterApplied]["value"] =
          this.filterForChips[keyOfFilterApplied]["value"].join(" ~ ");
      }
      if (itemTextOfFilteredForChips && itemValueOfFilteredForChips) {
        let valuesOfFilteredId = _.map(
          this.inputFilterObject[keyOfFilterApplied].values,
          (item) => {
            if (item[itemValueOfFilteredForChips] === valueOfFilteredForChips) {
              return item[itemTextOfFilteredForChips];
            }
          }
        );
        valuesOfFilteredId = _.compact(valuesOfFilteredId);
        this.filterForChips[keyOfFilterApplied]["value"] =
          valuesOfFilteredId[0];
      }
    },
    resetDispositionAndStageFilter: async function () {
      this.mainDisposition = 0;
      this.subDisposition = null;
      delete this.filter["stage"];
      delete this.filter["researchStatus"];
      this.selectedStage = "Total";
      this.splittedResearchStatus = {};
    },
    pageRefresh: async function () {
      this.loading = true;
      await this.fetchContactList();
      await this.fetchStats();
      this.loading = false;
    },
    fetchContactList: function () {
      Api.getContacts(this.projectId, {
        pageNo: this.pageNumber - 1,
        pageSize: this.pageSize,
        filter: JSON.stringify(this.filter),
        sort: JSON.stringify(this.sort),
      })
        .then((response) => {
          this.contactsTotalCount = response.data.totalCount;
          this.contactList = response.data.docs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchStats: function () {
      return Api.getStats(this.projectId, {
        filter: JSON.stringify(this.filter),
      })
        .then((response) => {
          this.researchStatus = response.data.researchStatus.data;
          this.generateContactResearchStatus(this.researchStatus);
          this.stagesTotalCount = response.data.stage.totalCount;
          this.stages = response.data.stage.data;
          this.stages = this.orderStages();
        })
        .catch((error) => console.log(error));
    },
    disableDownloadBtn: function (stage, disable) {
      if (stage === "Ready") {
        if (disable) {
          this.downloadDeliverableIcon = "downloading";
          this.disabledDownloadDeliverableBtn = true;
        } else {
          this.downloadDeliverableIcon = "file_download";
          this.disabledDownloadDeliverableBtn = false;
        }
      } else if (stage === "In Progress") {
        if (disable) {
          this.downloadInProgressIcon = "downloading";
          this.disabledDownloadInProgressBtn = true;
        } else {
          this.downloadInProgressIcon = "file_download";
          this.disabledDownloadInProgressBtn = false;
        }
      }
    },
    contactDownload: function (stage) {
      let self = this;
      this.isDownloading = true;
      const downloadFilter = Object.assign({}, this.filter);
      downloadFilter.stage = {
        operator: "=",
        value: stage,
      };
      if (stage === "Total") {
        delete downloadFilter["stage"];
      }
      this.disableDownloadBtn(stage, true);
      Api.downloadContact(this.projectId, {
        download: true,
        filter: downloadFilter,
      })
        .then((response) => {
          const processedProjectName = this.projectName
            .trim()
            .split(" ")
            .join("_");
          var downloadFileName = `${processedProjectName}_contact_${Date.now()}.csv`;

          if (stage === "Ready") {
            downloadFileName = `${processedProjectName}_contact_deliverable_${Date.now()}.csv`;
          } else if (stage === "In Progress") {
            downloadFileName = `${processedProjectName}_contact_in_progress_${Date.now()}.csv`;
          } else {
            downloadFileName = `${processedProjectName}_contact_compliance_${Date.now()}.csv`;
          }

          let notification = {
            type: "success",
            showMessage: true,
          };

          const responseContentType = response.headers["content-type"];
          this.disableDownloadBtn(stage, false);
          this.isDownloading = false;
          if (responseContentType === "application/csv") {
            notification.message = self.$notifications().FILE_SUCCESS;
            self.setNotification(notification);
            return fileDownload(response.data, downloadFileName);
          }
          notification.message =
            self.$notifications().DOWNLOAD_JOB_SUBMISSION_SUCCESS;
          self.setNotification(notification);
          return;
        })
        .catch((error) => {
          console.log(error);
          let notification = {
            type: "error",
            showMessage: true,
            message: self.$notifications().FILE_ERROR,
          };
          self.setNotification(notification);
          this.isDownloading = false;
          this.disableDownloadBtn(stage, false);
        });
    },
    generateContactResearchStatus(researchStatus) {
      var self = this;
      var finalResearchStatus = {
        Total: 0,
        "Newly Built": 0,
        Verified: 0,
        Modified: 0,
        Disqualified: 0,
        Bad: 0,
        Duplicate: 0,
        "Bad Disposition": 0,
        Pending: 0,
      };
      researchStatus.forEach(function (item) {
        finalResearchStatus["Total"] =
          finalResearchStatus["Total"] + parseInt(item.count);

        if (self.researchStatusMapping[item.researchStatus]) {
          finalResearchStatus[self.researchStatusMapping[item.researchStatus]] =
            finalResearchStatus[
              self.researchStatusMapping[item.researchStatus]
            ] + parseInt(item.count);
        } else {
          if (item.researchStatus === null) {
            item.researchStatus = "Pending";
          } else if (
            self.researchStatusMapping[item.researchStatus] === undefined
          ) {
            finalResearchStatus["Bad Disposition"] =
              Math.floor(finalResearchStatus["Bad Disposition"]) +
              Math.floor(item.count);
            self.badResearchStatusSplit[item.researchStatus] = item.count;
          } else {
            finalResearchStatus[item.researchStatus] = item.count;
          }
        }
      });
      this.totalCount = finalResearchStatus["Total"];
      var arrayResearchStatus = Object.keys(finalResearchStatus).map((key) => {
        return {
          status: key,
          count: finalResearchStatus[key],
        };
      });
      self.researchStatusList = arrayResearchStatus;
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    assignStyletoSplitResearchStatus(researchStatus) {
      this.filterListByResearchStatus(researchStatus);
      switch (researchStatus) {
        case "Newly Built":
          this.styleSplitResearchStatus.margin = "0 0 0 210px";
          break;
        case "Verified":
          this.styleSplitResearchStatus.margin = "0 0 0 370px";
          break;
        case "Modified":
          this.styleSplitResearchStatus.margin = "0 0 0 530px";
          break;
        case "Disqualified":
          this.styleSplitResearchStatus.margin = "0 0 0 685px";
          break;
        case "Bad":
          this.styleSplitResearchStatus.margin = "0 0 0 845px";
          break;
        case "Duplicate":
          this.styleSplitResearchStatus.margin = "0 0 0 940px";
          break;
        case "Bad Disposition":
          this.styleSplitResearchStatus.margin = "0 0 0 1000px";
          break;
        case "Pending":
          this.styleSplitResearchStatus.margin = "0 0 0 1000px";
          break;
        default:
          this.styleSplitResearchStatus.margin = "0 0 0 150px";
      }

      this.splitResearchStatus(researchStatus);
    },
    splitResearchStatus(researchStatus) {
      this.splittedResearchStatus = {};
      var self = this;

      for (const key in this.researchStatusMapping) {
        if (this.researchStatusMapping[key] === researchStatus) {
          this.splittedResearchStatus[key] = 0;
        }
      }
      this.researchStatus.forEach(function (item) {
        if (
          self.researchStatusMapping[item.researchStatus] === researchStatus
        ) {
          self.splittedResearchStatus[item.researchStatus] = item.count;
        }
      });

      if (researchStatus == "Bad Disposition") {
        self.splittedResearchStatus = Object.assign(
          {},
          this.badResearchStatusSplit
        );
      }
    },
    filterListByResearchStatus(filterResearchStatus) {
      this.loading = true;

      // Reset the stage Filter
      this.selectedStage = "Total";
      delete this.filter["stage"];
      delete this.filter["researchStatus"];

      let researchStatus = Object.keys(this.researchStatusMapping).filter(
        (k) => this.researchStatusMapping[k] === filterResearchStatus
      );

      if (
        filterResearchStatus === "Pending" ||
        filterResearchStatus === "Newly Built" ||
        filterResearchStatus === "Verified" ||
        filterResearchStatus === "Modified" ||
        filterResearchStatus === "Disqualified" ||
        filterResearchStatus === "Bad" ||
        filterResearchStatus === "Duplicate"
      ) {
        filterResearchStatus = researchStatus;
      } else if (filterResearchStatus === "Bad Disposition") {
        filterResearchStatus = Object.keys(this.badResearchStatusSplit);
        if (filterResearchStatus.length === 0) {
          filterResearchStatus = ["Bad Disposition"];
        }
      } else if (filterResearchStatus === "Total") {
        filterResearchStatus = [];
      } else {
        filterResearchStatus;
      }

      if (typeof filterResearchStatus === "string") {
        filterResearchStatus = [filterResearchStatus];
      }
      if (filterResearchStatus.length) {
        this.filter["researchStatus"] = {
          operator: "=",
          value: filterResearchStatus,
        };
      }
      this.pageNumber = 1;
      this.fetchContactList();
      this.fetchStats();
      this.loading = false;
    },
    generateSortPayload(sortBy, sortDesc) {
      var sortPayload = {};
      sortBy.forEach(function (item, index) {
        sortPayload[item] = sortDesc[index] ? "asc" : "desc";
      });
      return sortPayload;
    },
    fetchTaskData(options) {
      const { sortBy, sortDesc, page, itemsPerPage } = options;

      this.pageNumber = page;
      this.pageSize = itemsPerPage;
      this.sort = this.generateSortPayload(sortBy, sortDesc);
      //api call
      this.loading = true;
      this.fetchContactList();
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.counter_stats {
  float: left;
  width: 100%;
}
.counter_stats .counter {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 5px;
  height: 100px !important;
}

.counter_stage .counter .counter_title,
.counter_stats .counter .counter_title {
  font-weight: 400;
  margin: 10px 0 15px;
  font-family: "Lato";
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #205fac;
}
.counter_stage .counter .counter_value,
.counter_stats .counter .counter_value {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #1c76d2;
  margin: 0;
  text-align: center;
}
.counter_stage .counter {
  padding: 5px 0 10px;
  padding-left: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.counter_stage .counter.active {
  background-color: rgb(32, 95, 172);
}

.counter_stage .counter:last-of-type {
  border-bottom: 0;
}

.counter_stage {
  float: left;
  width: 90px;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-left: 13px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  background: white;
}

.counter_stage:hover {
  z-index: 100000;
  width: 150px;
  background: white;
  transition: width 0.1s;
}
.counter_stage:hover i {
  padding: 7px 13px 0 8px;
}
.counter_stage i {
  position: absolute;
  left: -20px;
  top: 65px;
  padding: 7px 0 0 10px;
  border: 2px solid white;
  width: 42px;
  height: 42px;
  background: #205fac;
  border-radius: 100%;
  color: white;
}
.counter_stage i.arrow_forward_ios {
  display: none;
}
.counter_stage:hover i.arrow_forward_ios {
  display: block;
}
.counter_stage i.arrow_back_ios {
  display: block;
}
.counter_stage:hover i.arrow_back_ios {
  display: none;
}
.stat_item {
  display: inline-block;
  margin: 0 15px;
  float: left;
}
.stat_item.contact_stage {
  width: 105px;
}
.stat_item.account_domain {
  width: 290px;
}
.stat_item.contact_stage h4 {
  display: inline-block;
  width: auto;
  border: 1px solid #eee;
  background: #3273dc;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 500;
}
.stat_item h6 {
  font-weight: 400;
  font-size: 13px;
  color: #075ab1;
}
.account_domain h4,
.contact_job_title {
  height: 22px;
  overflow: hidden;
}

.contact_import_download_buttons {
  position: absolute;
  top: 45px;
  right: 25px;
}

.counter_stats .counter.activeDisposition {
  border-radius: 4px;
}

.counter_stats .counter.activeDisposition .counter_value,
.counter_stats .counter.activeDisposition .counter_title,
.counter_stage .counter.active .counter_title,
.counter_stage .counter.active .counter_value {
  color: white;
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .contact_list_headers .field,
  .list_item .field {
    width: 220px;
  }

  .contact_list_headers h6,
  .list_item h6 {
    font-size: 14px;
  }

  .list_item .field h4 {
    font-size: 15px;
  }
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .contact_list_headers .field,
  .list_item .field {
    width: 250px;
  }

  .contact_list_headers .field.sm,
  .list_item .field.sm {
    width: 180px;
  }
  .contact_list_headers h6,
  .list_item h6 {
    font-size: 15px;
  }

  .list_item .field h4 {
    font-size: 16px;
  }
}
.notification {
  position: absolute;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
