<template>
  <v-container fluid class="account_form form">
    <div
      :class="{ 'scroller-lg': fromContactFrom, scroller: !fromContactFrom }"
      class="col-md-12"
    >
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="orderSection">
          <!-- Detail Section -->
          <div
            v-if="sectionKeyBy.Detail && sectionKeyBy.Detail.display"
            v-bind:style="{
              order: sectionKeyBy.Detail.order,
            }"
            class="border orderFields"
          >
            <v-row class="col-md-12">
              <!-- name -->
              <v-col
                v-if="sectionKeyBy.Detail.fields['account.name']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order: sectionKeyBy.Detail.fields['account.name'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Detail.fields["account.name"].label }}
                  <span v-if="mandatoryFields['name']" style="color: #ff0000"
                    >*</span
                  >
                </h4>

                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.name }"
                  v-model="account.name"
                  @blur="formatFormFields($event.target.value, 'name', '')"
                  :tabindex="sectionKeyBy.Detail.fields['account.name'].order"
                  outlined
                  dense
                  hide-details
                  :required="mandatoryFields['name']"
                  :rules="
                    mandatoryFields['name']
                      ? [
                          (v) =>
                            !!v ||
                            !!_.trim(v) ||
                            `${sectionKeyBy.Detail.fields['account.name'].label} is required`,
                        ]
                      : []
                  "
                ></v-text-field>
              </v-col>

              <!-- website -->
              <v-col
                v-if="sectionKeyBy.Detail.fields['account.website']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order: sectionKeyBy.Detail.fields['account.website'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Detail.fields["account.website"].label }}
                  <span v-if="mandatoryFields['website']" style="color: #ff0000"
                    >*</span
                  >
                </h4>
                <v-text-field
                  class="input_data"
                  v-model="account.website"
                  v-bind:class="{ highlight: !account.website }"
                  @blur="formatFormFields($event.target.value, 'website', '')"
                  :tabindex="
                    sectionKeyBy.Detail.fields['account.website'].order
                  "
                  @change="fetchMasterAccount(account.website)"
                  outlined
                  dense
                  hide-details
                  :required="mandatoryFields['website']"
                  :rules="
                    mandatoryFields['website']
                      ? [
                          (v) =>
                            !!v ||
                            !!_.trim(v) ||
                            `${sectionKeyBy.Detail.fields['account.website'].label} is required`,
                          rules.url,
                        ]
                      : []
                  "
                ></v-text-field>
              </v-col>

              <!-- HardCoded Not template base Company Type -->
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order: sectionKeyBy.Detail.fields['account.website'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{ "Company Type" }}
                  <span v-if="mandatoryFields['type']" style="color: #ff0000"
                    >*</span
                  >
                </h4>
                <v-select
                  class="input_data"
                  v-model="account.type"
                  :tabindex="
                    sectionKeyBy.Detail.fields['account.website'].order + 1
                  "
                  v-bind:class="{ highlight: !account.type }"
                  :items="companyTypeList"
                  label="Select Company Type"
                  :rules="
                    mandatoryFields['type']
                      ? [(v) => !!v || 'Company Type is required']
                      : []
                  "
                  :required="mandatoryFields['type']"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>

              <!-- emailDomain -->
              <v-col
                v-if="sectionKeyBy.Detail.fields['account.emailDomain']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Detail.fields['account.emailDomain'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Detail.fields["account.emailDomain"].label }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.emailDomain }"
                  v-model="account.emailDomain"
                  @blur="
                    formatFormFields($event.target.value, 'emailDomain', '')
                  "
                  :tabindex="
                    sectionKeyBy.Detail.fields['account.emailDomain'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- custom1 -->
              <v-col
                v-if="sectionKeyBy.Detail.fields['account.custom1']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order: sectionKeyBy.Detail.fields['account.custom1'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{ this.sectionKeyBy.Detail.fields["account.custom1"].label }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.custom1 }"
                  v-model="account.custom1"
                  @blur="formatFormFields($event.target.value, 'custom1', '')"
                  :tabindex="
                    sectionKeyBy.Detail.fields['account.custom1'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <!-- Technology-->
          <div
            v-if="sectionKeyBy.Technology && sectionKeyBy.Technology.display"
            v-bind:style="{
              order: sectionKeyBy.Technology.order,
            }"
            class="border orderFields"
          >
            <v-row class="col-md-12">
              <!-- segment_technology -->
              <v-col
                v-if="
                  sectionKeyBy.Technology.fields['account.segment_technology']
                "
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Technology.fields[
                      'account.segment_technology'
                    ],
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Technology.fields["account.segment_technology"]
                      .label
                  }}
                </h4>
                <v-combobox
                  class="input_data"
                  v-bind:class="{
                    highlight: !isAccountSegmentHighlight,
                  }"
                  v-model="account.segment_technology"
                  :items="segmentTechnologyList"
                  :tabindex="
                    sectionKeyBy.Technology.fields['account.segment_technology']
                      .order
                  "
                  chips
                  clearable
                  deletable-chips
                  multiple
                  outlined
                  dense
                  hide-details
                ></v-combobox>
              </v-col>
            </v-row>
          </div>

          <!-- Industry -->
          <div
            v-if="sectionKeyBy.Industry && sectionKeyBy.Industry.display"
            v-bind:style="{
              order: sectionKeyBy.Industry.order,
            }"
            class="border orderFields"
          >
            <v-row class="col-md-12">
              <!-- industry -->
              <v-col
                v-if="sectionKeyBy.Industry.fields['account.industry']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order: sectionKeyBy.Industry.fields['account.industry'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Industry.fields["account.industry"].label }}
                  <span
                    v-if="mandatoryFields['industry']"
                    style="color: #ff0000"
                    >*</span
                  >
                </h4>
                <v-combobox
                  class="input_data"
                  v-bind:class="{ highlight: !account.industry }"
                  v-model="account.industry"
                  @blur="formatFormFields($event.target.value, 'industry', '')"
                  :items="industryList"
                  :tabindex="
                    sectionKeyBy.Industry.fields['account.industry'].order
                  "
                  outlined
                  dense
                  hide-details
                  :required="mandatoryFields['industry']"
                  :rules="
                    mandatoryFields['industry']
                      ? [
                          (v) =>
                            !!v ||
                            `${sectionKeyBy.Industry.fields['account.industry'].label} is required`,
                          (v) =>
                            (v && !!_.trim(v)) ||
                            `${sectionKeyBy.Industry.fields['account.industry'].label} is required`,
                        ]
                      : []
                  "
                ></v-combobox>
              </v-col>

              <!-- subIndustry -->
              <v-col
                v-if="sectionKeyBy.Industry.fields['account.subIndustry']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Industry.fields['account.subIndustry'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Industry.fields["account.subIndustry"].label
                  }}
                </h4>
                <v-combobox
                  class="input_data"
                  v-bind:class="{ highlight: !account.subIndustry }"
                  @focus="(event) => fetchSubIndustryList(event)"
                  v-model="account.subIndustry"
                  @blur="
                    formatFormFields($event.target.value, 'subIndustry', '')
                  "
                  :items="subIndustryList"
                  :tabindex="
                    sectionKeyBy.Industry.fields['account.subIndustry'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-combobox>
              </v-col>

              <!-- sicCode -->
              <v-col
                v-if="sectionKeyBy.Industry.fields['account.sicCode']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order: sectionKeyBy.Industry.fields['account.sicCode'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Industry.fields["account.sicCode"].label }}
                </h4>
                <v-combobox
                  class="input_data"
                  v-bind:class="{ highlight: !account.sicCode }"
                  v-model="account.sicCode"
                  :items="sicCodeData"
                  @blur="formatFormFields($event.target.value, 'sicCode', '')"
                  item-text="id"
                  item-value="id"
                  v-on:click.native="
                    (event) => autocompleteCode('sicCode', account.sicCode)
                  "
                  @change="setCodeDescription('sicCode', account.sicCode)"
                  :tabindex="
                    sectionKeyBy.Industry.fields['account.sicCode'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-combobox>
              </v-col>

              <!-- sicDescription -->
              <v-col
                v-if="sectionKeyBy.Industry.fields['account.sicDescription']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Industry.fields['account.sicDescription']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Industry.fields["account.sicDescription"].label
                  }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.sicDescription }"
                  v-model="account.sicDescription"
                  @blur="
                    formatFormFields($event.target.value, 'sicDescription', '')
                  "
                  :tabindex="
                    sectionKeyBy.Industry.fields['account.sicDescription'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- naicsCode -->
              <v-col
                v-if="sectionKeyBy.Industry.fields['account.naicsCode']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Industry.fields['account.naicsCode'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Industry.fields["account.naicsCode"].label }}
                </h4>
                <v-combobox
                  class="input_data"
                  v-bind:class="{ highlight: !account.naicsCode }"
                  item-text="id"
                  item-value="id"
                  v-model="account.naicsCode"
                  @blur="formatFormFields($event.target.value, 'naicsCode', '')"
                  :items="naicsCodeData"
                  v-on:click.native="
                    (event) => autocompleteCode('naicsCode', account.naicsCode)
                  "
                  @change="setCodeDescription('naicsCode', account.naicsCode)"
                  :tabindex="
                    sectionKeyBy.Industry.fields['account.naicsCode'].order
                  "
                  outlined
                  dense
                  hide-details
                ></v-combobox>
              </v-col>

              <!-- naicsDescription -->
              <v-col
                v-if="sectionKeyBy.Industry.fields['account.naicsDescription']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Industry.fields['account.naicsDescription']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Industry.fields["account.naicsDescription"]
                      .label
                  }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.naicsDescription }"
                  v-model="account.naicsDescription"
                  @blur="
                    formatFormFields(
                      $event.target.value,
                      'naicsDescription',
                      ''
                    )
                  "
                  :tabindex="
                    sectionKeyBy.Industry.fields['account.naicsDescription']
                      .order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <!-- address -->
          <div
            v-if="sectionKeyBy.Address && sectionKeyBy.Address.display"
            v-bind:style="{
              order: sectionKeyBy.Address.order,
            }"
            class="border orderFields"
          >
            <v-row class="col-md-12 mx-0 px-0 mt-0">
              <v-col class="pt-0 mt-2 pb-0" cols="12" sm="12">
                <h4 class="text-uppercase col-md-12 pa-0">Address Search</h4>
              </v-col>

              <!-- addressSearch -->
              <v-col
                v-if="
                  sectionKeyBy.Address.fields['account.addressHQ.address1HQ']
                "
                class="d-flex pt-0 pb-1 pr-8"
                style="position: relative"
                cols="12"
                sm="12"
              >
                <addressSearch
                  v-on:placechanged="getGoogleAddress"
                  addressId="accountFormId"
                  addressRef="accountFormRef"
                  :tabIndex="
                    sectionKeyBy.Address.fields['account.addressHQ.address1HQ']
                      .order
                  "
                  class="py-2 addressWrapper"
                />

                <v-icon> search </v-icon>
              </v-col>

              <!-- address1HQ -->
              <v-col
                v-if="
                  sectionKeyBy.Address.fields['account.addressHQ.address1HQ']
                "
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['account.addressHQ.address1HQ']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Address.fields["account.addressHQ.address1HQ"]
                      .label
                  }}
                  <span
                    v-if="mandatoryFields['addressHQ.address1HQ']"
                    style="color: #ff0000"
                    >*</span
                  >
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.addressHQ.address1HQ }"
                  v-model="account.addressHQ.address1HQ"
                  @blur="
                    formatFormFields(
                      $event.target.value,
                      'addressHQ',
                      '',
                      'address1HQ'
                    )
                  "
                  :tabindex="
                    sectionKeyBy.Address.fields['account.addressHQ.address1HQ']
                      .order
                  "
                  :rules="
                    mandatoryFields['addressHQ.address1HQ']
                      ? [(v) => !!v || 'Address 1 is required']
                      : []
                  "
                  :required="mandatoryFields['addressHQ.address1HQ']"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- address2HQ -->
              <v-col
                v-if="
                  sectionKeyBy.Address.fields['account.addressHQ.address2HQ']
                "
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['account.addressHQ.address2HQ']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Address.fields["account.addressHQ.address2HQ"]
                      .label
                  }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.addressHQ.address2HQ }"
                  v-model="account.addressHQ.address2HQ"
                  @blur="
                    formatFormFields(
                      $event.target.value,
                      'addressHQ',
                      '',
                      'address2HQ'
                    )
                  "
                  :tabindex="
                    sectionKeyBy.Address.fields['account.addressHQ.address2HQ']
                      .order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- cityHQ -->
              <v-col
                v-if="sectionKeyBy.Address.fields['account.addressHQ.cityHQ']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['account.addressHQ.cityHQ']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Address.fields["account.addressHQ.cityHQ"]
                      .label
                  }}
                  <span
                    v-if="mandatoryFields['addressHQ.cityHQ']"
                    style="color: #ff0000"
                    >*</span
                  >
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.addressHQ.cityHQ }"
                  v-model="account.addressHQ.cityHQ"
                  @blur="
                    formatFormFields(
                      $event.target.value,
                      'addressHQ',
                      '',
                      'cityHQ'
                    )
                  "
                  :tabindex="
                    sectionKeyBy.Address.fields['account.addressHQ.cityHQ']
                      .order
                  "
                  :rules="
                    mandatoryFields['addressHQ.address1HQ']
                      ? [(v) => !!v || 'City is required']
                      : []
                  "
                  :required="mandatoryFields['addressHQ.cityHQ']"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- stateHQ -->
              <v-col
                v-if="sectionKeyBy.Address.fields['account.addressHQ.stateHQ']"
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['account.addressHQ.stateHQ']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Address.fields["account.addressHQ.stateHQ"]
                      .label
                  }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.addressHQ.stateHQ }"
                  v-model="account.addressHQ.stateHQ"
                  @blur="
                    formatFormFields(
                      $event.target.value,
                      'addressHQ',
                      '',
                      'stateHQ'
                    )
                  "
                  :tabindex="
                    sectionKeyBy.Address.fields['account.addressHQ.stateHQ']
                      .order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>

              <!-- countryHQ -->
              <v-col
                v-if="
                  sectionKeyBy.Address.fields['account.addressHQ.countryHQ']
                "
                class="pt-0 mt-2 pb-0"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['account.addressHQ.countryHQ']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Address.fields["account.addressHQ.countryHQ"]
                      .label
                  }}
                  <span
                    v-if="mandatoryFields['addressHQ.countryHQ']"
                    style="color: #ff0000"
                    >*</span
                  >
                </h4>

                <v-autocomplete
                  class="input_data"
                  v-bind:class="{
                    highlight: !_.get(account, 'addressHQ.countryHQ', null),
                  }"
                  v-model="account.addressHQ.countryHQ"
                  :tabindex="
                    sectionKeyBy.Address.fields['account.addressHQ.countryHQ']
                      .order
                  "
                  :items="countriesIso"
                  @input="setDialCodeForNumbers(account.addressHQ.countryHQ)"
                  :rules="
                    mandatoryFields['addressHQ.countryHQ']
                      ? [(v) => !!v || 'Country is required']
                      : []
                  "
                  :required="mandatoryFields['addressHQ.countryHQ']"
                  return-object
                  clearable
                  dense
                  outlined
                  hide-details
                ></v-autocomplete>
              </v-col>

              <!-- zipCodeHQ -->
              <v-col
                v-if="
                  sectionKeyBy.Address.fields['account.addressHQ.zipCodeHQ']
                "
                class="pt-0 mt-2 pb-0"
                cols="12"
                sm="6"
                v-bind:style="{
                  order:
                    sectionKeyBy.Address.fields['account.addressHQ.zipCodeHQ']
                      .order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{
                    sectionKeyBy.Address.fields["account.addressHQ.zipCodeHQ"]
                      .label
                  }}
                </h4>
                <v-text-field
                  class="input_data"
                  v-bind:class="{ highlight: !account.addressHQ.zipCodeHQ }"
                  v-model="account.addressHQ.zipCodeHQ"
                  @blur="
                    formatFormFields(
                      $event.target.value,
                      'addressHQ',
                      '',
                      'zipCodeHQ'
                    )
                  "
                  :tabindex="
                    sectionKeyBy.Address.fields['account.addressHQ.zipCodeHQ']
                      .order
                  "
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <!-- Phone-->
          <div
            v-if="sectionKeyBy.Phone && sectionKeyBy.Phone.display"
            v-bind:style="{
              order: sectionKeyBy.Phone.order,
            }"
            class="border orderFields"
          >
            <v-row class="col-md-12">
              <!-- phoneHQ -->
              <v-col
                v-if="sectionKeyBy.Phone.fields['account.phoneHQ']"
                class="pt-0 pb-1 mt-2"
                cols="12"
                sm="6"
                v-bind:style="{
                  order: sectionKeyBy.Phone.fields['account.phoneHQ'].order,
                }"
              >
                <h4 class="text-uppercase mb-2">
                  {{ sectionKeyBy.Phone.fields["account.phoneHQ"].label }}
                  <span v-if="mandatoryFields['phoneHQ']" style="color: #ff0000"
                    >*</span
                  >
                </h4>

                <vue-phone-number-input
                  v-bind:class="{ highlight: !account.phoneHQ }"
                  v-model="phoneHQ"
                  :error="false"
                  @update="validatePhoneNumber($event)"
                  :tabindex="sectionKeyBy.Phone.fields['account.phoneHQ'].order"
                  :no-use-browser-locale="true"
                  :fetch-country="false"
                  ref="phoneHQ_number_ref"
                  clearable
                />
                <v-text-field
                  v-show="false"
                  v-model="phoneHQ"
                  :rules="
                    mandatoryFields['phoneHQ']
                      ? [(v) => !!v || 'Phone is required']
                      : []
                  "
                  :required="mandatoryFields['phoneHQ']"
                ></v-text-field>
                <p
                  v-if="isPhoneError"
                  class="mt-2 mb-0"
                  style="color: #ff0000; margin-left: 5px"
                >
                  {{ sectionKeyBy.Phone.fields["account.phoneHQ"].label }}
                  is not valid
                </p>
              </v-col>
            </v-row>
          </div>

          <!-- Misc(miscellaneous) -->
          <div
            v-if="sectionKeyBy.Misc && sectionKeyBy.Misc.display"
            v-bind:style="{
              order: sectionKeyBy.Misc.order,
            }"
            class="border"
          >
            <!-- other miscellaneous field -->
            <v-row class="col-md-12">
              <v-col
                class="pt-0 mt-2 pb-1"
                cols="12"
                sm="6"
                v-for="(field, index) in miscellaneousFields"
                :key="field.id"
                v-show="miscellaneousFields[index]"
              >
                <h4 class="text-uppercase mb-2">
                  {{ field.label }}
                </h4>

                <!-- employeeSizeField -->
                <div
                  v-if="
                    [
                      'employeeSize',
                      'employeeSize_others',
                      'employeeSizeLI',
                      'employeeSizeZ_plus',
                    ].indexOf(field.id) > -1
                  "
                >
                  <v-text-field
                    class="input_data"
                    v-bind:class="{ highlight: !account[field.id] }"
                    v-model="account[field.id]"
                    @blur="formatFormFields($event.target.value, field.id, '')"
                    @keyup="validateEmployeeSize(field.id)"
                    type="number"
                    :tabindex="field.order"
                    outlined
                    :min="0"
                    dense
                    hide-details
                  ></v-text-field>

                  <!-- isEmployeeSizeFieldError -->
                  <span
                    class="pt-0 pb-1"
                    cols="12"
                    sm="6"
                    v-if="
                      [
                        'employeeSize_others',
                        'employeeSizeLI',
                        'employeeSizeZ_plus',
                      ].indexOf(field.id) > -1
                    "
                  >
                    <p
                      v-if="isEmployeeSizeFieldError"
                      class="mb-0"
                      style="color: red; margin-left: 5px"
                    >
                      Any one employeeSize field(LINKEDIN,Z+ or OTHERS) is
                      required
                    </p>
                  </span>
                </div>

                <div v-else>
                  <v-text-field
                    class="input_data"
                    v-bind:class="{ highlight: !account[field.id] }"
                    v-model="account[field.id]"
                    @blur="formatFormFields($event.target.value, field.id, '')"
                    :tabindex="field.order"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-form>
    </div>

    <accountCheckDialogBox />
  </v-container>
</template>

<script>
import Api from "../services/Api";
import MasterApi from "../services/MasterApi";
import _ from "lodash";
import validator from "validator";
import addressSearch from "./AddressSearch.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import sanitize from "./sanitizer";
import countryJSON from "../assets/country.json";
import accountCheckDialogBox from "../components/accountCheckDialogBox.vue";
import difference from "./differenceBetweenObject";
import tldjs from "tldjs";

export default {
  props: {
    isChecking: {
      type: Boolean,
      default: false,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    fromContactFrom: {
      type: Boolean,
      default: false,
    },
  },
  components: { addressSearch, VuePhoneNumberInput, accountCheckDialogBox },
  data() {
    return {
      mandatoryFields: {
        name: true,
        website: true,
        type: true,
        industry: true,
        "addressHQ.address1HQ": true,
        "addressHQ.cityHQ": true,
        "addressHQ.countryHQ": true,
        phoneHQ: true,
      },
      companyTypeList: ["Parent", "Subsidiary", "Independent"],
      industryList: [],
      subIndustryList: [],
      segmentTechnologyList: [],
      valid: false,
      rules: {
        url: (value) => {
          if (value && !validator.isURL(value.trim(), [".+"])) {
            return "URL is not valid";
          }
          return true;
        },
        email: (value) => {
          if (value && !validator.isEmail(value.trim())) {
            return "Email is not valid";
          }
          return true;
        },
      },
      isPhoneError: false,
      naicsCodeData: [],
      sicCodeData: [],
      account: {
        segment_technology: [],
        addressHQ: {
          address1HQ: "",
          address2HQ: "",
          cityHQ: "",
          stateHQ: "",
          countryHQ: "",
          zipCodeHQ: "",
        },
        TaskLink: {},
      },
      sectionKeyBy: [],
      miscellaneousFields: [],
      phoneHQ: "",
      countriesIso: Object.keys(countryJSON),
      countryJSON: countryJSON,
      previousWebsite: "",
      isEmployeeSizeFieldError: false,
    };
  },
  mounted: function () {
    this.fetchIndustryList();
    this.fetchSegmentTechnologyList();
    this.validateEmployeeSize();
  },
  computed: {
    _() {
      return _;
    },
    projectId() {
      if (this.$store.state.workspace && this.$store.state.workspace.task) {
        return _.get(this.$store.state.workspace.task, "Project.id", null);
      }
      return null;
    },
    taskId() {
      return this.$store.state.workspace.task.id;
    },
    workspaceAccount() {
      return this.$store.state.workspace.account;
    },
    currentWorkspaceAccount() {
      return this.$store.state.currentWorkspaceAccount;
    },
    customizationAccountForm() {
      return this.$store.state.fieldCustomization.accountFields;
    },
    accountFields() {
      return _.get(
        this.$store,
        "state.workspace.fieldCustomization.accountFields"
      );
    },
    contactList() {
      return this.$store.state.workspace.contacts;
    },
    selectedContact() {
      return this.$store.state.workspace.selectedContact;
    },
    isAccountSegmentHighlight() {
      if (this.workspaceAccount.segment_technology) {
        return this.workspaceAccount.segment_technology.length;
      } else {
        return false;
      }
    },
  },
  watch: {
    workspaceAccount: function (updatedAccount) {
      this.account = sanitize(Object.assign({}, updatedAccount));
      this.setMandatoryFields(this.account.disposition);
      if (updatedAccount.phoneHQ) {
        this.phoneHQ = updatedAccount.phoneHQ;
      } else {
        this.phoneHQ = "";
      }

      // console.log("watch master Account");
      if (
        (_.has(this.account, "employeeSize_others") &&
          !_.isEmpty(this.account.employeeSize_others)) ||
        (_.has(this.account, "employeeSizeLI") &&
          !_.isEmpty(this.account.employeeSizeLI)) ||
        (_.has(this.account, "employeeSizeZ_plus") &&
          !_.isEmpty(this.account.employeeSizeZ_plus))
      ) {
        this.validateEmployeeSize();
      }
    },
    accountFields: {
      handler: function (value) {
        this.accountFormFieldCustomization(value);
      },
      immediate: true,
    },
  },
  methods: {
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    debounceOnShortKey: _.debounce(async function (value) {
      switch (value) {
        case "save":
          if (!this.isSaving) {
            await this.$refs.saveAccountBtn.$el.focus();
            await this.saveAccount();
          }
          break;
        case "back":
          await this.$refs.backToContactFormBtn.$el.focus();
          await this.backToContactForm();
          break;
      }
    }, 1000),
    compareAccounts(newAccount, oldAccount) {
      const changedAccountData = difference(newAccount, oldAccount);

      if (_.isEmpty(changedAccountData)) {
        return { isAccountChanged: false };
      }

      return { isAccountChanged: true, changedAccountData };
    },
    setDialCodeForNumbers(selectedCountry) {
      if (!selectedCountry) return;

      let selectedCountryCode = this.countryJSON[selectedCountry];
      if (!selectedCountryCode) return;

      this.setCountryCode(_.toUpper(selectedCountryCode));
    },
    setCountryCode(selectedCountryCode) {
      if (!selectedCountryCode) {
        return;
      }
      if (this.$refs.phoneHQ_number_ref) {
        this.$refs.phoneHQ_number_ref.countryCode = selectedCountryCode;
      }
    },
    accountFormFieldCustomization: function (value) {
      this.sectionKeyBy = _.keyBy(value, "section");

      this.customizationAccountFormFields(this.sectionKeyBy);
      for (const key in this.sectionKeyBy) {
        let section = this.sectionKeyBy[key];
        let sectionFields = section.fields;

        let fieldKeyBy = _.keyBy(sectionFields, "id");
        this.sectionKeyBy[key].fields = fieldKeyBy;
      }
    },
    customizationAccountFormFields(value) {
      for (const property in value) {
        if (property === "Misc") {
          this.miscellaneousFields = value.Misc.fields;
        }
      }
    },
    formatFormFields: async function (
      fieldValue,
      fieldModel,
      operation,
      subFieldModel
    ) {
      if (!fieldValue || !fieldModel) {
        return;
      }
      if (subFieldModel) {
        this.account[fieldModel][subFieldModel] = fieldValue.trim();
      } else {
        this.account[fieldModel] = fieldValue.trim();
      }
      if (operation === "titleCase" && this.account[fieldModel]) {
        this.account[fieldModel] = this.account[fieldModel]
          .toLowerCase()
          .replace(/\b(\w)/g, (s) => s.toUpperCase());
        return;
      }
      if (operation === "lowerCase" && this.account[fieldModel]) {
        this.account[fieldModel] = this.account[fieldModel].toLowerCase();
        return;
      }
      return;
    },
    setCodeDescription: function (codeName, data) {
      if (codeName == "sicCode" && data) {
        this.account.sicDescription = data.title;
        this.account.sicCode = data.id;
      } else if (codeName == "sicCode" && !data) {
        this.account.sicDescription = "";
      } else if (codeName == "naicsCode" && data) {
        this.account.naicsDescription = data.title;
        this.account.naicsCode = data.id;
      } else if (codeName == "naicsCode" && !data) {
        this.account.naicsDescription = "";
      }
    },
    checkFormIsValid() {
      var formValid = this.$refs.form.validate();

      // phone Number Valid
      if (
        this.account.phoneHQ &&
        this.isPhoneError &&
        this.mandatoryFields["phoneHQ"]
      ) {
        return false;
      }

      // employeeSizeField
      if (this.isEmployeeSizeFieldError) {
        return false;
      }

      // form Valid
      if (!formValid) {
        return false;
      }

      return true;
    },
    saveAccount: async function () {
      this.$emit("isSavingUpdate", true);

      const { isAccountChanged, changedAccountData } = this.compareAccounts(
        this.account,
        this.currentWorkspaceAccount
      );

      if (this.checkFormIsValid()) {
        if (isAccountChanged) {
          await this.$store.dispatch("saveAccount", {
            projectId: this.projectId,
            accountId: this.account.id,
            account: this.account,
            taskId: this.taskId,
            contacts: this.contactList,
            selectedContact: this.selectedContact,
            changedAccountData,
          });
        } else {
          let notification = {
            type: "success",
            showMessage: true,
            message: "Account Saved Successfully",
          };
          this.setNotification(notification);
        }
        this.$emit("isSavingUpdate", false);
      } else {
        this.$emit("isSavingUpdate", false);
        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().FORM_VALIDATION_ERROR,
        };
        this.setNotification(notification);
      }
    },
    createAccount: async function () {
      this.$emit("isSavingUpdate", true);

      this.compareAccounts(this.account, this.currentWorkspaceAccount);

      if (this.checkFormIsValid()) {
        try {
          await MasterApi.saveVerifyAccount(this.account, this.account);
        } catch (error) {
          console.log(error);
          this.$emit("isSavingUpdate", false);
        }

        this.$emit("isSavingUpdate", false);
        let notification = {
          type: "success",
          showMessage: true,
          message: "Account Created Successfully",
        };
        this.setNotification(notification);
      } else {
        this.$emit("isSavingUpdate", false);
        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().FORM_VALIDATION_ERROR,
        };
        this.setNotification(notification);
      }
    },
    getDispostion(value) {
      if (value) {
        this.contactDisposition = value;
        this.disposeContact();
      }
    },
    fetchIndustryList: function () {
      Api.getAllIndustry()
        .then((response) => {
          this.industryList = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchSubIndustryList: function () {
      var industry = [this.account.industry];
      Api.getAllSubIndustry(industry)
        .then((response) => {
          this.subIndustryList = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchSegmentTechnologyList: function () {
      Api.getAllSegmentTechnology()
        .then((response) => {
          this.segmentTechnologyList = response.data;
        })
        .catch((error) => console.log(error));
    },
    getGoogleAddress: function (addressData) {
      this.account.addressHQ.address1HQ = "";
      this.account.addressHQ.address2HQ = "";
      this.account.addressHQ.cityHQ = "";
      this.account.addressHQ.stateHQ = "";
      this.account.addressHQ.countryHQ = "";
      this.account.addressHQ.zipCodeHQ = "";
      if (addressData) {
        this.account.addressHQ.address1HQ = addressData.streetAddress;
        this.account.addressHQ.cityHQ = addressData.city;
        this.account.addressHQ.stateHQ = addressData.state;
        this.account.addressHQ.countryHQ = addressData.country;
        this.account.addressHQ.zipCodeHQ = addressData.zipCode;
      }
      this.setDialCodeForNumbers(this.account.addressHQ.countryHQ);
    },
    validatePhoneNumber(data) {
      this.account.phoneHQ = data.phoneNumber || "";
      if (!data.phoneNumber) {
        this.isPhoneError = false;
        return;
      }
      if (data.isValid && data.formattedNumber) {
        this.account.phoneHQ = data.formattedNumber;
        this.isPhoneError = false;
        return;
      }
      this.isPhoneError = true;

      return;
    },
    autocompleteCode(codeName, data) {
      var queryParams = { param: data };

      Api.autoCompleteInterFace(codeName, queryParams)
        .then((response) => {
          if (codeName === "sicCode") {
            this.sicCodeData = response.data;
          } else if (codeName === "naicsCode") {
            this.naicsCodeData = response.data;
          }
        })
        .catch((error) => console.log(error));
    },
    backToContactForm() {
      this.$emit("changeTab", "contact");
    },
    fetchAccountForm() {
      return this.account;
    },
    checkAccount() {
      this.$emit("isCheckingUpdate", true);
      this.$store.dispatch("populateAccountCheckResult", {
        projectId: this.projectId,
        account: this.account,
      });
      this.$emit("isCheckingUpdate", false);
    },
    getDomain(website) {
      // nullish input check
      if (!website) {
        return "";
      }
      // datatype check
      if (typeof website !== "string") {
        return "";
      }
      const result = tldjs.parse(website);

      if (!result.isValid) {
        return;
      }

      return result.domain ? result.domain : "";
    },
    fetchMasterAccount: _.debounce(async function (website) {
      if (!website || this.previousWebsite === website) {
        return;
      }

      this.previousWebsite = website;

      let domain = this.getDomain(website);
      const accountId = this.account.id;

      if (!domain) {
        return;
      }

      this.$store.dispatch("fetchMasterAccountByDomain", { domain, accountId });
    }, 500),

    validateEmployeeSize: function (field) {
      let isEmployeeSizeField = false;

      if (field && _.has(this.account, field)) {
        let employeeValue = parseInt(this.account[field], 10);
        this.account[field] = employeeValue < 0 ? 0 : employeeValue;
      }

      // account has any employeeSize Field
      if (
        _.has(this.account, "employeeSize_others") ||
        _.has(this.account, "employeeSizeLI") ||
        _.has(this.account, "employeeSizeZ_plus")
      ) {
        isEmployeeSizeField = true;
      }

      // all employeeFields & All is Empty
      if (
        isEmployeeSizeField &&
        !_.get(this.account, "employeeSize_others", null) &&
        !_.get(this.account, "employeeSizeLI", null) &&
        !_.get(this.account, "employeeSizeZ_plus", null)
      ) {
        this.isEmployeeSizeFieldError = true;
      }

      // all employeeFields Exists & one of them is not empty
      if (
        isEmployeeSizeField &&
        _.get(this.account, "employeeSize_others", null)
      ) {
        this.isEmployeeSizeFieldError = false;
      }

      // all employeeFields Exists & one of them is not empty
      if (isEmployeeSizeField && _.get(this.account, "employeeSizeLI", null)) {
        this.isEmployeeSizeFieldError = false;
      }

      // all employeeFields Exists & one of them is not empty
      if (
        isEmployeeSizeField &&
        _.get(this.account, "employeeSizeZ_plus", null)
      ) {
        this.isEmployeeSizeFieldError = false;
      }
    },
    setMasterAccountDefaultData(accountPayload) {
      let { id, website, disposition, employeeSizeZ_plus, type } =
        accountPayload;

      if (_.isEmpty(employeeSizeZ_plus)) {
        accountPayload.employeeSizeZ_plus = 0;
      }

      if (_.isEmpty(type)) {
        switch (disposition) {
          case this.$constants().ACCOUNT_DISPOSITIONS
            .ACQUIRED_MERGED_SUBSIDIARY:
            accountPayload.type = this.$constants().ACCOUNT_TYPE.SUBSIDIARY;
            break;
          case this.$constants().ACCOUNT_DISPOSITIONS.BANKRUPT_SHUT_DOWN:
          case this.$constants().ACCOUNT_DISPOSITIONS.WEBSITE_NOT_FOUND:
            accountPayload.type = this.$constants().ACCOUNT_TYPE.INDEPENDENT;
            break;
        }
      }

      if (_.isEmpty(website)) {
        switch (disposition) {
          case this.$constants().ACCOUNT_DISPOSITIONS
            .ACQUIRED_MERGED_SUBSIDIARY:
            accountPayload.website = this.getWebsitePrefixed("ams", id);
            break;
          case this.$constants().ACCOUNT_DISPOSITIONS.BANKRUPT_SHUT_DOWN:
            accountPayload.website = this.getWebsitePrefixed("bsd", id);
            break;
          case this.$constants().ACCOUNT_DISPOSITIONS.WEBSITE_NOT_FOUND:
            accountPayload.website = this.getWebsitePrefixed("wnf", id);
            break;
        }
      }
      this.account = accountPayload;
      this.setMandatoryFields(disposition);
      return accountPayload;
    },
    getWebsitePrefixed(prefix, id) {
      return prefix + "-" + id + ".com";
    },
    setMandatoryFields(disposition) {
      switch (disposition) {
        case this.$constants().ACCOUNT_DISPOSITIONS.ACQUIRED_MERGED_SUBSIDIARY:
        case this.$constants().ACCOUNT_DISPOSITIONS.BANKRUPT_SHUT_DOWN:
        case this.$constants().ACCOUNT_DISPOSITIONS.WEBSITE_NOT_FOUND:
          this.mandatoryFields["industry"] = false;
          this.mandatoryFields["addressHQ.address1HQ"] = false;
          this.mandatoryFields["addressHQ.cityHQ"] = false;
          this.mandatoryFields["addressHQ.countryHQ"] = false;
          this.mandatoryFields["phoneHQ"] = false;
          break;
        default:
          this.mandatoryFields["industry"] = true;
          this.mandatoryFields["addressHQ.address1HQ"] = true;
          this.mandatoryFields["addressHQ.cityHQ"] = true;
          this.mandatoryFields["addressHQ.countryHQ"] = true;
          this.mandatoryFields["phoneHQ"] = true;
          break;
      }
    },
  },
};
</script>

<style scoped>
.border {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px !important;
}
.account_form .theme--light.v-input {
  background-color: white;
}
.account_form {
  background: repeating-linear-gradient(350deg, #f7f9fb, transparent 5px);
  margin-top: -10px;
  padding-top: 20px;
}

.orderFields {
  display: flex;
  flex-direction: row;
}

.orderSection {
  display: flex;
  flex-direction: column;
}

.highlight {
  background: lightyellow !important;
}

.vue-phone-number-input.highlight {
  border: 1px solid red;
  border-radius: 4px;
}

.form h4 {
  font-family: Lato;
  font-size: 14px;
  color: #6e6893;
  font-weight: 600;
}
.scroller {
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 30px;
  height: calc(100vh - 245px);
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}

.scroller-lg {
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 30px;
  height: calc(100vh - 9vh);
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
} /* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} /* Handle */
::-webkit-scrollbar-thumb {
  background: #8b83ba;
  border-radius: 10px;
} /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #686094;
}

/* Buttons */

::-webkit-scrollbar-button:single-button {
  background-color: #686094;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
} /* Up
*/
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
}
::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
} /* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
}
::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.addressWrapper .address-container {
  width: 100%;
  height: 45px;
  padding: 8px 0;
  padding-left: 8px;
  border: 1px solid #ccc;
}

.addressWrapper + i {
  right: 40px;
  font-size: 25px;
  position: absolute;
  top: 15px;
}

.addressWrapper {
  width: 100%;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
