<template>
  <div class="row mt-4">
    <div class="col-8 pt-0">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Manage Sections</v-toolbar-title>
      </v-toolbar>
      <div class="scroller" style="border: 1px solid #eee; padding: 10px 15px">
        <draggable
          v-if="listType === 'form'"
          tag="v-list"
          :list="sectionList"
          @change="setOrder"
        >
          <v-list-item
            v-for="(element, index) in sectionList"
            :key="index"
            class="d-block pa-0"
            @click="selectSection(element)"
          >
            <v-card
              outlined
              :color="element.bg"
              elevation="1"
              rounded
              class="pa-4 mb-6"
            >
              <v-row style="cursor: move">
                <v-col cols="12" md="1" class="pb-0">
                  <v-icon>menu</v-icon>
                </v-col>
                <v-col cols="12" md="10" class="pb-0">
                  <v-text-field
                    label="Section Name"
                    outlined
                    dense
                    :disabled="true"
                    style="background: white"
                    hide-details
                    v-model="element.section"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="1" class="pt-0 pb-0">
                  <v-switch
                    v-if="!element.isMandatory"
                    v-model="element.display"
                  ></v-switch>
                </v-col>
                <v-col
                  cols="12"
                  md="11"
                  class="pa-0 ml-6"
                  v-if="element.active"
                >
                  <draggable
                    tag="v-list"
                    :list="element.fields"
                    @change="setOrder"
                    style="background: rgb(224 240 255 / 20%)"
                  >
                    <v-list-item
                      two-line
                      dense
                      v-for="(field, index) in element.fields"
                      :key="index"
                      style="cursor: move"
                    >
                      <v-col cols="12" md="1">
                        <v-icon>menu</v-icon>
                      </v-col>
                      <v-col cols="12" md="10" class="py-2">
                        <v-text-field
                          :label="field.id"
                          outlined
                          dense
                          style="background: white"
                          hide-details
                          v-model="field.label"
                          @change="updateLabel(field)"
                        ></v-text-field>
                      </v-col>
                    </v-list-item>
                  </draggable>
                </v-col>
              </v-row>
            </v-card>
          </v-list-item>
        </draggable>
        <draggable v-if="listType === 'csv'" tag="v-list" :list="sectionList">
          <v-list-item
            v-for="(element, index) in sectionList"
            :key="index"
            class="d-block pa-0"
          >
            <v-card
              style="background: white"
              outlined
              elevation="1"
              rounded
              class="pa-4 mb-2"
            >
              <v-row class="mb-0" style="cursor: move">
                <v-col cols="12" md="1" class="pb-0">
                  <v-icon>menu</v-icon>
                </v-col>
                <v-col cols="12" md="10" class="pb-0">
                  <v-text-field
                    :label="element.id"
                    outlined
                    dense
                    hide-details
                    v-model="element.label"
                    @change="updateLabel(element)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-list-item>
        </draggable>
      </div>
    </div>

    <v-card
      class="col-md-4 pa-0 mx-auto scroller"
      outlined
      style="background: rgb(224 240 255 / 20%)"
    >
      <v-toolbar color="primary" dark>
        <v-toolbar-title v-if="listType === 'form'"
          >Choose Fields for "{{ selectedFieldSectionName }}"
        </v-toolbar-title>
        <v-toolbar-title v-if="listType === 'csv'"
          >Choose Fields for "{{ typeLabelTitle }}"
        </v-toolbar-title>
      </v-toolbar>
      <div v-if="listType === 'form'">
        <v-list-item dense v-for="(field, index) in rules" :key="index">
          <v-checkbox
            hide-details
            v-model="field.isSelected"
            :label="field.label"
            @change="updateFields(field)"
          ></v-checkbox>
        </v-list-item>
      </div>
      <v-treeview
        v-if="listType === 'csv'"
        selectable
        v-model="csvSelection"
        :items="rules"
        selected-color="primary"
      ></v-treeview>
    </v-card>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Api from "../services/Api";
import _ from "lodash";

export default {
  props: {
    templateId: String,
    type: String,
  },
  name: "handle",
  display: "Handle",
  instruction: "Drag using the handle icon",
  order: 5,
  components: {
    draggable,
  },
  data() {
    return {
      listType: "",
      rules: [],
      csvSelection: [],
      sectionList: [],
      template: {},
      typeLabelTitle: "",
      typeLabel: {
        "01": "Contact Form",
        "02": "Contact Readonly",
        "03": "Account Form",
        "04": "CSV Task Export",
        "05": "CSV Account Export",
        "06": "CSV Contact Export",
      },
      selectedFieldSectionName: "",
      fieldKeyBy: {},
    };
  },
  watch: {
    templateId: function (value) {
      this.fetchTemplates(value);
    },
    type: function (value) {
      this.setConfig(value);
    },
    csvSelection: function (newValue, old) {
      let res;

      //POP Remove
      let removeField = old.filter((x) => !newValue.includes(x));
      res = this.fieldKeyBy[removeField];

      if (removeField.length && res) {
        this.updateCSVFields("remove", res);
      }

      //Push ADD
      let addField = newValue.filter((x) => !old.includes(x));
      res = this.fieldKeyBy[addField];

      if (addField.length && res) {
        this.updateCSVFields("add", res);
      }
    },
  },
  methods: {
    updateCSVFields: function (type, value) {
      //Need to optimize
      switch (type) {
        case "add":
          this.sectionList.push(value);
          break;
        case "remove":
          _.remove(this.sectionList, function (n) {
            return n.id === value.id;
          });
          break;
      }
    },
    updateFields: function (field) {
      if (field.isSelected) {
        //add field
        this.sectionList.find((section) => {
          if (section.section === this.selectedFieldSectionName) {
            section.fields.push(field);
          }
        });
      } else {
        this.sectionList.find((section) => {
          if (section.section === this.selectedFieldSectionName) {
            //remove field
            for (var i = 0; i < section.fields.length; i++) {
              if (section.fields[i].id === field.id) {
                section.fields.splice(i, 1);
                i--;
              }
            }
          }
        });
      }
      this.setOrder();
    },
    selectSection: function (section) {
      this.selectedFieldSectionName = section.section;
      this.disableActives();
      section.active = true;
      section.bg = "rgb(224 240 255 / 20%)";
      this.rules = section.rules;
    },
    disableActives() {
      this.sectionList.forEach((section) => {
        section.active = false;
        section.bg = "white";
      });
    },
    setPreview: function () {
      this.$emit("selectTemplate", this.template);
      this.$emit("setCsvTemplate", this.sectionList);
    },
    setOrder: function () {
      let sectionList = this.sectionList.map((item, index) => {
        item.order = index + 1;
        item.fields = item.fields.map((field, idx) => {
          let fieldIndex = idx + 1;
          field.order = parseInt(item.order + "" + fieldIndex);
          return field;
        });
        return item;
      });
      this.sectionList = sectionList;
    },
    setConfig: async function (type) {
      let self = this;
      await this.setTemplate(this.templateId);
      this.typeLabelTitle = this.typeLabel[type];
      this.template.config.fieldLabels = {};
      switch (type) {
        case "01":
          this.sectionList =
            this.template.config.forms.AgentContactWorkspace.config;
          this.disableActives();
          this.selectedFieldSectionName = "Name";
          this.sectionList[0].active = true;
          this.sectionList[0].bg = "rgb(224 240 255 / 20%)";
          this.rules = this.sectionList[0].rules;
          this.listType = "form";
          break;
        case "02":
          this.sectionList =
            this.template.config.forms.AgentContactReadOnly.config;
          this.disableActives();
          this.listType = "form";
          this.sectionList[0].active = true;
          this.sectionList[0].bg = "rgb(224 240 255 / 20%)";
          this.rules = this.sectionList[0].rules;
          break;
        case "03":
          this.sectionList =
            this.template.config.forms.AgentAccountWorkspace.config;
          this.disableActives();
          this.selectedFieldSectionName = "Detail";
          this.listType = "form";
          this.sectionList[0].active = true;
          this.sectionList[0].bg = "rgb(224 240 255 / 20%)";
          this.rules = this.sectionList[0].rules;
          break;
        case "04":
          this.sectionList = this.template.config.csv.TaskDownload.config;
          this.listType = "csv";
          this.rules = this.template.config.csv.TaskDownload.rules;
          this.makeRuleKeyBy();
          //need to look for async
          setTimeout(() => {
            self.setCSVSelection();
          }, 0);

          break;
        case "05":
          this.sectionList = this.template.config.csv.AccountDownload.config;
          this.listType = "csv";
          this.rules = this.template.config.csv.AccountDownload.rules;
          this.makeRuleKeyBy();
          //need to look for async
          setTimeout(() => {
            self.setCSVSelection();
          }, 0);
          break;
        case "06":
          this.sectionList = this.template.config.csv.ContactDownload.config;
          this.listType = "csv";
          this.rules = this.template.config.csv.ContactDownload.rules;
          this.makeRuleKeyBy();
          //need to look for async
          setTimeout(() => {
            self.setCSVSelection();
          }, 0);
          break;
      }
    },
    makeRuleKeyBy() {
      //need to optimize
      let ruleKeyBy = _.keyBy(this.rules, "id");
      if (ruleKeyBy.account) {
        this.fieldKeyBy = _.assign(
          this.fieldKeyBy,
          _.keyBy(ruleKeyBy.account.children, "id")
        );
      }
      if (ruleKeyBy.contact) {
        this.fieldKeyBy = _.assign(
          this.fieldKeyBy,
          _.keyBy(ruleKeyBy.contact.children, "id")
        );
      }
      if (ruleKeyBy.task) {
        this.fieldKeyBy = _.assign(
          this.fieldKeyBy,
          _.keyBy(ruleKeyBy.task.children, "id")
        );
      }
    },
    setTemplate: async function (id) {
      const result = await Api.getTemplateById(id);
      this.template = result.data;
    },
    fetchTemplates: function (id) {
      return Api.getTemplateNames({})
        .then((response) => {
          this.templateList = response.data;
          this.setTemplate(id);
        })
        .catch((error) => console.log(error));
    },
    updateLabel(item) {
      var payload = {
        id: item.id,
        label: item.label,
      };
      this.template.config.fieldLabels[item.id] = payload;
    },
    setCSVSelection() {
      this.csvSelection = this.sectionList.map((item) => {
        return item.id;
      });
    },
  },
};
</script>
<style scoped>
.scroller {
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 245px);
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
} /* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
} /* Handle */
::-webkit-scrollbar-thumb {
  background: #8b83ba;
  border-radius: 5px;
} /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #686094;
}

/* Buttons */

::-webkit-scrollbar-button:single-button {
  background-color: #686094;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
} /* Up
*/
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
}
::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
} /* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
}
::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.button {
  margin-top: 35px;
}
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}

input {
  display: inline-block;
  width: 50%;
}

.text {
  margin: 20px;
}
</style>
