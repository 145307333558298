<template>
  <div>
    <div class="contact_form">
      <div class="form pt-2" v-if="contactReadOnlyFields.length">
        <v-card class="mx-auto" tile>
          <v-list dense flat>
            <v-list-item-group color="primary" class="orderSection">
              <div
                v-for="section in sectionKeyBy"
                :key="section.section"
                v-bind:style="{
                  order: section.order,
                }"
              >
                <v-list-item v-if="section.display">
                  <v-list-item-icon>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="#6e6893" v-bind="attrs" v-on="on">{{
                          section.icon
                        }}</v-icon>
                      </template>
                      <span>{{ section.iconLabel }}</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 14px; font-family: 'Lato'"
                    >
                      <div
                        v-for="field in section.fields"
                        :key="field.id"
                        v-bind:style="section.direction"
                      >
                        <span v-if="formContact[field.id]" class="mx-1">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                {{ formContact[field.id] | checkEmpty }}
                              </span>
                              {{ section.separator }}
                            </template>
                            <span>{{ field.label }}</span>
                          </v-tooltip>
                        </span>
                        <i
                          class="mx-1"
                          style="color: red; font-size: 15px"
                          v-else
                        >
                          {{ field.label }}</i
                        >
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-item-group>
          </v-list>
        </v-card>
      </div>
      <v-alert v-else text class="mt-3 col-md-12 float-left" type="info">
        <v-row align="center" class="text-center"> No Contact Selected </v-row>
      </v-alert>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {},
  components: {},
  data() {
    return {
      formContact: {
        address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
        previous_address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
      },
      phoneNumber: "",
      directPhoneNumber: "",
      mobileNumber: "",
      sectionKeyBy: {},
    };
  },
  mounted: function () {
    this.contactFormFieldCustomization(this.contactReadOnlyFields);
  },
  computed: {
    contactReadOnlyFields() {
      return this.$store.state.template.contactReadOnlyFields;
    },
  },
  watch: {
    contactReadOnlyFields: function (value) {
      this.contactFormFieldCustomization(value);
    },
  },
  methods: {
    contactFormFieldCustomization: function (contactReadOnly) {
      this.sectionKeyBy = _.keyBy(contactReadOnly, "section");

      for (const key in this.sectionKeyBy) {
        let section = this.sectionKeyBy[key];
        let sectionFields = section.fields;

        let fieldKeyBy = _.keyBy(sectionFields, "id");
        this.sectionKeyBy[key].fields = fieldKeyBy;
      }
    },
  },
};
</script>

<style scoped>
.highlight {
  background: lightyellow;
}

.orderSection {
  display: flex;
  flex-direction: column;
}

.previousTitles {
  font-size: 12px;
  margin-top: 5px;
  text-transform: none;
  position: absolute;
  right: 0;
  bottom: 2px;
}
.addressWrapper .address-container {
  width: 100%;
  height: 45px;
  padding: 8px 0;
  padding-left: 8px;
  border: 1px solid #ccc;
}

.addressWrapper + i {
  right: 20px;
  font-size: 25px;
  position: absolute;
  top: 8px;
}

.addressWrapper {
  width: 100%;
}

.contact_form hr {
  margin: 15px 10px 2px 10px;
  border-color: white;
}
.contact_form {
  padding: 10px;
}
.form h4 {
  font-family: Lato;
  font-size: 12px;
  color: #6e6893;
  font-weight: 400;
  position: relative;
}
.zerobounce-wrapper {
  width: 90%;
  float: right;
  margin-right: 0 !important;
}

.zerobounce-wrapper p {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #00458d;
}
.zerobounce-wrapper p b {
  float: right;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.right {
  float: right;
}
.scroller.minimized {
  height: calc(100vh - 210px);
}
.scroller {
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 10px;
  height: calc(100vh - 330px);
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
}
.v-list-item__title {
  height: 30px !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
} /* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
} /* Handle */
::-webkit-scrollbar-thumb {
  background: #8b83ba;
  border-radius: 10px;
} /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #686094;
}

/* Buttons */

::-webkit-scrollbar-button:single-button {
  background-color: #686094;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
} /* Up
  */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
}
::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
} /* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
}
::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
