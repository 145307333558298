var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact_form"},[(_vm.disableContactFormFields)?_c('div',{staticClass:"text-center float-left col-md-12 pa-0 mt-3"},[_c('v-alert',{staticClass:"py-3 mb-2",attrs:{"color":_vm.contactStatusRibbon,"border":"bottom","elevation":"2","colored-border":""}},[_c('v-list-item-icon',{staticStyle:{"float":"left","margin-top":"0px","margin-right":"10px"}},[(
            _vm.formContact.complianceStatus &&
            _vm.formContact.complianceStatus !== 'Compliant'
          )?_c('v-icon',{attrs:{"color":"red","left":""}},[_vm._v("highlight_off")]):_c('v-icon',{attrs:{"color":"green","left":""}},[_vm._v("check_circle_outline")])],1),_c('v-list-item-title',{staticClass:"text-left"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.formContact.TaskLink)?_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.formContact.TaskLink.disposition)+", ")]):_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.formContact.disposition)+", ")])]}}],null,false,1800664065)},[_c('span',[_c('h4',[_vm._v("Disposition")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.formContact.TaskLink)?_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.formContact.TaskLink.researchStatus)+" ")]):_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.formContact.researchStatus)+" ")])]}}],null,false,3821115713)},[_c('span',[_c('h4',[_vm._v("Research Status")])])]),(_vm.formContact.TaskLink)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.formContact.TaskLink)?_c('span',_vm._g(_vm._b({staticStyle:{"font-size":"14px"}},'span',attrs,false),on),[_vm._v(" , "+_vm._s(_vm._f("momentFormatDate")(_vm._f("markEmpty")(_vm._f("checkEmpty")(_vm.formContact.TaskLink.updatedAt))))+" ")]):_vm._e()]}}],null,false,3531054333)},[_c('span',[_vm._v("Disposed At ")])]):_vm._e(),(_vm.checkUserName)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.formContact.contactCreator.userName)?_c('span',_vm._g(_vm._b({staticStyle:{"font-size":"14px"}},'span',attrs,false),on),[_vm._v(" , "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(_vm.formContact.contactCreator.userName)))+" ")]):_vm._e()]}}],null,false,1535604429)},[_c('span',[_vm._v("Created By ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.contactDuplicateFlag)?_c('v-badge',{staticClass:"float-right",attrs:{"color":"red","inline":"","content":"Duplicate"}}):(_vm.contactSuppressionFlag)?_c('v-badge',{staticClass:"float-right",attrs:{"color":"orange","inline":"","content":"Suppressed"}}):(_vm.contactFuzzySuppressedFlag)?_c('v-badge',{attrs:{"color":"#936827","inline":"","content":"Fuzzzy Suppressed"}}):(_vm.contactReusedFlag)?_c('v-badge',{attrs:{"color":"green","inline":"","content":_vm.formContact.label}}):_vm._e()],1)]}}],null,false,12024214)},[_c('span',[(_vm.contactDuplicateFlag)?_c('h4',[_vm._v("Contact Duplicate")]):(_vm.contactSuppressionFlag)?_c('h4',[_vm._v(" Contact Exact Suppressed ")]):(_vm.contactFuzzySuppressedFlag)?_c('h4',[_vm._v(" Contact Fuzzy Suppressed ")]):(_vm.contactReusedFlag)?_c('h4',[_vm._v("Contact Reused")]):_vm._e()])])],1),(_vm.formContact.complianceStatus)?_c('v-list-item-title',{staticClass:"text-left"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.formContact.complianceStatus)?_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.formContact.complianceStatus)+" ")]):_vm._e()]}}],null,false,2369335046)},[_c('span',[_c('h4',[_vm._v("Compliance Status")])])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.formContact.complianceComments)?_c('span',_vm._g(_vm._b({staticStyle:{"font-size":"14px"}},'span',attrs,false),on),[_vm._v(", "+_vm._s(_vm.formContact.complianceComments)+" ")]):_vm._e()]}}],null,false,4188227774)},[_c('span',[_c('h4',[_vm._v("Compliance Comments")])])])],1):_vm._e()],1)],1):_vm._e(),(_vm.formContact.id)?_c('div',{staticClass:"form pt-3 mt-4 scroller",class:{ minimized: !_vm.formMinimized }},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"border":"1px solid #ddd","border-radius":"4px"},attrs:{"tile":""}},[_c('v-list',{attrs:{"dense":"","flat":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.sectionKeyBy),function(section){return _c('div',{key:section.section},[(section.display)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#6e6893"}},'v-icon',attrs,false),on),[_vm._v(_vm._s(section.icon))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(section.iconLabel))])])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-size":"14px","font-family":"'Lato'"}},_vm._l((section.fields),function(field){return _c('div',{key:field.id,style:(section.direction)},[(_vm.contactDetail[field.id])?_c('span',{staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("checkEmpty")(_vm.contactDetail[field.id]))+" ")]),_vm._v(" "+_vm._s(section.separator)+" ")]}}],null,true)},[_c('span',[_vm._v(_vm._s(field.label))])])],1):_c('i',{staticClass:"mx-1",staticStyle:{"color":"red","font-size":"15px"}},[_vm._v(" "+_vm._s(field.label))])])}),0)],1)],1):_vm._e()],1)}),0)],1)],1)],1):_c('v-alert',{staticClass:"mt-3 col-md-12 float-left",attrs:{"text":"","type":"info"}},[_c('v-row',{staticClass:"text-center",attrs:{"align":"center"}},[_vm._v(" No Contact Selected ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }