var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-row',{staticClass:"mb-2 px-4 counter_stats"},[_c('v-col',{staticClass:"py-0"},[_c('div',[_c('p',{staticClass:"account_title mb-2"},[_vm._v("Contacts")]),_c('p',{staticClass:"account_value"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"default"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.totalContact)+" ")])]}}])},[_c('span',[_c('h4',[_vm._v("Contacts Built")])])]),(_vm.qualifiedContactCount || _vm.qualifiedContactCount === 0)?_c('span',[_vm._v(" / ")]):_vm._e(),(_vm.qualifiedContactCount || _vm.qualifiedContactCount === 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"default"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("humanizeNumber")(_vm.qualifiedContactCount))+" ")])]}}],null,false,2686785668)},[_c('span',[_c('h4',[_vm._v("Qualified Contacts")])])]):_vm._e(),(_vm.potential || _vm.potential === 0)?_c('span',[_vm._v(" / ")]):_vm._e(),(_vm.potential || _vm.potential === 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"default"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("humanizeNumber")(_vm.potential))+" ")])]}}],null,false,1504516857)},[_c('span',[_c('h4',[_vm._v("Potential")])])]):_vm._e()],1)])]),_c('v-col',{staticClass:"py-0"},[_c('p',{staticClass:"account_title mb-2"},[_vm._v("Domain")]),_c('a',{staticClass:"account_value",attrs:{"href":_vm.domainAnchor,"target":"_blank"}},[_vm._v(_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(_vm.domain))))])]),_c('v-col',{staticClass:"py-0"},[_c('div',[_c('p',{staticClass:"account_title mb-2"},[_vm._v("Employee Size")]),_c('p',{staticClass:"account_value"},[_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(_vm.employeeSizeGeneral)))+" ")])])]),_c('v-col',{staticClass:"py-0"},[_c('div',[_c('p',{staticClass:"account_title mb-2"},[_vm._v("Revenue")]),_c('p',{staticClass:"account_value"},[_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(_vm.revenueGeneral)))+" ")])])]),_c('v-col',{staticClass:"py-0"},[_c('div',[_c('div',[_c('p',{staticClass:"account_title mb-2"},[_vm._v("Address")]),_c('p',{staticClass:"account_value"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(_vm.city)))+", "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(_vm.state)))+", "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(_vm.country)))+" ")])])])])]),_c('v-col',{staticClass:"py-0"},[_c('div',[_c('div',[_c('p',{staticClass:"account_title mb-2"},[_vm._v("Phone")]),_c('p',{staticClass:"account_value"},[_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(_vm.account.phoneHQ)))+" ")])])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }