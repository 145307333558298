<template>
  <div>
    <!-- Warning Dialog TO Leave ContactForm -->
    <v-dialog v-model="contactFormWarningDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"> Contact Not Saved </v-card-title>
        <v-card-subtitle class="mt-2"
          >Are you sure you want to go back?</v-card-subtitle
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="contactFormWarningDialog = false"
          >
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="goBack(previousFormContact)"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- account form dialog -->
    <v-dialog
      v-model="accountFormDialog"
      fullscreen
      hide-overlay
      style="z-index: 1051"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <!-- mdi-close -->
        <v-toolbar dark color="primary">
          <v-btn
            style="float: right"
            icon
            dark
            @click="closeAccountFormDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn
              class="float-right mr-8 mb-3"
              color="primary"
              ref="saveAccountBtn"
              :loading="isSavingAccount"
              :disabled="isSavingAccount"
              depressed
              @click="createAccount()"
            >
              Create
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <AccountForm
          fromContactFrom
          :isSaving="isSavingAccount"
          @isSavingUpdate="updateIsSavingAccount"
          ref="AccountFormRef"
        ></AccountForm>
      </v-card>
    </v-dialog>

    <!-- Conatct Form Dialog -->
    <v-dialog
      persistent
      v-model="contactFormDialog"
      fullscreen
      hide-overlay
      style="z-index: 1050"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <!-- mdi-close -->
        <v-toolbar dark color="primary">
          <v-btn
            style="float: right"
            icon
            dark
            @click="displayContactFormEmptyWarningDialog()"
            v-shortkey.once="['ctrl', 'alt', 'x']"
            @shortkey="debounceOnShortKey('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ account.name }}</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- specification -->
            <specification />

            <!-- Save Button -->
            <v-btn
              class="float-right"
              color="primary"
              ref="saveBtn"
              :loading="isSaving"
              :disabled="isSaving"
              depressed
              @click="saveContact()"
              v-shortkey.once="['ctrl', 'alt', '.']"
              @shortkey="debounceOnShortKey('save')"
            >
              Save <v-icon right>save</v-icon>
            </v-btn>

            <!-- Dispose Button -->
            <Dispose
              ref="dispose"
              @disposition="getDispostion($event)"
              style="float: right"
            />
          </v-toolbar-items>
        </v-toolbar>

        <v-list class="gradient_bg">
          <div class="contact_form pa-0">
            <!-- badge -->
            <div class="action_btn ml-2" style="display: block">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-badge
                      color="red"
                      inline
                      content="Duplicate"
                      v-if="contactDuplicateFlag"
                    ></v-badge>

                    <v-badge
                      color="orange"
                      inline
                      content="Contact Exact Suppressed"
                      v-else-if="contactSuppressionFlag"
                    ></v-badge>

                    <v-badge
                      color="#936827"
                      inline
                      content="Contact Fuzzy Suppressed"
                      v-else-if="contactFuzzySuppressedFlag"
                    ></v-badge>

                    <!-- Contact Reused -->
                    <v-badge
                      color="green"
                      inline
                      :content="formContact.label"
                      v-else-if="contactReusedFlag && formContact.label"
                    ></v-badge>
                  </span>
                </template>

                <span>
                  <h4 v-if="contactDuplicateFlag">Contact Duplicate</h4>
                  <h4 v-else-if="contactSuppressionFlag">
                    Contact Exact Suppressed
                  </h4>
                  <h4 v-else-if="contactFuzzySuppressedFlag">
                    Contact Fuzzy Suppressed
                  </h4>
                  <h4 v-else-if="contactReusedFlag">Contact Reused</h4>
                </span>
              </v-tooltip>
            </div>

            <div class="text-center mx-3 mt-3" v-if="disableContactFormFields">
              <v-alert
                color="cyan"
                border="bottom"
                class="mb-2"
                elevation="2"
                colored-border
              >
                <div class="float-left">
                  <v-icon color="green" left>verified</v-icon>
                </div>
                <div class="mr-2 float-left">
                  <strong>Disposition: </strong>
                  <span v-if="formContact.TaskLink"
                    >{{ formContact.TaskLink.disposition }},
                  </span>
                  <span v-else>{{ formContact.disposition }}, </span>
                </div>

                <div class="mr-2 float-left">
                  <strong style="padding-left: 3px">Research Status: </strong>
                  <span v-if="formContact.TaskLink"
                    >{{ formContact.TaskLink.researchStatus }},
                  </span>
                  <span v-else>{{ formContact.researchStatus }}, </span>
                </div>

                <div class="float-left">
                  <strong style="padding-left: 3px">Disposed At: </strong>
                  <span v-if="formContact.TaskLink"
                    >{{
                      formContact.TaskLink.updatedAt
                        | checkEmpty
                        | markEmpty
                        | momentFormatDate
                    }}
                  </span>
                  <span v-else>{{ "" | checkEmpty | markEmpty }} </span>
                </div>
              </v-alert>
            </div>

            <div class="form pt-2" v-bind:class="{ minimized: !formMinimized }">
              <v-form ref="form" v-model="valid" lazy-validation>
                <div class="orderSection">
                  <!-- Name -->
                  <div
                    v-if="sectionKeyBy.Name && sectionKeyBy.Name.display"
                    v-bind:style="{
                      order: sectionKeyBy.Name.order,
                    }"
                    class="orderFields border"
                  >
                    <v-row class="col-md-12 mx-0 pa-0 mb-2">
                      <!-- prefix -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="sectionKeyBy.Name.fields['contact.prefix']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Name.fields['contact.prefix'].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{ sectionKeyBy.Name.fields["contact.prefix"].label
                          }}<span style="color: #ff0000"></span>
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_prefix !== formContact.prefix
                            "
                          >
                            {{ formContact.previous_prefix | checkEmpty }}
                          </span>
                        </h4>
                        <v-combobox
                          class="input_data"
                          v-bind:class="{ highlight: !formContact.prefix }"
                          v-model="formContact.prefix"
                          :items="['Mr', 'Ms']"
                          ref="prefixTxtField"
                          @blur="
                            formatFormFields($event.target.value, 'prefix', '')
                          "
                          :tabindex="
                            sectionKeyBy.Name.fields['contact.prefix'].order
                          "
                          hide-details
                          outlined
                          dense
                          autofocus
                        ></v-combobox>
                      </v-col>

                      <!-- firstName -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="sectionKeyBy.Name.fields['contact.firstName']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Name.fields['contact.firstName'].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Name.fields["contact.firstName"].label
                          }}
                          <span style="color: #ff0000">*</span>
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_firstName !==
                              formContact.firstName
                            "
                          >
                            {{ formContact.previous_firstName | checkEmpty }}
                          </span>
                        </h4>
                        <v-text-field
                          class="input_data"
                          v-bind:class="{ highlight: !formContact.firstName }"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'firstName',
                              'titleCase'
                            )
                          "
                          v-model="formContact.firstName"
                          required
                          hide-details
                          :rules="[
                            (v) =>
                              !!v ||
                              `${sectionKeyBy.Name.fields['contact.firstName'].label} is required`,
                            (v) =>
                              (v && !!v.trim()) ||
                              `${sectionKeyBy.Name.fields['contact.firstName'].label} is required`,
                          ]"
                          @keyup="
                            checkFormIsValid();
                            enableDisableCheckButton();
                          "
                          :tabindex="
                            sectionKeyBy.Name.fields['contact.firstName'].order
                          "
                          dense
                          outlined
                          autofocus
                        ></v-text-field>
                      </v-col>

                      <!-- splitName -->
                      <v-col
                        class="pt-0 mt-2 pb-1 mt-8"
                        cols="12"
                        sm="1"
                        v-if="sectionKeyBy.Name.fields['contact.firstName']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Name.fields['contact.firstName'].order,
                        }"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              depressed
                              v-bind="attrs"
                              v-on="on"
                              color="primary"
                              @click="splitContactName()"
                              :tabindex="
                                sectionKeyBy.Name.fields['contact.firstName']
                                  .order
                              "
                            >
                              <v-icon large>call_split</v-icon>
                            </v-btn>
                          </template>
                          <span>Split Name</span>
                        </v-tooltip>
                      </v-col>

                      <!-- middleName -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="sectionKeyBy.Name.fields['contact.middleName']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Name.fields['contact.middleName']
                              .order,
                        }"
                      >
                        <h4 class="text-uppercase">
                          {{
                            sectionKeyBy.Name.fields["contact.middleName"].label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_middleName !==
                              formContact.middleName
                            "
                          >
                            {{ formContact.previous_middleName | checkEmpty }}
                          </span>
                        </h4>
                        <v-text-field
                          class="input_data"
                          v-bind:class="{ highlight: !formContact.middleName }"
                          v-model="formContact.middleName"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'middleName',
                              'titleCase'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Name.fields['contact.middleName'].order
                          "
                          outlined
                          hide-details
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- lastName -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="sectionKeyBy.Name.fields['contact.lastName']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Name.fields['contact.lastName'].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Name.fields["contact.lastName"].label
                          }}
                          <span style="color: #ff0000">*</span>
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_lastName !==
                              formContact.lastName
                            "
                            >{{ formContact.previous_lastName }}</span
                          >
                        </h4>
                        <v-text-field
                          v-model="formContact.lastName"
                          v-bind:class="{ highlight: !formContact.lastName }"
                          required
                          hide-details
                          :rules="[
                            (v) =>
                              !!v ||
                              `${sectionKeyBy.Name.fields['contact.lastName'].label} is required`,
                            (v) =>
                              (v && !!v.trim()) ||
                              `${sectionKeyBy.Name.fields['contact.lastName'].label} is required`,
                          ]"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'lastName',
                              'titleCase'
                            )
                          "
                          @keyup="
                            checkFormIsValid();
                            enableDisableCheckButton();
                          "
                          :tabindex="
                            sectionKeyBy.Name.fields['contact.lastName'].order
                          "
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- splitName -->
                      <v-col
                        class="pt-0 mt-2 pb-1 mt-8"
                        cols="12"
                        sm="1"
                        v-if="sectionKeyBy.Name.fields['contact.lastName']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Name.fields['contact.lastName'].order +
                            1,
                        }"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              depressed
                              v-bind="attrs"
                              v-on="on"
                              color="primary"
                              @click="searchByContactName()"
                              :tabindex="
                                sectionKeyBy.Name.fields['contact.lastName']
                                  .order + 1
                              "
                            >
                              <v-icon large>manage_search</v-icon>
                            </v-btn>
                          </template>
                          <span>Search in Google</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- Email -->
                  <div
                    v-if="sectionKeyBy.Email && sectionKeyBy.Email.display"
                    v-bind:style="{
                      order: sectionKeyBy.Email.order,
                    }"
                    class="orderFields border"
                  >
                    <v-row class="col-md-12 mx-0 px-0 pb-0">
                      <!-- email -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="sectionKeyBy.Email.fields['contact.email']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Email.fields['contact.email'].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{ sectionKeyBy.Email.fields["contact.email"].label }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_email !== formContact.email
                            "
                            >{{ formContact.previous_email | checkEmpty }}</span
                          >
                        </h4>
                        <v-combobox
                          class="input_data"
                          v-bind:class="{ highlight: !formContact.email }"
                          hide-selected
                          v-model="formContact.email"
                          :items="emailPatterns"
                          item-value="pattern"
                          item-text="item"
                          outlined
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'email',
                              'lowerCase'
                            )
                          "
                          @click="
                            suggestEmail(
                              formContact.firstName,
                              formContact.middleName,
                              formContact.lastName,
                              emailDomain
                            )
                          "
                          @input="emailChanged()"
                          @keyup="verifyEmail($event.target.value)"
                          :tabindex="
                            sectionKeyBy.Email.fields['contact.email'].order
                          "
                          dense
                          hide-details
                        >
                          <template slot="item" slot-scope="{ item }">
                            <v-list-item-avatar
                              :color="
                                item.source === 'master' ? 'teal' : 'orange'
                              "
                              size="45"
                              class="text-h6 font-weight-light white--text"
                            >
                              {{ item.pattern.charAt(0)
                              }}{{ item.pattern.charAt(1) }}
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.pattern"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                >Occurence: {{ item.contacts }} out of
                                {{ item.totalContact }} contacts
                              </v-list-item-subtitle>
                              <v-list-item-subtitle
                                >Confidence: {{ item.occurance }}%
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-combobox>
                        <p
                          v-if="isEmailError"
                          class="mt-2 mb-0"
                          style="color: #ff0000; margin-left: 5px"
                        >
                          {{ sectionKeyBy.Email.fields["contact.email"].label }}
                          is not valid
                        </p>
                      </v-col>

                      <!-- check button -->
                      <v-col
                        class="pt-0 pb-1 mt-8"
                        cols="12"
                        sm="1"
                        v-if="sectionKeyBy.Email.fields['contact.email']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Email.fields['contact.email'].order,
                        }"
                      >
                        <v-btn
                          v-show="visibilityOfCheckButton"
                          :disabled="isCheckBtnDisable"
                          color="primary"
                          depressed
                          block
                          @click="checkZbDupSup()"
                          v-shortkey.once="['ctrl', 'alt', ',']"
                          @shortkey="debounceOnShortKey('check')"
                          :tabindex="
                            sectionKeyBy.Email.fields['contact.email'].order
                          "
                          ref="checkBtn"
                        >
                          check<v-icon right>find_in_page</v-icon>
                        </v-btn>
                        <v-progress-circular
                          v-show="!visibilityOfCheckButton"
                          class="float-right"
                          :size="30"
                          :width="3"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                      </v-col>

                      <!-- zb result -->
                      <v-col
                        class="pt-0 pb-2"
                        cols="12"
                        sm="3"
                        v-if="sectionKeyBy.Email.fields['contact.email']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Email.fields['contact.email'].order,
                        }"
                      >
                        <v-card
                          class="
                            zerobounce-wrapper
                            mt-5
                            mb-1
                            float-left
                            col-md-12
                            pa-0
                          "
                          outlined
                          :tabindex="
                            sectionKeyBy.Email.fields['contact.email'].order
                          "
                        >
                          <v-list-item two-line class="px-3">
                            <v-list-item-content class="pt-2 pb-1">
                              <div class="mb-1">
                                <p>
                                  {{
                                    sectionKeyBy.Email.fields["contact.zb"]
                                      .label
                                  }}
                                  Status:
                                  <b
                                    v-if="formContact.zb === 'valid'"
                                    style="color: #00ae31"
                                    >{{ formContact.zb }}</b
                                  >
                                  <b
                                    v-else-if="formContact.zb === 'catch-all'"
                                    style="color: #daa520"
                                    >{{ formContact.zb }}</b
                                  >
                                  <b v-else style="color: #ff0000">{{
                                    formContact.zb
                                  }}</b>
                                </p>
                              </div>
                              <div class="mb-1">
                                <p>
                                  Last Verified:
                                  <b style="color: #00ae31">{{
                                    formContact.zbDateAndTime
                                      | checkEmpty
                                      | markEmpty
                                      | momentFormatDate
                                  }}</b>
                                </p>
                              </div>
                              <div class="mb-1" v-if="zbResult.subStatus">
                                <p>
                                  {{
                                    sectionKeyBy.Email.fields["contact.zb"]
                                      .label
                                  }}
                                  Sub Status:
                                  <b style="color: #ff0000">{{
                                    zbResult.subStatus
                                  }}</b>
                                </p>
                              </div>
                              <div class="mb-1" v-if="zbResult.internalError">
                                <p>
                                  {{
                                    sectionKeyBy.Email.fields["contact.zb"]
                                      .label
                                  }}
                                  Check Error:
                                  <b style="color: #ff0000">{{
                                    zbResult.internalError
                                  }}</b>
                                </p>
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                      </v-col>

                      <!-- mailTesterStatus -->
                      <v-col
                        class="pt-0 pb-1 mt-2"
                        cols="12"
                        sm="3"
                        v-if="
                          sectionKeyBy.Email.fields['contact.mailTesterStatus']
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Email.fields[
                              'contact.mailTesterStatus'
                            ].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Email.fields[
                              "contact.mailTesterStatus"
                            ].label
                          }}

                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_mailTesterStatus !==
                              formContact.mailTesterStatus
                            "
                          >
                            {{
                              formContact.previous_mailTesterStatus | checkEmpty
                            }}
                          </span>
                        </h4>
                        <v-text-field
                          v-model="formContact.mailTesterStatus"
                          v-bind:class="{
                            highlight: !formContact.mailTesterStatus,
                          }"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'mailTesterStatus',
                              ''
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Email.fields[
                              'contact.mailTesterStatus'
                            ].order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>

                      <!-- gmailStatus -->
                      <v-col
                        class="pt-0 pb-1 mt-2"
                        cols="12"
                        sm="2"
                        v-if="sectionKeyBy.Email.fields['contact.gmailStatus']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Email.fields['contact.gmailStatus']
                              .order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Email.fields["contact.gmailStatus"]
                              .label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_gmailStatus !==
                              formContact.gmailStatus
                            "
                          >
                            {{ formContact.previous_gmailStatus | checkEmpty }}
                          </span>
                        </h4>
                        <v-select
                          v-model="formContact.gmailStatus"
                          v-bind:class="{ highlight: !formContact.gmailStatus }"
                          @blur="
                            formatFormFields(
                              formContact.gmailStatus,
                              'gmailStatus',
                              ''
                            )
                          "
                          :items="['Passed', 'Failed', 'Blocked']"
                          :tabindex="
                            sectionKeyBy.Email.fields['contact.gmailStatus']
                              .order
                          "
                          outlined
                          dense
                          hide-details
                          clearable
                        ></v-select>
                      </v-col>

                      <!-- genericEmail -->
                      <v-col
                        class="pt-0 pb-1 mt-2"
                        cols="12"
                        sm="3"
                        v-if="sectionKeyBy.Email.fields['contact.genericEmail']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Email.fields['contact.genericEmail']
                              .order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Email.fields["contact.genericEmail"]
                              .label
                          }}

                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_genericEmail !==
                              formContact.genericEmail
                            "
                          >
                            {{ formContact.previous_genericEmail | checkEmpty }}
                          </span>
                        </h4>
                        <v-text-field
                          v-model="formContact.genericEmail"
                          v-bind:class="{
                            highlight: !formContact.genericEmail,
                          }"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'genericEmail',
                              ''
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Email.fields['contact.genericEmail']
                              .order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- Job -->
                  <div
                    v-if="sectionKeyBy.Job && sectionKeyBy.Job.display"
                    v-bind:style="{
                      order: sectionKeyBy.Job.order,
                    }"
                    class="orderFields border"
                  >
                    <v-row class="col-md-12 mx-0 px-0 pb-1">
                      <!-- jobTitle -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="sectionKeyBy.Job.fields['contact.jobTitle']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Job.fields['contact.jobTitle'].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Job.fields["contact.jobTitle"].label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_jobTitle !==
                              formContact.jobTitle
                            "
                          >
                            {{ formContact.previous_jobTitle | checkEmpty }}
                          </span>
                        </h4>
                        <v-text-field
                          v-model="formContact.jobTitle"
                          v-bind:class="{ highlight: !formContact.jobTitle }"
                          outlined
                          dense
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'jobTitle',
                              ''
                            )
                          "
                          hide-details
                          @focusout="
                            setJobDataBasedOnJobTitle(formContact.jobTitle);
                            checkExclusionExistance(formContact.jobTitle);
                          "
                          :tabindex="
                            sectionKeyBy.Job.fields['contact.jobTitle'].order
                          "
                        >
                          outlined dense ></v-text-field
                        >

                        <!-- Exclusion Titles Error -->
                        <p
                          style="
                            color: #8b0000;
                            margin-top: 15px;
                            padding-left: 5px;
                            font-weight: bold;
                          "
                        >
                          {{ excludedJobTitlesWarning }}
                        </p>
                      </v-col>

                      <!-- custom1 -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="sectionKeyBy.Job.fields['contact.custom1']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Job.fields['contact.custom1'].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{ sectionKeyBy.Job.fields["contact.custom1"].label }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_custom1 !==
                              formContact.custom1
                            "
                          >
                            {{ formContact.previous_custom1 | checkEmpty }}
                          </span>
                        </h4>
                        <v-text-field
                          v-model="formContact.custom1"
                          v-bind:class="{ highlight: !formContact.custom1 }"
                          outlined
                          dense
                          @blur="
                            formatFormFields($event.target.value, 'custom1', '')
                          "
                          hide-details
                          :tabindex="
                            sectionKeyBy.Job.fields['contact.custom1'].order
                          "
                        >
                          outlined dense ></v-text-field
                        >
                      </v-col>

                      <!-- jobLevel -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="sectionKeyBy.Job.fields['contact.jobLevel']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Job.fields['contact.jobLevel'].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{ sectionKeyBy.Job.fields["contact.jobLevel"].label
                          }}<span
                            class="previousTitles"
                            v-if="
                              formContact.previous_jobLevel !==
                              formContact.jobLevel
                            "
                          >
                            {{ formContact.previous_jobLevel | checkEmpty }}
                          </span>
                        </h4>
                        <v-combobox
                          v-bind:class="{ highlight: !formContact.jobLevel }"
                          v-model="formContact.jobLevel"
                          :items="jobLevels"
                          outlined
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'jobLevel',
                              ''
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Job.fields['contact.jobLevel'].order
                          "
                          dense
                          hide-details
                        ></v-combobox>
                      </v-col>

                      <!-- jobDepartment -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="sectionKeyBy.Job.fields['contact.jobDepartment']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Job.fields['contact.jobDepartment']
                              .order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Job.fields["contact.jobDepartment"]
                              .label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_jobDepartment !==
                              formContact.jobDepartment
                            "
                          >
                            {{
                              formContact.previous_jobDepartment | checkEmpty
                            }}
                          </span>
                        </h4>
                        <v-combobox
                          v-bind:class="{
                            highlight: !formContact.jobDepartment,
                          }"
                          v-model="formContact.jobDepartment"
                          :items="jobDepartments"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'jobDepartment',
                              ''
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Job.fields['contact.jobDepartment']
                              .order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-combobox>
                      </v-col>

                      <!-- functions -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="sectionKeyBy.Job.fields['contact.functions']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Job.fields['contact.functions'].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Job.fields["contact.functions"].label
                          }}
                          <span class="previousTitles">
                            {{ formContact.previous_functions | checkEmpty }}
                          </span>
                        </h4>
                        <v-combobox
                          v-bind:class="{ highlight: !formContact.functions }"
                          v-model="formContact.functions"
                          :items="functions"
                          outlined
                          @focus="fetchJobFunction()"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'functions',
                              ''
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Job.fields['contact.functions'].order
                          "
                          dense
                          hide-details
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- Address -->
                  <div
                    v-if="sectionKeyBy.Address && sectionKeyBy.Address.display"
                    v-bind:style="{
                      order: sectionKeyBy.Address.order,
                    }"
                    class="orderFields border"
                  >
                    <v-row class="col-md-12 mx-0 px-0 pb-0">
                      <!-- Copy HQ Info -->
                      <v-col
                        class="pt-0 pb-1 mt-8"
                        cols="12"
                        sm="2"
                        v-if="
                          sectionKeyBy.Address.fields['contact.address.street1']
                        "
                      >
                        <v-btn
                          depressed
                          block
                          color="primary"
                          @click="
                            copyAccountAddressAndPhoneToContactAddressAndPhone()
                          "
                          :tabindex="
                            sectionKeyBy.Address.fields[
                              'contact.address.street1'
                            ].order
                          "
                        >
                          Copy HQ Info
                          <v-icon right>content_copy</v-icon>
                        </v-btn>
                      </v-col>

                      <!-- Copy Contact Info -->
                      <v-col
                        class="pt-0 pb-1 mt-2"
                        cols="12"
                        sm="3"
                        v-if="
                          sectionKeyBy.Address.fields['contact.address.street1']
                        "
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          Copy Contact Info
                        </h4>
                        <v-autocomplete
                          class="input_data"
                          hide-selected
                          v-model="previousBuildContact"
                          :items="copyContactInfoContactList"
                          :item-value="(contact) => contact"
                          :item-text="(contact) => contact.address.street1"
                          @input="copyBuildContactAddressAndPhone()"
                          :tabIndex="
                            sectionKeyBy.Address.fields[
                              'contact.address.street1'
                            ].order
                          "
                          outlined
                          dense
                          hide-details
                          clearable
                        >
                          <template slot="selection" slot-scope="{ item }">
                            {{ item.address.street1 }}
                          </template>
                          <template slot="item" slot-scope="{ item }">
                            <v-list-item-content>
                              <v-list-item-subtitle>
                                <span
                                  style="font-weight: bold; color: blue"
                                  v-if="item.contactWithSameAddress"
                                  >( {{ item.contactWithSameAddress }} )
                                </span>
                                <span
                                  v-if="item.address && item.address.street1"
                                  >{{ item.address.street1 }}</span
                                >
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                <span
                                  v-if="item.address && item.address.city"
                                  >{{ item.address.city }}</span
                                >
                                <span v-if="item.address && item.address.state"
                                  >,
                                </span>
                                <span
                                  v-if="item.address && item.address.state"
                                  >{{ item.address.state }}</span
                                >
                                <span
                                  v-if="item.address && item.address.zipCode"
                                  >,
                                </span>
                                <span
                                  v-if="item.address && item.address.zipCode"
                                  >{{ item.address.zipCode }}</span
                                >
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                {{ item.phone | formatPhone }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <!-- Address Search -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="4"
                        v-if="
                          sectionKeyBy.Address.fields['contact.address.street1']
                        "
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          Address Search
                        </h4>
                        <v-col
                          class="d-flex pt-0 pb-1 px-0"
                          style="position: relative"
                          cols="12"
                          sm="12"
                        >
                          <addressSearch
                            v-on:placechanged="getGoogleAddress"
                            addressId="contactFormId"
                            addressRef="contactFormRef"
                            :tabIndex="
                              sectionKeyBy.Address.fields[
                                'contact.address.street1'
                              ].order
                            "
                            class="py-0 addressWrapper"
                          />
                          <v-icon> search </v-icon>
                        </v-col>
                      </v-col>

                      <!-- street1 -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="
                          sectionKeyBy.Address.fields['contact.address.street1']
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Address.fields[
                              'contact.address.street1'
                            ].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Address.fields[
                              "contact.address.street1"
                            ].label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_address.street1 !==
                              formContact.address.street1
                            "
                          >
                            {{
                              formContact.previous_address.street1 | checkEmpty
                            }}
                          </span>
                        </h4>
                        <v-text-field
                          v-bind:class="{
                            highlight: !_.get(
                              formContact,
                              'address.street1',
                              null
                            ),
                          }"
                          v-model="formContact.address.street1"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'address',
                              '',
                              'street1'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Address.fields[
                              'contact.address.street1'
                            ].order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>

                      <!-- street2 -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="
                          sectionKeyBy.Address.fields['contact.address.street2']
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Address.fields[
                              'contact.address.street2'
                            ].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Address.fields[
                              "contact.address.street2"
                            ].label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_address.street2 !==
                              formContact.address.street2
                            "
                          >
                            {{
                              formContact.previous_address.street2 | checkEmpty
                            }}
                          </span>
                        </h4>
                        <v-text-field
                          v-bind:class="{
                            highlight: !_.get(
                              formContact,
                              'address.street2',
                              null
                            ),
                          }"
                          v-model="formContact.address.street2"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'address',
                              '',
                              'street2'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Address.fields[
                              'contact.address.street2'
                            ].order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>

                      <!-- city -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="
                          sectionKeyBy.Address.fields['contact.address.city']
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Address.fields['contact.address.city']
                              .order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Address.fields["contact.address.city"]
                              .label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_address.city !==
                              formContact.address.city
                            "
                          >
                            {{ formContact.previous_address.city | checkEmpty }}
                          </span>
                        </h4>
                        <v-text-field
                          v-bind:class="{
                            highlight: !_.get(
                              formContact,
                              'address.city',
                              null
                            ),
                          }"
                          v-model="formContact.address.city"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'address',
                              '',
                              'city'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Address.fields['contact.address.city']
                              .order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>

                      <!-- state -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="2"
                        v-if="
                          sectionKeyBy.Address.fields['contact.address.state']
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Address.fields['contact.address.state']
                              .order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Address.fields["contact.address.state"]
                              .label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_address.state !==
                              formContact.address.state
                            "
                          >
                            {{
                              formContact.previous_address.state | checkEmpty
                            }}
                          </span>
                        </h4>
                        <v-text-field
                          v-bind:class="{
                            highlight: !_.get(
                              formContact,
                              'address.state',
                              null
                            ),
                          }"
                          v-model="formContact.address.state"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'address',
                              '',
                              'state'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Address.fields['contact.address.state']
                              .order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>

                      <!-- country -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="2"
                        v-if="
                          sectionKeyBy.Address.fields['contact.address.country']
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Address.fields[
                              'contact.address.country'
                            ].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Address.fields[
                              "contact.address.country"
                            ].label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_address.country !==
                              formContact.address.country
                            "
                          >
                            {{
                              formContact.previous_address.country | checkEmpty
                            }}
                          </span>
                        </h4>

                        <v-autocomplete
                          v-bind:class="{
                            highlight: !_.get(
                              formContact,
                              'address.country',
                              null
                            ),
                          }"
                          :tabindex="
                            sectionKeyBy.Address.fields[
                              'contact.address.country'
                            ].order
                          "
                          v-model="formContact.address.country"
                          :items="countriesIso"
                          @input="
                            setDialCodeForNumbers(formContact.address.country)
                          "
                          return-object
                          clearable
                          dense
                          outlined
                          hide-details
                        ></v-autocomplete>
                      </v-col>

                      <!-- zipCode -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="2"
                        v-if="
                          sectionKeyBy.Address.fields['contact.address.zipCode']
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Address.fields[
                              'contact.address.zipCode'
                            ].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Address.fields[
                              "contact.address.zipCode"
                            ].label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_address.zipCode !==
                              formContact.address.zipCode
                            "
                          >
                            {{
                              formContact.previous_address.zipCode | checkEmpty
                            }}
                          </span>
                        </h4>
                        <v-text-field
                          v-model="formContact.address.zipCode"
                          v-bind:class="{
                            highlight: !_.get(
                              formContact,
                              'address.zipCode',
                              null
                            ),
                          }"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'address',
                              '',
                              'zipCode'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Address.fields[
                              'contact.address.zipCode'
                            ].order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="
                          sectionKeyBy.Address.fields[
                            'contact.homeAddressStreet1'
                          ]
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Address.fields[
                              'contact.homeAddressStreet1'
                            ].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Address.fields[
                              "contact.homeAddressStreet1"
                            ].label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_homeAddressStreet1 !==
                              formContact.homeAddressStreet1
                            "
                          >
                            {{
                              formContact.previous_homeAddressStreet1
                                | checkEmpty
                            }}
                          </span>
                        </h4>
                        <v-text-field
                          v-bind:class="{
                            highlight: !_.get(
                              formContact,
                              'address.street1',
                              null
                            ),
                          }"
                          v-model="formContact.homeAddressStreet1"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'address',
                              '',
                              'street1'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Address.fields[
                              'contact.homeAddressStreet1'
                            ].order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>

                      <!-- street2 -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="
                          sectionKeyBy.Address.fields[
                            'contact.homeAddressStreet2'
                          ]
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Address.fields[
                              'contact.homeAddressStreet2'
                            ].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Address.fields[
                              "contact.homeAddressStreet2"
                            ].label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_homeAddressStreet2 !==
                              formContact.homeAddressStreet2
                            "
                          >
                            {{
                              formContact.previous_homeAddressStreet2
                                | checkEmpty
                            }}
                          </span>
                        </h4>
                        <v-text-field
                          v-bind:class="{
                            highlight: !_.get(
                              formContact,
                              'homeAddressStreet2',
                              null
                            ),
                          }"
                          v-model="formContact.homeAddressStreet2"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'address',
                              '',
                              'street2'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Address.fields[
                              'contact.homeAddressStreet2'
                            ].order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>

                      <!-- city -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="
                          sectionKeyBy.Address.fields['contact.homeAddressCity']
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Address.fields[
                              'contact.homeAddressCity'
                            ].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Address.fields[
                              "contact.homeAddressCity"
                            ].label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_homeAddressCity !==
                              formContact.homeAddressCity
                            "
                          >
                            {{
                              formContact.previous_homeAddressCity | checkEmpty
                            }}
                          </span>
                        </h4>
                        <v-text-field
                          v-bind:class="{
                            highlight: !_.get(
                              formContact,
                              'homeAddressCity',
                              null
                            ),
                          }"
                          v-model="formContact.homeAddressCity"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'address',
                              '',
                              'city'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Address.fields[
                              'contact.homeAddressCity'
                            ].order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>

                      <!-- state -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="
                          sectionKeyBy.Address.fields[
                            'contact.homeAddressState'
                          ]
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Address.fields[
                              'contact.homeAddressState'
                            ].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Address.fields[
                              "contact.homeAddressState"
                            ].label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_homeAddressState !==
                              formContact.homeAddressState
                            "
                          >
                            {{
                              formContact.previous_homeAddressState | checkEmpty
                            }}
                          </span>
                        </h4>
                        <v-text-field
                          v-bind:class="{
                            highlight: !_.get(
                              formContact,
                              'homeAddressState',
                              null
                            ),
                          }"
                          v-model="formContact.homeAddressState"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'address',
                              '',
                              'state'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Address.fields[
                              'contact.homeAddressState'
                            ].order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>

                      <!-- country -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="
                          sectionKeyBy.Address.fields[
                            'contact.homeAddressCountry'
                          ]
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Address.fields[
                              'contact.homeAddressCountry'
                            ].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Address.fields[
                              "contact.homeAddressCountry"
                            ].label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_homeAddressCountry !==
                              formContact.homeAddressCountry
                            "
                          >
                            {{
                              formContact.previous_homeAddressCountry
                                | checkEmpty
                            }}
                          </span>
                        </h4>

                        <v-autocomplete
                          v-bind:class="{
                            highlight: !_.get(
                              formContact,
                              'homeAddressCountry',
                              null
                            ),
                          }"
                          :tabindex="
                            sectionKeyBy.Address.fields[
                              'contact.homeAddressCountry'
                            ].order
                          "
                          v-model="formContact.homeAddressCountry"
                          :items="countriesIso"
                          @input="
                            setDialCodeForNumbers(
                              formContact.homeAddressCountry
                            )
                          "
                          return-object
                          clearable
                          dense
                          outlined
                          hide-details
                        ></v-autocomplete>
                      </v-col>

                      <!-- zipCode -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-if="
                          sectionKeyBy.Address.fields[
                            'contact.homeAddressZipCode'
                          ]
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Address.fields[
                              'contact.homeAddressZipCode'
                            ].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Address.fields[
                              "contact.homeAddressZipCode"
                            ].label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_homeAddressZipCode !==
                              formContact.homeAddressZipCode
                            "
                          >
                            {{
                              formContact.previous_homeAddressZipCode
                                | checkEmpty
                            }}
                          </span>
                        </h4>
                        <v-text-field
                          v-model="formContact.homeAddressZipCode"
                          v-bind:class="{
                            highlight: !_.get(
                              formContact,
                              'homeAddressZipCode',
                              null
                            ),
                          }"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'address',
                              '',
                              'zipCode'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Address.fields[
                              'contact.homeAddressZipCode'
                            ].order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- Phone -->
                  <div
                    v-if="sectionKeyBy.Phone && sectionKeyBy.Phone.display"
                    v-bind:style="{
                      order: sectionKeyBy.Phone.order,
                    }"
                    style="flex-wrap: wrap"
                    class="orderFields border"
                  >
                    <v-row class="col-md-12 mx-0 px-0 pb-0">
                      <!-- Copy PhoneHQ -->
                      <v-col
                        class="pt-0 pb-1 mt-8"
                        cols="12"
                        sm="2"
                        v-if="sectionKeyBy.Phone.fields['contact.phone']"
                      >
                        <v-btn
                          depressed
                          block
                          color="primary"
                          @click="bindAccountPhoneHQToContactPhoneHQ()"
                          :tabindex="
                            sectionKeyBy.Phone.fields['contact.phone'].order
                          "
                          ref="copyPhoneHQ_ref"
                        >
                          Copy PhoneHQ <v-icon right>content_copy</v-icon>
                        </v-btn>
                      </v-col>

                      <!-- phone -->
                      <v-col
                        v-bind:class="{ highlight: !phoneNumber }"
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="4"
                        v-if="sectionKeyBy.Phone.fields['contact.phone']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Phone.fields['contact.phone'].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{ sectionKeyBy.Phone.fields["contact.phone"].label }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_phone !== formContact.phone
                            "
                          >
                            {{ formContact.previous_phone | checkEmpty }}
                          </span>
                        </h4>

                        <vue-phone-number-input
                          v-model="phoneNumber"
                          @update="validatePhoneNumber($event)"
                          :tabindex="
                            sectionKeyBy.Phone.fields['contact.phone'].order
                          "
                          :error="false"
                          :no-use-browser-locale="true"
                          :fetch-country="false"
                          ref="phone_number_ref"
                          clearable
                          :preferred-countries="preferredCountries"
                        />

                        <p
                          v-if="phoneError"
                          class="mt-2 mb-2 ml-3"
                          style="color: #ff0000; margin-left: 5px"
                        >
                          {{ sectionKeyBy.Phone.fields["contact.phone"].label }}
                          is not valid
                        </p>
                      </v-col>

                      <!-- phoneExtension -->
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="2"
                        v-if="
                          sectionKeyBy.Phone.fields['contact.phoneExtension']
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Phone.fields['contact.phoneExtension']
                              .order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Phone.fields["contact.phoneExtension"]
                              .label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_phoneExtension !==
                              formContact.phoneExtension
                            "
                          >
                            {{
                              formContact.previous_phoneExtension | checkEmpty
                            }}
                          </span>
                        </h4>
                        <v-text-field
                          v-bind:class="{
                            highlight: !formContact.phoneExtension,
                          }"
                          v-model="formContact.phoneExtension"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              'phoneExtension',
                              ''
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Phone.fields['contact.phoneExtension']
                              .order
                          "
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>

                      <!-- directPhoneNumber -->
                      <v-col
                        v-bind:class="{ highlight: !directPhoneNumber }"
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="4"
                        v-if="sectionKeyBy.Phone.fields['contact.directPhone']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Phone.fields['contact.directPhone']
                              .order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Phone.fields["contact.directPhone"]
                              .label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_directPhone !==
                              formContact.directPhone
                            "
                          >
                            {{ formContact.previous_directPhone | checkEmpty }}
                          </span>
                        </h4>
                        <vue-phone-number-input
                          v-model="directPhoneNumber"
                          :error="false"
                          @update="validateDirectPhoneNumber($event)"
                          :tabindex="
                            sectionKeyBy.Phone.fields['contact.directPhone']
                              .order
                          "
                          ref="direct_phone_number_ref"
                          :no-use-browser-locale="true"
                          :fetch-country="false"
                          :preferred-countries="preferredCountries"
                          clearable
                        />
                        <p
                          v-if="directPhoneError"
                          class="mt-2 mb-0"
                          style="color: #ff0000; margin-left: 5px"
                        >
                          {{
                            sectionKeyBy.Phone.fields["contact.directPhone"]
                              .label
                          }}
                          is not valid
                        </p>
                      </v-col>

                      <!-- Mobile -->
                      <v-col
                        v-bind:class="{ highlight: !mobileNumber }"
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="4"
                        v-if="sectionKeyBy.Phone.fields['contact.mobile']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Phone.fields['contact.mobile'].order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Phone.fields["contact.mobile"].label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_mobile !== formContact.mobile
                            "
                          >
                            {{ formContact.previous_mobile | checkEmpty }}
                          </span>
                        </h4>
                        <vue-phone-number-input
                          v-model="mobileNumber"
                          :error="false"
                          @update="
                            validateMobileNumber(
                              $event,
                              'mobileError',
                              'mobile'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Phone.fields['contact.mobile'].order
                          "
                          ref="mobile_number_ref"
                          :no-use-browser-locale="true"
                          :fetch-country="false"
                          :preferred-countries="preferredCountries"
                          clearable
                        />
                        <p
                          v-if="mobileError"
                          class="mt-2 mb-0"
                          style="color: #ff0000; margin-left: 5px"
                        >
                          {{
                            sectionKeyBy.Phone.fields["contact.mobile"].label
                          }}
                          is not valid
                        </p>
                      </v-col>
                      <!-- Mobile 1 -->
                      <v-col
                        v-bind:class="{ highlight: !mobileNumber1 }"
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="4"
                        v-if="
                          sectionKeyBy.Phone.fields['contact.mobileNumber1']
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Phone.fields['contact.mobileNumber1']
                              .order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Phone.fields["contact.mobileNumber1"]
                              .label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_mobileNumber1 !==
                              formContact.mobileNumber1
                            "
                          >
                            {{
                              formContact.previous_mobileNumber1 | checkEmpty
                            }}
                          </span>
                        </h4>
                        <vue-phone-number-input
                          v-model="mobileNumber1"
                          :error="false"
                          @update="
                            validateMobileNumber(
                              $event,
                              'mobile1Error',
                              'mobileNumber1'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Phone.fields['contact.mobileNumber1']
                              .order
                          "
                          ref="mobile_number1_ref"
                          :no-use-browser-locale="true"
                          :fetch-country="false"
                          :preferred-countries="preferredCountries"
                          clearable
                        />
                        <p
                          v-if="mobile1Error"
                          class="mt-2 mb-0"
                          style="color: #ff0000; margin-left: 5px"
                        >
                          {{
                            sectionKeyBy.Phone.fields["contact.mobileNumber1"]
                              .label
                          }}
                          is not valid
                        </p>
                      </v-col>

                      <!-- Mobile 2 -->
                      <v-col
                        v-bind:class="{ highlight: !mobileNumber2 }"
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="4"
                        v-if="
                          sectionKeyBy.Phone.fields['contact.mobileNumber2']
                        "
                        v-bind:style="{
                          order:
                            sectionKeyBy.Phone.fields['contact.mobileNumber2']
                              .order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Phone.fields["contact.mobileNumber2"]
                              .label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_mobileNumber2 !==
                              formContact.mobileNumber2
                            "
                          >
                            {{
                              formContact.previous_mobileNumber2 | checkEmpty
                            }}
                          </span>
                        </h4>
                        <vue-phone-number-input
                          v-model="mobileNumber2"
                          :error="false"
                          @update="
                            validateMobileNumber(
                              $event,
                              'mobile2Error',
                              'mobileNumber2'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Phone.fields['contact.mobileNumber2']
                              .order
                          "
                          ref="mobile_number2_ref"
                          :no-use-browser-locale="true"
                          :fetch-country="false"
                          :preferred-countries="preferredCountries"
                          clearable
                        />
                        <p
                          v-if="mobile2Error"
                          class="mt-2 mb-0"
                          style="color: #ff0000; margin-left: 5px"
                        >
                          {{
                            sectionKeyBy.Phone.fields["contact.mobileNumber2"]
                              .label
                          }}
                          is not valid
                        </p>
                      </v-col>

                      <!-- Home Number -->
                      <v-col
                        v-bind:class="{ highlight: !homeNumber }"
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="4"
                        v-if="sectionKeyBy.Phone.fields['contact.homeNumber']"
                        v-bind:style="{
                          order:
                            sectionKeyBy.Phone.fields['contact.homeNumber']
                              .order,
                        }"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{
                            sectionKeyBy.Phone.fields["contact.homeNumber"]
                              .label
                          }}
                          <span
                            class="previousTitles"
                            v-if="
                              formContact.previous_homeNumber !==
                              formContact.homeNumber
                            "
                          >
                            {{ formContact.previous_homeNumber | checkEmpty }}
                          </span>
                        </h4>
                        <vue-phone-number-input
                          v-model="homeNumber"
                          :error="false"
                          @update="
                            validateMobileNumber(
                              $event,
                              'homeNumberError',
                              'homeNumber'
                            )
                          "
                          :tabindex="
                            sectionKeyBy.Phone.fields['contact.homeNumber']
                              .order
                          "
                          ref="home_number_ref"
                          :no-use-browser-locale="true"
                          :fetch-country="false"
                          :preferred-countries="preferredCountries"
                          clearable
                        />
                        <p
                          v-if="homeNumberError"
                          class="mt-2 mb-0"
                          style="color: #ff0000; margin-left: 5px"
                        >
                          {{
                            sectionKeyBy.Phone.fields["contact.homeNumber"]
                              .label
                          }}
                          is not valid
                        </p>
                      </v-col>
                    </v-row>

                    <!-- sameNumberError -->
                    <v-row class="col-md-12 mx-0 px-0 pb-0">
                      <v-col
                        v-if="phoneNumberSameMsg && isPhoneNumberSame"
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="4"
                      >
                        <p style="color: #ff0000">
                          {{ phoneNumberSameMsg }} cannot be same
                        </p>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- Misc -->
                  <div
                    v-if="sectionKeyBy.Misc && sectionKeyBy.Misc.display"
                    v-bind:style="{
                      order: sectionKeyBy.Misc.order,
                    }"
                    class="border"
                  >
                    <v-row class="col-md-12 mx-0 px-0 pb-0">
                      <v-col
                        class="pt-0 mt-2 pb-1"
                        cols="12"
                        sm="3"
                        v-for="miscField in sectionKeyBy.Misc.fields"
                        :key="miscField.id"
                      >
                        <h4 class="text-uppercase col-md-12 pa-0">
                          {{ miscField.label }}

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                v-if="
                                  [
                                    'source',
                                    'linkedInUrl',
                                    'screenshot',
                                  ].indexOf(miscField.id) > -1
                                "
                                icon
                                color="primary"
                                x-small
                                class="mb-1"
                                @click="openLink(formContact[miscField.id])"
                              >
                                <v-icon>open_in_new</v-icon>
                              </v-btn>
                            </template>
                            <span>Open Link in new tab</span>
                          </v-tooltip>
                          <span
                            class="previousTitles"
                            v-if="
                              formContact[`previous_${miscField.id}`] !==
                              formContact[miscField.id]
                            "
                          >
                            {{
                              formContact[`previous_${miscField.id}`]
                                | checkEmpty
                            }}
                          </span>
                        </h4>
                        <v-text-field
                          class="input_data"
                          v-bind:class="{
                            highlight: !formContact[miscField.id],
                          }"
                          v-model="formContact[miscField.id]"
                          @blur="
                            formatFormFields(
                              $event.target.value,
                              miscField.id,
                              ''
                            )
                          "
                          :tabindex="miscField.order"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-form>
            </div>
          </div>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from "../services/Api";
import _ from "lodash";
import Dispose from "./Dispose.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import validator from "validator";
import addressSearch from "./AddressSearch.vue";
import sanitize from "./sanitizer";
import difference from "./differenceBetweenObject";
import specification from "./specification.vue";
import countryJSON from "../assets/country.json";
import AccountForm from "./AccountForm.vue";

export default {
  props: {
    isNonEditable: {
      type: Boolean,
      default: false,
    },
    formMinimized: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Dispose,
    VuePhoneNumberInput,
    addressSearch,
    specification,
    AccountForm,
  },
  data() {
    return {
      accountFormDialog: false,
      isSavingAccount: false,
      previousBuildContact: null,
      contactFormDialog: false,
      clearForm: false,
      isSaving: false,
      jobLevels: [],
      functions: [],
      emailPatterns: [],
      jobDepartments: [],
      contactDisposition: {},
      valid: false,
      screenshotUrlError: false,
      sourceUrlError: false,
      rules: {
        screenshotUrlLink: (value) => {
          if (value && !validator.isURL(value.trim(), [".+"])) {
            this.screenshotUrlError = true;
            this.checkFormIsValid();
            return "URL is not valid";
          }
          this.screenshotUrlError = false;
          this.checkFormIsValid();
          return true;
        },
        sourceUrlLink: (value) => {
          if (value && !validator.isURL(value.trim(), [".+"])) {
            this.sourceUrlError = true;
            this.checkFormIsValid();
            return "URL is not valid";
          }
          this.sourceUrlError = false;
          this.checkFormIsValid();
          return true;
        },
      },
      phoneError: false,
      directPhoneError: false,
      mobileError: false,
      mobile1Error: false,
      mobile2Error: false,
      homeNumberError: false,
      warningFlag: false,
      isCheckBtnDisable: true,
      isEmailError: false,
      formContact: {
        address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
        previous_address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
      },
      phoneNumber: "",
      directPhoneNumber: "",
      mobileNumber: "",
      mobileNumber1: "",
      mobileNumber2: "",
      homeNumber: "",
      contactFormWarningDialog: false,
      copyContactInfoContactList: [],
      updatedContactList: [],
      previousFormContact: {},
      isPhoneNumberSame: false,
      phoneNumberSameMsg: "",
      sectionKeyBy: {},
      countriesIso: Object.keys(countryJSON),
      countryJSON: countryJSON,
      preferredCountries: ["US", "CA", "GB", "IE", "DE", "FR"],
      previousZBCheckedEmail: "",
      previousZBCheckedEmailResult: {},
      visibilityOfCheckButton: true,
      excludedJobTitles: [],
      processedExcludedJobTitles: [],
      excludedJobTitlesWarning: "",
    };
  },
  mounted: function () {
    this.getExcludedJobTitles();
    this.resetNumber();
    this.fetchJobLevel();
    this.fetchJobDepartment();
    this.checkFormIsValid();
    this.contactFormFieldCustomization(this.contactFields);
  },
  computed: {
    _() {
      return _;
    },
    contactList() {
      return sanitize(this.$store.state.workspace.contacts.docs);
    },
    selectedContact() {
      return this.$store.state.workspace.selectedContact;
    },
    projectId() {
      if (this.$store.state.workspace && this.$store.state.workspace.task) {
        return _.get(this.$store.state.workspace.task, "Project.id", null);
      }
      return null;
    },
    account() {
      return _.get(this.$store.state.workspace, "account", null);
    },
    taskId() {
      return this.$store.state.workspace.task.id;
    },
    contactSuppressionFlag() {
      return this.$store.state.contactSuppressionFlag;
    },
    contactFuzzySuppressedFlag() {
      return this.$store.state.contactFuzzySuppressedFlag;
    },
    contactReusedFlag() {
      return this.$store.state.contactReusedFlag;
    },
    contactDuplicateFlag() {
      return this.$store.state.contactDuplicateFlag;
    },
    zbResult() {
      return this.$store.state.zeroBounce;
    },
    accountAddress() {
      return this.$store.state.workspace.account.addressHQ;
    },
    emailDomain() {
      return this.$store.state.workspace.account.emailDomain;
    },
    disableContactFormFields() {
      if (this.formContact.disposition) {
        return true;
      } else {
        return false;
      }
    },
    accountPhoneHQ() {
      return this.$store.state.workspace.account.phoneHQ;
    },
    contactFields() {
      return this.$store.state.workspace.fieldCustomization.contactFields;
    },
    currentWorkspaceContact() {
      return this.$store.state.currentWorkspaceContact;
    },
  },
  watch: {
    selectedContact: function (value) {
      this.formContact = sanitize(value);
      this.$emit("formContactIdUpdate", this.formContact.id);

      this.checkFormIsValid();
      if (
        this.formContact.stage === "Ready" ||
        this.formContact.stage === "Delivered" ||
        this.formContact.stage === "Compliance"
      ) {
        this.$emit("isNonEditabeUpdate", true);
      } else {
        this.$emit("isNonEditabeUpdate", false);
      }
      if (value.phone) {
        this.phoneNumber = value.phone;
      } else {
        this.phoneNumber = "";
      }
      if (value.directPhone) {
        this.directPhoneNumber = value.directPhone;
      } else {
        this.directPhoneNumber = "";
      }
      if (value.mobile) {
        this.mobileNumber = value.mobile;
      } else {
        this.mobileNumber = "";
      }
      if (value.mobileNumber1) {
        this.mobileNumber1 = value.mobileNumber1;
      } else {
        this.mobileNumber1 = "";
      }
      if (value.mobileNumber2) {
        this.mobileNumber2 = value.mobileNumber2;
      } else {
        this.mobileNumber2 = "";
      }
      if (value.homeNumber) {
        this.homeNumber = value.homeNumber;
      } else {
        this.homeNumber = "";
      }
    },
    contactList: function (value) {
      this.updatedContactList = value;
      let modifiedContacts = _.map(value, (contact) => {
        return _.pick(contact, [
          "firstName",
          "middleName",
          "lastName",
          "address",
          "phone",
          "researchStatus",
          "TaskLink",
        ]);
      });
      _.remove(modifiedContacts, (contact) => {
        return (
          !_.get(contact, "address.street1", null) ||
          _.get(contact, "TaskLink.researchStatus", null) === "QR" ||
          _.get(contact, "researchStatus", null) === "QR"
        );
      });
      let groupedContacts = _.groupBy(modifiedContacts, (contact) =>
        _.get(contact, "address.street1", null)
      );
      this.copyContactInfoContactList = _.uniqBy(
        modifiedContacts,
        "address.street1"
      );
      this.copyContactInfoContactList = _.map(
        this.copyContactInfoContactList,
        (contact) => {
          if (contact.address && contact.address.street1) {
            contact.contactWithSameAddress =
              groupedContacts[contact.address.street1].length;
          }
          return contact;
        }
      );
    },
    contactFields: function (value) {
      this.contactFormFieldCustomization(value);
    },
  },
  methods: {
    closeAccountFormDialog() {
      this.accountFormDialog = false;
    },
    openAccountFormDialog() {
      this.accountFormDialog = true;
    },
    openLink(url) {
      if (!url) return;
      window.open(url, "_blank");
    },
    searchByContactName() {
      let searchValue = [];
      if (this.selectedContact.firstName) {
        searchValue.push(this.selectedContact.firstName);
      }
      if (this.selectedContact.lastName) {
        searchValue.push(this.selectedContact.lastName);
      }
      if (this.account.name) {
        searchValue.push(this.account.name);
      }
      let url =
        `https://www.google.com/search?q=` +
        encodeURIComponent(searchValue.join(" "));
      window.open(url, "_blank");
    },
    getExcludedJobTitles: async function () {
      Api.getExcludedJobTitles(this.projectId)
        .then((response) => {
          this.excludedJobTitles = response.data;
          this.processedExcludedJobTitles = _.map(
            response.data,
            (excludedJobTitle) => {
              // Regex replaces values other than characters (case insensitive), numbers and space with empty string
              excludedJobTitle = _.trim(
                excludedJobTitle.replace(/[^A-Z0-9\s]+/gi, "")
              );
              excludedJobTitle = excludedJobTitle.replace(/\s\s+/g, " ");
              return _.trim(excludedJobTitle).toLowerCase();
            }
          );
        })
        .catch((error) => console.log(error));
    },
    setDialCodeForNumbers(selectedCountry) {
      if (!selectedCountry) return;

      let selectedCountryCode = this.countryJSON[selectedCountry];
      if (!selectedCountryCode) return;

      this.setCountryCode(_.toUpper(selectedCountryCode));
    },
    setCountryCode(selectedCountryCode) {
      if (!selectedCountryCode) {
        return;
      }
      if (this.$refs.phone_number_ref) {
        this.$refs.phone_number_ref.countryCode = selectedCountryCode;
      }
      if (this.$refs.direct_phone_number_ref) {
        this.$refs.direct_phone_number_ref.countryCode = selectedCountryCode;
      }
      if (this.$refs.mobile_number_ref) {
        this.$refs.mobile_number_ref.countryCode = selectedCountryCode;
      }
      if (this.$refs.mobile1_number_ref) {
        this.$refs.mobile1_number_ref.countryCode = selectedCountryCode;
      }
      if (this.$refs.mobile2_number_ref) {
        this.$refs.mobile2_number_ref.countryCode = selectedCountryCode;
      }
      if (this.$refs.home_number_ref) {
        this.$refs.home_number_ref.countryCode = selectedCountryCode;
      }
      this.setFocusToCopyPhoneHQ();
    },
    setFocusToCopyPhoneHQ() {
      this.$refs.copyPhoneHQ_ref.$el.focus();
    },
    resetPhoneComponent: function () {
      //reset countryCode & phoneNumber
      if (this.$refs.phone_number_ref) {
        this.$refs.phone_number_ref.userLocale = null;
        this.$refs.phone_number_ref.emitValues({
          countryCode: null,
          phoneNumber: null,
        });
      }
      if (this.$refs.direct_phone_number_ref) {
        this.$refs.direct_phone_number_ref.userLocale = null;
        this.$refs.direct_phone_number_ref.emitValues({
          countryCode: null,
          phoneNumber: null,
        });
      }
      if (this.$refs.mobile_number_ref) {
        this.$refs.mobile_number_ref.userLocale = null;
        this.$refs.mobile_number_ref.emitValues({
          countryCode: null,
          phoneNumber: null,
        });
      }
      if (this.$refs.mobile1_number_ref) {
        this.$refs.mobile1_number_ref.userLocale = null;
        this.$refs.mobile1_number_ref.emitValues({
          countryCode: null,
          phoneNumber: null,
        });
      }
      if (this.$refs.mobile2_number_ref) {
        this.$refs.mobile2_number_ref.userLocale = null;
        this.$refs.mobile2_number_ref.emitValues({
          countryCode: null,
          phoneNumber: null,
        });
      }
      if (this.$refs.home_number_ref) {
        this.$refs.home_number_ref.userLocale = null;
        this.$refs.home_number_ref.emitValues({
          countryCode: null,
          phoneNumber: null,
        });
      }
    },
    contactFormFieldCustomization: function (contactFormFields) {
      this.sectionKeyBy = _.keyBy(contactFormFields, "section");
      for (const key in this.sectionKeyBy) {
        let section = this.sectionKeyBy[key];
        let sectionFields = section.fields;

        let fieldKeyBy = _.keyBy(sectionFields, "id");
        this.sectionKeyBy[key].fields = fieldKeyBy;
      }
    },
    resetNumber() {
      this.directPhoneNumber = "";
      this.phoneNumber = "";
      this.mobileNumber = "";
      this.mobileNumber1 = "";
      this.mobileNumber2 = "";
      this.homeNumber = "";
    },
    displayContactFormEmptyWarningDialog() {
      let formContact = _.cloneDeep(this.formContact);
      formContact = sanitize(formContact);
      if (!formContact.id) {
        let formContactAddress;
        if (formContact.address) {
          formContactAddress = Object.entries(formContact.address).reduce(
            (a, [k, v]) => (v ? ((a[k] = v), a) : a),
            {}
          );
        }
        let formContactPreviousAddress;
        if (formContact.address) {
          formContactPreviousAddress = Object.entries(
            formContact.previous_address
          ).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});
        }
        if (
          !Object.keys(formContactAddress).length &&
          !Object.keys(formContactPreviousAddress).length
        ) {
          delete formContact.address;
          delete formContact.previous_address;
          formContact = Object.entries(formContact).reduce(
            (a, [k, v]) => (v ? ((a[k] = v), a) : a),
            {}
          );
        }
        if (Object.keys(formContact).length) {
          this.contactFormWarningDialog = true;
        } else {
          this.resetContactForm(true, true);
        }
      } else {
        let previousFormContacts = _.map(
          this.updatedContactList,
          function (contact) {
            if (contact.id === formContact.id) {
              return contact;
            }
          }
        );
        previousFormContacts = _.compact(previousFormContacts);
        this.previousFormContact = previousFormContacts[0];
        this.previousFormContact = sanitize(this.previousFormContact);
        formContact = sanitize(formContact);
        let formContactDiff = difference(formContact, this.previousFormContact);
        if (
          formContactDiff.Account &&
          !Object.keys(formContactDiff.Account).length
        ) {
          delete formContactDiff.Account;
        }
        if (Object.keys(formContactDiff).length) {
          this.contactFormWarningDialog = true;
          return;
        }
        this.resetContactForm(true, false);
      }
    },
    goBack(previousFormContactValue) {
      this.$store.dispatch("fetchSelectedWorspaceContactData", {
        selectContact: previousFormContactValue,
      });
      var _payload = {
        status: previousFormContactValue.zb,
        processed_at: previousFormContactValue.zbDateAndTime,
      };
      this.$store.dispatch("updateZeroBounceResult", _payload);
      this.contactFormWarningDialog = false;
      this.resetContactForm(true, true);
    },
    copyBuildContactAddressAndPhone: function () {
      this.$store.dispatch("setResetGoogleAddressSearchValue", true);
      if (this.previousBuildContact && this.previousBuildContact.address) {
        this.formContact.address.street1 =
          this.previousBuildContact.address.street1 ||
          this.previousBuildContact.address.address1;
        this.formContact.address.street2 =
          this.previousBuildContact.address.street2 ||
          this.previousBuildContact.address.address2;
        this.formContact.address.city = this.previousBuildContact.address.city;
        this.formContact.address.state =
          this.previousBuildContact.address.state;
        this.formContact.address.country =
          this.previousBuildContact.address.country;
        this.formContact.address.zipCode =
          this.previousBuildContact.address.zipCode;

        this.setDialCodeForNumbers(this.formContact.address.country);
      }

      if (this.previousBuildContact.phone) {
        this.phoneNumber = this.previousBuildContact.phone;
        this.formContact.phone = this.previousBuildContact.phone;
      }
    },
    debounceOnShortKey: _.debounce(async function (value) {
      switch (value) {
        case "check":
          if (!this.isCheckBtnDisable) {
            await this.$refs.checkBtn.$el.focus();
            await this.checkZbDupSup();
          }
          break;
        case "save":
          if (!this.isSaving) {
            await this.$refs.saveBtn.$el.focus();
            await this.saveContact();
          }
          break;
        case "createNew":
          await this.resetContactForm(false, true);
          break;
        case "close":
          this.displayContactFormEmptyWarningDialog();
          break;
      }
    }, 1000),
    formatFormFields: async function (
      fieldValue,
      fieldModel,
      operation,
      subFieldModel
    ) {
      if (!fieldValue || !fieldModel) {
        return;
      }
      if (subFieldModel) {
        this.formContact[fieldModel][subFieldModel] = fieldValue.trim();
      } else {
        this.formContact[fieldModel] = fieldValue.trim();
      }
      if (operation === "titleCase" && this.formContact[fieldModel]) {
        this.formContact[fieldModel] = this.formContact[fieldModel]
          .toLowerCase()
          .replace(/\b(\w)/g, (s) => s.toUpperCase());
        await this.$store.dispatch("fetchSelectedWorspaceContactData", {
          selectContact: this.formContact,
        });
        return;
      }
      if (operation === "lowerCase" && this.formContact[fieldModel]) {
        this.formContact[fieldModel] =
          this.formContact[fieldModel].toLowerCase();
        await this.$store.dispatch("fetchSelectedWorspaceContactData", {
          selectContact: this.formContact,
        });
        return;
      }
      await this.$store.dispatch("fetchSelectedWorspaceContactData", {
        selectContact: this.formContact,
      });
      return;
    },
    enableDisableCheckButton: function () {
      if (
        _.trim(this.formContact.firstName) &&
        _.trim(this.formContact.lastName) &&
        _.trim(this.formContact.email) &&
        !this.isEmailError
      ) {
        this.isCheckBtnDisable = false;
        return;
      }
      this.isCheckBtnDisable = true;
      return;
    },
    verifyEmail(value) {
      if (
        this.formContact.email &&
        this.formContact.email.trim() !== value.trim()
      ) {
        this.formContact.zb = "";
        this.formContact.zbDateAndTime = "";
        var _payload = {
          address: "",
          status: "",
          sub_status: "",
          free_email: "",
          processed_at: "",
        };
        this.$store.dispatch("updateZeroBounceResult", _payload);
      }
      this.formContact.email = value;
      this.$store.dispatch("fetchSelectedWorspaceContactData", {
        selectContact: this.formContact,
      });

      if (value && !validator.isEmail(value.trim())) {
        this.isEmailError = true;
        this.isCheckBtnDisable = true;
        this.checkFormIsValid();
        return;
      }
      this.isEmailError = false;
      this.enableDisableCheckButton();
      return;
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    emailChanged: async function () {
      this.formContact.zb = "";
      if (this.formContact.email && this.formContact.email.pattern) {
        this.formContact.email = this.formContact.email.pattern;
      }
      if (
        this.formContact.email &&
        !validator.isEmail(this.formContact.email.trim())
      ) {
        this.isEmailError = true;
        this.isCheckBtnDisable = true;
      } else {
        this.isEmailError = false;
        this.enableDisableCheckButton();
      }
      this.formContact.zbDateAndTime = "";
      this.$store.dispatch("fetchSelectedWorspaceContactData", {
        selectContact: this.formContact,
      });
      var _payload = {
        address: "",
        status: "",
        sub_status: "",
        free_email: "",
        processed_at: "",
      };
      this.$store.dispatch("updateZeroBounceResult", _payload);
    },
    checkZbDupSup: async function () {
      this.visibilityOfCheckButton = false;

      await this.checkDupSup();

      if (this.formContact.label === "suppressed") {
        this.visibilityOfCheckButton = true;
        return;
      }

      setTimeout(() => {
        if (_.get(this.formContact, "researchStatus", "") === "QR") {
          this.$refs.dispose.fetchDispositions(true);
        } else {
          this.$refs.dispose.fetchDispositions();
        }
      }, 0);

      if (this.formContact.email !== this.previousZBCheckedEmail) {
        await this.checkZeroBounce();
      }

      this.formContact.zb = this.previousZBCheckedEmailResult.zbStatus;
      this.formContact.zbDateAndTime =
        this.previousZBCheckedEmailResult.zbDateAndTime;
      this.visibilityOfCheckButton = true;
    },
    checkZeroBounce: async function () {
      try {
        await this.$store.dispatch("checkZeroBounce", {
          taskId: this.taskId,
          emailId: this.formContact.email,
          formContact: this.formContact,
          emailDomain: this.emailDomain,
        });

        this.previousZBCheckedEmail = this.formContact.zb
          ? this.formContact.email
          : "";

        this.previousZBCheckedEmailResult.zbStatus = this.formContact.zb
          ? this.formContact.zb
          : "";

        this.previousZBCheckedEmailResult.zbDateAndTime = this.formContact.zb
          ? this.formContact.zbDateAndTime
          : null;
      } catch (error) {
        console.log(error);
      }
    },
    saveContact: async function () {
      this.isSaving = true;
      var formValid = this.$refs.form.validate();

      if (formValid && this.checkFormIsValid()) {
        if (this.formContact.email === this.previousZBCheckedEmail) {
          this.formContact.zb = this.previousZBCheckedEmailResult.zbStatus;

          this.formContact.zbDateAndTime =
            this.previousZBCheckedEmailResult.zbDateAndTime;
        } else {
          this.formContact.zb = this.zbResult.status;
          this.formContact.zbDateAndTime = this.zbResult.processedAt;
        }
        this.phoneError = false;
        this.directPhoneError = false;
        this.mobileError = false;
        this.mobile1Error = false;
        this.mobile2Error = false;
        this.homeNumberError = false;
        this.isEmailError = false;
        this.screenshotUrlError = false;
        this.sourceUrlError = false;
        this.checkFormIsValid();

        var saveContact = await this.$store.dispatch("saveContact", {
          formContact: this.formContact,
          projectId: this.projectId,
          taskId: this.taskId,
          accountId: this.account.id,
          accountName: this.account.name,
          disposeContact: false,
        });
        this.isSaving = false;
        this.$refs.form.resetValidation();
        return saveContact;
      } else {
        this.isSaving = false;
        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().FORM_VALIDATION_ERROR,
        };
        this.setNotification(notification);
      }
    },
    checkDupSup: async function () {
      var formValid = this.$refs.form.validate();

      if (formValid) {
        await this.$store.dispatch("contactCheck", {
          formContact: this.formContact,
          projectId: this.projectId,
          accountName: this.account.name,
          accountDomain: this.account.domain,
          checkReusable: true,
        });
        return;
      }

      let notification = {
        type: "error",
        showMessage: true,
        message: this.$notifications().FORM_VALIDATION_ERROR,
      };
      this.setNotification(notification);
    },
    resetContactForm: async function (closed, resetPhoneNubers) {
      this.contactFormDialog = true;
      if (closed) {
        this.previousZBCheckedEmail = "";
        this.previousZBCheckedEmailResult = {};
        this.contactFormDialog = false;
        this.excludedJobTitlesWarning = "";
      }
      this.clearForm = true;
      this.previousBuildContact = null;
      var discardContact = {
        address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
        previous_address: {
          street1: "",
          street2: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
      };
      this.phoneError = false;
      this.directPhoneError = false;
      this.mobileError = false;
      this.mobile1Error = false;
      this.mobile2Error = false;
      this.homeNumberError = false;
      this.isEmailError = false;
      this.isCheckBtnDisable = true;
      this.screenshotUrlError = false;
      this.sourceUrlError = false;
      this.phoneNumber = "";
      this.directPhoneNumber = "";
      this.mobileNumber = "";
      this.mobileNumber1 = "";
      this.mobileNumber2 = "";
      this.homeNumber = "";
      // Need better way to clear Form
      var discardRes;
      if (!closed) {
        discardRes = await this.$store.dispatch(
          "fetchSelectedWorspaceContactData",
          {
            selectContact: discardContact,
          }
        );
        await this.$store.dispatch("resetZbResult", {});
        this.$store.dispatch("setContactSuppressionFlag", false);
        this.$store.dispatch("setFuzzyContactSuppressionFlag", false);
        this.$store.dispatch("setContactDuplicateFlag", false);
      }
      this.$store.dispatch("setResetGoogleAddressSearchValue", true);
      this.$store.commit("changeResetContactDisposeFormValue");

      //Reset The country code for vue-phone-number-input
      if (resetPhoneNubers) {
        this.resetPhoneComponent();
      }
      setTimeout(() => {
        this.$refs.dispose.fetchDispositions();
      }, 0);

      return discardRes;
    },
    editSelectedContact() {
      this.enableDisableCheckButton();
      this.checkFormIsValid();

      if (this.formContact.phone) {
        this.phoneNumber = this.formContact.phone;
      } else {
        this.phoneNumber = "";
      }
      if (this.formContact.directPhone) {
        this.directPhoneNumber = this.formContact.directPhone;
      } else {
        this.directPhoneNumber = "";
      }
      if (this.formContact.mobile) {
        this.mobileNumber = this.formContact.mobile;
      } else {
        this.mobileNumber = "";
      }

      if (this.formContact.mobileNumber1) {
        this.mobileNumber1 = this.formContact.mobileNumber1;
      } else {
        this.mobileNumber1 = "";
      }
      if (this.formContact.mobileNumber2) {
        this.mobileNumber2 = this.formContact.mobileNumber2;
      } else {
        this.mobileNumber2 = "";
      }
      if (this.formContact.homeNumber) {
        this.homeNumber = this.formContact.homeNumber;
      } else {
        this.homeNumber = "";
      }

      this.contactFormDialog = true;

      setTimeout(() => {
        if (
          _.get(this.formContact, "researchStatus", "") === "QR" &&
          this.$refs.dispose
        ) {
          this.$refs.dispose.fetchDispositions(true);
        } else {
          this.$refs.dispose.fetchDispositions();
        }
      }, 0);
    },
    fetchJobLevel: function () {
      Api.getJobLevel()
        .then((response) => {
          this.jobLevels = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchJobFunction: function () {
      var departments = [];
      if (this.formContact.jobDepartment) {
        departments.push(this.formContact.jobDepartment);
      }
      Api.getJobFunction(departments)
        .then((response) => {
          this.functions = response.data;
        })
        .catch((error) => console.log(error));
    },
    fetchJobDepartment: function () {
      Api.getJobDepartment()
        .then((response) => {
          this.jobDepartments = response.data;
        })
        .catch((error) => console.log(error));
    },
    checkExclusionExistance: function (jobTitle) {
      if (!_.trim(jobTitle)) {
        this.excludedJobTitlesWarning = "";
        return;
      }
      const unProcessedJobTitle = _.cloneDeep(jobTitle);
      console.log("Job Title (Contact Form): ", unProcessedJobTitle);
      // Regex replaces values other than characters (case insensitive), numbers and space with empty string
      jobTitle = _.trim(jobTitle.replace(/[^A-Z0-9\s]+/gi, ""));
      jobTitle = jobTitle.replace(/\s\s+/g, " ");
      jobTitle = jobTitle.toLowerCase();
      let exclusionExistance = [];
      const totalExlusionTitles = this.processedExcludedJobTitles.length;
      for (var i = 0; i < totalExlusionTitles; i++) {
        const indexOfSubStr = jobTitle.indexOf(
          this.processedExcludedJobTitles[i]
        );
        if (indexOfSubStr > -1) {
          exclusionExistance.push(this.excludedJobTitles[i]);
        }
      }
      if (exclusionExistance.length) {
        this.excludedJobTitlesWarning = `Exclusion Titles Contains: "${_.join(
          exclusionExistance,
          ", "
        )}"`;
        return;
      }
      this.excludedJobTitlesWarning = "";
      return;
    },
    setJobDataBasedOnJobTitle: function (jobTitle) {
      if (!jobTitle) {
        return;
      }
      const me = this;
      Api.getJobTitle(jobTitle)
        .then((response) => {
          if (_.get(me.sectionKeyBy, "Job.fields.['contact.jobLevel']", null))
            me.formContact.jobLevel = response.data.jobLevel;
          if (
            _.get(me.sectionKeyBy, "Job.fields.['contact.jobDepartment']", null)
          )
            me.formContact.jobDepartment = response.data.jobDepartment;
          if (_.get(me.sectionKeyBy, "Job.fields.['contact.functions']", null))
            me.formContact.functions = response.data.jobFunction;
          this.$forceUpdate();
        })
        .catch((error) => console.log(error));
    },
    getDispostion(value) {
      if (value) {
        // RAHUL GUPTA [TO-DO] :: CONTACT REFRESH D(NO LONGER)
        // if (value.researchStatus === "D") {
        //   this.openAccountFormDialog();
        // }
        this.contactDisposition = value;
        this.disposeContact();
      }
    },

    compareContacts(newContact, oldContact) {
      const changedContactData = difference(newContact, oldContact);
      if (_.isEmpty(changedContactData)) {
        return { isContactChanged: false };
      }
      return {
        isContactChanged: true,
        changedContactData,
      };
    },
    disposeContact: async function () {
      var formValid = this.$refs.form.validate();

      if (formValid && this.checkFormIsValid()) {
        if (this.formContact.email === this.previousZBCheckedEmail) {
          this.formContact.zb = this.previousZBCheckedEmailResult.zbStatus;
          this.formContact.zbDateAndTime =
            this.previousZBCheckedEmailResult.zbDateAndTime;
        } else {
          this.formContact.zb = this.zbResult.status;
          this.formContact.zbDateAndTime = this.zbResult.processedAt;
        }
        this.formContact.researchStatus =
          this.contactDisposition.researchStatus;

        this.formContact.disposition =
          this.contactDisposition.contactDisposition;

        this.formContact.comments = this.contactDisposition.comments;

        this.excludedJobTitlesWarning = "";
        this.phoneNumber = "";
        this.directPhoneNumber = "";
        this.mobileNumber = "";
        this.mobileNumber1 = "";
        this.mobileNumber2 = "";
        this.homeNumber = "";
        this.phoneError = false;
        this.directPhoneError = false;
        this.mobileError = false;
        this.mobile1Error = false;
        this.mobile2Error = false;
        this.homeNumberError = false;
        this.isEmailError = false;
        this.screenshotUrlError = false;
        this.sourceUrlError = false;
        this.checkFormIsValid();

        let taskLink = {
          TaskId: this.taskId,
          disposition: this.contactDisposition.contactDisposition,
          comments: this.contactDisposition.comments,
          researchStatus: this.contactDisposition.researchStatus,
        };

        let { changedContactData } = this.compareContacts(
          this.formContact,
          this.currentWorkspaceContact
        );

        if (!changedContactData) {
          changedContactData = {};
        }
        changedContactData["email"] = this.formContact.email;

        if (!_.get(changedContactData, "comments", "").length) {
          delete changedContactData["comments"];
        }

        const accountDomain = _.get(this.account, "website", "");

        var saveContact = await this.$store.dispatch("saveContact", {
          formContact: this.formContact,
          projectId: this.projectId,
          taskId: this.taskId,
          accountId: this.account.id,
          accountName: this.account.name,
          disposeContact: true,
          taskLink: taskLink,
          changedContactData,
          accountDomain,
        });
        this.$store.commit("changeResetContactDisposeFormValue");
        this.$refs.form.resetValidation();
        this.contactFormDialog = false;
        this.previousZBCheckedEmail = "";
        this.previousZBCheckedEmailResult = {};
        if (this.formContact.id) {
          const oldContact = _.find(this.contactList, {
            id: this.formContact.id,
          });
          this.$store.dispatch("fetchSelectedWorspaceContactData", {
            selectContact: oldContact,
          });
        }
        return saveContact;
      }
    },
    validatePhoneNumber(data) {
      this.formContact.phone = data.phoneNumber || "";
      if (!data.phoneNumber) {
        this.phoneError = false;
        this.checkNumberAreSame();
        this.checkFormIsValid();
        return;
      }
      if (data.isValid && data.formattedNumber) {
        this.formContact.phone = data.formattedNumber;
        this.phoneError = false;
        this.checkNumberAreSame();
        this.checkFormIsValid();
        return;
      }
      this.phoneError = true;
      this.checkNumberAreSame();
      this.checkFormIsValid();
      return;
    },
    validateDirectPhoneNumber(data) {
      this.formContact.directPhone = data.phoneNumber || "";
      if (!data.phoneNumber) {
        this.directPhoneError = false;
        this.checkNumberAreSame();
        this.checkFormIsValid();
        return;
      }
      if (data.isValid && data.formattedNumber) {
        this.formContact.directPhone = data.formattedNumber;
        this.directPhoneError = false;
        this.checkNumberAreSame();
        this.checkFormIsValid();
        return;
      }
      this.directPhoneError = true;
      this.checkNumberAreSame();
      this.checkFormIsValid();
      return;
    },
    validateMobileNumber(data, phoneErrorType, phoneType) {
      this.formContact[phoneType] = data.phoneNumber || "";
      if (!phoneErrorType) {
        return;
      }
      if (!data.phoneNumber) {
        this[phoneErrorType] = false;
        this.checkNumberAreSame();
        this.checkFormIsValid();
        return;
      }
      if (data.isValid && data.formattedNumber) {
        this.formContact.mobile = data.formattedNumber;
        this[phoneErrorType] = false;
        this.checkNumberAreSame();
        this.checkFormIsValid();
        return;
      }
      this[phoneErrorType] = true;
      this.checkNumberAreSame();
      this.checkFormIsValid();
      return;
    },
    checkNumberAreSame() {
      //If numbers & equal,show error else don't
      var numbers = [
        {
          label: _.get(
            this.sectionKeyBy,
            'Phone.fields["contact.phone"].label',
            null
          ),
          value: this.formContact.phone,
        },
        {
          label: _.get(
            this.sectionKeyBy,
            'Phone.fields["contact.directPhone"].label',
            null
          ),
          value: this.formContact.directPhone,
        },
        {
          label: _.get(
            this.sectionKeyBy,
            'Phone.fields["contact.mobile"].label',
            null
          ),
          value: this.formContact.mobile,
        },
        {
          label: _.get(
            this.sectionKeyBy,
            'Phone.fields["contact.mobileNumber1"].label',
            null
          ),
          value: this.formContact.mobileNumber1,
        },
        {
          label: _.get(
            this.sectionKeyBy,
            'Phone.fields["contact.mobileNumber2"].label',
            null
          ),
          value: this.formContact.mobileNumber2,
        },
        {
          label: _.get(
            this.sectionKeyBy,
            'Phone.fields["contact.homeNumber"].label',
            null
          ),
          value: this.formContact.homeNumber,
        },
      ];

      _.remove(numbers, (number) => !number.label || !number.value);

      var grouped = _.groupBy(numbers, (number) => number.value);

      var modified = [];
      _.forOwn(grouped, function (value, key) {
        console.log(key);
        if (value.length > 1) {
          modified.push(value);
        }
        return;
      });

      var duplicates = _.map(modified, function (element) {
        return _.map(element, "label").join(", ");
      });

      if (duplicates.length) {
        this.isPhoneNumberSame = true;
        this.phoneNumberSameMsg = `${_.join(duplicates, ", ")}`;
      } else {
        this.isPhoneNumberSame = false;
        this.phoneNumberSameMsg = "";
      }
    },
    copyAccountAddressAndPhoneToContactAddressAndPhone() {
      this.previousBuildContact = null;
      this.formContact.address.street1 = "";
      this.formContact.address.street2 = "";
      this.formContact.address.city = "";
      this.formContact.address.state = "";
      this.formContact.address.country = "";
      this.formContact.address.zipCode = "";
      this.$store.dispatch("setResetGoogleAddressSearchValue", true);
      if (this.accountAddress) {
        if (this.accountAddress.address1HQ) {
          this.formContact.address.street1 = this.accountAddress.address1HQ;
        }
        if (this.accountAddress.address2HQ) {
          this.formContact.address.street2 = this.accountAddress.address2HQ;
        }
        if (this.accountAddress.cityHQ) {
          this.formContact.address.city = this.accountAddress.cityHQ;
        }
        if (this.accountAddress.stateHQ) {
          this.formContact.address.state = this.accountAddress.stateHQ;
        }
        if (this.accountAddress.countryHQ) {
          this.formContact.address.country = this.accountAddress.countryHQ;
        }
        if (this.accountAddress.zipCodeHQ) {
          this.formContact.address.zipCode = this.accountAddress.zipCodeHQ;
        }
        this.setDialCodeForNumbers(this.formContact.address.country);
      }
      this.bindAccountPhoneHQToContactPhoneHQ();
    },
    bindAccountPhoneHQToContactPhoneHQ() {
      this.phoneNumber = this.accountPhoneHQ;
      this.formContact.phone = this.accountPhoneHQ;
    },
    checkFormIsValid() {
      if (
        !this.formContact.firstName ||
        !this.formContact.lastName ||
        (this.formContact.phone && this.phoneError) ||
        (this.formContact.phone && this.isPhoneNumberSame) ||
        (this.formContact.directPhone && this.directPhoneError) ||
        (this.formContact.mobile && this.mobileError) ||
        (this.formContact.mobileNumber1 && this.mobile1Error) ||
        (this.formContact.mobileNumber2 && this.mobile2Error) ||
        (this.formContact.homeNumber && this.homeNumberError) ||
        (this.formContact.email && this.isEmailError) ||
        (this.formContact.screenshot && this.screenshotUrlError) ||
        (this.formContact.source && this.sourceUrlError)
      ) {
        this.$store.dispatch("setIsContactFormValid", false);
        return false;
      }
      this.$store.dispatch("setIsContactFormValid", true);
      return true;
    },
    suggestEmail(firstName, middleName, lastName, domain) {
      this.emailPatterns = [];
      if (!domain || (!firstName && !middleName && !lastName)) {
        return;
      }
      Api.getEmailPatterns(firstName, middleName, lastName, domain)
        .then((response) => {
          this.emailPatterns = response.data;
          console.dir(response.data);
        })
        .catch((error) => console.log(error));
    },
    getGoogleAddress: function (addressData /* , placeResultData, id */) {
      this.formContact.address.street1 = "";
      this.formContact.address.street2 = "";
      this.formContact.address.city = "";
      this.formContact.address.state = "";
      this.formContact.address.country = "";
      this.formContact.address.zipCode = "";
      if (addressData) {
        this.formContact.address.street1 = addressData.streetAddress;
        this.formContact.address.city = addressData.city;
        this.formContact.address.state = addressData.state;
        this.formContact.address.country = addressData.country;
        this.formContact.address.zipCode = addressData.zipCode;
        this.setDialCodeForNumbers(addressData.country);
      }
    },
    splitContactName: function () {
      var contactName = this.formContact.firstName;
      if (!contactName) {
        return;
      }
      var splitContactName = contactName.split(" ");
      var lengthOfSplitContactName = splitContactName.length;
      if (lengthOfSplitContactName > 3 || lengthOfSplitContactName === 1) {
        return;
      }
      this.formContact.firstName = splitContactName[0];
      this.formContact.lastName =
        splitContactName[lengthOfSplitContactName - 1];
      if (lengthOfSplitContactName === 3) {
        this.formContact.middleName = splitContactName[1];
      }
      this.checkFormIsValid();
      this.enableDisableCheckButton();
    },
    createAccount() {
      const AccountFormRef = this.$refs.AccountFormRef;
      if (AccountFormRef) {
        AccountFormRef.createAccount();
      }
    },
    updateIsSavingAccount(value) {
      this.isSavingAccount = value;
      this.closeAccountFormDialog();
    },
  },
};
</script>

<style scoped>
.orderFields {
  display: flex;
  flex-direction: row;
}

.border {
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px !important;
}

.orderSection {
  display: flex;
  flex-direction: column;
}

.v-input.highlight,
.highlight .input-tel__input {
  background: lightyellow;
}

.previousTitles {
  font-size: 12px;
  margin-top: 5px;
  text-transform: none;
  position: absolute;
  right: 0;
  bottom: 2px;
}
.addressWrapper .address-container {
  width: 100%;
  height: 45px;
  padding: 8px 0;
  padding-left: 8px;
  border: 1px solid #ccc;
}

.addressWrapper + i {
  right: 5px;
  font-size: 25px;
  position: absolute;
  top: 8px;
}

.addressWrapper {
  width: 100%;
}

.contact_form hr {
  margin: 15px 10px 2px 10px;
  border-color: white;
}
.contact_form {
  padding: 10px;
}
.form h4 {
  font-family: Lato;
  font-size: 16px;
  color: #6e6893;
  font-weight: 400;
  position: relative;
}
.zerobounce-wrapper {
  width: 90%;
  float: right;
  margin-right: 0 !important;
}

.zerobounce-wrapper p {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #00458d;
}
.zerobounce-wrapper p b {
  float: right;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.right {
  float: right;
}
/* Buttons */

::-webkit-scrollbar-button:single-button {
  background-color: #686094;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
} /* Up
*/
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #555555 transparent;
}
::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777777 transparent;
} /* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 8px 8px 0 8px;
  border-color: #555555 transparent transparent transparent;
}
::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.v-input {
  background: white;
}
</style>
