var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"persistent":"","width":"1000","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"#7c7c7c"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(_vm.contactCheckResultValue))))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.cancel()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-title',{staticStyle:{"display":"inline-flex"}},[_c('div',{staticStyle:{"font-family":"Lato","font-size":"17px"}},[_c('span',[_vm._v(" Matched Type ")]),_vm._v(" : "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.matchType)+" ")])]),_c('div',{staticClass:"pl-4",staticStyle:{"font-family":"Lato","font-size":"17px"}},[_c('span',[_vm._v(" Matched By ")]),_vm._v(" : "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.matchCase)+" ")])])]),_c('v-card-text',{staticClass:"pt-4",staticStyle:{"padding-top":"5px","max-height":"300px"}},[_c('v-simple-table',{attrs:{"fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",staticStyle:{"color":"black"}},[_vm._v("FirstName")]),_c('th',{staticClass:"text-left",staticStyle:{"color":"black"}},[_vm._v("LastName")]),_c('th',{staticClass:"text-left",staticStyle:{"color":"black"}},[_vm._v("Email")]),_c('th',{staticClass:"text-left",staticStyle:{"color":"black"}},[_vm._v("CompanyName")]),_c('th',{staticClass:"text-left",staticStyle:{"color":"black"}},[_vm._v("Phone")])])]),_c('tbody',_vm._l((_vm.data),function(item){return _c('tr',{key:item.name},[_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm._f("markEmpty")(_vm._f("checkEmpty")(item.firstName)),_vm.$constants().COLUMN_LIMIT_15))+" ")])]}}],null,true)},[_c('span',[_c('span',[_vm._v(_vm._s(item.firstName))])])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm._f("markEmpty")(_vm._f("checkEmpty")(item.lastName)),_vm.$constants().COLUMN_LIMIT_15))+" ")])]}}],null,true)},[_c('span',[_c('span',[_vm._v(_vm._s(item.lastName))])])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm._f("markEmpty")(_vm._f("checkEmpty")(item.email)),_vm.$constants().COLUMN_LIMIT20))+" ")])]}}],null,true)},[_c('span',[_c('span',[_vm._v(_vm._s(item.email))])])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm._f("markEmpty")(_vm._f("checkEmpty")(item.companyName)),_vm.$constants().COLUMN_LIMIT_15))+" ")])]}}],null,true)},[_c('span',[_c('span',[_vm._v(_vm._s(item.companyName))])])])],1),_c('td',[_vm._v(" "+_vm._s(_vm._f("markEmpty")(_vm._f("checkEmpty")(item.phone)))+" ")])])}),0)]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }