var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{staticClass:"mt-1",attrs:{"close-on-content-click":false,"nudge-width":800,"max-width":800,"offset-y":"","back":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"10px !important"},attrs:{"color":"#8B83BA","depressed":"","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.loadFilterForm()}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('span',{staticClass:"material-icons"},[_vm._v(" filter_alt ")])])]}}],null,true)},[_c('span',[_vm._v("Filters")])])]}}]),model:{value:(_vm.filtersMenu),callback:function ($$v) {_vm.filtersMenu=$$v},expression:"filtersMenu"}},[_c('v-form',{ref:"GeneralizedFilterForm",staticStyle:{"background":"white"},attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilter()}}},[_c('h3',{staticClass:"filterHeadersTitle pt-3"},[_vm._v("Filter & Search")]),_c('v-list',{staticClass:"pa-4"},[_c('v-row',{staticClass:"filterHeaders mt-0",staticStyle:{"border-bottom":"1px solid #eee"}},[_c('v-col',{staticClass:"py-1"},[_c('span',[_vm._v("field to search")])]),_c('v-col',{staticClass:"py-1"},[_c('span',[_vm._v("type of search")])]),_c('v-col',{staticClass:"py-1"},[_c('span',[_vm._v("search for...")])])],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"item-text":"text","item-value":"value","items":_vm.filterableFields,"label":"Filter By","rules":[_vm.rules.notEmptyRule],"required":""},on:{"change":function($event){return _vm.processOperatorsAndItsValues()}},model:{value:(_vm.filterBy),callback:function ($$v) {_vm.filterBy=$$v},expression:"filterBy"}})],1),_c('v-col',[_c('v-select',{attrs:{"item-text":"text","item-value":"value","disabled":!_vm.isFilterBySelected,"items":_vm.filterableFieldsAllowedOperators,"label":"Operator","rules":[_vm.rules.notEmptyRule],"required":""},on:{"change":function($event){return _vm.processValuesSelection()}},model:{value:(_vm.operator),callback:function ($$v) {_vm.operator=$$v},expression:"operator"}})],1),_c('v-col',[(
              _vm.valueSelectionType == 'string' &&
              _vm.selectedFilterItemText &&
              _vm.selectedFilterItemValue
            )?_c('v-autocomplete',{attrs:{"search-input":_vm.inputSearch,"item-text":_vm.selectedFilterItemText,"item-value":_vm.selectedFilterItemValue,"disabled":!_vm.isFilterBySelected || !_vm.isFilterValuesRequired,"items":_vm.filterableFieldsAllowedValues,"label":"Value","no-filter":"","rules":_vm.isFilterValuesRequired ? [_vm.rules.notEmptyRule] : [],"required":""},on:{"update:searchInput":function($event){_vm.inputSearch=$event},"update:search-input":function($event){_vm.inputSearch=$event}},model:{value:(_vm.filterValueStr),callback:function ($$v) {_vm.filterValueStr=$$v},expression:"filterValueStr"}}):(
              (_vm.valueSelectionType == 'string' ||
                _vm.valueSelectionType == 'number') &&
              !_vm.filterableFieldsAllowedValues.length
            )?_c('v-text-field',{attrs:{"disabled":!_vm.isFilterBySelected || !_vm.isFilterValuesRequired,"type":_vm.valueSelectionType == 'number' ? 'number' : 'text',"label":"Value","rules":_vm.isFilterValuesRequired ? [_vm.rules.notEmptyRule] : [],"required":""},model:{value:(_vm.filterValueStr),callback:function ($$v) {_vm.filterValueStr=$$v},expression:"filterValueStr"}}):_vm._e(),(
              _vm.valueSelectionType == 'string' &&
              _vm.filterableFieldsAllowedValues.length &&
              !_vm.selectedFilterItemText &&
              !_vm.selectedFilterItemValue
            )?_c('v-autocomplete',{attrs:{"disabled":!_vm.isFilterBySelected || !_vm.isFilterValuesRequired,"items":_vm.filterableFieldsAllowedValues,"label":"Value","rules":_vm.isFilterValuesRequired ? [_vm.rules.notEmptyRule] : [],"required":""},model:{value:(_vm.filterValueStr),callback:function ($$v) {_vm.filterValueStr=$$v},expression:"filterValueStr"}}):_vm._e(),(
              _vm.valueSelectionType == 'array' &&
              !_vm.filterableFieldsAllowedValues.length
            )?_c('v-combobox',{attrs:{"disabled":!_vm.isFilterBySelected || !_vm.isFilterValuesRequired,"rules":_vm.isFilterValuesRequired ? [_vm.rules.notEmptyRule] : [],"label":"Value","multiple":"","dense":"","chips":"","deletable-chips":"","required":""},on:{"change":function($event){return _vm.enableApply()}},model:{value:(_vm.filterValueArr),callback:function ($$v) {_vm.filterValueArr=$$v},expression:"filterValueArr"}}):(
              _vm.valueSelectionType == 'array' &&
              _vm.selectedFilterItemText &&
              _vm.selectedFilterItemValue
            )?_c('v-autocomplete',{attrs:{"search-input":_vm.inputSearch,"item-text":_vm.selectedFilterItemText,"item-value":_vm.selectedFilterItemValue,"disabled":!_vm.isFilterBySelected || !_vm.isFilterValuesRequired,"items":_vm.filterableFieldsAllowedValues,"label":"Value","no-filter":"","rules":_vm.isFilterValuesRequired ? [_vm.rules.notEmptyRule] : [],"required":"","multiple":"","dense":"","chips":"","deletable-chips":""},on:{"update:searchInput":function($event){_vm.inputSearch=$event},"update:search-input":function($event){_vm.inputSearch=$event}},model:{value:(_vm.filterValueArr),callback:function ($$v) {_vm.filterValueArr=$$v},expression:"filterValueArr"}}):(_vm.valueSelectionType == 'array')?_c('v-autocomplete',{attrs:{"disabled":!_vm.isFilterBySelected ||
              !_vm.isFilterValuesRequired ||
              _vm.selectedOperatorValue === 'isNull',"items":_vm.filterableFieldsAllowedValues,"clearable":"","dense":"","label":"Value","rules":_vm.filterableFieldsAllowedValues.length &&
              _vm.selectedOperatorValue !== 'isNull'
                ? [_vm.rules.listNotEmptyRule]
                : [],"required":"","chips":"","deletable-chips":"","multiple":""},on:{"change":function($event){return _vm.enableApply()}},model:{value:(_vm.filterValueArr),callback:function ($$v) {_vm.filterValueArr=$$v},expression:"filterValueArr"}}):_vm._e(),(
              _vm.valueSelectionType == 'date' &&
              _vm.selectedOperatorValue != 'between'
            )?_c('v-menu',{attrs:{"disabled":!_vm.isFilterBySelected || !_vm.isFilterValuesRequired,"close-on-content-click":false,"min-width":"290px","nudge-right":"30px","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.isFilterValuesRequired ? [_vm.rules.notEmptyRule] : [],"label":"Date","prepend-icon":"mdi-calendar","readonly":""},on:{"click":function($event){return _vm.emptyFilterValue()}},model:{value:(_vm.filterValueStr),callback:function ($$v) {_vm.filterValueStr=$$v},expression:"filterValueStr"}},'v-text-field',attrs,false),on))]}}],null,false,146192921),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.filterValueStr),callback:function ($$v) {_vm.filterValueStr=$$v},expression:"filterValueStr"}})],1):_vm._e(),(
              _vm.valueSelectionType == 'date' &&
              _vm.selectedOperatorValue == 'between'
            )?_c('v-menu',{attrs:{"disabled":!_vm.isFilterBySelected || !_vm.isFilterValuesRequired,"close-on-content-click":false,"min-width":"290px","nudge-right":"30px","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":_vm.isFilterValuesRequired
                    ? [_vm.rules.notEmptyRule, _vm.rules.validDateRangeRule]
                    : [],"label":"Date","prepend-icon":"mdi-calendar","readonly":""},on:{"click":function($event){return _vm.emptyFilterValue()}},model:{value:(_vm.filterValueStr),callback:function ($$v) {_vm.filterValueStr=$$v},expression:"filterValueStr"}},'v-text-field',attrs,false),on))]}}],null,false,826058709),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.filterValueArr),callback:function ($$v) {_vm.filterValueArr=$$v},expression:"filterValueArr"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.formatDateRangeView()}}},[_vm._v(" OK ")])],1)],1):_vm._e()],1)],1),_c('v-list-item',{staticClass:"d-flex flex-row-reverse"},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ml-2 white--text",attrs:{"color":"#8B83BA","depressed":"","disabled":_vm.applyDisabled,"type":"submit"}},[_vm._v(" Apply ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }