<template>
  <div class="menu">
    <v-card>
      <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        dark
        expand-on-hover
        color="#7C7C7C"
      >
        <v-list-item class="px-2" @click="goToLink(projectLink)">
          <v-list-item-avatar>
            <v-icon>mdi-home-city</v-icon>
          </v-list-item-avatar>

          <v-list-item-title class="text-capitalize"
            >Welcome<br /><b>{{ userName }}</b></v-list-item-title
          >

          <!-- <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn> -->
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            v-bind:class="{ active: currentActive === item.link }"
            link
          >
            <v-list-item-content @click="goToLink(item.link)">
              <v-list-item-title>
                <v-icon>{{ item.icon }}</v-icon>
                <span style="padding: 30px">{{ item.title }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<script>
import { getRoles } from "../roles";

export default {
  name: "Navbar",
  data() {
    return {
      drawer: true,
      projectLink: "/project-list",
      items: [],
      mini: true,
      userName: "",
      currentActive: "",
    };
  },
  mounted: async function () {
    this.items = [];
    this.userName = localStorage.getItem("name");
    this.currentActive = this.$route.path;
    await this.setRole();
  },
  methods: {
    goToLink: function (path) {
      this.currentActive = this.$route.path;
      if (path === "/logout") {
        let logoutDomain =
          location.protocol + "//" + location.host + "/auth/login.html";
        window.auth0Client.logout({
          returnTo: logoutDomain,
          clientId: process.env.VUE_APP_OAUTHCLIENTID,
        });
        localStorage.clear();
        return;
      }
      if (this.$route.path !== path) {
        this.$router
          .push({
            path,
          })
          .catch((e) => e);
      }
      if (this.$route.path === path) {
        this.$router.go(0);
      }
    },
    //
    async setRole() {
      let role = await getRoles();
      if (role.indexOf("agent") > -1) {
        this.projectLink = "/agent-project-list";
        this.items.push({
          title: "Projects",
          icon: "source",
          link: "/agent-project-list",
        });
        this.items.push({
          title: "Agent Work Preview",
          icon: "mdi-account-multiple",
          link: "/agent-preview",
        });
      }
      if (role.indexOf("manager") > -1) {
        this.items.push({
          title: "Projects",
          icon: "source",
          link: "/project-list",
        });
        this.items.push({
          title: "Templates",
          icon: "dashboard_customize",
          link: "/templates",
        });
        this.items.push({
          title: "Agents",
          icon: "mdi-account-multiple",
          link: "/agents",
        });
        this.items.push({
          title: "Clients",
          icon: "contact_phone",
          link: "/client-list",
        });
        this.items.push({
          title: "Suppression Files",
          icon: "article",
          link: "/files",
        });
        this.items.push({
          title: "Master Database",
          icon: "storage",
          link: "/master/account",
        });
      }
      this.items.push({ title: "Logout", icon: "mdi-power", link: "/logout" });
    },
  },
};
</script>

<style scoped>
.menu {
  position: absolute;
  background: linear-gradient(
    to left,
    rgb(153, 153, 153) 0%,
    rgb(42 63 83) 100%
  );
  left: 0px;
  top: 0px;
  bottom: 0;
  z-index: 1000;
}

.menu .v-list-item.active .v-list-item__title .v-icon {
  color: #00458d;
}
.menu .v-list-item.active span {
  font-weight: 800;
}
.menu .v-list-item.active {
  background: white;
  color: #00458d !important;
  border-right: 3px solid #00458d;
}

.menu .theme--dark.v-navigation-drawer {
  background: linear-gradient(
    to left,
    rgb(153, 153, 153) 0%,
    rgb(42 63 83) 100%
  );
}
</style>
