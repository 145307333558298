<template>
  <v-menu
    :max-height="disposeMenuHeight"
    v-model="isDisposeDropdown"
    :close-on-content-click="false"
    :nudge-width="175"
    right
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="ml-4 float-right"
        ref="disposeContactBtn"
        @click="resetDispose()"
        depressed
        color="primary"
        v-bind="attrs"
        v-on="on"
        :disabled="!isContactFormValid"
        v-shortkey.once="['ctrl', 'alt', '/']"
        @shortkey="debounceOnShortKey()"
      >
        Dispose - Contact
        <span class="material-icons"> expand_more </span>
      </v-btn>
    </template>
    <v-card>
      <div v-if="!changeCard">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-list>
            <v-list-item class="pt-4">
              <v-select
                v-model="researchStatus"
                :items="researchStatusList"
                item-text="type"
                :rules="[
                  (v) => !!v || 'Research Status is required',
                  (v) => (v && !!v.trim()) || 'Research Status is required',
                ]"
                @blur="formatFormFields($event.target.value, 'researchStatus')"
                label="Select Research Status"
                @input="selectContactDispositionBasedOnMapping(researchStatus)"
                required
                hide-details
                outlined
                dense
                clearable
                autofocus
              ></v-select>
            </v-list-item>
            <v-list-item class="pt-4" v-if="showWebsite">
              <v-text-field
                label="Enter Website"
                id="website"
                v-model="website"
                :rules="[rules.url]"
                @blur="formatFormFields($event.target.value, 'website')"
                outlined
                dense
              ></v-text-field>
            </v-list-item>
            <v-list-item>
              <v-btn
                style="position: absolute; right: 15px"
                plain
                small
                color="primary"
                @click="toggleMenuSize()"
              >
                <v-icon left v-if="compactMenu"> mdi-chevron-down </v-icon>
                <v-icon left v-else> mdi-chevron-up </v-icon>
                {{ compactMenu ? "Show More" : "Show Less" }}
              </v-btn>
            </v-list-item>
            <v-list-item class="pt-4" v-show="!compactMenu">
              <v-select
                v-model="contactDisposition"
                :items="contactDispositionList"
                item-text="dispositionType"
                label="Select Contact Disposition"
                @blur="
                  formatFormFields($event.target.value, 'contactDisposition')
                "
                outlined
                dense
                clearable
              ></v-select>
            </v-list-item>
            <v-list-item class="pt-4" v-show="!compactMenu">
              <v-textarea
                label="Comments"
                id="comment"
                @blur="formatFormFields($event.target.value, 'comments')"
                v-model="comments"
                outlined
                dense
              >
              </v-textarea>
            </v-list-item>
          </v-list>
          <v-list-item class="d-flex flex-row-reverse">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed @click="submitDisposition()">
                Submit Disposition
              </v-btn>
            </v-card-actions>
          </v-list-item>
        </v-form>
      </div>

      <div v-if="changeCard">
        <v-card-title class="text-h6">
          ZB check is pending! Are you sure you want to dispose?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="zbCheckPendingDisagree()">
            Disagree
          </v-btn>
          <v-btn color="green darken-1" text @click="changeCard = false">
            Agree
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import Api from "../services/Api";
import _ from "lodash";
import validator from "validator";

export default {
  name: "Dispose",
  props: {
    // contactId: String,
  },
  data() {
    return {
      compactMenu: true,
      disposeMenuHeight: 0,
      researchStatusDispositionMapping: {
        QA: "Contact Built",
        QF: "Contact Built",
        Q: "Contact Built",
        Dup: "Duplicate Contact",
        NQ: "Incorrect Contact",
        NF: "Incorrect Contact",
        D: "Incorrect Contact",
        QR: "Contact Built/Reuse",
      },
      changeCard: false,
      isDisposeDropdown: false,
      contactDisposition: "",
      researchStatus: "",
      comments: "",
      // sourceImage: "",
      // sourceLink: "",
      contactDispositionList: [],
      researchStatusList: [],
      valid: false,
      website: "",
      showWebsite: false,
      rules: {
        url: (value) => {
          if (!value) {
            return "website is required";
          }
          if (value && !validator.isURL(value.trim(), [".+"])) {
            return "URL is not valid";
          }
          return true;
        },
        link: (value) => {
          if (value) {
            const url = validator.isURL(value.trim(), [".+"]);
            return url || "URL is not valid";
          } else {
            return true;
          }
        },
      },
    };
  },
  computed: {
    contactSuppressionFlag() {
      return this.$store.state.contactSuppressionFlag;
    },
    zbResult() {
      return this.$store.state.zeroBounce;
    },
    formContact() {
      return this.$store.state.workspace.selectedContact;
    },
    isContactFormValid() {
      return this.$store.state.isContactFormValid;
    },
    inActionDisposeMenu() {
      return this.$store.state.workspace.inActionDisposeMenu;
    },
    resetContactDisposeForm() {
      return this.$store.state.workspace.resetContactDisposeForm;
    },
  },
  watch: {
    // RAHUL GUPTA [TO-DO] :: CONTACT REFRESH D(NO LONGER)
    // researchStatus: function (newDisposition) {
    //   if (newDisposition === "D") {
    //     this.showWebsite = true;
    //   } else {
    //     this.showWebsite = false;
    //   }
    // },
    inActionDisposeMenu: function (value) {
      if (value === "account" || value === "") {
        this.isDisposeDropdown = false;
      } else {
        this.isDisposeDropdown = true;
      }
    },
    resetContactDisposeForm: function () {
      this.researchStatus = "";
      this.contactDisposition = "";
      this.comments = "";
      // this.sourceImage = "";
      // this.sourceLink = "";
    },
  },
  mounted: function () {
    this.disposeMenuHeight = document.body.offsetHeight - 360;
    this.fetchDispositions();
    this.fetchAllDispostions();
  },
  methods: {
    toggleMenuSize: async function () {
      this.compactMenu = !this.compactMenu;
    },
    selectContactDispositionBasedOnMapping: async function (
      selectedResearchStatus
    ) {
      this.contactDisposition =
        this.researchStatusDispositionMapping[selectedResearchStatus] || "";
    },
    zbCheckPendingDisagree() {
      this.isDisposeDropdown = false;
      this.$store.dispatch("setInActionDisposeMenu", "");
    },
    debounceOnShortKey: _.debounce(async function () {
      if (this.isContactFormValid) {
        this.isDisposeDropdown = true;
        await this.$store.dispatch("setInActionDisposeMenu", "contact");
        await this.$refs.disposeContactBtn.$el.focus();
        await this.resetDispose();
      } else {
        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().Dispose_Contact_Shortcut_ERROR,
        };
        this.setNotification(notification);
      }
    }, 1000),
    formatFormFields: async function (fieldValue, fieldModel) {
      if (!fieldValue || !fieldModel) {
        return;
      }
      this[fieldModel] = fieldValue.trim();
      return;
    },
    fetchDispositions(reuseDispositions) {
      this.researchStatusList = this.$constants().RESEARCH_STATUS_LIST;
      if (reuseDispositions) {
        this.researchStatusList = this.$constants().RESEARCH_STATUS_REUSE_LIST;
      }
    },
    setNotification: function (notification) {
      this.$store.commit("publishNotification", notification);
    },
    submitDisposition() {
      let self = this;

      if (!self.contactDisposition) {
        self.contactDisposition =
          this.researchStatusDispositionMapping[self.researchStatus];
      }

      let dispositionPayload = {
        researchStatus: self.researchStatus,
        contactDisposition: self.contactDisposition,
        comments: self.comments,
        // sourceImage: self.sourceImage,
        // sourceLink: self.sourceLink,
      };
      var formValid = this.$refs.form.validate();

      if (formValid && this.isContactFormValid) {
        this.researchStatus = "";
        (this.website = ""), (this.contactDisposition = "");
        this.comments = "";
        // this.sourceImage = "";
        // this.sourceLink = "";
        this.$emit("disposition", dispositionPayload);
        self.isDisposeDropdown = false;
        // this.$store.dispatch("setInActionDisposeMenu", "");
      } else {
        let notification = {
          type: "error",
          showMessage: true,
          message: this.$notifications().FORM_VALIDATION_ERROR,
        };
        this.setNotification(notification);
      }
    },
    fetchAllDispostions: function () {
      Api.getAllDispostions()
        .then((response) => {
          this.contactDispositionList = _.map(
            response.data,
            function (disposition) {
              if (
                disposition.dispositionLevel === "Contact/Account" ||
                disposition.dispositionLevel === "Contact"
              ) {
                return disposition;
              }
            }
          );
          this.contactDispositionList = _.compact(this.contactDispositionList);
        })
        .catch((error) => console.log(error));
    },
    resetDispose: function () {
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }
      });
      this.compactMenu = true;
      if (this.formContact.TaskLink) {
        this.researchStatus =
          this.researchStatus || this.formContact.TaskLink.researchStatus || "";
        this.contactDisposition =
          this.contactDisposition ||
          this.formContact.TaskLink.disposition ||
          "";
        this.comments =
          this.comments || this.formContact.TaskLink.comments || "";
      } else {
        this.researchStatus =
          this.researchStatus || this.formContact.researchStatus || "";
        this.contactDisposition =
          this.contactDisposition || this.formContact.disposition || "";
        this.comments = this.comments || this.formContact.comments || "";
      }
      // this.sourceImage = this.sourceImage || this.formContact.screenshot || "";
      // this.sourceLink = this.sourceLink || this.formContact.source || "";

      if (this.formContact.email) {
        if (
          (this.zbResult.status && this.zbResult.processedAt) ||
          (this.formContact.zb && this.formContact.zbDateAndTime)
        ) {
          this.changeCard = false;
        } else {
          this.changeCard = true;
        }
      } else {
        this.changeCard = false;
      }
    },
  },
};
</script>

<style scoped>
.dispose_actions {
}
</style>
