<template>
  <div style="position: absolute; top: 13px; right: 70px">
    <v-tooltip left v-model="showShortKeyMenu" color="blue-grey darken-4">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="white"
          dark
          v-bind="attrs"
          v-on="on"
          v-shortkey.once="['ctrl', 'alt', 'h']"
          @shortkey="toggleMenu()"
        >
          help_outline
        </v-icon>
      </template>

      <div class="list pt-3">
        <h2 class="mb-2">Keyboard shortcuts</h2>
        <hr />
        <div>
          <div class="list_row">
            <p class="list__item_quote">Create New Contact</p>
            <div class="list__item_key">ctrl</div>
            <div class="list__item_key">alt</div>
            <div class="list__item_key">n</div>
          </div>

          <div class="list_row">
            <p class="list__item_quote">Open Specifications</p>
            <div class="list__item_key">ctrl</div>
            <div class="list__item_key">alt</div>
            <div class="list__item_key">m</div>
          </div>

          <div class="list_row">
            <p class="list__item_quote">Check Contact</p>
            <div class="list__item_key">ctrl</div>
            <div class="list__item_key">alt</div>
            <div class="list__item_key">,</div>
          </div>

          <div class="list_row">
            <p class="list__item_quote">Save Contact</p>
            <div class="list__item_key">ctrl</div>
            <div class="list__item_key">alt</div>
            <div class="list__item_key">.</div>
          </div>

          <div class="list_row">
            <p class="list__item_quote">Dispose Contact</p>
            <div class="list__item_key">ctrl</div>
            <div class="list__item_key">alt</div>
            <div class="list__item_key">/</div>
          </div>

          <div class="list_row">
            <p class="list__item_quote">Edit Account</p>
            <div class="list__item_key">ctrl</div>
            <div class="list__item_key">alt</div>
            <div class="list__item_key">j</div>
          </div>

          <div class="list_row">
            <p class="list__item_quote">Save Account</p>
            <div class="list__item_key">ctrl</div>
            <div class="list__item_key">alt</div>
            <div class="list__item_key">k</div>
          </div>

          <div class="list_row">
            <p class="list__item_quote">Dispose Account</p>
            <div class="list__item_key">ctrl</div>
            <div class="list__item_key">alt</div>
            <div class="list__item_key">l</div>
          </div>

          <div class="list_row">
            <p class="list__item_quote">Close ContactForm/Specifications</p>
            <div class="list__item_key">ctrl</div>
            <div class="list__item_key">alt</div>
            <div class="list__item_key">x</div>
          </div>

          <div class="list_row">
            <p class="list__item_quote">
              Go Back To Contact-Form From Account-Form
            </p>
            <div class="list__item_key">ctrl</div>
            <div class="list__item_key">alt</div>
            <div class="list__item_key">;</div>
          </div>

          <div class="list_row">
            <p class="list__item_quote">Go Back To Task-List</p>
            <div class="list__item_key">ctrl</div>
            <div class="list__item_key">alt</div>
            <div class="list__item_key">\</div>
          </div>

          <div class="list_row">
            <p class="list__item_quote">Open Shortcut Keyboard Menu</p>
            <div class="list__item_key">ctrl</div>
            <div class="list__item_key">alt</div>
            <div class="list__item_key">h</div>
          </div>
        </div>
      </div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showShortKeyMenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.showShortKeyMenu = !this.showShortKeyMenu;
    },
  },
};
</script>

<style scoped>
.list_row {
  display: -webkit-box;
  margin: 10px;
  margin-left: 0px;
  border-bottom: 1px solid #ccc;
}

p {
  width: 70%;
  font-size: 13px;
  line-height: 18px;
  font-weight: 200;
}

.list__item_key {
  border: 1px solid #ccc;
  background: #f3f3f3;
  line-height: 1;
  font-weight: bold;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  margin: 5px;
}

.list__item_quote {
  margin-left: 14px;
  margin: 5px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
  font-family: "Lato";
}
</style>
