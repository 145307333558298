<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="1000" scrollable>
      <v-card>
        <v-toolbar dark color="#7c7c7c">
          <v-toolbar-title>{{
            contactCheckResultValue | checkEmpty | markEmpty
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="cancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-title style="display: inline-flex">
          <div style="font-family: Lato; font-size: 17px">
            <span> Matched Type </span>
            :
            <span style="color: red">{{ matchType }} </span>
          </div>

          <div class="pl-4" style="font-family: Lato; font-size: 17px">
            <span> Matched By </span> :
            <span style="color: red"> {{ matchCase }} </span>
          </div>
        </v-card-title>

        <v-card-text style="padding-top: 5px; max-height: 300px" class="pt-4">
          <v-simple-table fixed-header dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" style="color: black">FirstName</th>
                  <th class="text-left" style="color: black">LastName</th>
                  <th class="text-left" style="color: black">Email</th>
                  <th class="text-left" style="color: black">CompanyName</th>
                  <th class="text-left" style="color: black">Phone</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in data" :key="item.name">
                  <!-- firstName -->
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{
                            item.firstName
                              | checkEmpty
                              | markEmpty
                              | truncate($constants().COLUMN_LIMIT_15)
                          }}
                        </span>
                      </template>
                      <span
                        ><span>{{ item.firstName }}</span></span
                      >
                    </v-tooltip>
                  </td>

                  <!-- lastName -->
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{
                            item.lastName
                              | checkEmpty
                              | markEmpty
                              | truncate($constants().COLUMN_LIMIT_15)
                          }}
                        </span>
                      </template>
                      <span
                        ><span>{{ item.lastName }}</span></span
                      >
                    </v-tooltip>
                  </td>

                  <!-- email -->
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{
                            item.email
                              | checkEmpty
                              | markEmpty
                              | truncate($constants().COLUMN_LIMIT20)
                          }}
                        </span>
                      </template>
                      <span
                        ><span>{{ item.email }}</span></span
                      >
                    </v-tooltip>
                  </td>

                  <!-- companyName -->
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{
                            item.companyName
                              | checkEmpty
                              | markEmpty
                              | truncate($constants().COLUMN_LIMIT_15)
                          }}
                        </span>
                      </template>
                      <span
                        ><span>{{ item.companyName }}</span></span
                      >
                    </v-tooltip>
                  </td>

                  <!-- phone -->
                  <td>
                    {{ item.phone | checkEmpty | markEmpty }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "ContactCheck",
  data() {
    return {
      dialog: false,
      contactCheckResultValue: "",
      matchCase: "",
      matchedObject: {
        companyName: "",
        directPhone: "",
        email: "",
        firstName: "",
        middleName: "",
        lastName: "",
        fullName: "",
        phone: "",
        id: "",
      },
      matchType: "",
      data: [],
    };
  },
  computed: {
    contactCheckResult: function () {
      return this.$store.state.workspace.contactCheckResult;
    },
  },
  watch: {
    contactCheckResult: function (value) {
      if (value) {
        switch (value.matchType) {
          case "Exact Suppressed":
            this.contactCheckResultValue =
              "Contact Matched Against Suppression";
            this.matchCase = value.matchCase;
            this.matchType = "Exact";
            this.matchedObject.companyName = value.matchWith
              ? value.matchWith.companyName
              : "";
            this.matchedObject.directPhone = value.matchWith
              ? value.matchWith.directPhone
              : "";
            this.matchedObject.email = value.matchWith
              ? value.matchWith.email
              : "";
            this.matchedObject.firstName = value.matchWith
              ? value.matchWith.firstName
              : "";
            this.matchedObject.middleName = value.matchWith
              ? value.matchWith.middleName
              : "";
            this.matchedObject.lastName = value.matchWith
              ? value.matchWith.lastName
              : "";
            this.matchedObject.fullName = value.matchWith
              ? _.join(
                  [
                    value.matchWith.firstName,
                    value.matchWith.middleName,
                    value.matchWith.lastName,
                  ],
                  " "
                ).trim()
              : "";
            this.matchedObject.phone = value.matchWith
              ? value.matchWith.phone
              : "";
            this.matchedObject.id = value.matchWith ? value.matchWith.id : "";
            this.data = [];
            this.data.push(this.matchedObject);
            this.dialog = true;
            break;
          case "duplicate":
            this.contactCheckResultValue = "Duplicate Contact";
            this.matchType = "Exact";

            this.matchCase = value.matchCase;
            this.matchedObject.companyName =
              value.matchWith && value.matchWith.Account
                ? value.matchWith.Account.CompanyName
                : "";
            this.matchedObject.directPhone = value.matchWith
              ? value.matchWith.directPhone
              : "";
            this.matchedObject.email = value.matchWith
              ? value.matchWith.email
              : "";
            this.matchedObject.firstName = value.matchWith
              ? value.matchWith.firstName
              : "";
            this.matchedObject.middleName = value.matchWith
              ? value.matchWith.middleName
              : "";
            this.matchedObject.lastName = value.matchWith
              ? value.matchWith.lastName
              : "";
            this.matchedObject.fullName = value.matchWith
              ? _.join(
                  [
                    value.matchWith.firstName,
                    value.matchWith.middleName,
                    value.matchWith.lastName,
                  ],
                  " "
                ).trim()
              : "";
            this.matchedObject.phone = value.matchWith
              ? value.matchWith.phone
              : "";
            this.matchedObject.id = value.matchWith ? value.matchWith.id : "";
            this.data = [];
            this.data.push(this.matchedObject);
            this.dialog = true;
            break;
          case "Fuzzy Suppressed":
            this.contactCheckResultValue =
              "Fuzzy Contact Matched Against Suppression";
            this.matchCase = value.matchCase;
            this.matchType = "Fuzzy";

            this.data = [];
            for (let index = 0; index < value.matchWith.length; index += 1) {
              this.matchedObject = {};
              this.matchedObject.firstName = value.matchWith[index]
                ? value.matchWith[index].firstName
                : "";
              this.matchedObject.middleName = value.matchWith[index]
                ? value.matchWith[index].middleName
                : "";
              this.matchedObject.lastName = value.matchWith[index]
                ? value.matchWith[index].lastName
                : "";
              this.matchedObject.email = value.matchWith[index]
                ? value.matchWith[index].email
                : "";
              this.matchedObject.companyName = value.matchWith[index]
                ? value.matchWith[index].companyName
                : "";
              this.matchedObject.phone = value.matchWith[index]
                ? value.matchWith[index].phone
                : "";

              this.data.push(this.matchedObject);
            }
            this.dialog = true;
            break;
        }
      }
    },
  },
  methods: {
    cancel: function () {
      this.dialog = false;
      this.data = [];
      this.$store.dispatch("populateContactCheckResult", {});
    },
  },
};
</script>

<style scoped></style>
