module.exports = [
  {
    label: "Contact ID",
    id: "contact.id",
  },
  {
    label: "Location ID",
    id: "contact.LocationId",
  },
  {
    label: "Account Domain",
    id: "contact.AccountDomain",
  },
  {
    label: "First Name",
    id: "contact.firstName",
  },
  {
    label: "Middle Name",
    id: "contact.middleName",
  },
  {
    label: "Last Name",
    id: "contact.lastName",
  },
  {
    label: "Job Title",
    id: "contact.jobTitle",
  },
  {
    label: "Job Level",
    id: "contact.jobLevel",
  },
  {
    label: "Job Department",
    id: "contact.jobDepartment",
  },
  {
    label: "Work Email",
    id: "contact.workEmail",
  },
  {
    label: "ZB Status",
    id: "contact.zbStatus",
  },
  {
    label: "ZB Date",
    id: "contact.zbDate",
  },
  {
    label: "Domain Status",
    id: "contact.domainStatus",
  },
  {
    label: "Domain Date",
    id: "contact.domainDate",
  },
  {
    label: "Personal Email",
    id: "contact.personalEmail",
  },
  {
    label: "Personal Email Validation Status",
    id: "contact.personalEmailStatus",
  },
  {
    label: "Email Tags",
    id: "contact.emailTags",
  },
  {
    label: "Email Open",
    id: "contact.emailOpen",
  },
  {
    label: "Email Click",
    id: "contact.emailClick",
  },
  {
    label: "Direct Dial",
    id: "contact.directDial",
  },
  {
    label: "Mobile Number 1",
    id: "contact.mobileNumber1",
  },
  {
    label: "Mobile Number 2",
    id: "contact.mobileNumber2",
  },
  {
    label: "Home Number",
    id: "contact.homeNumber",
  },
  {
    label: "Linkedin Handle",
    id: "contact.linkedinHandle",
  },
  {
    label: "Facebook Handle",
    id: "contact.facebookHandle",
  },
  {
    label: "Twitter Handle",
    id: "contact.twitterHandle",
  },
  {
    label: "Contact Disposition",
    id: "contact.disposition",
  },
  {
    label: "Home Address Street 1",
    id: "contact.homeAddressStreet1",
  },
  {
    label: "Home Address Street 2",
    id: "contact.homeAddressStreet2",
  },
  {
    label: "Home Address City",
    id: "contact.homeAddressCity",
  },
  {
    label: "Home Address State",
    id: "contact.homeAddressState",
  },
  {
    label: "Home Address ZipCode",
    id: "contact.homeAddressZipCode",
  },
  {
    label: "Home Address Country",
    id: "contact.homeAddressCountry",
  },
  {
    label: "Other Source Link  ",
    id: "contact.otherSourceLink",
  },
  {
    id: "location.type",
    label: "Location Type",
  },
  {
    id: "location.address1",
    label: "Address 1",
  },
  {
    id: "location.address2",
    label: "Address 2",
  },
  {
    id: "location.city",
    label: "City",
  },
  {
    id: "location.state",
    label: "State",
  },
  {
    id: "location.stateAbbreviation",
    label: "State Abbreviation",
  },
  {
    id: "location.zipCode",
    label: "Zip Code",
  },
  {
    id: "location.country",
    label: "Country",
  },
  {
    id: "location.phone1",
    label: "Phone 1",
  },
  {
    id: "location.phone2",
    label: "Phone 2",
  },
];
